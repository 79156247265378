import { modalAction } from '@/utils/modal';
import { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';

interface OnClickCloseModalHOCProps {
  children: ReactNode;
}

export const OnClickCloseModalHOC: FC<OnClickCloseModalHOCProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { closeModal } = modalAction(dispatch);
  return <span onClick={closeModal}>{children}</span>;
};
