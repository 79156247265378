import Link from '@/components/UI/Link';
import { _first, _notNil } from '@/littledash';
import useStepForm from '@/support/Hooks/form/useStepForm';
import { DateUtils } from '@/utils/Date.utils';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import type { FC } from 'react';
import { Fragment, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { RiArrowRightSLine } from 'react-icons/ri';
import type {
  AddTaskProps,
  iStep,
  OptionalStepFormPayloadProps,
  StepFormPayloadProps,
  StepRenderProps,
} from './AddTask.model';
import { AddTaskFormTypes } from './AddTask.model';
import styles from './AddTask.module.scss';
import { handleAddTaskStepFormPayload } from './AddTask.utils';
import AddTaskAssigneesForm from './Steps/AddTaskAssigneesForm';
import AddTaskOverviewForm, { taskTypeOptions } from './Steps/AddTaskOverviewForm';
import AddTaskScheduleForm from './Steps/AddTaskScheduleForm/AddTaskScheduleForm';
import AddTaskTargetForm from './Steps/AddTaskTargetForm';

/**
 * A strictly linear stepform used to create a task spec either as part
 * of a study create form or in an existing study
 */

export const steps: iStep[] = [
  {
    name: AddTaskFormTypes.overview,
    title: 'Overview',
    render: (props: StepRenderProps) => <AddTaskOverviewForm {...props} />,
  },
  {
    name: AddTaskFormTypes.assignees,
    title: 'Assignees',
    render: (props: StepRenderProps) => <AddTaskAssigneesForm {...props} />,
  },
  {
    name: AddTaskFormTypes.schedule,
    title: 'Schedule',
    render: (props: StepRenderProps) => <AddTaskScheduleForm {...props} />,
  },
  {
    name: AddTaskFormTypes.target,
    title: 'Target',
    render: (props: StepRenderProps) => <AddTaskTargetForm {...props} />,
  },
];

const AddTask: FC<AddTaskProps> = ({
  closeModal,
  studyId,
  studyApiId,
  studyStartedOn,
  studyCreationUsers,
  teamUsers,
  studyCreationGroups,
  handleSubmit,
}) => {
  const initialValues: StepFormPayloadProps = useMemo(
    () => ({
      [AddTaskFormTypes.overview]: {
        title: '',
        type: _first(taskTypeOptions).value,
        description: '',
      },
      [AddTaskFormTypes.assignees]: [],
      [AddTaskFormTypes.schedule]: {
        type: 'one_off',
        start: _notNil(studyStartedOn) && DateUtils.isValidDate(studyStartedOn) ? studyStartedOn : DateUtils.dateNow(),
        end: '',
      },
      target: {
        type: 'animal',
      },
    }),
    []
  );
  const [stepFormState, setStepFormState] = useState<StepFormPayloadProps>(initialValues);
  const formMethods = useForm({ defaultValues: initialValues });
  const { reset: resetForm } = formMethods;
  const { step: currentStep, nextStep, backStep, hasBack, hasNext } = useStepForm({ totalSteps: steps.length });

  const handleNextStep = (stepFormData: OptionalStepFormPayloadProps): void => {
    const updatedStepFormPayload: StepFormPayloadProps = { ...stepFormState, ...stepFormData };
    setStepFormState(updatedStepFormPayload);
    if (hasNext) {
      // On each step reset the defaultValues cache with the updated form state
      resetForm(updatedStepFormPayload);
      nextStep();
    } else {
      if (_notNil(handleSubmit)) {
        handleSubmit(handleAddTaskStepFormPayload(updatedStepFormPayload));
        closeModal();
      }
    }
  };

  const handleBackStep = () => {
    if (hasBack) {
      backStep();
    } else {
      closeModal();
    }
  };

  return (
    <ModalContainer size="medium" testKey="add-task-v2-modal">
      <ModalHeader
        title="Create a task"
        subText={
          <>
            Enter the details of the task you want to create.{' '}
            <Link
              to="https://help.benchling.com/hc/en-us/articles/22039063448077-Adding-and-amending-Tasks"
              openTab
              className="dib link blue"
            >
              Read more
            </Link>
          </>
        }
        subTextClasses="f6 lh-copy mt1 measure-wide"
        closeModal={closeModal}
      />
      <AddTaskBreadCrumbs currentStep={currentStep} />
      <FormProvider {...formMethods}>
        <div className={`${styles['step-container']} h-100`}>
          {steps[currentStep].render({
            onSubmit: handleNextStep,
            onCancel: handleBackStep,
            submitButtonText: hasNext ? 'Next' : 'Submit',
            cancelButtonText: hasBack ? 'Back' : 'Cancel',
            /* @Todo: remove `studyId` when Groups API is in Java */
            studyId,
            studyApiId,
            studyCreationUsers,
            teamUsers,
            studyCreationGroups,
          })}
        </div>
      </FormProvider>
    </ModalContainer>
  );
};

export const AddTaskBreadCrumbs: FC<{ currentStep: number }> = ({ currentStep }) => (
  <div className="flex justify-between items-center bg-near-white br2 pa2 ma3">
    {steps.map((step, index) => (
      <Fragment key={step.name}>
        <h3
          className={`${currentStep === index ? 'near-black fw5' : 'dark-gray'} pa2 f6 lh-title`}
          data-testid="breadcrumb-title"
        >
          {step.title}
        </h3>
        {steps.length > index + 1 && (
          <span data-testid="breadcrumb-next-arr">
            <RiArrowRightSLine size={20} />
          </span>
        )}
      </Fragment>
    ))}
  </div>
);

export default AddTask;
