import { _notNil } from '@/littledash';
import { modalAction } from '@/utils/modal';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './TimedDeleteConfirmation.module.scss';

interface TimedDeleteConfirmationProps {
  title: string;
  text?: string;
  render?: () => React.Component;
  btnTxt: string;
  onClick: () => Promise<void>;
}

export const TimedDeleteConfirmation: FC<TimedDeleteConfirmationProps> = ({ title, btnTxt, text, render, onClick }) => {
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const { closeModal } = modalAction(useDispatch());
  const handleClick = async () => {
    if (!deleteDisabled) {
      await onClick();
      closeModal();
    }
  };

  return (
    <div className="center mv3 bg-white br2 shadow-4" style={{ maxWidth: '19rem' }}>
      <div className="pa3">
        <div className="pb3 tc">
          <h3 className="normal f4 pb2">{title}</h3>
          {_notNil(render) && _notNil(render()) ? <>{render()}</> : <p className="f6 pb2">{text}</p>}
        </div>
        <div>
          <div
            className={styles.deleteConfirmButton}
            onAnimationEnd={() => setDeleteDisabled(false)}
            onClick={handleClick}
          >
            <div className={styles.content}>{btnTxt}</div>
            <div className={styles.progress}></div>
          </div>
          <a className="button plain db w-100 mt2" onClick={closeModal}>
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};
