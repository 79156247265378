import type { components } from '@/generated/internal-api/openapi-types';
import type { StudyApiId } from 'model/Study.model';
import type { RepeatOnDays, RepeatUnit, TaskScheduleConditionalType, TaskSpecCreate, TaskType } from 'model/Task.model';
import type { GroupApiId, TreatmentGroup } from 'model/TreatmentGroup.model';
import type { BaseUser, UserApiId } from 'model/User.model';

export const enum AddTaskFormTypes {
  overview = 'overview',
  assignees = 'assignees',
  schedule = 'schedule',
  target = 'target',
}
export type ScheduleTypes = 'one_off' | 'conditional' | 'recurring';
export type TargetTypeValues = 'animal' | 'group';

export type TaskSpecFieldNames =
  | `${AddTaskFormTypes.schedule}.type`
  | `${AddTaskFormTypes.schedule}.repeat`
  | `${AddTaskFormTypes.schedule}.repeat.days`
  | `${AddTaskFormTypes.schedule}.condition.type`
  | `${AddTaskFormTypes.target}.groups`
  | `${AddTaskFormTypes.target}.type`;

export interface AddTaskOverviewData {
  title: string;
  description: string;
  type: TaskType;
}
export type AddTaskAssgineeData = UserApiId[];
export interface AddTaskScheduleData {
  type: ScheduleTypes;
  start?: string;
  end?: string;
  repeat?: {
    value: number;
    unit: RepeatUnit;
    days?: RepeatOnDays;
  };
  condition?: {
    type: TaskScheduleConditionalType;
    value: number[];
  };
}

export interface AddTaskTargetData {
  type: TargetTypeValues;
  groups?: GroupApiId[];
}

export interface iStep {
  name: AddTaskFormTypes;
  title: string;
  render: (props: StepRenderProps) => JSX.Element;
}

export interface StepFormPayloadProps {
  [AddTaskFormTypes.overview]: AddTaskOverviewData;
  [AddTaskFormTypes.assignees]: AddTaskAssgineeData;
  [AddTaskFormTypes.schedule]: AddTaskScheduleData;
  [AddTaskFormTypes.target]: AddTaskTargetData;
}
export type OptionalStepFormPayloadProps = Partial<StepFormPayloadProps>;

export type StudyUsersApi = components['schemas']['StudyUserSummaryV1.schema'][];
export type TaskSpecUser = Pick<BaseUser, 'api_id' | 'name' | 'email'>;

/** @Todo: Remove `studyId` when Groups API is migrated to Java */
export interface AddTaskProps {
  /* For existing studies to fetch legacy resources eg. Groups API */
  studyId?: number;
  studyApiId?: StudyApiId;
  /** studyStarted on will default the `begins` field */
  studyStartedOn?: string;
  /* For study creation form, after users have been assigned */
  studyCreationUsers?: TaskSpecUser[];
  teamUsers?: TaskSpecUser[];
  /* For study creation form, after groups have been input */
  studyCreationGroups?: Array<TreatmentGroup>;
  handleSubmit?: (addTaskStepFormData: TaskSpecCreate) => void;
  closeModal: () => void;
}

export interface StepRenderProps {
  onSubmit: (payload: OptionalStepFormPayloadProps) => void;
  onCancel: () => void;
  submitButtonText: string;
  cancelButtonText: string;
  studyId?: AddTaskProps['studyId'];
  studyApiId?: AddTaskProps['studyApiId'];
  studyCreationUsers?: AddTaskProps['studyCreationUsers'];
  teamUsers?: AddTaskProps['studyCreationUsers'];
  studyCreationGroups?: AddTaskProps['studyCreationGroups'];
  /** When editing a task spec in an existing study, specific fields will be disabled, this will change as we add more API coverage  */
  disabledFields?: TaskSpecFieldNames[];
}
