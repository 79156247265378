import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Link from '@/components/UI/Link';
import UserAvatar from '@/components/UI/UserAvatar';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { PickRequired } from '@/model/Common.model';
import type { TaskType, TaskV1 } from '@/model/Task.model';
import { web } from '@/support/route';
import { DateRenderFormat } from '@/utils/Date.utils';
import { useModalAction } from '@/utils/modal';
import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction, useMemo } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { RiCalendarLine, RiCheckboxMultipleLine } from 'react-icons/ri';

interface TaskInfoRowProps {
  label: string;
}

const taskTypeLabels: Record<TaskType, string> = {
  dosing: 'Dosing',
  measurement: 'Measurement',
  observation: 'Observation',
  sample: 'Sample',
  other: 'Other',
};

const TaskInfoRow: FC<PropsWithChildren<TaskInfoRowProps>> = ({ label, children }) => (
  <div className="flex items-center">
    <div className="flex flex-column">
      <p className="ml3" style={{ width: '90px' }}>
        {label}
      </p>
    </div>
    <div className="flex flex-column">
      <p className="black pl3 ui__overflow__scroll_y" style={{ maxWidth: '340px' }}>
        {children}
      </p>
    </div>
  </div>
);

export const OverviewPanel: FC<{
  task: PickRequired<TaskV1, 'animals'>;
  setActiveTab: Dispatch<SetStateAction<number>>;
}> = ({ task, setActiveTab }) => {
  const { closeModal } = useModalAction();
  const groupCount = useMemo(
    () =>
      task.animals.reduce((acc, { group }) => {
        if (_notNil(group)) {
          acc.add(group.api_id);
        }
        return acc;
      }, new Set()).size,
    [task]
  );

  return (
    <>
      <div className="pt3 pr4">
        <div className="pl4">
          <div className="pa3 mt2 w-100 bg-light-gray" style={{ height: '100px' }}>
            <div className="flex mt1">
              <span className="ml3 mr4 black">
                <RiCalendarLine size={20} />
              </span>
              <p className="black fw5 f6">
                Due <DateTimeRenderer value={task.duration.start} format={DateRenderFormat.Date} />
              </p>
            </div>
            <div className="flex mt2">
              <span className="ml3 mr4 black">
                <RiCheckboxMultipleLine size={20} />
              </span>
              <p className="black fw4 f6 link" onClick={() => setActiveTab(2)}>
                Affects {task.animals.length} animals{' '}
                {task.target.type === 'group' && (
                  <>
                    in {groupCount} / {task.target.groups.length} groups
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="mv4 pl3 ml1 f6 min-h4 w-100">
          <div className="mt3">
            <TaskInfoRow label="Type">{taskTypeLabels[task.type]}</TaskInfoRow>
          </div>

          <div className="mt3">
            <TaskInfoRow label="Study">
              <Link className="link" to={web('studies.show', { id: task.study.id })} onClick={() => closeModal()}>
                {task.study.name}
              </Link>
            </TaskInfoRow>
          </div>

          {_isNotEmpty(task.assignees) && (
            <div className="mt2">
              <TaskInfoRow label="Assignees">
                <div className="flex items-center">
                  {task.assignees.map(({ id, name }) => (
                    <span className="mr1" key={id}>
                      <UserAvatar user={{ name }} />
                    </span>
                  ))}
                  {task.study.active && (
                    <div
                      className="flex pa2 link"
                      onClick={() => setActiveTab(1)}
                      style={{ border: '1px dashed lightgrey', borderRadius: '23px' }}
                    >
                      <FiUserPlus size={20} />
                    </div>
                  )}
                </div>
              </TaskInfoRow>
            </div>
          )}
          {_isNotEmpty(task.description) && (
            <div className="mt2">
              <TaskInfoRow label="Description">{task.description}</TaskInfoRow>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
