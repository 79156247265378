// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import { closeModal, openModal } from '@/components/Shared/methods';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { updateColumns, updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import { successToast } from '@/helpers';
import Http from '@/support/http';
import { useDispatch, useSelector } from 'react-redux';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import {
  animalsHaveStudies,
  generateColumns,
  generateFilterOptions,
  reducer,
  selectedAnimalsStudyIds,
  validateManageAnimalsSelection,
} from './Animals.utils';

const Animals = ({ settings = {} }) => {
  const initialState = useMemo(
    () => ({
      loading: true,
      updating: false,
      error: false,
      data: [],
      columns: generateColumns({ metadata: [] }, {}),
      searchQuery: '',
      filterOptions: generateFilterOptions({ fields: [] }),
      filters: [],
      filterMatch: true,
      selectedRows: {},
      total_pages: 1,
      current_page: 1,
      per_page: 10,
      order: 'desc',
      sort: ['id'],
    }),
    []
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const { columns, loading, updating, error, filterOptions } = state;
  const storeDispatch = useDispatch();
  const history = useHistory();
  const reduxTableName = 'colonyAnimals';

  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  useEffect(() => {
    const updatedColumns = updateColumns(reduxTableName, columns, settings);
    dispatch({
      type: 'SET_COLUMNS',
      data: updatedColumns,
    });
  }, [settings?.tables]);

  const updatedSettings = useMemo(() => {
    // Ensure any derived columns (e.g: from measurements) are listed in the settings.
    return updateSettings('colonyAnimals', columns, settings);
  }, [columns]);

  const updateDeceased = (animals) => {
    const data = animals.map((animal) => {
      if (animal.terminated_at !== null) {
        animal.collection = {
          data: {
            name: 'Deceased',
            catalog: 'Deceased',
          },
        };
      }
      return animal;
    });
    dispatch({
      type: 'SET_DATA',
      data: { data },
    });
  };

  const fetchFilterOptions = async () => {
    try {
      const { data } = await Http.get(apiRoute('animals.filterOptions'));
      dispatch({
        type: 'SET_FILTER_OPTIONS',
        data: generateFilterOptions(data),
      });
      dispatch({
        type: 'SET_COLUMNS',
        data: generateColumns(data, settings?.tables?.colonyAnimals?.columns),
      });
    } catch (data) {
      dispatch({ type: 'SET_ERROR', data });
    } finally {
      dispatch({ type: 'SET_LOADING', data: false });
    }
  };

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  const handleDelete = async (callback, selectedAnimals, reasonForDelete?: string) => {
    try {
      await Http.delete(apiRoute('animals.destroyManyColony', {}), {
        data: {
          animal_ids: selectedAnimals.map((s) => s.id),
          reason_for_change: reasonForDelete,
        },
      });
      return callback('Successfully deleted!');
    } catch (data) {
      dispatch({ type: 'SET_ERROR', data });
    }
  };

  const defaultSelectionProps = { hasWritePermission: true, isNotDeceased: true, hasActiveStudy: true };

  const bulkActions = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { fetchTableData },
    },
  }) => {
    const selectedAnimals = selectedFlatRows.map((s) => s.original);
    const callback = (res) => {
      closeModal(storeDispatch);
      successToast(res);
      fetchTableData();
    };
    const actions = [
      {
        name: 'Assign identifiers',
        key: 'assign_identifiers',
        ...validateManageAnimalsSelection(
          { ...defaultSelectionProps, hasIdenticalStudy: true, isNotDeceased: false },
          selectedAnimals
        ),
        action: () => {
          const animals = selectedAnimals.map(({ id, name, alt_ids: altIds, collection: { data: cage } }) => ({
            id,
            name,
            alt_ids: altIds,
            cage,
          }));

          storeDispatch({
            type: 'OPEN_MODAL',
            modal: 'ASSIGN_IDENTIFIERS',
            props: {
              animals,
              handleCallback: callback,
              closeModal: () => closeModal(storeDispatch),
            },
          });
        },
      },
      {
        name: 'Edit Animal details',
        key: 'edit_details',
        ...validateManageAnimalsSelection(defaultSelectionProps, selectedAnimals),
        action: () => {
          sessionStorage.setItem('selectedColonyAnimals', JSON.stringify(selectedAnimals));
          history.push(webRoute('colony.edit.animals'));
        },
      },
      {
        name: 'Move cage',
        ...validateManageAnimalsSelection({ ...defaultSelectionProps, hasIdenticalStudy: true }, selectedAnimals),
        action: () => {
          storeDispatch({
            type: 'OPEN_MODAL',
            modal: 'MOVE_SUBJECT_SEARCH',
            props: {
              selectedAnimals,
              selectedAnimalsStudyIds: () => selectedAnimalsStudyIds(selectedAnimals),
              animalsHaveStudies: animalsHaveStudies(selectedAnimals),
              handleCallback: callback,
            },
          });
        },
      },
    ];

    if (!atunesIntegrationEnabled) {
      actions.push(
        {
          name: 'Mark as deceased',
          ...validateManageAnimalsSelection(defaultSelectionProps, selectedAnimals),
          action: () =>
            openModal('BULK_EUTHANISE_SUBJECTS', storeDispatch, {
              subjects: selectedAnimals,
              closeModal: () => closeModal(storeDispatch),
              handleCallback: callback,
            }),
        },
        {
          name: 'Delete',
          ...validateManageAnimalsSelection(
            { hasWritePermission: true, isNotDeceased: false, hasActiveStudy: true },
            selectedAnimals
          ),
          action: () =>
            openModal('CONFIRM_DELETE_SUBJECTS', storeDispatch, {
              subject: selectedAnimals,
              onClick: (reasonForDelete?: string) => handleDelete(callback, selectedAnimals, reasonForDelete),
            }),
          className: 'red',
        }
      );
    }
    return actions;
  };

  return (
    <div className="ma4">
      {error && (
        <Banner critical dismiss={false} className="mw6 ma3">
          <h3 className="f5 normal lh-title pb2">There was an error fetching animal information</h3>
          <p className="f6 pb3 lh-copy">If this error persists try again later or contact support.</p>
          <Button outline critical url={'mailto:support@benchling.com'}>
            Contact support
          </Button>
        </Banner>
      )}
      <div className={`animals ${!loading && updating ? 'ui__disabled' : ''}`}>
        {loading ? (
          <div style={{ height: 530 }}>
            <Loading txt="Fetching animals..." />
          </div>
        ) : (
          <APITable
            apiInfo={{ type: 'legacyInternalApi', route: apiRoute('animals.show') }}
            includeParam="collection,meta,study_link"
            defaultSortBy={{ id: 'id', desc: true }}
            onFetchTableData={updateDeceased}
            columns={columns.filter((col) => col.Header)}
            bulkActions={bulkActions}
            filterOptions={filterOptions}
            searchPlaceholderText="Search by name, id, no. or cage"
            pageSizes={[50, 100, 200, 300]}
            settings={updatedSettings}
            reduxTableName={reduxTableName}
            testId="animals-api-table"
          />
        )}
      </div>
    </div>
  );
};

export default Animals;
