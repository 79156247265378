import { _isNil } from '@/littledash';

export const endpointPattern = /^(?<method>GET|POST|PUT|DELETE|PATCH)\s(?<path>\S+)$/;
export const pathParameterPattern = /{(\w+)}/g;

export const hydrateUrlTemplate = (urlTemplate: string, pathParameters: Record<string, string | number>) =>
  urlTemplate.replace(pathParameterPattern, (match: string, parameter: string) => {
    const value = pathParameters?.[parameter];
    if (_isNil(value)) {
      throw new Error(`Missing path parameter [${parameter}]`);
    }
    return `${value}`;
  });

export const updateQueryParams = (
  searchParams: URLSearchParams,
  query?: Record<string, string | number | boolean | Array<string | number | boolean>>
) => {
  Object.entries(query ?? {}).reduce((searchParams, [name, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => searchParams.append(name, `${v}`));
    } else {
      searchParams.append(name, `${value}`);
    }
    return searchParams;
  }, searchParams);
};
