import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { AnimalAlertsOverview } from '@/components/Subjects/Show/AnimalAlertsOverview';
import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import GroupLabel from '@/components/UI/GroupLabel';
import { altIds } from '@/constants/utils';
import { renderMetadataValue } from '@/helpers';
import { _get, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID } from '@/model/Common.model';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sexes } from '@/referenceData/study/animals';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { renderAnimalAge } from './Animal.utils';
import type { AnimalAlertAction } from './Show.utils';

interface SubjectOverviewProps {
  study: Study;
  currentAnimalId: ID;
  dispatch: React.Dispatch<AnimalAlertAction>;
  readOnly?: boolean;
}

interface StudyOverviewFieldProps {
  children: React.ReactNode;
  label: string;
  className?: string;
}

const Overview: React.FC<SubjectOverviewProps> = ({ study, currentAnimalId, dispatch, readOnly = false }) => {
  const { id: studyId, subjectId } = useParams<{ id: string; subjectId: string }>();
  const {
    entity: animal,
    entityLoading: animalLoading,
    entityError: animalError,
  } = useFetchEntity<Animal>({
    entityType: 'studyAnimal',
    params: { studyId, id: subjectId },
    includes: 'alerts,cage,study_group,animal_selection,terminated_at_data,latestMeasurement',
  });
  const dataAnalysisEnabled = useSelector<State>((state) => state?.team?.features?.data_analysis ?? false);

  useEffect(() => {
    if (currentAnimalId !== Number(subjectId)) {
      dispatch({ type: 'SET_ALERTS', data: [] });
    }
  }, [currentAnimalId, subjectId, dispatch]);

  if (animalLoading) {
    return <Loading />;
  }

  if (animalError || !animal) {
    return (
      <ApiErrorBanner
        className="mb4"
        title={'There was an error retrieving the animal'}
        text={
          'An error has occurred when fetching the animal, please try again later. If this keeps occurring please contact support.'
        }
        errorType={ApiErrorType.FETCH}
        error={animalError}
      />
    );
  }

  const alerts = animal?.alerts ?? [];
  const fieldClasses = 'bb b--moon-gray';

  return (
    <>
      {_isNotEmpty(alerts) && <AnimalAlertsOverview study={study} alerts={alerts ?? []} dispatch={dispatch} />}
      <div className="ui-card">
        {altIds.map(({ key, title }) => (
          <Field label={title} key={key} className={fieldClasses}>
            {_get(animal, key)}
          </Field>
        ))}
        {!readOnly && (
          <>
            <Field label="Group" className={fieldClasses}>
              <GroupLabel group={animal?.study_group} />
            </Field>
            <Field label="Tracking date" className={fieldClasses}>
              {_notNil(animal.tracking_started_at) && <DateRenderer value={animal.tracking_started_at} />}
            </Field>
            {dataAnalysisEnabled && (
              <Field label="Disease induction date" className={fieldClasses}>
                {_notNil(animal.disease_inducted_at) && <DateRenderer value={animal.disease_inducted_at} />}
              </Field>
            )}
            {_notNil(animal?.dob) && (
              <Field label="Age" className={fieldClasses}>
                {renderAnimalAge(animal.dob, animal?.terminated_at)}
              </Field>
            )}
          </>
        )}
        {_notNil(animal.dob) && (
          <Field label="Date of birth" className={fieldClasses}>
            <DateRenderer value={animal.dob} />
          </Field>
        )}
        <Field label="Sex" className={fieldClasses}>
          {animal?.sex && sexes[animal.sex]}
        </Field>
        <Field label="Species" className={fieldClasses}>
          {animal?.species?.name}
        </Field>
        <Field label="Strain">{animal?.strain?.name}</Field>
      </div>
      {_isNotEmpty(animal?.metadata) && (
        <div className="ui-card mt4">
          {animal?.metadata.map((meta) => {
            const lastMetaId = animal.metadata[animal.metadata.length - 1].id;
            return (
              <Field key={meta.id} label={meta.title} className={`${meta.id !== lastMetaId ? fieldClasses : ''}`}>
                {renderMetadataValue(meta)}
              </Field>
            );
          })}
        </div>
      )}
    </>
  );
};

const Field: React.FC<StudyOverviewFieldProps> = ({ children, label = '', className = '' }) => (
  <div className={`flex items-center pa3 ${className}`}>
    <div className="w-third lh-title">{label}</div>
    <div className="w-two-thirds near-black lh-title">{children || '-'}</div>
  </div>
);

export default Overview;
