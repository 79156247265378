import { _notNil, uuid } from '@/littledash.ts';
import { isAxiosError, isCancel } from 'axios';

export default class InVivoError extends Error {
  #context: Record<string, string>;
  #level: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
  #emit = true;

  constructor(
    message: string,
    options: ErrorOptions & {
      context?: Record<string, string>;
      slug?: string;
      level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
    }
  ) {
    super(message, options);
    this.name = _notNil(options?.slug) ? `InVivoError:${options?.slug}` : 'InVivoError';
    this.#level = options.level ?? 'error';
    if (isAxiosError(options.cause)) {
      const axiosCode = options.cause?.response?.status;
      this.#context = Object.freeze({
        id: uuid(),
        ApiMethod: (options.cause.config?.method ?? '-').toUpperCase(),
        ApiURL: options.cause?.config?.baseURL ?? '-',
        ApiStatus: options.cause.response?.statusText ?? '-',
        ApiStatusCode: `${axiosCode ?? '-'}`,
        ApiResponseBody: JSON.stringify(options.cause?.response?.data),
        ...(options.context ?? {}),
      });
      this.#emit = !(options.cause.code === 'ERR_NETWORK' || isCancel(options.cause) || axiosCode === 401);
    } else {
      this.#context = Object.freeze({
        id: uuid(),
        ...(options?.cause instanceof InVivoError ? (options?.cause?.context ?? {}) : {}),
        ...(options.context ?? {}),
      });
    }
  }

  get context(): Record<string, string> {
    return this.#context;
  }

  get level(): 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug' {
    return this.#level;
  }

  get shouldEmit(): boolean {
    return this.#emit;
  }
}
