// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { ADDITIONAL_ENTRY_KEYS } from '@/components/UI/TagInput/TagInput';
import { altIds } from '@/constants/utils';
import { preventNumberScroll } from '@/helpers';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import TagsInput from 'react-tagsinput';
import { nameAnimalsInSequence, repeatIdentifersAcrossCages } from './BulkGenerateForm.utils';
import { State } from '@/model/State.model';
import { useSelector } from 'react-redux';

const BulkGenerateForm = ({ data, setData }) => {
  const state = useSelector((state: State) => state?.team);
  const formMethods = useForm({
    defaultValues: {
      name: 'name',
      type: 'repeating',
      repeatingIds: [],
      sequencePrefix: '',
      sequenceStarting: '',
    },
  });
  const { handleSubmit, control, register } = formMethods;
  const typeWatch = useWatch({
    control,
    name: 'type',
  });
  const onSubmit = ({ name, type, repeatingIds, sequencePrefix, sequenceStarting }) => {
    if (type === 'repeating') {
      setData(repeatIdentifersAcrossCages(data, name, repeatingIds));
    } else if (type === 'sequence') {
      setData(nameAnimalsInSequence(data, name, sequencePrefix, sequenceStarting));
    }
  };

  let altIdOptions: Array<{ title: string; key: string }> = altIds;
  if (state?.features?.assign_identifiers_tag_unique === true) {
    altIdOptions = altIdOptions.filter(({ key }) => key !== 'alt_ids.tag');
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="br2 ba b--moon-gray flex flex-wrap mb3 overflow-hidden">
        <div className="w-50 br b--moon-gray pa3 flex justify-between items-center">
          <label className="mb0">Bulk assign IDs for:</label>
          <div className="flex">
            <select name="name" ref={register()} className="w4 mr3 mb0">
              {altIdOptions.map(({ title, key }) => (
                <option value={key} key={key}>
                  {title}
                </option>
              ))}
            </select>
            <select name="type" ref={register()} className="w4 mb0">
              <option value="repeating">Repeating</option>
              <option value="sequence">Sequence</option>
            </select>
          </div>
        </div>
        <div className="w-50 bg-near-white pa3 flex justify-between items-center">
          {FormTypeRender[typeWatch]}
          <Button submit stateless className="blue pr0">
            Bulk assign
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const RepeatIdentifiers = () => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="repeatingIds"
      defaultValue={[]}
      render={({ value, onChange }) => (
        <TagsInput
          className="ui-tags w-100 mr3 mb0"
          value={value}
          onChange={onChange}
          onlyUnique
          addKeys={ADDITIONAL_ENTRY_KEYS}
          maxTags="10"
          inputProps={{
            placeholder: 'Separate tags with a comma',
          }}
        />
      )}
    />
  );
};

const PrefixAndSequence = () => {
  const { register } = useFormContext();
  return (
    <div className="flex">
      <input
        type="text"
        name="sequencePrefix"
        ref={register}
        placeholder="ABC"
        className="w4 mr3"
        style={{ marginBottom: 0 }}
      />
      <input
        type="number"
        onWheel={preventNumberScroll}
        min="0"
        name="sequenceStarting"
        ref={register}
        placeholder="123"
        className="w4 mr3"
        style={{ marginBottom: 0 }}
      />
    </div>
  );
};

const FormTypeRender = {
  repeating: <RepeatIdentifiers />,
  sequence: <PrefixAndSequence />,
};

export default BulkGenerateForm;
