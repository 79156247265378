import {
  compileFormulaEngine,
  generateMeasurementDependencyGraph,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import Button from '@/components/UI/Button';
import { formatNumber, infoToast } from '@/helpers.tsx';
import { _isNil, _isNotBlank, _isNotEmpty, _notNil } from '@/littledash.ts';
import { PresetCreateOrUpdateV1, PresetMeasurementCreateV1 } from '@/model/Preset.model.ts';
import { Variable } from '@/utils/FormulaDsl.ts';
import cn from 'classnames';
import { FC, useMemo, useState } from 'react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form@latest';
import styles from './PresetBuilder.module.scss';

type PreviewFormData = Record<Variable, Record<Variable, number>>;

export const PresetPreview: FC = () => {
  const formMethods = useFormContext<PresetCreateOrUpdateV1>();
  const preset = useWatch({ control: formMethods.control });
  const [formulaResults, updateFormulaResults] = useState<Record<Variable, number>>({});

  const previewFormMethods = useForm<PreviewFormData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {},
  });

  const clearFormulaResult = (slug: string) =>
    updateFormulaResults((prevState) => {
      let update = delete prevState[`$${slug}`];
      previewData?.measurementDependencyGraph.get(slug)?.children?.forEach((childSlug) => {
        update = delete prevState[`$${childSlug}`];
      });
      return update ? { ...prevState } : prevState;
    });
  const recalculate = async (data: PreviewFormData) => {
    const slugsToZero = (preset?.measurements as Array<PresetMeasurementCreateV1>).reduce((acc, measurement) => {
      if (_notNil(measurement.config.auto_swap)) {
        const maxInput = data[`$${measurement.slug}`][`$${measurement.config.auto_swap.max}`];
        const minInput = data[`$${measurement.slug}`][`$${measurement.config.auto_swap.min}`];
        if (maxInput !== Math.max(maxInput, minInput)) {
          data[`$${measurement.slug}`][`$${measurement.config.auto_swap.min}`] = maxInput;
          previewFormMethods.setValue(`$${measurement.slug}.$${measurement.config.auto_swap.min}`, maxInput);
          data[`$${measurement.slug}`][`$${measurement.config.auto_swap.max}`] = minInput;
          previewFormMethods.setValue(`$${measurement.slug}.$${measurement.config.auto_swap.max}`, minInput);
          infoToast(
            <>
              <h3 className="white">{measurement.name} auto swap</h3>
              <p>
                ${measurement.config.auto_swap.min} ({maxInput}) ↔ ${measurement.config.auto_swap.max} ({minInput})
              </p>
            </>
          );
        }
      }

      if (measurement.config.all_inputs_required === false && measurement.inputs.length >= 2) {
        measurement.inputs.forEach((input) => {
          if (!Number.isFinite(data[`$${measurement.slug}`][`$${input.slug}`])) {
            acc.add(`$${input.slug}`);
          }
        });
      }
      return acc;
    }, new Set<Variable>());

    const updatedResults: Record<Variable, number> =
      previewData?.measurementSlugsSortedByDependants.reduce<Record<Variable, number>>((acc, measurementSlug) => {
        const variable: Variable = `$${measurementSlug}`;
        const formulaEngine = compileFormulaEngine(previewData?.measurementData[measurementSlug].formula, {
          variablesToZero: slugsToZero,
        });
        if (_notNil(formulaEngine)) {
          return { ...acc, [variable]: formulaEngine.evaluate({ ...acc, ...(data?.[variable] ?? {}) }) };
        }
        return acc;
      }, {}) ?? {};
    updateFormulaResults(updatedResults);
  };
  const measurementsValid = !Object.prototype.hasOwnProperty.call(formMethods?.formState?.errors ?? {}, 'measurements');
  const previewData = useMemo(() => {
    if (measurementsValid && _notNil(preset)) {
      const { measurementDependencyGraph, measurementData, measurementSlugsSortedByDependants } =
        generateMeasurementDependencyGraph(preset as PresetCreateOrUpdateV1);
      return { measurementDependencyGraph, measurementData, measurementSlugsSortedByDependants };
    }
    return null;
  }, [preset, measurementsValid]);

  return (
    <>
      <h2 className="pa4 f3 black">Preview</h2>
      <div className="black mh4 ui__overflow__scroll_y">
        {!measurementsValid ? (
          <div className="bg-light-gray br2 pa3 br3 ma3">
            <h2 className="black f5 fw5">A preset preview appears here.</h2>
            <p className="pt1">Add measurements to get started.</p>
            <p className="pt2">
              <a
                href="https://help.benchling.com/hc/en-us/articles/29260886232589-Creating-and-managing-presets"
                className="link"
              >
                Read more.
              </a>
            </p>
          </div>
        ) : _isNil(previewData) ? (
          <h2 className="black f5 fw6">Generating preview</h2>
        ) : (
          <div
            data-test-component="PresetPreview"
            data-test-element="container"
            onSubmit={(e) => {
              e.preventDefault();
              previewFormMethods.trigger();
            }}
          >
            <>
              {(preset?.measurements as Array<PresetMeasurementCreateV1>).map((measurement) => (
                <div
                  key={measurement.slug}
                  className={`ph3 ${styles.measurementPreview}`}
                  data-test-element="measurement-container"
                  data-test-key={measurement.slug}
                >
                  <div>
                    {measurement.inputs.map(({ slug, name, unit }) => (
                      <div key={slug} data-test-element="input-container" data-test-key={slug}>
                        <label>{name}</label>
                        <div className="flex relative">
                          <Controller
                            control={previewFormMethods.control}
                            name={`$${measurement.slug}.$${slug}`}
                            rules={{ required: measurement.config.all_inputs_required ?? true, shouldUnregister: true }}
                            render={({ field, fieldState }) => (
                              <>
                                <input
                                  type="number"
                                  {...field}
                                  data-test-element="input-field"
                                  onChange={(event) => {
                                    field.onChange(event.target.valueAsNumber);
                                    clearFormulaResult(measurement.slug);
                                  }}
                                  className={cn({ ui__error: fieldState.invalid })}
                                />
                                {_isNotEmpty(unit) && (
                                  <span className="absolute tr" style={{ top: '0.5rem', right: '0.5rem' }}>
                                    <small>{unit}</small>
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <label>{measurement.name}</label>
                    <MeasurementOutput output={measurement} value={formulaResults[`$${measurement.slug}`]} />
                  </div>
                </div>
              ))}
            </>
            <Button
              className="ma3"
              disabled={!previewFormMethods.formState.isValid || previewFormMethods.formState.isSubmitting}
              onClick={previewFormMethods.handleSubmit(recalculate)}
              testId="calculate-button"
            >
              Calculate
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

type MeasurementOutputProps = {
  output: PresetMeasurementCreateV1;
  value?: number;
};
const MeasurementOutput: FC<MeasurementOutputProps> = ({ output, value }) => {
  return (
    <div className="flex relative" data-test-component="MeasurementOutput" data-test-element="container">
      <input
        value={_isNil(value) ? '' : formatNumber(value)}
        type="number"
        disabled
        data-test-element="disabled-input"
      />
      {_isNotBlank(output.unit) && (
        <span className="absolute tr" style={{ top: '0.5rem', right: '0.5rem' }}>
          <small>{output.unit}</small>
        </span>
      )}
    </div>
  );
};
