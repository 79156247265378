// @ts-nocheck: converted from JS

import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import _isEqual from 'lodash/isEqual';
import validate from 'validate.js';
import { ExtendedMetadataField } from './Metadata';

interface ValidationSchema {
  title: {
    checkDuplicate: object;
    presence: { allowEmpty: boolean; message: string };
  };
  options?: {
    metaOptions: string;
  };
}

export const validateMetaData = (rowData: ExtendedMetadataField, formData: Array<ExtendedMetadataField>) => {
  validate.validators.checkDuplicate = function (value: string) {
    const duplicates = formData.filter(
      ({ active, title, type }) => active && type === rowData.type && title.toLowerCase() === value.toLowerCase()
    );

    if (duplicates.length >= 2) {
      return 'Category must be unique.';
    } else {
      return null;
    }
  };

  const schema: ValidationSchema = {
    title: {
      checkDuplicate: {},
      presence: { allowEmpty: false, message: "Category can't be blank" },
    },
  };

  const selectFields = ['select', 'multi_select'];

  if (selectFields.includes(rowData.field_type)) {
    validate.validators.metaOptions = function (value: Array<string>) {
      if (_isNotEmpty(value)) {
        const checkValues = value.map((v) => (v === '' ? "Option can't be blank" : false));
        if (checkValues.every((o) => o === false)) {
          return null;
        } else {
          // Im not sure if im doing this wrong but you have to pass it in an array otherwise it will only return the first item from the array
          // Expect error to return like errors.options[0][0] -> to get the first error
          return [checkValues];
        }
      } else {
        return 'Terms must have values to select from';
      }
    };

    schema.options = {
      metaOptions: 'test',
    };
  }

  return validate(rowData, schema, { fullMessages: false });
};

const constructData = (data, initialData) =>
  data.reduce(
    (acc, meta, index) => {
      if (_notNil(meta.new)) {
        delete meta.id;
        acc.posts.push(meta);
      } else {
        if (!_isEqual(meta, initialData[index])) {
          acc.puts.push(meta);
        }
      }

      return acc;
    },
    {
      posts: [],
      puts: [],
    }
  );

const constructDeletes = (data, initialData) => {
  return initialData.filter((intD) => _isEmpty(data.find((d) => Number(d.id) === Number(intD.id)))).map((d) => d.id);
};

export const saveGlossaryMetadata = async (data, initialData) => {
  const deletes = constructDeletes(data, initialData);
  const metas = constructData(
    data,
    initialData.filter(({ id }) => !deletes.includes(id))
  );
  if (_isNotEmpty(deletes)) {
    await Http.delete(apiRoutes('meta-glossary.destroy'), {
      data: {
        items: deletes,
      },
    });
  }
  const promisesToMake = [postMetas(metas.posts), putMetas(metas.puts)];
  return Promise.all(promisesToMake);
};

export const handlePayload = ({ id, title, type, field_type, options, active }) => {
  const payload = {
    id: id || undefined,
    title,
    type,
    field_type,
    options: options || [],
    active,
  };

  if (field_type === 'multi_select' || field_type === 'lookup_multi_select') {
    payload.options = payload.options.map((value) => encodeURIComponent(value));
  }

  return payload;
};

const postMetas = (metas) => {
  if (_isNotEmpty(metas)) {
    return Http.post(apiRoutes('meta-glossary.create'), {
      items: metas.map((g) => handlePayload(g)),
    });
  }
};

const putMetas = (metas) => {
  if (_isNotEmpty(metas)) {
    return Http.put(apiRoutes('meta-glossary.update'), {
      items: metas.map((g) => handlePayload(g)),
    });
  }
};

export const metaDataTypes = [
  {
    key: 'study_meta',
    title: 'Study',
  },
  {
    key: 'group_meta',
    title: 'Groups',
  },
  {
    key: 'subject_meta',
    title: 'Animals',
  },
  {
    key: 'collection_meta',
    title: 'Cages',
  },
  {
    key: 'sample_meta',
    title: 'Samples',
  },
  {
    key: 'attachment_meta',
    title: 'Attachments',
  },
  {
    key: 'treatment_meta',
    title: 'Treatments',
  },
];
