import { FlowControlTypes, ParityTypes, ReadingTypes, TerminationCharacters } from '@/model/Device.model';

export const parityOptions = (Object.keys(ParityTypes) as Array<keyof typeof ParityTypes>).map((key) => ({
  label: key,
  value: ParityTypes[key],
}));

export const flowControlOptions = (Object.keys(FlowControlTypes) as Array<keyof typeof FlowControlTypes>).map(
  (key) => ({
    label: key,
    value: FlowControlTypes[key],
  })
);

export const readingTypeOptions = (Object.keys(ReadingTypes) as Array<keyof typeof ReadingTypes>).map((key) => ({
  label: key,
  value: ReadingTypes[key],
}));

export const terminationCharacterOptions = (
  Object.keys(TerminationCharacters) as Array<keyof typeof TerminationCharacters>
).map((key) => ({
  label: key,
  value: TerminationCharacters[key],
}));
