// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import { formatNumberComingIn } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import { DraftFormData, StudyDraft } from '@/model/Draft.model';
import type { StudyFormMetaDetails, StudyFormPayload } from '@/model/StudyForm.model';
import { useThrottle } from '@/support/Hooks/throttle/useThrottle';
import { DateUtils } from '@/utils/Date.utils';
import React, { ReactNode } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CreateStudyReducer, CreateStudyState } from './Create.model';
import { MetadataFieldValueTypes } from '@/model/Metadata.model';

interface FormActionsProps {
  state: unknown;
  dispatch: () => void;
  submitForm?: () => void;
  isUseForm?: boolean;
  submitAction?: string;
  disableSaveDraft?: boolean;
}

export const FormActions: React.FC<FormActionsProps> = ({
  state,
  dispatch,
  submitForm,
  isUseForm = false,
  submitAction = 'updateStudy',
  disableSaveDraft = false,
}) => {
  const formContext = useFormContext();
  const onSubmit = (data, _, draftSaveOnNext = false) => {
    data.links = state.study.links;
    dispatch({
      type: submitAction,
      data: { ...data, files: state.study.files },
    });
    if (draftSaveOnNext) {
      dispatch({ type: 'SAVE_DRAFT_ON_NEXT' });
    } else {
      dispatch({ type: 'SAVE_DRAFT' });
    }
  };

  const next = useThrottle({
    func: () => {
      if (isUseForm) {
        formContext.handleSubmit((data) => onSubmit(data, '_', true))();
      } else if (submitForm) {
        submitForm(state);
      } else {
        dispatch({ type: 'SAVE_DRAFT_ON_NEXT' });
      }
    },
    duration: 500,
  });

  const handleDraftSave = () => {
    if (isUseForm) {
      formContext.handleSubmit((data) => onSubmit(data, '_', false))();
    } else {
      dispatch({ type: 'SAVE_DRAFT' });
    }
  };

  return (
    <div className="pv3 flex items-center justify-between pr4">
      <div className="flex items-center">
        <Button onClick={next} className="mr3" testId="next-or-create-button" disabled={!state.stepReady}>
          {submitForm ? 'Create' : 'Next'}
        </Button>
        <Button
          plain
          disabled={state.form.step < 1}
          testId="back-button"
          onClick={() =>
            dispatch({
              type: 'goBack',
            })
          }
        >
          Back
        </Button>
      </div>
      {!disableSaveDraft && (
        <Button
          onClick={handleDraftSave}
          disabled={state.form.savingDraft}
          stateless
          className="link blue ph0"
          tooltip="Save your progress and continue at another time"
          testId="save-draft-button"
        >
          Save as draft
        </Button>
      )}
    </div>
  );
};

interface UseFormProviderProps {
  state: unknown;
  dispatch: () => void;
  children: ReactNode;
}

export const UseFormProvider: React.FC<UseFormProviderProps> = ({ state, dispatch, children }) => {
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      ...state.study,
    },
  });
  return (
    <FormProvider {...methods}>
      <form>
        {children}
        <FormActions state={state} dispatch={dispatch} isUseForm />
      </form>
    </FormProvider>
  );
};

interface PanelLayoutProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
}

export const PanelLayout: React.FC<PanelLayoutProps> = ({ children, title, subtitle }) => {
  return (
    <>
      <div className="mb4">
        <h3 className="fw5 f5 pb2">{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <div className="mw7 ui-card">
        <div className="pa4 bb b--moon-gray">{children}</div>
      </div>
    </>
  );
};

const initialState: CreateStudyState = {
  stepReady: false,
  form: {
    loading: true,
    step: 0,
    errors: [],
    submitting: false,
    submitted: false,
    saving: false,
    savingDraft: false,
    result: {},
    selectableMetadata: [],
    totalCages: 10,
    bulkEdit: false,
    templates: [],
    presets: [],
    selectedPreset: null,
    createCages: true,
    draftId: null,
    selectedProjectName: '',
    selectedStudyCode: '',
  },
  study: {
    name: '',
    notes: '',
    metadata: [],
    metadataHash: {},
    collections: [],
    owner: null,
    users: [],
    alerts: [],
    files: [],
    average_calculation: 'arithmetic', // arithmetic || geometric
    error_deviation: 'sd', // sd || sem
    groups: [],
    task_template_group_id: null,
    tasks: null,
    started_on: DateUtils.dateNow(),
    preset_id: null,
    project_id: '',
    links: [],
    treatments: [],
    groups_treatments: [],
    benchling_folder: null,
  },
};

export const initialiseState = (draft: StudyDraft): CreateStudyState => {
  let state = { ...initialState };
  if (draft) {
    state = {
      ...state,
      form: {
        ...state.form,
        draftId: draft.id,
      },
      study: {
        ...state.study,
        ...draft.form_data,
      },
    };
    if (draft.selectedProjectName) {
      state.form.selectedProjectName = draft.selectedProjectName;
    }
    if (draft.selectedStudyCode) {
      state.form.selectedStudyCode = draft.selectedStudyCode;
      state.study.study_code = draft.selectedStudyCode;
    }
  }

  return state;
};

export const validMultiSelectValue = (metaValue: MetadataFieldValueTypes): boolean => {
  // Empty stringified array is not valid
  if (Array.isArray(metaValue) && metaValue.length > 0) {
    return true;
  }
  try {
    // Check if the string is a valid JSON array
    const parsedValue = JSON.parse(metaValue as string);
    if (Array.isArray(parsedValue) && parsedValue.length > 0) {
      return true;
    }
    // Unpopulated array is not valid
    return false;
  } catch (e) {
    return false;
  }
};

const mapMetadata = (metadata) =>
  (metadata ?? []).reduce((acc, meta) => {
    if (meta.value) {
      if (
        (meta.field_type === 'multi_select' || meta.field_type === 'lookup_multi_select') &&
        !validMultiSelectValue(meta.value)
      ) {
        return acc;
      }
      if (meta.field_type === 'numeric') {
        meta.value = formatNumberComingIn(meta.value).toString();
      }
      acc.push(meta);
    }
    return acc;
  }, []);

export const assemblePayload: (
  study: DraftFormData,
  formMeta?: StudyFormMetaDetails,
  formId?: ID
) => StudyFormPayload = (study, formMeta, formId) => {
  const result = {
    ...study,
    study_code_id: study.study_code?.api_id,
    alerts: study.alerts?.map((a, i) => ({ ...a, id: String(i) })),
    collections: study.collections?.map((c) => ({
      ...c,
      subjects: Number(c.subjects),
    })),
    // Currently adds all metadata options to create for groups
    // This is a workaround to empty those options to prevent HTTP 413
    groups: study.groups.map((group) => ({
      ...group,
      metadata: group.metadata?.map((metadata) => ({ ...metadata, options: [] })),
    })),
    metadata: mapMetadata(study.metadata ?? []),
    type: formMeta?.type,
    study_creation_template_id: formId,
    treatments: study.treatments?.map((treatment) => ({
      ...treatment,
      treatment_type_id: treatment?.treatment_type?.id,
      metadata: mapMetadata(treatment.metadata),
    })),
  };
  if (_isNil(result.project_id)) {
    delete result.project_id;
  }
  delete result.study_code;
  return result;
};

export const reducer: CreateStudyReducer = (state, action) => {
  switch (action.type) {
    case 'updateForm':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case 'setErrors':
      return {
        ...state,
        form: {
          ...state.form,
          errors: action.data,
        },
      };
    case 'stepReady':
      return { ...state, stepReady: action.data ?? true };
    case 'goBack':
      return {
        ...state,
        stepReady: false,
        form: {
          ...state.form,
          step: state.form.step - 1,
        },
      };
    case 'jumpToStep':
      return {
        ...state,
        stepReady: false,
        form: {
          ...state.form,
          step: action.data,
        },
      };
    case 'setPreset':
      return {
        ...state,
        form: {
          ...state.form,
          selectedPreset: action.data,
        },
        study: {
          ...state.study,
          preset_id: action.data?.id || null,
        },
      };
    case 'updateStudy':
      return {
        ...state,
        study: {
          ...state.study,
          ...action.data,
        },
      };
    case 'updateMetadata': {
      // @Todo temporary fix that needs time to refactor
      // discussion to be had about this entire section;
      // using arrays is really difficult/wasteful in here
      const submittedMetadata = Object.entries(action.data.metadata ?? []).map(([key, val]) => {
        const glossary_id = Number(key);
        return {
          glossary_id,
          value: val,
          field_type: state.study.metadataHash[glossary_id]?.field_type ?? '',
        };
      });
      const combined = [...state.study.metadata, ...submittedMetadata];
      const updatedMetadata = [...new Map(combined.map((item) => [item['glossary_id'], item])).values()];
      return {
        ...state,
        study: {
          ...state.study,
          metadata: updatedMetadata,
        },
      };
    }
    case 'SET_SELECTABLE_METADATA': {
      let metadata = state.study.metadata;
      const metadataHash = {};
      if (_isEmpty(state.study.metadata)) {
        metadata = action.data.reduce((acc, { id, required_by, field_type }) => {
          if (required_by !== 'optional') {
            acc.push({ glossary_id: id, value: '', field_type });
            metadataHash[id] = { field_type, glossary_id: id };
          }
          return acc;
        }, []);
      }
      return {
        form: {
          ...state.form,
          selectableMetadata: action.data,
        },
        study: {
          ...state.study,
          metadata,
          metadataHash,
        },
      };
    }
    case 'update':
      return {
        ...state,
        ...action.data,
      };
    case 'SAVE_DRAFT':
      return {
        ...state,
        form: {
          ...state.form,
          savingDraft: true,
        },
      };
    case 'SAVE_DRAFT_ON_NEXT':
      return {
        ...state,
        stepReady: false,
        form: {
          ...state.form,
          savingDraft: true,
          savingDraftOnNext: true,
        },
      };
    case 'setDraftId':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data,
        },
      };
    case 'add-task': {
      return {
        ...state,
        study: { ...state.study, tasks: { ...(state.study.tasks ?? null), ...action.data } },
      };
    }
    case 'delete-task': {
      const tasks = action.data.reduce(
        (acc, id) => {
          delete acc[id];
          return { ...acc };
        },
        { ...(state.study.tasks ?? {}) }
      );
      return {
        ...state,
        study: { ...state.study, tasks },
      };
    }
    default: {
      return { ...state };
    }
  }
};
