import { _notNil } from '@/littledash';
import { ReadingTypes } from '@/model/Device.model';

export const stripReading = (reading: string, readingType: ReadingTypes) => {
  let result = '';
  switch (readingType) {
    case ReadingTypes.Number: {
      result = stripReadingNumericFormat(reading);
      break;
    }
    case ReadingTypes['Trim Channel']: {
      result = stripReadingNumericFormat(reading.slice(4));
      break;
    }
    case ReadingTypes.Text: {
      result = reading;
      break;
    }
  }
  // remove new lines, tabs and spaces from all readings
  result = result.replaceAll(/[\n\r\s]+/g, '');

  return result;
};

export const stripReadingNumericFormat = (reading: string) => {
  const regex = /[\d.,-]/g;
  const match = reading.match(regex);

  return _notNil(match) ? match.join('') : '';
};
