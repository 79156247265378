// @ts-nocheck: converted from JS

import Link from '@/components/UI/Link';
import { _notNil } from '@/littledash';
import * as Auth from '@/support/auth';
import useSelectStudyCreationFormModal from '@/support/Hooks/study/useSelectStudyCreationFormModal';
import { web as webRoute } from '@/support/route';
import { useDevices } from '@/utils/devices/useDevices';
import React from 'react';
import {
  RiAddCircleLine,
  RiBaiduLine,
  RiCalendarLine,
  RiCheckboxMultipleLine,
  RiDraftLine,
  RiErrorWarningFill,
  RiFile3Line,
  RiGpsLine,
  RiGroupLine,
  RiLogoutBoxRLine,
  RiQuestionLine,
  RiTestTubeLine,
  RiUser3Line,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './Sidebar.scss';

const Sidebar = () => {
  const { openSelectStudyCreationFormModal } = useSelectStudyCreationFormModal();

  const { currentStudyDetails } = useSelector(({ ui: { currentStudyDetails } }: State) => ({
    currentStudyDetails,
  }));
  const dispatch = useDispatch();
  const { unmappedDevices } = useDevices({});

  const openDeviceConnectionsModal = () => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'DEVICE_CONNECTIONS',
      props: { study: currentStudyDetails },
    });
  };

  return (
    <div className="bg-branding-color white h-100 flex flex-column justify-between w3">
      <div className="f3">
        <MenuItem to={webRoute('dashboard')} tooltip="Studies">
          <RiFile3Line />
        </MenuItem>
        <MenuItem to={webRoute('colony')} tooltip="Colony">
          <RiBaiduLine />
        </MenuItem>
        <MenuItem to={webRoute('samples')} tooltip="Samples">
          <RiTestTubeLine />
        </MenuItem>
        <MenuItem to={webRoute('tasks')} tooltip="Tasks">
          <RiCalendarLine />
        </MenuItem>
        <MenuItem to={webRoute('approvals')} tooltip="Reviews">
          <RiCheckboxMultipleLine />
        </MenuItem>
        <MenuItem to={webRoute('requests')} tooltip="Requests">
          <RiDraftLine />
        </MenuItem>
      </div>
      <div className="f3">
        <MenuItem onClick={openSelectStudyCreationFormModal} tooltip="Create new study">
          <RiAddCircleLine />
        </MenuItem>
        {Auth.isCurrentTeamOwner() && (
          <MenuItem to={webRoute('team.manage')} tooltip="Manage team">
            <RiGroupLine />
          </MenuItem>
        )}
        <MenuItem to={webRoute('account')} tooltip="Account">
          <RiUser3Line />
        </MenuItem>
        {_notNil(navigator.serial) && (
          <MenuItem onClick={openDeviceConnectionsModal} tooltip="Devices">
            <RiGpsLine />
            {unmappedDevices > 0 && (
              <RiErrorWarningFill style={{ position: 'absolute', left: '28px', top: '8px' }} color="red" />
            )}
          </MenuItem>
        )}

        <MenuItem to={webRoute('help')} tooltip="Help" newTab>
          <RiQuestionLine />
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
            dispatch({ type: 'TEAM_SET_TEAM', team: null });
            dispatch({ type: 'USER_SET_CURRENT_USER', currentUser: null });
          }}
          tooltip="Log out"
          testId="logout-menu-item"
        >
          <RiLogoutBoxRLine />
        </MenuItem>
      </div>
    </div>
  );
};

interface MenuItemProps {
  className: string;
  hasNotification: boolean;
  tooltip: string;
  children: React.ReactNode | React.ReactNode[];
  onClick: Function; // eslint-disable-line @typescript-eslint/ban-types
  to?: boolean | string;
  newTab?: boolean;
  testId?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  className,
  hasNotification,
  tooltip,
  children,
  onClick,
  to = '#',
  newTab,
  testId,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link to={to} onClick={onClick} openTab={newTab} className={`db tc mv2 ${className}`}>
      <div
        data-tooltip-content={tooltip}
        data-tooltip-id="global-tooltip-id"
        data-testid={testId}
        className={`sidebar-menu-item dib ${hasNotification ? '--has-notification' : ''} ${
          isActive ? '--is-active' : ''
        }`}
      >
        {children}
      </div>
    </Link>
  );
};

export default Sidebar;
