/**
 * Auth helper
 */

import { _isNotEmpty, _notNil } from '@/littledash';
import type { Study } from '@/model/Study.model';
import type { StudyUserSummaryV1, StudyUserTransformer } from '@/model/User.model';
import store from '@/store';

export const getCurrentUser = () => store.getState().user.currentUser;

type StudyUserCondition = (user: StudyUserTransformer) => boolean;

export function isStudyUser(study: Study | undefined, condition?: StudyUserCondition): boolean {
  const user = getCurrentUser();
  const users = (Array.isArray(study?.users) ? study?.users : study?.users?.data) ?? [];
  return users.some((u) => u.id === user.id && (condition?.(u) ?? true));
}

export function isAuthor(study: Study) {
  const user = getCurrentUser();
  return Number(user.id) === Number(study?.author?.id);
}

export const isOwner = (study: Study) => {
  const user = getCurrentUser();
  return Number(user.id) === Number(study?.owner?.id);
};

export function isWriteUserForStudy(study: Study, disableArchived = true, disableCancelled = true) {
  if (_notNil(study)) {
    if ((disableArchived && study.archived_at) || (disableCancelled && study.canceled_at)) {
      return false;
    }
    return isStudyUser(study, (u: StudyUserTransformer) => u.access === 'write') || isOwner(study) || isAuthor(study);
  }
  return false;
}

export const isWriteUserForStudyUsers = (studyUsers: Array<StudyUserSummaryV1>) => {
  if (_isNotEmpty(studyUsers)) {
    const user = getCurrentUser();
    return studyUsers.some((u) => u.id === user.id && u.access === 'write');
  }
  return false;
};

export function isCurrentTeamOwner() {
  try {
    const team = store.getState().team.team;
    return team.pivot.owner;
  } catch (e) {
    return false;
  }
}

export const isCurrentTeamAdminConsoleUser = () => {
  try {
    return isCurrentTeamOwner() && store.getState().team.team.pivot.admin_console_user;
  } catch (e) {
    return false;
  }
};
