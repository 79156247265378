// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import List, { asListItem } from '@/components/UI/List';
import { isFunction, successToast } from '@/helpers';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import AnimalFormFields from './AnimalFormFields';
import { DateUtils } from '@/utils/Date.utils';

const AddAnimals = ({ cage, handleCallback, closeModal }) => {
  const MAX_ANIMALS_IN_CAGE = 10;
  const { collection: selectableSpecies, collectionLoading: speciesLoading } = useFetchCollection({
    collectionType: 'species',
    includes: 'strains',
  });
  const currentCagePopulation = Number(cage?.subjects?.data?.length ?? 0);
  const newAnimal = {
    cage_id: cage.id,
    name: `Animal ${currentCagePopulation + 1}`,
    sex: 'm',
    dob: DateUtils.dateNow(),
    strain_id: null,
  };
  const formMethods = useForm({
    defaultValues: {
      animals: [{ ...newAnimal }],
    },
    mode: 'onChange',
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods;
  const {
    fields: animals,
    append: appendAnimal,
    remove: removeAnimal,
  } = useFieldArray({
    control,
    name: 'animals',
  });
  const onAppendAnimal = () => {
    appendAnimal({
      ...newAnimal,
      name: `Animal ${currentCagePopulation + animals.length + 1}`,
    });
  };

  const {
    sendRequest: createAnimals,
    requestSending: creatingAnimals,
    requestError,
  } = useRequest({
    route: 'animals.createMany',
    method: 'post',
  });

  const onSubmit = async (data) => {
    const res = await createAnimals(data);
    if (!requestError) {
      successToast(`Created ${animals.length} animal${animals.length !== 1 ? 's' : ''} in ${cage.name}`);
      if (isFunction(handleCallback)) {
        handleCallback(res, cage);
      }
    }
  };

  if (speciesLoading) {
    return <Loading />;
  }

  let modalHeaderSubText;
  if (currentCagePopulation >= MAX_ANIMALS_IN_CAGE) {
    modalHeaderSubText = 'This cage has reached its animal population limit';
  } else if (currentCagePopulation >= 0) {
    modalHeaderSubText = `There are currently ${currentCagePopulation} animal${
      currentCagePopulation !== 1 ? 's' : ''
    } in this cage. ${MAX_ANIMALS_IN_CAGE - currentCagePopulation} more spaces are available.`;
  }

  return (
    <ModalContainer size="narrow" className="bg-white">
      <ModalHeader
        title={`Add animals to ${cage.name}`}
        subText={modalHeaderSubText}
        closeModal={closeModal}
        closeDisabled={creatingAnimals}
        className="pa3 bb b--moon-gray w-100"
      />
      {requestError && <ApiErrorBanner error={requestError} />}
      <FormProvider {...formMethods}>
        <List
          canAmend
          canAdd={animals.length + currentCagePopulation < MAX_ANIMALS_IN_CAGE}
          canRemove={animals.length > 1}
          data={animals}
          onAddClick={onAppendAnimal}
          onRemoveClick={removeAnimal}
          addButtonText="Add new animal"
          renderItem={asListItem(AnimalFormFields, {
            selectableSpecies,
            formMethods,
          })}
          containerProps={{ className: 'pa3' }}
        />
      </FormProvider>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        cancelBtnText="Cancel"
        submitButtonProps={{
          disabled: creatingAnimals || Object.keys(errors).length > 0,
          loading: creatingAnimals,
        }}
        cancelButtonProps={{ disabled: creatingAnimals }}
      />
    </ModalContainer>
  );
};

export default AddAnimals;
