import ApiErrorBanner from '@/components/ApiErrorBanner';
import { assembleToggleOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.utils';
import Switch from '@/components/UI/FormElements/Switch';
import Link, { OnClickCloseModalHOC } from '@/components/UI/Link';
import Lookup from '@/components/UI/Lookup';
import type { ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import type { Treatment } from '@/model/Treatment.model';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface MapTreatmentsOutput extends Treatment {
  label: string;
  value: ID;
}

const mapTreatments = (treatments: Array<Treatment>): Array<MapTreatmentsOutput> =>
  (treatments ?? []).map((treatment: Treatment) => ({
    ...treatment,
    label: treatment?.display_name ?? '',
    value: treatment?.id ?? '',
  }));

const Dosing = () => {
  const [apiError, setApiError] = useState<unknown | false>(false);
  const { id: studyId } = useParams<{ id: string }>();
  const { control, watch } = useFormContext();

  const loadOptions = async ({ inputValue, page }: { inputValue: unknown; page: unknown }) => {
    setApiError(false);
    let url = apiRoute('studies.treatments', {
      studyId,
    });
    url = `${url}?page=${page}&include=study_groups,metadata`;
    if (inputValue) {
      url = `${url}&query=${inputValue}`;
    }
    try {
      const {
        data: { data, meta },
      } = await Http.get(url);
      const { current_page: currentPage, last_page: lastPage } = meta;
      const hasMore = currentPage < lastPage;
      const options = mapTreatments(data);
      return { options, hasMore };
    } catch (error) {
      setApiError(error);
      ExceptionHandler.captureException(
        new InVivoError('Could not load study treatments', {
          cause: error,
          slug: 'dosing-load-treatments',
        })
      );
    }
  };
  const toggleOptions = assembleToggleOptions(watch('workflowSetup.workflow'), 'dosing');
  return (
    <div data-testid="workflow-dosing-setup-main">
      <div className="pa3">
        <>
          <label>Select treatments to administer</label>
          <p className="f6 lh-copy mb3">
            Treatments can be added in the{' '}
            <OnClickCloseModalHOC>
              <Link to={webRoute('studies.studyGroups.tab', { id: studyId, tab: 'treatments' })} className="link">
                Treatment Groups section
              </Link>
            </OnClickCloseModalHOC>
            .
          </p>
          {apiError && <ApiErrorBanner />}
          <Controller
            name="workflowSetup.workflow[3].children"
            control={control}
            defaultValue={[]}
            render={({ onChange, value, name }: ControllerRenderProps) => (
              <Lookup
                name={name}
                isMulti
                defaultValue={value}
                handleSelect={onChange}
                loadOptions={loadOptions}
                closeMenuOnSelect={false}
              />
            )}
          />
        </>
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">Skip dosing for animals that do not require any treatments</label>
        <Controller
          defaultValue={false}
          name="workflowSetup.workflow[3].skipDosing"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => <Switch value={value} onChange={onChange} />}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <div>
          <label className="mb1 f6">Use new body weight</label>
          <small>For dose calculation without amending study data.</small>
        </div>
        <Controller
          defaultValue={false}
          name="workflowSetup.workflow[3].takeWeight"
          control={control}
          render={({ value, onChange, name }: ControllerRenderProps) => (
            <Switch testId={name} value={value} onChange={onChange} />
          )}
        />
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name="workflowSetup.workflow[3].onSave"
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search">Focus search bar</option>
              {Object.keys(toggleOptions).map((k) => (
                <option key={k} value={toggleOptions[k].value}>
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject">Move to next animal</option>
            </select>
          )}
        />
      </div>
    </div>
  );
};

export default Dosing;
