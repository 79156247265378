import Loading from '@/components/Loading';
import { SelectionReorder } from '@/components/Modals/SetupWorkflow/SelectionReorder';
import { assembleToggleOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.utils';
import Switch from '@/components/UI/FormElements/Switch';
import type { GlossaryItem } from '@/model/Glosary.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import FormField from '../Forms/FormField';

const formContextPath = 'workflowSetup.workflow[1]';
export const Samples: FC = () => {
  const { control, watch } = useFormContext();

  const { collection: samples, collectionLoading } = useFetchCollection<GlossaryItem>({
    collectionType: 'teamGlossary',
    queryParams: { group: 'samples' },
  });
  const toggleOptions = assembleToggleOptions(watch('workflowSetup.workflow'), 'samples');
  if (collectionLoading) {
    return <Loading />;
  }
  return (
    <>
      <Controller
        name={`${formContextPath}.preSelectedSampleTypeIds`}
        control={control}
        render={({ value, onChange }: ControllerRenderProps) => (
          <SelectionReorder
            selectionTitle="sample"
            options={samples ?? []}
            selection={value}
            selectionChange={(selection) => onChange(selection)}
          />
        )}
      />
      <FormField label="Auto generate Sample ID">
        <Controller
          defaultValue={true}
          name={`${formContextPath}.autoGenerateSampleIds`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => <Switch value={value} onChange={onChange} />}
        />
      </FormField>
      <FormField label="After saving">
        <Controller
          defaultValue="focus-search"
          name={`${formContextPath}.onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search">Focus search bar</option>
              {Object.keys(toggleOptions).map((k) => (
                <option key={k} value={toggleOptions[k].value}>
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject">Move to next animal</option>
            </select>
          )}
        />
      </FormField>
    </>
  );
};
