import { AllInputsRequiredForm } from '@/components/Glossary/Sections/Presets/Builder/AllInputsRequiredForm.tsx';
import { AutoSwapForm } from '@/components/Glossary/Sections/Presets/Builder/AutoSwapForm.tsx';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form@latest';

interface MeasurementConfigFormProps {
  index: number;
}

export const MeasurementConfigForm: FC<MeasurementConfigFormProps> = ({ index }) => {
  const formMethods = useFormContext<PresetCreateOrUpdateV1>();
  const measurementInputs = useWatch({ control: formMethods.control, name: `measurements.${index}.inputs` });
  return (
    <>
      <h3>Settings</h3>
      <p className="f6">
        Apply advanced configuration to your measurement.{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://help.benchling.com/hc/en-us/articles/29260886232589-Creating-and-managing-presets"
          className="link blue"
        >
          Read more
        </a>
      </p>
      <div className="flex flex-row mt4">
        <AllInputsRequiredForm index={index} disabled={measurementInputs.length > 0 && measurementInputs.length < 2} />
      </div>
      <div className="mt4">
        <Controller
          name={`measurements.${index}.config.data_analysis`}
          render={({ field }) => (
            <Checkbox
              label="Measurement is a tumor volume (mm³)"
              className="mt2"
              checked={field.value.efficacy && field.value.efficacy_prophylactic && field.value.oncology}
              disabled={field.disabled}
              onChange={(e) =>
                field.onChange({
                  efficacy: e.target.checked,
                  efficacy_prophylactic: e.target.checked,
                  oncology: e.target.checked,
                  survival: true,
                  tolerance: true,
                })
              }
              onBlur={field.onBlur}
            />
          )}
        />
      </div>
      <div className="flex flex-row mt4">
        <AutoSwapForm index={index} disabled={measurementInputs.length < 2} />
      </div>
    </>
  );
};
