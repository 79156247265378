import Button from '@/components/UI/Button';
import { DropdownMenuDivider } from '@/components/UI/Dropdown';
import Select from '@/components/UI/Select';
import { _notNil } from '@/littledash';
import { TargetField } from '@/model/Device.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import { useDevices } from '@/utils/devices/useDevices';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import { type FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const initialTargetFields = [
  { label: 'Follow focus', value: 'follow-focus' },
  { label: 'Search Bar', value: 'focus-search' },
  { label: 'Dosing Weight', value: 'dosing-weight' },
  { label: 'Assign ID', value: 'assign-id' },
];

export const DeviceConnections: FC = () => {
  const [targetFields, setTargetFields] = useState(initialTargetFields);
  const {
    mappedDevices,
    onChangeDeviceTarget,
    onChangeDeviceType,
    activePreset,
    deviceTypes,
    getDevices,
    refreshDevices,
    getDeviceType,
  } = useDevices({});

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  useEffect(() => {
    if (activePreset) {
      const targetFields = [...initialTargetFields];
      targetFields.push(
        ...activePreset.calculations.map(({ name, id }: PresetCalculation) => ({
          label: name,
          value: id,
        }))
      );

      setTargetFields(targetFields);
    }
  }, [activePreset]);

  return (
    <ModalContainer size="medium">
      <ModalHeader
        title="Device connections"
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
        subText="Find more information about connecting devices here. "
        readMoreLink="https://help.benchling.com/hc/en-us/articles/22039000010509"
      />
      <div className="pa3">
        <div className="pb3 flex justify-between items-end">
          <div className="flex items-center">
            <label>Active preset: {activePreset?.title}</label>
          </div>
          <div className="flex items-center">
            <Button
              onClick={() => {
                getDevices();
              }}
              plain
              className="mr2"
            >
              Connect device
            </Button>
            <Button
              onClick={() => {
                refreshDevices();
              }}
              plain
              icon="refresh"
              viewBox="0 0 24 24"
            />
          </div>
        </div>
        <DropdownMenuDivider />
        {_notNil(activePreset) && (
          <div>
            {mappedDevices?.map((device) => {
              const deviceType = getDeviceType(device.usb_product_id, device.usb_vendor_id);
              let usbProductId, usbVendorId;
              if (_notNil(deviceType?.type)) {
                usbProductId = deviceType.type.usb_product_id;
                usbVendorId = deviceType.type.usb_vendor_id;
              }
              return (
                <div key={usbProductId} className="pv3 flex justify-between">
                  <div className="pa1">
                    <div className="pb1 black-90">
                      {usbProductId || '-'} · {usbVendorId || '-'}
                    </div>
                    <div>{deviceType?.type?.title || 'Unmapped'}</div>
                  </div>
                  <div className="flex pa1 w-two-thirds">
                    <select
                      className="mw5 mb0 mr2"
                      value={deviceType?.type?.id}
                      onChange={(event) => {
                        if (_notNil(deviceType)) {
                          onChangeDeviceType(deviceType, event?.target.value);
                        }
                      }}
                    >
                      <option>Unselected</option>
                      {deviceTypes.map(({ title, id }) => (
                        <option key={id} value={id}>
                          {title}
                        </option>
                      ))}
                    </select>

                    <Select
                      value={device.target}
                      options={targetFields}
                      isMulti={true}
                      onChange={(targets) => {
                        if (_notNil(onChangeDeviceTarget)) {
                          onChangeDeviceTarget(device, targets as TargetField[]);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
