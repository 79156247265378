import { MeasurementForm } from '@/components/Glossary/Sections/Presets/Builder/MeasurementForm.tsx';
import { MeasurementsForm } from '@/components/Glossary/Sections/Presets/Builder/MeasurementsForm.tsx';
import {
  CreateOrUpdatePresetContext,
  DraftMeasurement,
  formDefault,
  reducer,
  SlugDetail,
  useFormResolverHook,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import { PresetHeader } from '@/components/Glossary/Sections/Presets/Builder/PresetHeader.tsx';
import { PresetPreview } from '@/components/Glossary/Sections/Presets/Builder/PresetPreview.tsx';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button/Button';
import { errorToast, successToast } from '@/helpers.tsx';
import { _notNil } from '@/littledash.ts';
import InVivoError from '@/model/InVivoError.ts';
import { PresetApiId, PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { useQueryParams } from '@/support/Hooks/useQueryParams/useQueryParams.ts';
import { web } from '@/support/route.ts';
import { structuredCloneUtil } from '@/utils/browser.utils.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form@latest';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import style from './PresetBuilder.module.scss';

export const PresetBuilder: FC = () => {
  const slugDetailsRef = useRef<Array<SlugDetail>>([]);
  const [state, dispatch] = useReducer(reducer, {
    draftMeasurements: new Map<string, DraftMeasurement>(),
  });
  const resolver = useFormResolverHook(slugDetailsRef);
  const { presetApiId, presetAction } = useParams<{
    presetApiId: PresetApiId;
    presetAction: 'create' | 'edit' | 'clone';
  }>();
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation<{ preset?: PresetCreateOrUpdateV1 }>();

  const [selectedMeasurementIndex, updateSelectedMeasurementIndex] = useState<number>(-1);
  const { response, invoke: loadPreset } = useApiHook({
    endpoint: 'GET /api/v1/team/presets/{presetApiId}',
    invokeOnInit: false,
  });
  const { invoke: createPreset } = useApiHook({
    endpoint: 'POST /api/v1/team/presets',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });
  const { invoke: updatePreset } = useApiHook({
    endpoint: 'POST /api/v1/team/presets/{presetApiId}',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });
  const formDisabled = useMemo(
    () => (response?.type === 'success' && presetAction === 'edit' ? response.body.published : false),
    [response, presetAction]
  );

  const formMethods = useForm<PresetCreateOrUpdateV1>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    disabled: formDisabled,
    resolver,
    defaultValues: { ...(location?.state?.preset ?? structuredCloneUtil(formDefault)) },
  });
  const measurements = useWatch({ control: formMethods.control, name: 'measurements' });

  const handleSubmit = async (formData: PresetCreateOrUpdateV1) => {
    if (formDisabled) {
      errorToast('Published preset cannot be updated');
      return Promise.resolve();
    }
    try {
      if (presetAction === 'edit') {
        await updatePreset({ path: { presetApiId }, body: formData });
      } else {
        await createPreset({ body: formData });
      }

      requestAnimationFrame(() => {
        history.push(web('team.glossary.presets'));
      });
      successToast('Preset saved');
    } catch (err) {
      const ivError = new InVivoError('Could not create new study preset', { slug: 'preset-builder', cause: err });
      ExceptionHandler.captureException(ivError);
      errorToast('Could not create preset');
      return Promise.reject(ivError);
    }
  };
  const handleMeasurementSelected = (index: number) => {
    if (!formMethods.formState.isSubmitting) {
      updateSelectedMeasurementIndex(index);
      if (index < 0) {
        queryParams.delete('measurement');
      } else {
        queryParams.set('measurement', `${index}`);
      }
      history.push({ search: queryParams.toString() });
    }
  };

  useEffect(() => {
    if (_notNil(presetApiId)) {
      loadPreset({ path: { presetApiId } }).then((response) => {
        if (response.type === 'success') {
          const { name, measurements } = response.body;
          formMethods.reset({ name, measurements });
        }
      });
    }
  }, [formMethods, presetApiId]);
  useEffect(() => {
    const index = Number(queryParams.get('measurement') ?? '-1');
    if (Number.isFinite(index)) {
      updateSelectedMeasurementIndex(index);
    }
  }, [queryParams, updateSelectedMeasurementIndex]);

  const windowBeforeUnloadListener = useCallback(
    (event: BeforeUnloadEvent) => {
      if (formMethods.formState.isDirty && !formMethods.formState.isSubmitSuccessful) {
        event.preventDefault();
        return 'Changes you have made may not be saved';
      }
    },
    [formMethods]
  );
  const reactRouterBeforeUnload = useCallback(
    (location: any, action: string) => {
      if (
        window.location.pathname !== (location as Location).pathname &&
        formMethods.formState.isDirty &&
        !formMethods.formState.isSubmitSuccessful &&
        !formMethods.formState.disabled
      ) {
        return 'Changes you have made may not be saved';
      }
      return true;
    },
    [formMethods]
  );
  useEffect(() => {
    const abortController = new AbortController();
    window.addEventListener('beforeunload', windowBeforeUnloadListener, {
      capture: true,
      signal: abortController.signal,
    });
    return () => abortController.abort('unload');
  }, [windowBeforeUnloadListener]);

  if (_notNil(presetApiId) && response?.type !== 'success') {
    return <Loading txt="Loading preset" />;
  }

  return (
    <CreateOrUpdatePresetContext.Provider value={{ state, slugDetailsRef, dispatch }}>
      <Prompt message={reactRouterBeforeUnload} />
      <FormProvider {...formMethods}>
        {selectedMeasurementIndex < 0 || measurements.length < selectedMeasurementIndex ? (
          <div className="flex w-100">
            <div className="flex flex-row h-100 flex-grow-1">
              <div className="pt3 pl4 mr2 flex flex-column w-100">
                <PresetHeader action={presetAction} />
                <Controller
                  name="name"
                  control={formMethods.control}
                  disabled={formMethods.formState.disabled}
                  render={({ field, fieldState }) => (
                    <>
                      <label>Preset name</label>
                      <input
                        {...field}
                        type="text"
                        autoComplete="off"
                        className={cn({ ui__disabled: field.disabled, ui__error: fieldState.invalid })}
                        style={{ minHeight: '41px' }}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={formMethods.formState.errors}
                  name="name"
                  render={({ message }) => <p className="f6 red db">{message}</p>}
                />
                <div className="flex flex-row justify-between mt3">
                  <MeasurementsForm handleMeasurementSelected={handleMeasurementSelected} />
                </div>

                <div className="flex flex-row pt4 pb4">
                  <Button
                    className="mr3"
                    onClick={formMethods.handleSubmit(handleSubmit)}
                    loading={formMethods.formState.isSubmitting}
                    testId="save-preset-button"
                    disabled={
                      !(formMethods.formState.isValid || formMethods.formState.isDirty) ||
                      formMethods.formState.isSubmitting ||
                      formMethods.formState.disabled
                    }
                  >
                    Save preset
                  </Button>
                  <Button
                    plain
                    disabled={formMethods.formState.isSubmitting}
                    onClick={() => history.push(web('team.glossary.presets'))}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <div className={`${style.previewSidebar}`}>
              <div
                className={`${style.previewSidebar} fixed right-0 bl h-100 b--moon-gray ui__overflow__scroll_y bg-white`}
              >
                <PresetPreview />
              </div>
            </div>
          </div>
        ) : (
          <div className="pt3 ph4">
            <MeasurementForm index={selectedMeasurementIndex} handleClose={() => handleMeasurementSelected(-1)} />
          </div>
        )}
      </FormProvider>
    </CreateOrUpdatePresetContext.Provider>
  );
};
