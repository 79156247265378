import { _notNil, uuid } from '@/littledash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DeviceContext, DevicesProviderContextResponse } from './DevicesProvider';

interface UseDevicesProps {
  targetId?: string;
  onNewReading?: (reading: string) => void;
  ref?: React.RefObject<HTMLElement>;
}

type UnwantedKeys =
  | 'nextReading'
  | 'readings'
  | 'clearReading'
  | 'deregisterTarget'
  | 'registerTarget'
  | 'isTargetReady';

interface UseDevicesResponse extends Omit<DevicesProviderContextResponse, UnwantedKeys> {
  nextReading: (complete: boolean) => void;
}

export const useDevices = ({ targetId, onNewReading }: UseDevicesProps): UseDevicesResponse => {
  const [identifier] = useState(uuid());
  const {
    disabled,
    deviceTypes,
    mappedDevices,
    unmappedDevices,
    readyTargets,
    readings,
    activePreset,
    onChangeDeviceTarget,
    onChangeDeviceType,
    clearReading,
    deregisterTarget,
    registerTarget,
    nextReading,
    getDevices,
    getDeviceType,
    refreshDevices,
    isTargetReady,
  } = useContext(DeviceContext);

  useEffect(() => {
    if (_notNil(targetId) && _notNil(mappedDevices)) {
      registerTarget(targetId, identifier);
      return () => {
        deregisterTarget(targetId, identifier);
      };
    }
  }, [mappedDevices]);

  useEffect(() => {
    if (_notNil(targetId)) {
      const isReady = isTargetReady(targetId, identifier);
      const reading = readings[targetId];
      // ignore the reading if the field is not visible or if the ref has not been set
      if (_notNil(reading) && _notNil(onNewReading)) {
        clearReading(targetId);
        if (isReady) {
          onNewReading(reading);
        } else {
          _nextReading(true);
        }
      }
    }
  }, [readings]);

  const _nextReading = useCallback(
    (complete: boolean) => {
      if (_notNil(targetId)) {
        nextReading(targetId, complete);
      }
    },
    [targetId]
  );

  return {
    disabled,
    deviceTypes,
    mappedDevices,
    unmappedDevices,
    readyTargets,
    onChangeDeviceTarget,
    onChangeDeviceType,
    activePreset,
    nextReading: _nextReading,
    getDevices,
    getDeviceType,
    refreshDevices,
  };
};
