import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import GroupLabel from '@/components/UI/GroupLabel';
import Link from '@/components/UI/Link';
import type { Column, FilterOption } from '@/components/UI/Table/TableComponent.model';
import UserAvatar from '@/components/UI/UserAvatar';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { fetchMetadataColumns, formatNumber } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import type { Sample, SampleMeasurement } from '@/model/Sample.model';
import type { BaseUser } from '@/model/User.model';
import { web as webRoute } from '@/support/route';
import { DateRenderFormat, DateTimeRenderFormat } from '@/utils/Date.utils';

export const buildColumns = (
  groupMetadata: Array<MetadataFieldWithValue> = [],
  sampleMetadata: Array<MetadataFieldWithValue> = [],
  columns: Record<string, boolean>,
  isStudy: boolean
): Array<Column<Sample>> => {
  const cellWidth = 100;

  let tableColumns: Array<Column<Sample>> = [
    {
      id: 'sample_id',
      Header: 'Sample ID',
      accessor: 'sample_id',
      width: cellWidth,
      Cell: ({ row: { original } }) => original?.sample_id ?? '-',
      isVisible: columns?.sample_id ?? true,
    },
    {
      id: 'subject',
      Header: 'Animal',
      accessor: 'subject',
      width: cellWidth,
      Cell: ({ row: { original } }) => {
        if (_isNil(original?.subject?.id)) {
          return '-';
        }
        return (
          <Link
            className="link blue"
            to={webRoute('animals.show', {
              id: original.study_id,
              subject_id: original?.subject?.id,
            })}
          >
            {original?.subject?.name}
          </Link>
        );
      },
      isVisible: columns?.subject ?? true,
    },
    {
      id: 'tail',
      Header: 'Tail',
      accessor: 'subject.alt_ids.tail',
      sortDisabled: true,
      Cell: ({ row: { original } }) => original?.subject?.alt_ids?.tail ?? '-',
      isVisible: columns?.tail,
    },
    {
      id: 'donor',
      Header: 'Donor',
      accessor: 'subject.alt_ids.donor',
      sortDisabled: true,
      Cell: ({ row: { original } }) => original?.subject?.alt_ids?.donor ?? '-',
      isVisible: columns?.donor,
    },
    {
      id: 'ear',
      Header: 'Ear',
      accessor: 'subject.alt_ids.ear',
      sortDisabled: true,
      Cell: ({ row: { original } }) => original?.subject?.alt_ids?.ear ?? '-',
      isVisible: columns?.ear,
    },
    {
      id: 'tag',
      Header: 'Tag',
      accessor: 'subject.alt_ids.tag',
      sortDisabled: true,
      Cell: ({ row: { original } }) => original?.subject?.alt_ids?.tag ?? '-',
      isVisible: columns?.tag,
    },
    {
      id: 'collection',
      Header: 'Cage',
      accessor: 'collection',
      width: cellWidth,
      Cell: ({ row: { original } }) => original?.collection?.name ?? '-',
      isVisible: columns?.collection ?? true,
    },
    {
      id: 'date',
      Header: 'Date taken',
      accessor: 'date',
      width: cellWidth,
      Cell: ({ row: { original } }) => (
        <DateTimeRenderer value={original?.date} format={DateRenderFormat.Date} defaultResponse="-" />
      ),
      isVisible: columns?.date ?? true,
    },
    {
      id: 'time',
      Header: 'Time taken',
      accessor: 'time',
      width: cellWidth,
      Cell: ({ row: { original } }) => (
        <DateTimeRenderer value={original?.date} format={DateTimeRenderFormat.Time} defaultResponse="-" />
      ),
      isVisible: columns?.time ?? true,
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'type',
      width: cellWidth,
      Cell: ({ row: { original } }) => original?.type ?? '-',
      isVisible: columns?.type ?? true,
    },
    {
      id: 'user',
      Header: 'User',
      accessor: 'user',
      width: cellWidth,
      Cell: ({ row: { original } }) => (
        <UserAvatar user={original?.user} tooltip={original?.user?.name ?? original?.user?.email} />
      ),
      isVisible: columns?.user ?? true,
    },
    {
      id: 'study_group',
      Header: 'Group',
      accessor: 'study_group',
      width: 150,
      style: { overflow: 'visible' },
      Cell: ({ row: { original } }) => <GroupLabel group={original?.study_group} />,
      isVisible: columns?.study_group ?? true,
    },
  ];

  if (isStudy) {
    tableColumns = [
      ...tableColumns,
      {
        id: 'comments',
        Header: 'Comments',
        accessor: 'comments',
        sortDisabled: true,
        width: cellWidth,
        Cell: ({ row: { original } }) => original?.comments ?? '-',
        isVisible: columns?.comments ?? true,
      },
      {
        id: 'weight',
        Header: 'Weight',
        accessor: 'details?.weight',
        sortDisabled: true,
        Cell: ({ row: { original } }) => processDetails(original?.details, 'weight'),
        isVisible: columns?.weight,
      },
      {
        id: 'volume',
        Header: 'Volume',
        accessor: 'details?.volume',
        sortDisabled: true,
        Cell: ({ row: { original } }) => processDetails(original?.details, 'volume'),
        isVisible: columns?.volume,
      },
      {
        id: 'length',
        Header: 'Length',
        accessor: 'details?.length',
        sortDisabled: true,
        Cell: ({ row: { original } }) => processDetails(original?.details, 'length'),
        isVisible: columns?.length,
      },
      {
        id: 'depth',
        Header: 'Depth',
        accessor: 'details?.depth',
        sortDisabled: true,
        Cell: ({ row: { original } }) => processDetails(original?.details, 'depth'),
        isVisible: columns?.depth,
      },
      {
        id: 'width',
        Header: 'Width',
        accessor: 'details?.width',
        sortDisabled: true,
        Cell: ({ row: { original } }) => processDetails(original?.details, 'width'),
        isVisible: columns?.width,
      },
    ];
  } else {
    tableColumns = [
      ...tableColumns,
      ...fetchMetadataColumns({ metadata: groupMetadata, columns, nestedStudyLocation: 'study_group' }),
      {
        id: 'study',
        Header: 'Study',
        accessor: 'study',
        sortDisabled: true,
        width: cellWidth,
        Cell: ({ row: { original } }) => {
          if (_isNil(original['study_link']?.id)) {
            return '-';
          }
          return (
            <Link
              className="link blue"
              to={webRoute('studies.show', {
                id: original['study_link']?.id,
              })}
            >
              {original['study_link']?.name}
            </Link>
          );
        },
        isVisible: columns?.study ?? true,
      },
      {
        id: 'maxSubjects',
        Header: 'Max Subjects',
        accessor: 'max_subjects',
        sortDisabled: true,
        width: cellWidth,
        Cell: ({ row: { original } }) => {
          return original?.study_group?.max_subjects ?? '-';
        },
        isVisible: columns?.max_subjects ?? true,
      },
    ];
  }

  return [...tableColumns, ...fetchMetadataColumns({ metadata: sampleMetadata, columns })];
};

const processDetails = (details: Array<SampleMeasurement>, key: string) => {
  const detailsInstance = details?.find((x) => x.key === key);
  if (_isEmpty(detailsInstance)) {
    return '-';
  }
  if (detailsInstance?.value) {
    return `${formatNumber(detailsInstance.value)} ${detailsInstance?.unit}`;
  }
  return `${detailsInstance?.value} ${detailsInstance?.unit}`;
};

export const generateFilterOptions = (users: Array<BaseUser>, studyId: ID) => {
  const mapped = users.map(({ name, id: value }) => ({
    name,
    value,
  }));

  const filterOptions: Array<FilterOption> = [
    {
      value: 'type',
      name: 'Type',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [],
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: [],
        },
      ],
      fetchFromApi: {
        placeholder: 'Sample type',
        url: 'team-glossary.list',
        queryParams: { group: 'samples' },
      },
    },
    {
      value: 'user',
      name: 'User',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: mapped,
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: mapped,
        },
      ],
    },
  ];

  if (_notNil(studyId)) {
    filterOptions.push({
      value: 'study_group',
      name: 'Study Group',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [],
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: [],
        },
        {
          name: filterType.IS_NULL,
          value: filterType.IS_NULL,
        },
        {
          name: filterType.IS_NOT_NULL,
          value: filterType.IS_NOT_NULL,
        },
      ],
      fetchFromApi: {
        placeholder: 'Search groups',
        url: 'studies.groups.list',
        params: {
          id: studyId,
        },
      },
    });
  } else {
    filterOptions.push({
      value: 'study',
      name: 'Study',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [],
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: [],
        },
      ],
      fetchFromApi: {
        placeholder: 'Search studies',
        url: 'studies.search',
      },
    });
  }

  return filterOptions;
};

export const validateManageSamplesSelection = (
  hasAccess: boolean,
  selectedSamples: Array<Sample> | undefined = []
): { disabled: boolean; tooltip: string } => {
  const defaultResult = {
    disabled: false,
    tooltip: '',
  };

  if (_isEmpty(selectedSamples)) {
    return defaultResult;
  }

  if (_isNotEmpty(selectedSamples)) {
    if (!hasAccess) {
      return {
        disabled: true,
        tooltip: 'You must have at least write access to the study to manage samples',
      };
    } else if (new Set(selectedSamples.map((x) => x.type)).size > 1) {
      return {
        disabled: true,
        tooltip: 'You cannot edit more than one type of sample at a time',
      };
    }
  }
  return defaultResult;
};
