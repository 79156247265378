// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import TasksV1 from '@/components/Studies/Create/Steps/Tasks/TasksV1';
import { TasksV2 } from '@/components/Studies/Settings/Steps/Tasks/TasksV2';
import Banner from '@/components/UI/Banner';
import StepForm from '@/components/UI/StepForm';
import SubHeader from '@/components/UI/SubHeader';
import { isNotApproved, successToast } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import { StudyFormMetaDetails } from '@/model/StudyForm.model';
import { StudyUser } from '@/model/User.model';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AlertsSettings } from '../Create/Steps/Alerts/AlertsSettings';
import BenchlingIntegration from '../Create/Steps/BenchlingIntegration';
import Information from '../Create/Steps/Information';
import SelectPreset from '../Create/Steps/SelectPreset';
import Team from '../Create/Steps/Team';
import {
  DefaultFormProvider,
  generateInitialState,
  PresetFormProvider,
  reducer,
  UseFormProvider,
} from './Settings.utils';
import DangerZone from './Steps/DangerZone';
import Metadata from './Steps/Metadata';
import { BENCHLING_INTEGRATION_META_SLUG } from '@/helpers.constants.tsx';

interface SettingsStepFormProps {
  study?: Study;
  defaultProps: unknown;
}

const Settings = () => {
  const { id: studyId } = useParams();

  const {
    entity: study,
    fetchEntity: reloadStudy,
    entityLoading: studyLoading,
  } = useFetchEntity({
    entityType: 'study',
    params: { id: studyId },
    includes: 'metadata,study_groups,users,tasks,project,preset',
  });

  const [state, dispatch] = useReducer(reducer, {
    form: { loading: true, studyGroups: [] },
  });

  const loadAndInitialiseState = async () => {
    if (Auth.isWriteUserForStudy(study, false, false)) {
      const initialState = generateInitialState(study);

      try {
        const taskTemplateResponse = await Http.get(apiRoute('tasks.templateGroups.show'));
        const presetResponse = await Http.get(apiRoute('study.presets'));
        const hasMeasurementsResponse = await Http.get(apiRoute('studies.check-measurements', { id: study.id }));
        let studyFormResponse;
        if (_notNil(study?.study_creation_template_id)) {
          studyFormResponse = await Http.get(
            apiRoute('team.studies.forms.get', { formId: study.study_creation_template_id })
          );
        }
        dispatch({
          type: 'initialiseState',
          data: {
            ...initialState,
            form: {
              ...initialState.form,
              templates: taskTemplateResponse.data.data,
              presets: presetResponse.data.data,
              hasMeasurements: hasMeasurementsResponse.data.has_measurements,
            },
            study: {
              ...initialState.study,
              studyForm: (studyFormResponse?.data?.data ?? {}) as StudyFormMetaDetails,
            },
          },
        });
      } catch (error) {
        dispatch({ type: 'SET_API_ERROR', data: error });
      } finally {
        dispatch({ type: 'SET_LOADING', data: false });
      }
    } else {
      dispatch({ type: 'SET_LOADING', data: false });
    }
  };

  useEffect(() => {
    if (_notNil(study) && !studyLoading) {
      loadAndInitialiseState();
    }
  }, [study, studyLoading]);

  useEffect(() => {
    if (state?.form?.submitting) {
      handlePatchStudy();
    }
  }, [state?.form?.submitting]);

  useEffect(() => {
    if (state?.form?.updatingBenchlingIntegration) {
      handlePatchBenchlingIntegration();
    }
  }, [state?.form?.updatingBenchlingIntegration]);

  const handlePatchStudy = async () => {
    try {
      const payload = {
        ...state.study,
      };
      delete payload.metadata;
      if (!Auth.isAuthor(study)) {
        // # Only authors can update the owner
        delete payload.owner;
      }
      await Http.patch(apiRoute('studies.update', study.id), payload);
      await reloadStudy();
      successToast('Successfully updated study.');
    } catch (error) {
      dispatch({ type: 'SET_API_ERROR', data: error });
    } finally {
      dispatch({ type: 'SET_SUBMITTING', data: false });
    }
  };

  const handlePatchBenchlingIntegration = async () => {
    try {
      const metadataValues = state.study.metadata.map((metadata) => ({
        glossary_id: String(metadata.glossary_id),
        value: metadata.value,
      }));

      const payload = {
        metadata: metadataValues,
      };
      await Http.patch(apiRoute('studies.update', study.id), payload);
      await reloadStudy();
      successToast('Successfully updated study.');
    } catch (error) {
      dispatch({ type: 'SET_API_ERROR', data: error });
    } finally {
      dispatch({ type: 'SET_UPDATING_BENCHLING_INTEGRATION', data: false });
    }
  };

  if (state.form.loading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  const studyNotApproved = isNotApproved(study);

  return (
    <>
      {Auth.isWriteUserForStudy(study, false, false) ? (
        <>
          <SubHeader linkToText={study.name} link={webRoute('studies.show', study.id)} />
          <div className="ph4">
            {state.form.archivedAt && (
              <Banner info className="mw6 mt4" dismiss={false}>
                <h3 className="f5 normal lh-title">This study has been completed</h3>
                <p className="f6 pt2 lh-copy">
                  Studies that have been completed can not change settings. You can resume a study in its danger zone.
                </p>
              </Banner>
            )}
            {state.form.canceledAt && (
              <Banner info className="mw6 mt4" dismiss={false}>
                <h3 className="f5 normal lh-title">This study has been cancelled</h3>
                <p className="f6 pt2 lh-copy">
                  Studies that have been cancelled can not change settings. You can resume a study in it&apos;s danger
                  zone.
                </p>
              </Banner>
            )}
            {state.form.error && (
              <ApiErrorBanner className="mb4" errorType={ApiErrorType.FETCH} error={state.form.error} />
            )}
            <SettingsStepForm
              study={study}
              defaultProps={{
                state,
                dispatch,
                handlePatchStudy,
                reloadStudy,
              }}
            />
          </div>
        </>
      ) : (
        <div className="w-100 h-100 flex items-center justify-center">
          <NoData
            title="You aren't authorized"
            text="Only team members with Write access have access to use Settings. The study author can update your permissions to give you access."
            link={`/studies/${studyId}${studyNotApproved ? '' : '/animals/'}`}
            btnTxt={`Return to ${studyNotApproved ? 'overview' : 'animals'}`}
          />
        </div>
      )}
    </>
  );
};

const PresetLockedMessage = ({ reason }) => (
  <div className="ui-card pa3 mw7">
    <div style={{ height: 400 }} className="pa3">
      <div className="flex flex-wrap justify-center items-center br2 bg-near-white h-100 w-100 tc">
        <div>
          <h3 className="normal lh-title f4 near-black">This study&apos;s preset is locked</h3>
          <p className="lh-copy f5 center mt2 measure-wide">{reason}</p>
        </div>
      </div>
    </div>
  </div>
);

const SettingsStepForm: React.FC<SettingsStepFormProps> = ({ study, defaultProps }) => {
  const features = useSelector((state: State) => state.team.features);
  const benchlingFoldersEnabled = features?.benchling_folders;

  const tasksVersion = !(features?.hide_tasks ?? false) ? 'V2' : 'V1';
  const currentUser = useSelector((state: State) => state.user.currentUser);

  const { response: userListResponse } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/users',
    path: { studyId: study.api_id },
    query: { perPage: -1 },
  });

  const usersData = userListResponse?.type === 'success' ? userListResponse.body.data : [];

  const users = useMemo(() => {
    const userMap = new Map(
      [...(_isNil(study?.author) ? [] : [study.author]), ...(usersData ?? [])].map((user) => [
        user.api_id,
        user as StudyUser,
      ])
    );
    return Array.from(userMap.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [currentUser, study, usersData]);

  const {
    collection: alerts,
    collectionLoading: loadingAlerts,
    fetchCollection: reloadAlerts,
  } = useFetchCollection({
    collectionType: 'studyAlertSettings',
    params: { studyId: study.id },
  });

  const steps = [
    {
      name: 'Information',
      isValid: () => true,
      renderStep: () => (
        <UseFormProvider {...defaultProps}>
          <Information {...defaultProps} settings />
        </UseFormProvider>
      ),
    },
    {
      name: 'Team',
      isValid: () => true,
      renderStep: () => (
        <UseFormProvider {...defaultProps}>
          <Team
            {...defaultProps.state.study}
            author={defaultProps.state.form.author}
            dispatch={defaultProps.dispatch}
            fromSettings
          />
        </UseFormProvider>
      ),
    },
    {
      name: 'Preset',
      isValid: () => true,
      renderStep: () => {
        if (defaultProps.state.form.hasMeasurements) {
          return <PresetLockedMessage reason="Once measurements have been recorded, the preset can't be changed." />;
        }

        if (_isNotEmpty(alerts)) {
          return <PresetLockedMessage reason="Once alerts have been added, the preset can't be changed." />;
        }

        return (
          <PresetFormProvider {...defaultProps}>
            <SelectPreset {...defaultProps} />
          </PresetFormProvider>
        );
      },
    },
    {
      name: 'Alerts',
      isValid: () => true,
      renderStep: () =>
        loadingAlerts && _isNil(alerts) ? (
          <Loading txt="Loading Alerts" />
        ) : (
          <AlertsSettings study={study} alerts={alerts} reloadAlerts={reloadAlerts} />
        ),
    },
    {
      name: 'Tasks',
      isValid: () => true,
      renderStep: () => {
        switch (tasksVersion) {
          case 'V2':
            if (_notNil(study)) {
              return (
                <TasksV2
                  study={study}
                  users={users}
                  groups={study.study_groups}
                  createTaskEnabled={Auth.isWriteUserForStudy(study)}
                />
              );
            }
            return null;
          default:
            return (
              <DefaultFormProvider {...defaultProps}>
                <TasksV1 {...defaultProps} />
              </DefaultFormProvider>
            );
        }
      },
    },
    {
      name: 'Metadata',
      isValid: () => true,
      renderStep: () => <Metadata {...defaultProps} />,
    },
  ];

  const benchlingFolderMetaGlossary = useMemo(() => {
    return defaultProps?.state?.study?.studyForm?.sections?.reduce(
      (acc, section) => acc || section?.metadata?.find((meta) => meta.info.slug === BENCHLING_INTEGRATION_META_SLUG),
      null
    );
  }, [defaultProps?.state?.study?.studyForm]);

  const benchlingIntegrationOnSubmit = (data) => {
    defaultProps.dispatch({ type: 'updateStudy', data });
    defaultProps.dispatch({ type: 'SET_UPDATING_BENCHLING_INTEGRATION', data: true });
  };

  if (benchlingFoldersEnabled && _notNil(benchlingFolderMetaGlossary)) {
    steps.push({
      name: 'Benchling Integration',
      isValid: () => true,
      renderStep: () => (
        <UseFormProvider state={defaultProps.state} overrideOnSubmit={benchlingIntegrationOnSubmit}>
          <BenchlingIntegration
            state={defaultProps.state}
            dispatch={defaultProps.dispatch}
            metaglossary={benchlingFolderMetaGlossary}
            studyID={defaultProps.state.study.id}
          />
        </UseFormProvider>
      ),
    });
  }

  steps.push({
    name: 'Danger zone',
    isValid: () => true,
    renderStep: () => <DangerZone study={study} />,
  });

  return (
    <StepForm
      steps={steps}
      initialState={{ currentStep: 0, steps }}
      testPrefix="study-settings"
      disableNav
      disableOverflow
    />
  );
};

export default Settings;
