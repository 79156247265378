// @ts-nocheck: converted from JS

import { _isEmpty } from '@/littledash';
import { useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import TableHeader from './Cells/TableHeader';
import Pagination from './PaginationNew';
import { calculateWindowHeight, cellProps, headerProps, virtualisedTableConfig } from './Table.utils';
import { useTableContext } from './TableContext';

const TableWithContext = ({ tableRef, testIdPrefix }) => {
  const [rowWidth, setRowWidth] = useState();
  const [tableWidth, setTableWidth] = useState();
  const { rowHeight } = virtualisedTableConfig;

  const { NoDataComponent, ...tableContext } = useTableContext();
  const {
    headerGroups,
    getTableBodyProps,
    rows,
    page,
    pageCount,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    pageSizes,
    setPageSize,
    totalColumnsWidth,
    noDataMessage,
  } = tableContext;

  const _NoDataComponent = NoDataComponent instanceof Function ? NoDataComponent(tableContext) : NoDataComponent;

  const resizeWindow = () => {
    setTableWidth(tableRef?.current?.scrollWidth ?? totalColumnsWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow, {
      capture: false,
      passive: true,
    });
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  useEffect(() => {
    const currentWidth = totalColumnsWidth <= tableWidth ? tableWidth : totalColumnsWidth;

    setRowWidth(currentWidth);
  }, [totalColumnsWidth, tableWidth]);

  return (
    <>
      <div
        className="ui__table"
        data-testid={`${testIdPrefix}__api-table`}
        data-test-component="TableWithContext"
        data-test-element="container"
      >
        <div className="table overflow-auto">
          <div style={{ width: rowWidth }}>
            <div className="thead" data-test-element="header" data-testid={`${testIdPrefix}__api-table-header`}>
              {headerGroups.map((headerGroup, index) => (
                <div className="tr" key={`headerGroup_${index}`} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <TableHeader {...column} key={`header_${index}`} headerProps={headerProps} />
                  ))}
                </div>
              ))}
            </div>
            <div
              className="tbody"
              {...getTableBodyProps()}
              data-test-element="body"
              data-testid={`${testIdPrefix}__api-table-body`}
            >
              {!_isEmpty(rows) ? (
                <FixedSizeList
                  width={rowWidth}
                  height={calculateWindowHeight(page.length)}
                  itemCount={page.length}
                  itemSize={rowHeight}
                >
                  {RenderRow}
                </FixedSizeList>
              ) : (
                <>
                  {_NoDataComponent ? (
                    <div className="ma3">{_NoDataComponent}</div>
                  ) : (
                    <div className="flex flex-column white min-h5 justify-center items-center tc pa3 ma3 bg-light-gray">
                      <h2 className="tc pa3 ma3">{noDataMessage ?? 'No rows to display'}</h2>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {pageCount && (
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizes={pageSizes}
          setPageSize={setPageSize}
          testPrefix={testIdPrefix}
        />
      )}
    </>
  );
};

const RenderRow = ({ index, style }) => {
  const { prepareRow, page } = useTableContext();
  const row = page[index];
  prepareRow(row);
  return (
    <div
      {...row.getRowProps({
        style,
      })}
      className="tr"
      data-test-component="RenderRow"
      data-test-element="container"
      data-test-key={index}
      data-testid={`api-table-row-${index}`}
    >
      {row.cells.map((cell, columnIndex) => {
        return (
          <div
            className={`td ${cell.column.className} bt b--moon-gray`}
            key={`row_${row.id}`}
            data-test-component="RenderRow"
            data-test-element="cell"
            data-test-key={`( ${cell?.column?.id} , ${cell?.row?.id} )`}
            {...cell.getCellProps(cellProps)}
            data-testid={`api-table-column-${columnIndex}`}
          >
            {cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
};

export default TableWithContext;
