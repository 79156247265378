import type { FC, ReactNode } from 'react';
import { type StepRenderProps, AddTaskFormTypes } from '../AddTask.model';
import Radio from '@/components/UI/FormElements/Radio';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import Button from '@/components/UI/Button';
import Loading from '@/components/Loading';
import type { TreatmentGroup } from 'model/TreatmentGroup.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import useSearch from '@/support/Hooks/useSearch';
import { _isEmptyString, _isNotEmpty, _notNil } from '@/littledash';
import { trunc } from '@/helpers';
import { SelectMenu } from '@/components/UI/SelectMenu';
import styles from '../AddTask.module.scss';
import { ErrorMessage } from '@hookform/error-message';

interface AddTaskOverviewFormProps extends StepRenderProps {}

const AddTaskTargetForm: FC<AddTaskOverviewFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  studyId,
  studyCreationGroups,
  disabledFields = [],
}) => {
  const { control, watch, handleSubmit: useFormHandleSubmit } = useFormContext();
  const selectedType = watch(`${AddTaskFormTypes.target}.type`);

  return (
    <form
      data-test-component="GroupTargetForm"
      className={styles['step-content']}
      onSubmit={useFormHandleSubmit(onSubmit)}
    >
      <div data-test-element="GroupTargetAnimals">
        <label className="mb2 ph3">This task applies to:</label>
        <div className="mb2 ph3">
          <Controller
            name={`${AddTaskFormTypes.target}.type`}
            control={control}
            defaultValue="animal"
            render={({ onChange }: ControllerRenderProps) => (
              <Radio
                onChange={onChange}
                id="animal"
                value="animal"
                label="All animals"
                checked={selectedType === 'animal'}
                disabled={disabledFields.includes(`${AddTaskFormTypes.target}.type`)}
              />
            )}
          />
        </div>
        <div data-test-element="GroupTargetGroups">
          <div className="mb2 ph3">
            <Controller
              name={`${AddTaskFormTypes.target}.type`}
              control={control}
              defaultValue="animal"
              render={({ onChange }: ControllerRenderProps) => (
                <Radio
                  onChange={onChange}
                  id="group"
                  value="group"
                  label="Groups"
                  checked={selectedType === 'group'}
                  disabled={disabledFields.includes(`${AddTaskFormTypes.target}.type`)}
                />
              )}
            />
          </div>
          {selectedType === 'group' && (
            <div className={`${disabledFields.includes(`${AddTaskFormTypes.target}.groups`) ? 'ui__disabled' : ''}`}>
              {_notNil(studyId) ? (
                <StudyGroupsProvider studyId={studyId}>
                  {(groups: TreatmentGroup[]) => <GroupMenu groups={groups} />}
                </StudyGroupsProvider>
              ) : (
                <GroupMenu groups={studyCreationGroups ?? []} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

const StudyGroupsProvider: FC<{ studyId: number; children: (studyGroups: TreatmentGroup[]) => ReactNode }> = ({
  studyId,
  children,
}) => {
  const { collection: groups, collectionLoading: loading } = useFetchCollection({
    collectionType: 'studyGroups',
    params: { id: studyId },
    queryParams: { perPage: -1 },
  });

  if (loading) {
    return <Loading />;
  }

  return <>{children(groups as TreatmentGroup[])}</>;
};

const GroupMenu: FC<{ groups: TreatmentGroup[] }> = ({ groups }) => {
  const { control, errors } = useFormContext();
  const { search, searchResults, resetSearch } = useSearch({
    items: groups,
    accessors: ['name'],
  });

  const handleSearch = (query: string): void => {
    if (_isEmptyString(query)) {
      resetSearch();
    } else {
      search(query);
    }
  };

  const groupResults = searchResults.map((result) => ({
    name: trunc(result.name, 30),
    // Study creation forms create a `uuid` => `temp_id` as part of the payload
    value: result?.temp_id ?? result.api_id,
  }));

  return (
    <>
      <ErrorMessage
        errors={errors}
        name={`${AddTaskFormTypes.target}.groups`}
        render={({ message }) => <p className="f6 red db pv2 ph3">{message}</p>}
      />
      <Controller
        name={`${AddTaskFormTypes.target}.groups`}
        defaultValue={[]}
        control={control}
        rules={{ validate: (value: any) => (_isNotEmpty(value) ? true : 'At least one group is required') }}
        render={({ value, onChange }: ControllerRenderProps) => (
          <SelectMenu
            preSelectedValues={value}
            onSearch={handleSearch}
            onSelect={onChange}
            items={groupResults}
            searchPlaceholderText="Search by name"
          />
        )}
      />
    </>
  );
};

export default AddTaskTargetForm;
