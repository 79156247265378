// @ts-nocheck: converted from JS

import { differenceInDays } from 'date-fns';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import React, { useEffect } from 'react';
import { Plotly } from '@/support/plotly';
import type { GraphsState } from '../Graph.model';
import { graphConfig } from './Graph.utils';
import GraphTitle from './GraphTitle';

interface SurvivalCurveProps {
  studyName: string;
  state: GraphsState;
}

// The survival curve shows the percentage of animals currently alive in the group. It begins at 100 and eventually drops to 0. If no animal in the group is marked as deceased then number will remain constant over multiple days.
const SurvivalCurve: React.FC<SurvivalCurveProps> = ({ studyName, state }) => {
  const { metrics, survivalDates } = state;
  const updateGraphData = (graphData: Plotly.Data) => {
    if (graphData) {
      const graphSettings = graphConfig({
        hasLegend: true,
        xTitle: 'Days',
        isSurvival: true,
        yTitle: '(% Survival)',
      });
      Plotly.newPlot('survivalCurve', {
        ...graphSettings,
        data: graphData,
      });
    }
  };

  const dataFormat = (group: TreatmentGroup, index: number): Plotly.Data => ({
    x: [],
    y: [],
    mode: 'lines',
    name: group.name,
    legendgroup: group.name,
    line: { color: group.color, shape: 'hv', width: 4 },
    type: 'scatter',
  });

  const formatGraphData = () => {
    const dateRange = {
      first: survivalDates.start,
      last: survivalDates.end,
    };
    // Difference in days doesn't include today, single addition is required
    // to prevent loss of data
    const totalDays = differenceInDays(new Date(dateRange.last), new Date(dateRange.first)) + 1;
    const graphData = metrics?.reduce<Plotly.Data>((survivalData, group, index) => {
      let currentValue = 100;

      const groupData = Array.from({ length: totalDays < 0 ? 0 : totalDays }).reduce(
        (groupData, _, i) => {
          if (group.survival) {
            const currentDay = group.survival.find(({ study_day: studyDay }) => studyDay === i);
            if (currentDay) {
              groupData.y.push(currentDay.value);
              currentValue = currentDay.value;
            } else {
              groupData.y.push(currentValue);
            }
            groupData.x.push(i);
            return groupData;
          }
        },
        dataFormat(group, index)
      );

      if (groupData) {
        survivalData.push(groupData);
      }
      return survivalData;
    }, []);

    updateGraphData(graphData);
  };

  useEffect(() => {
    formatGraphData();
  }, [metrics]);

  return (
    <div className="ph4 pv3" data-test-component="SurvivalCurve" data-test-element="container">
      <GraphTitle
        dates={{ first: survivalDates.start, last: survivalDates.end }}
        studyName={studyName}
        graphType="SURVIVAL"
      />
      <div className="ui__graph">
        <div id="survivalCurve" className="w-100 h-100" style={{ height: '548px' }} />
      </div>
    </div>
  );
};

export default SurvivalCurve;
