import Loading from '@/components/Loading';
import { ControlGroupSelect } from '@/components/Studies/StudyGroups/Groups/ControlGroupSelect';
import Radio from '@/components/UI/FormElements/Radio';
import { successToast } from '@/helpers';
import { _isEmpty, _isNil } from '@/littledash';
import type { State } from '@/model/State.model';
import type { Study } from '@/model/Study.model';
import type { GroupApiId, TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import { type FC, useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

interface ConfigurationProps {
  groups: Array<TreatmentGroup>;
  changeControlGroup: (id?: GroupApiId | string) => Promise<void>;
  canEdit: boolean;
}

const Configuration: FC<ConfigurationProps> = ({ canEdit = true, groups, changeControlGroup }) => {
  const { id: studyId } = useParams<{ id: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
  });

  if (studyLoading || _isNil(study)) {
    return (
      <div className="mv4 pa4">
        <Loading />
      </div>
    );
  }

  return <ConfigurationForm study={study} groups={groups} changeControlGroup={changeControlGroup} canEdit={canEdit} />;
};

interface ConfigurationFormProps {
  study: Study;
  groups: Array<TreatmentGroup>;
  changeControlGroup: (id?: GroupApiId | string) => Promise<void>;
  canEdit: boolean;
}

type FormData = {
  averageCalculation: Study['average_calculation'];
  errorDeviation: Study['error_deviation'];
};
const ConfigurationForm: FC<ConfigurationFormProps> = ({
  study: { id: studyId, average_calculation: averageCalculation, error_deviation: errorDeviation },
  groups,
  changeControlGroup,
  canEdit = true,
}) => {
  const [updatingGroupConfig, setUpdatingGroupConfig] = useState(false);
  const [defaultValues, updateDefaultValues] = useState({
    averageCalculation,
    errorDeviation,
  });
  const dataAnalysisEnabled = useSelector<State>((state) => state?.team?.features?.data_analysis ?? false);
  const { sendRequest: updateStudy } = useRequest({
    route: 'studies.update',
    method: 'patch',
    params: { id: studyId },
  });
  const { control, handleSubmit, watch } = useForm<FormData>({ defaultValues });
  const currentFormData = watch();
  const onSubmit = async ({ averageCalculation, errorDeviation }: FormData) => {
    setUpdatingGroupConfig(true);
    await updateStudy({
      average_calculation: averageCalculation,
      error_deviation: errorDeviation,
    });
    updateDefaultValues({ averageCalculation, errorDeviation });
    setUpdatingGroupConfig(false);
    successToast('Saved group settings.');
  };
  useEffect(() => {
    if (
      !updatingGroupConfig &&
      (currentFormData.averageCalculation !== defaultValues.averageCalculation ||
        currentFormData.errorDeviation !== defaultValues.errorDeviation)
    ) {
      onSubmit(currentFormData);
    }
  }, [currentFormData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pv4 flex flex-wrap">
      <div className="ph4 w-32">
        <label className="mb3 db">How would you like to calculate averages?</label>
        <div>
          <Controller
            control={control}
            name="averageCalculation"
            defaultValue={averageCalculation}
            render={({ onChange, value }: ControllerRenderProps) => (
              <>
                <Radio
                  id="arithmetic"
                  name="average_calculation"
                  checked={value === 'arithmetic'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="arithmetic"
                  label="Arithmetic"
                  onChange={onChange}
                  className="mb2"
                />
                <Radio
                  id="geometric"
                  name="average_calculation"
                  checked={value === 'geometric'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="geometric"
                  label="Geometric"
                  onChange={onChange}
                />
              </>
            )}
          />
        </div>
      </div>
      <div className="ph4 w-32">
        <label className="mb3 db">How would you like to display variation?</label>
        <div>
          <Controller
            control={control}
            name="errorDeviation"
            defaultValue={errorDeviation}
            render={({ onChange, value }: ControllerRenderProps) => (
              <>
                <Radio
                  id="sd"
                  name="error_deviation"
                  checked={value === 'sd'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="sd"
                  label="Standard deviation"
                  onChange={onChange}
                  className="mb2"
                />
                <Radio
                  id="sem"
                  name="error_deviation"
                  checked={value === 'sem'}
                  disabled={updatingGroupConfig || !canEdit}
                  value="sem"
                  label="Standard error of the mean"
                  onChange={onChange}
                />
              </>
            )}
          />
        </div>
      </div>
      {dataAnalysisEnabled ? (
        <div className="ph4 w-32">
          <label className="mb3 db">Control group</label>
          <ControlGroupSelect
            disabled={!canEdit || _isEmpty(groups)}
            groups={groups ?? []}
            onChange={changeControlGroup}
          />
        </div>
      ) : null}
    </form>
  );
};

export default Configuration;
