import { FeatureFlagType } from '@/model/FeatureFlag.model';
import { State } from '../model/State.model';
import { MainUser } from '@/model/User.model';

export const featuresSelector: (state: State) => Partial<Record<FeatureFlagType, boolean>> = (state) =>
  state?.team?.features ?? {};
export const adminConsoleUserSelector: (state: State) => boolean = (state) =>
  state?.team?.team?.pivot?.admin_console_user ?? false;
export const teamOwnerSelector: (state: State) => boolean = (state) => state?.team?.team?.pivot?.owner ?? false;
export const currentUserSelector: (state: State) => MainUser | undefined = (state) => state?.user?.currentUser;
