import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import SearchSelect from '@/components/UI/SearchSelect';
import { _notNil } from '@/littledash';
import { UserApiId } from '@/model/User.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import type { StudyApiId } from 'model/Study.model';
import { FC, ReactNode, useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { AddTaskFormTypes, type StepRenderProps, type StudyUsersApi, type TaskSpecUser } from '../AddTask.model';
import styles from '../AddTask.module.scss';

interface AddTaskAssigneeFormProps extends StepRenderProps {}

const AddTaskAssigneesForm: FC<AddTaskAssigneeFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  studyApiId,
  studyCreationUsers,
  teamUsers,
}) => {
  const { handleSubmit: useFormHandleSubmit } = useFormContext();

  return (
    <form className={styles['step-content']} onSubmit={useFormHandleSubmit(onSubmit)}>
      {_notNil(studyApiId) ? (
        <StudyUsersProvider studyApiId={studyApiId}>
          {(studyUsers: StudyUsersApi) => <AssigneeMenu studyUsers={studyUsers} teamUsers={teamUsers ?? []} />}
        </StudyUsersProvider>
      ) : (
        <AssigneeMenu studyUsers={studyCreationUsers ?? []} teamUsers={teamUsers ?? []} />
      )}
      <div className="pa3 bt b--moon-gray">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

const StudyUsersProvider: FC<{ studyApiId: StudyApiId; children: (studyUsers: StudyUsersApi) => ReactNode }> = ({
  studyApiId,
  children,
}) => {
  const { response, loading } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/users',
    path: { studyId: studyApiId },
    query: { perPage: -1 },
  });

  if (loading) {
    return <Loading />;
  }

  const studyUsers = response?.type === 'success' ? response?.body?.data : {};

  return <>{children(studyUsers as StudyUsersApi)}</>;
};

const AssigneeMenu: FC<{ studyUsers: TaskSpecUser[]; teamUsers: TaskSpecUser[] }> = ({ studyUsers, teamUsers }) => {
  const { control } = useFormContext();

  const { sections, studyUserApiIds } = useMemo(() => {
    const { studyUserItems, studyUserApiIds } = studyUsers.reduce<{
      studyUserItems: Array<{ id: UserApiId; title: string }>;
      studyUserApiIds: Set<UserApiId>;
    }>(
      (acc, user) => {
        acc.studyUserItems.push({ id: user.api_id, title: user.name ?? user.email });
        acc.studyUserApiIds.add(user.api_id);
        return acc;
      },
      { studyUserItems: [], studyUserApiIds: new Set() }
    );
    const teamUserItems = teamUsers.reduce<Array<{ id: UserApiId; title: string }>>((acc, user) => {
      if (!studyUserApiIds.has(user.api_id)) {
        acc.push({ id: user.api_id, title: user.name ?? user.email });
      }
      return acc;
    }, []);
    const sections = [
      {
        title: 'Study Users',
        items: studyUserItems,
      },
    ];
    if (teamUserItems.length > 0) {
      sections.push({
        title: 'Team Users',
        items: teamUserItems,
      });
    }
    return { sections, studyUserApiIds };
  }, [teamUsers, studyUsers]);

  return (
    <div className={styles['add-task-select-menu-container']}>
      <Controller
        name={AddTaskFormTypes.assignees}
        control={control}
        render={({ value, onChange }: ControllerRenderProps) => (
          <SearchSelect
            selected={value}
            onSelect={(event: { type: 'ADDED' | 'REMOVED'; value: UserApiId }) => {
              const update: Set<UserApiId> = new Set(value);
              switch (event.type) {
                case 'ADDED':
                  update.add(event.value);
                  break;
                case 'REMOVED':
                  update.delete(event.value);
                  break;
              }
              onChange([...update]);
            }}
            onSelectAll={() => onChange([...studyUserApiIds])}
            onClearSelection={() => onChange([])}
            sections={sections}
          />
        )}
      />
    </div>
  );
};

export default AddTaskAssigneesForm;
