import UserAvatar from '@/components/UI/UserAvatar';
import { TruncatedAvatar } from '@/components/UI/UserAvatar/UserAvatar';
import type { ColorDetail } from '@/constants/colors';
import type { TaskV1 } from '@/model/Task.model';
import styles from './DayList.module.scss';

interface TaskDayCardProps {
  task: TaskV1;
  colours?: ColorDetail;
  onClick?: () => void;
  maxUserBeforeTruncate?: number;
  narrow?: boolean;
}

const DayCard = ({ task, colours, onClick, maxUserBeforeTruncate = 4, narrow }: TaskDayCardProps) => {
  return (
    <div
      className={`${styles['task-day-item']} ${colours ? colours?.background?.class : 'bg-near-white'} ${
        onClick ? 'pointer' : ''
      } ${narrow ? 'flex-wrap pl3' : 'pl4 items-center '} w-100 h-100 pa2 flex justify-between relative`}
      onClick={onClick}
      data-test-component="DayCard"
      data-test-element="container"
      data-testid="day-card"
    >
      <div
        className={`${styles['task-day-aside-border']} br1 bg-red absolute z-1`}
        style={{ backgroundColor: colours?.color ?? 'white' }}
      />
      <div className="w-100" data-test-component="DayCard" data-test-element="task-title">
        <h3 className="fw5 f6 lh-title near-black w-100 truncate">{task.title}</h3>
        <p className="lh-copy f6 near-black w-100 truncate">{task.study.name}</p>
      </div>
      <div className={`flex ${narrow ? 'w-100 ui__overflow__scroll_y' : ''}`}>
        {(task.assignees ?? []).slice(0, maxUserBeforeTruncate).map((user, index) => (
          <UserAvatar
            key={user.id}
            tooltip={user.name}
            user={{ name: user.name }}
            className={`${narrow ? 'mr1' : 'ml1'}`}
            dataTestId={`user-avatar-${index}`}
          />
        ))}
        {(task.assignees?.length ?? 0) > maxUserBeforeTruncate && (
          <TruncatedAvatar
            remainder={(task.assignees?.length ?? 0) - maxUserBeforeTruncate}
            className={`${narrow ? 'mr1' : 'ml1'}`}
            dataTestId="truncated-avatar"
          />
        )}
      </div>
    </div>
  );
};

export default DayCard;
