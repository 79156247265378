import Tooltip from '@/components/UI/Tooltip';
import { _notNil } from '@/littledash';
import type { FC } from 'react';
import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import type { DataTableCellAlertUpdateEvent, DataTableCellComponentProps } from '../DataTable.model';
import {
  DataTableCellData,
  DataTableCellSelectionChangeEvent,
  DataTableCellSelectionState,
  DataTableCellState,
  DataTableCellStateChangeEvent,
  DataTableCellUpdateEvent,
  DataTableEvent,
} from '../DataTable.model';
import styles from '../DataTable.module.scss';
import { toCellRef } from '../DataTable.util';
import { DataTableCellValueRenderer } from './DataTableCellValueRenderer';

interface DataTableReadonlyCellProps extends DataTableCellComponentProps {}

export const DataTableReadonlyCell: FC<DataTableReadonlyCellProps> = ({
  dataTableService,
  coordinate,
  positionOffset,
}) => {
  const [cellData, setCellData] = useState<DataTableCellData>();
  const idCoordinate = useMemo(() => dataTableService.fromIndexCoordinate(coordinate), [coordinate, dataTableService]);
  const [cellState, setCellState] = useState<DataTableCellState>(dataTableService.cellState(idCoordinate));
  const [tooltip, setTooltip] = useState<string | undefined>();
  const [selected, setCellSelected] = useState<DataTableCellSelectionState>(DataTableCellSelectionState.notSelected);
  const [hasAlert, setHasAlert] = useState(false);
  const { cellHeight, cellWidth } = dataTableService.dimensions;

  const handleMouseDown: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: event.shiftKey });
    }
  };
  const handleMouseEnter: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: true });
    }
  };
  const handleMouseUp: MouseEventHandler<HTMLInputElement> = (event) => {
    if (event.buttons === 1) {
      dataTableService.selectCell(coordinate, { append: true });
    }
  };

  useEffect(() => {
    if (_notNil(idCoordinate)) {
      const ref = toCellRef(idCoordinate);
      setCellData(dataTableService.cellData(idCoordinate));

      setHasAlert((dataTableService.cellAlerts(idCoordinate)?.alerts?.size ?? 0) > 0);

      if (dataTableService.selection?.contains(coordinate) ?? false) {
        setCellSelected(
          dataTableService.selection?.from.column === coordinate.column &&
            dataTableService.selection?.from.row === coordinate.row
            ? DataTableCellSelectionState.originSelected
            : DataTableCellSelectionState.highlighted
        );
      } else {
        setCellSelected(DataTableCellSelectionState.notSelected);
      }

      const cellAlertUpdateListener = (event: DataTableCellAlertUpdateEvent) => {
        if (Object.prototype.hasOwnProperty.call(event.detail.cellAlerts, ref)) {
          setHasAlert((event.detail.cellAlerts[ref]?.alerts?.size ?? 0) > 0);
        }
      };

      const cellUpdateListener = (event: DataTableCellUpdateEvent) => {
        if (Object.prototype.hasOwnProperty.call(event.detail, ref)) {
          setCellData(event.detail[ref]);
          setCellState(dataTableService.cellState(idCoordinate));
        }
      };

      const cellSelectionChangeListener = (event: DataTableCellSelectionChangeEvent) => {
        const { from } = event.detail.selection;
        if (event.detail.selection?.contains(coordinate)) {
          setCellSelected(
            from.column === coordinate.column && from.row === coordinate.row
              ? DataTableCellSelectionState.originSelected
              : DataTableCellSelectionState.highlighted
          );
        } else {
          setCellSelected(DataTableCellSelectionState.notSelected);
        }
      };

      const cellStateChangeListener = (event: DataTableCellStateChangeEvent) => {
        if (_notNil(idCoordinate)) {
          setCellState((previousCellState) => {
            if (event.detail.invalidCells.has(ref) || previousCellState !== 'valid') {
              const updatedCellState = dataTableService.cellState(idCoordinate);
              if (updatedCellState === 'invalid') {
                setTooltip((dataTableService.cellStatus(idCoordinate) as { error: string })?.error);
              } else if (_notNil(tooltip)) {
                setTooltip(undefined);
              }
              return updatedCellState;
            }
            return previousCellState;
          });
        }
      };

      dataTableService.subscribe(DataTableEvent.CellUpdate, cellUpdateListener);
      dataTableService.subscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
      dataTableService.subscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
      dataTableService.subscribe(DataTableEvent.CellAlertUpdate, cellAlertUpdateListener);

      return () => {
        dataTableService.unsubscribe(DataTableEvent.CellUpdate, cellUpdateListener);
        dataTableService.unsubscribe(DataTableEvent.CellSelectionChange, cellSelectionChangeListener);
        dataTableService.unsubscribe(DataTableEvent.CellStateChange, cellStateChangeListener);
        dataTableService.unsubscribe(DataTableEvent.CellAlertUpdate, cellAlertUpdateListener);
      };
    }
  }, [dataTableService, idCoordinate]);

  return (
    <div
      className={styles['data-table-cell-container']}
      style={{
        position: 'absolute',
        top: positionOffset.y,
        left: positionOffset.x,
        width: `${cellWidth}px`,
        height: `${cellHeight}px`,
      }}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseUp={handleMouseUp}
    >
      {cellState === 'loading' ? (
        <div className={`${styles['data-table-cell']} ${styles['loading']}`} />
      ) : (
        <Tooltip render={tooltip}>
          <div
            className={`${styles['data-table-cell']} ${styles[selected]} truncate ${styles[cellState]} ${styles['data-table-readonly-column']}`}
            data-celltype="number"
            data-state={cellState}
            style={{ height: '100%' }}
          >
            <span>
              <DataTableCellValueRenderer value={cellData?.value} />
            </span>
          </div>
        </Tooltip>
      )}
      {hasAlert && <div className={styles['data-table-cell-alert']} />}
    </div>
  );
};
