// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import SubHeader from '@/components/UI/SubHeader';
import { successToast } from '@/helpers';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from '../Calendar';
import { hasPendingTasks, nextDate, prevDate, togglePendingTasksNotification } from './Tasks.utils';
import TaskTable from './TaskTable';

const Tasks = (): JSX.Element => {
  const { tasksEnabled } = useSelector(({ team }) => ({
    tasksEnabled: !(team?.features?.hide_tasks ?? false),
  }));

  if (tasksEnabled) {
    return <Calendar />;
  }

  return <LegacyTasks />;
};

const LegacyTasks = (): JSX.Element => {
  const [activeDate, setActiveDate] = useState(DateUtils.dateNow());
  const [studiesWithTasks, setStudiesWithTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Http.get(
        apiRoute('tasks.show', {
          date: activeDate,
        })
      );
      setStudiesWithTasks(data);

      if (isSameDay(new Date(activeDate), new Date())) {
        togglePendingTasksNotification(hasPendingTasks(data));
      }

      setError(false);
    } catch (error) {
      setStudiesWithTasks([]);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeDate]);

  const openFlash = (res) => {
    successToast(res);
  };

  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const updateTask = (task) => {
    const tasks = Array.from(studiesWithTasks);
    const index = tasks.findIndex((t) => t.id === task.id);
    tasks[index] = task;
    togglePendingTasksNotification(hasPendingTasks(tasks));
    return setStudiesWithTasks(tasks);
  };

  const deleteTaskCallback = (id) => {
    const tasks = Array.from(studiesWithTasks);
    const index = tasks.findIndex((t) => t.id === id);
    tasks.splice(index, 1);
    setStudiesWithTasks(tasks);
    return openFlash({ text: 'Successfully deleted a task!' });
  };

  const addNewTaskCallback = (task) => {
    const taskDate = task.date;
    const isActiveDate = isSameDay(new Date(activeDate), new Date(taskDate));

    if (!isActiveDate) {
      setActiveDate(taskDate);
    } else {
      const tasks = Array.from(studiesWithTasks);
      tasks.push(task);
      setStudiesWithTasks(tasks);
    }
    return openFlash({ text: 'Successfully created a new task' });
  };

  const next = nextDate(activeDate);
  const prev = prevDate(activeDate);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="pb5-l">
      <SubHeader linkToText="Studies" link={webRoute('dashboard')} />
      <div className="ph3 ph4-l pb3">
        <div className="flex-l flex-wrap-l justify-between-l items-center pt4">
          <div className="flex-l flex-wrap-l items-center">
            <Button
              stateless
              className="dark-gray mr4 hover-near-black"
              style={{ padding: '0' }}
              onClick={() => setActiveDate(DateUtils.dateNow())}
            >
              Today
            </Button>
            <DatePickerNative
              value={activeDate}
              style={{ marginBottom: 0 }}
              onChange={(value) => setActiveDate(value)}
            />
            <Button
              stateless
              className="dark-gray"
              icon="left_arrow"
              viewBox="0 0 32 32"
              style={{ padding: '0 .75rem' }}
              onClick={() => setActiveDate(prev)}
              tooltip={DateUtils.renderDate(prev)}
            />
            <Button
              stateless
              className="dark-gray"
              icon="right_arrow"
              viewBox="0 0 32 32"
              style={{ padding: '0 .75rem' }}
              onClick={() => setActiveDate(next)}
              tooltip={DateUtils.renderDate(next)}
            />
          </div>
          <Button
            onClick={() =>
              openModal('LEGACY_ADD_TASK', {
                addNewTaskCallback,
                closeModal,
              })
            }
          >
            Add task
          </Button>
        </div>
        <div className="pv3">
          {error ? (
            <ApiErrorBanner className="mb4" error={error} errorType={ApiErrorType.FETCH} />
          ) : (
            <TaskTable tasks={studiesWithTasks} updateTask={updateTask} deleteTask={deleteTaskCallback} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Tasks;
