// @ts-nocheck: converted from JS

import Loading from '@/components/Loading';
import SpreadSheet from '@/components/UI/SpreadSheet';
import { _isEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import { sortAscByProperty } from '@/utils/array';
import type HotTable from '@handsontable/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { getMetaData, getSpeciesAndStrains, initAnimalSettings, updateStrainSelectDropdown } from './../Colony.utils';

const Animals = ({ state, dispatch }) => {
  const {
    animals: { species, settings, metadata, cellErrors, data: animalData, overlay },
    cages: { data: cageData },
  } = state;
  const ref = useRef<HotTable>();
  const [isInvalid, setIsInvalid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const patchMetadata = () => {
    if (ref.current) {
      const hot = ref.current?.hotInstance;
      if (_notNil(hot)) {
        const col = hot.propToCol('species');
        const colData = hot.getDataAtCol(col);

        colData.forEach((value, row) => {
          updateStrainSelectDropdown(hot, species, row, value);
        });
        hot.render();
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const update: Record<string, unknown> = {};
    getSpeciesAndStrains()
      .then(({ data }) => {
        update.settings = initAnimalSettings(data.data, true, false).settings;
        update.species = data.data;
      })
      .then(() => getMetaData('subject_meta'))
      .then(({ data: { data } }) => {
        updateGlossaries(data);
        update.metadata = data;
        update.data = createAnimalData();
        updateAnimals({
          ...update,
        });
        setLoading(false);
        dispatch({ type: 'stepReady' });
      })
      .catch((error) => {
        updateApiError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const newAnimals = createAnimalData().sort(sortAscByProperty('cage_catalog'));
    updateAnimals({ data: newAnimals });
  }, [cageData]);

  const updateAnimals = (update) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: update,
    });
  };
  const updateOverlay = (overlay) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: { overlay },
    });
  };

  const updateCellErrors = (cellErrors) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: { cellErrors },
    });
  };
  const updateApiError = (apiError) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: apiError,
    });
  };

  const updateMetadata = (metadata) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: { metadata },
    });
  };

  const updateGlossaries = (metaGlossaries) => {
    dispatch({
      type: 'update',
      id: 'animals',
      data: { metaGlossaries },
    });
  };

  const createNewAnimal = (animalCount: number, cageName: string, cageCatalog, animalID) => {
    const animal: Animal = {};
    settings.columns.forEach((col) => {
      const name = col.data;
      if (name === 'id') {
        animal.id = animalCount;
      } else if (name === 'cage_name') {
        animal.cage_name = cageName;
      } else if (name === 'cage_catalog') {
        animal.cage_catalog = cageCatalog;
      } else if (name === 'animal_id') {
        animal.animal_id = `Animal ${animalID}`;
      } else if (name === 'name') {
        animal.name = `Animal ${animalID}`;
      } else {
        animal[name] = '';
      }
    });
    return animal;
  };

  const createAnimalData = () => {
    let newAnimalData = animalData
      .map((animal) => {
        animal.cage_name = cageData.find((newCage) => newCage.catalog === animal.cage_catalog)?.name;
        return animal;
      })
      .filter((animal) => animal.cage_name);
    cageData.forEach(({ name: cageName, catalog: cageCatalog, population }) => {
      if (cageName) {
        const currentCageAnimals = newAnimalData.filter((animal) => animal.cage_catalog === cageCatalog);
        const count = currentCageAnimals.length;
        const animalDiff = Math.abs(population - count);
        if (population < count) {
          const animalsToRemove = currentCageAnimals.slice(animalDiff * -1);
          newAnimalData = newAnimalData.filter((tempAnimal) => !animalsToRemove.includes(tempAnimal));
        } else if (population > count) {
          let animalCount = newAnimalData.length + 1;
          let animalID = count + 1;
          for (let i = 0; i < animalDiff; i++) {
            newAnimalData.push(createNewAnimal(animalCount, cageName, cageCatalog, animalID));
            animalID++;
            animalCount++;
          }
        }
      }
    });

    const newOverlay = _isEmpty(newAnimalData) ? (
      <Fragment>
        <h3 className="mb2">No animals added</h3>
        <p style={{ maxWidth: '520px', alignSelf: 'center' }}>
          Click continue to create empty cages, or go back and increase the number of animals for you cages.
        </p>
      </Fragment>
    ) : (
      ''
    );
    updateOverlay(newOverlay);

    return newAnimalData;
  };

  useEffect(() => {
    if (isInvalid) {
      applyInvalidError();
    } else {
      delete cellErrors['auto-validation'];
      updateCellErrors({ ...cellErrors });
    }
  }, [isInvalid]);

  const applyInvalidError = () => updateCellErrors({ ...cellErrors, 'auto-validation': 'There are invalid cells' });

  return loading ? (
    <div style={{ height: 650 }}>
      <Loading txt="Loading species..." />
    </div>
  ) : (
    <div className="ow-spreadsheet-styles">
      <SpreadSheet
        className={'mt3'}
        data={animalData}
        settings={settings}
        metadata={metadata}
        setMetadata={updateMetadata}
        patchMetadataAfterSettingsUpdated={patchMetadata}
        innerRef={ref}
        overlay={overlay}
        maxRows={animalData.length}
        setInvalid={setIsInvalid}
      />
    </div>
  );
};

export default Animals;
