import { _isNil, _isNotEmpty } from '@/littledash';
import { endOfDay, lastDayOfWeek, startOfDay, startOfWeek } from 'date-fns';
import {
  CalendarAction,
  CalendarFilterKeys,
  CalendarFilters,
  type CalendarState,
  CalendarViews,
} from './Calendar.model';

export const initialState = (): Readonly<CalendarState> => ({
  chosenDay: new Date(),
  span: CalendarViews.Week,
  yearAndMonth: [new Date().getFullYear(), new Date().getMonth()],
  filters: { study_api_id: [], assignee: [] },
});

export const reducer = (state: Readonly<CalendarState>, action: CalendarAction) => {
  switch (action.type) {
    case 'SET_CHOSEN_DAY':
      return {
        ...state,
        chosenDay: action.data,
      };
    case 'SET_SPAN':
      return {
        ...state,
        span: action.data,
      };
    case 'SET_YEAR_AND_MONTH':
      return {
        ...state,
        yearAndMonth: action.data,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.data,
      };
    case 'GO_TO_DAY':
      return {
        ...state,
        span: CalendarViews.Day,
        chosenDay: action.data,
      };
    default: {
      return state;
    }
  }
};

export const getStartAndEndDateForWeekView = (date: Date): { start: Date; end: Date } => {
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const endDate = lastDayOfWeek(date, { weekStartsOn: 1 });
  return { start: startOfDay(startDate), end: endOfDay(endDate) };
};

export const getStartAndEndForDayView = (date: Date): { start: Date; end: Date } => ({
  start: startOfDay(date),
  end: endOfDay(date),
});

export const getFilterQuery = (filters: CalendarFilters): Record<string, string> =>
  Object.keys(filters).reduce((acc: Record<string, string>, key: string) => {
    const filterQuery = filters?.[key as CalendarFilterKeys] ?? [];
    if (_isNil(acc?.[key]) && _isNotEmpty(filterQuery)) {
      acc[key] = (filters?.[key as CalendarFilterKeys] ?? []).join(',');
    }
    return acc;
  }, {});
