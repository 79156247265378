import Checkbox from '@/components/UI/FormElements/Checkbox/Checkbox';
import { SelectMenu } from '@/components/UI/SelectMenu';
import type { ItemsProps } from '@/components/UI/SelectMenu/SelectMenu.model';
import type { ColorDetail } from '@/constants/colors';
import { trunc } from '@/helpers';
import { _flatten, _isNotEmpty } from '@/littledash';
import type { Study, StudyApiId } from '@/model/Study.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useApiPagination } from '@/support/Hooks/api/useApiPagination';
import { CSSProperties, type FC, useMemo } from 'react';
import type { CalendarFilters } from '../Calendar.model';

interface StudyFiltersProps {
  filters: CalendarFilters;
  onFilterChange: (filters: CalendarFilters) => void;
  studyColors: Record<StudyApiId, ColorDetail>;
}

const StudyFilter: FC<StudyFiltersProps> = ({ filters, studyColors, onFilterChange }) => {
  const {
    response: studies,
    loading: fetchingStudies,
    invoke: getStudies,
  } = useApiHook({
    endpoint: 'GET /api/v1/studies',
  });

  const {
    pages: studiesPages,
    nextPage: nextStudyPage,
    hasNextPage: hasNextStudyPage,
    reset: resetStudiesPages,
  } = useApiPagination({ response: studies });

  const handleStudyFilter = (selected: string[]): void => {
    const selectedApiIds = selected as StudyApiId[];
    onFilterChange({ ...filters, study_api_id: selectedApiIds });
  };

  const onSearchStudies = (query: string): void => {
    resetStudiesPages();
    getStudies({ query: { name: query } });
  };

  const OnScrollBottomStudies = (): void => {
    if (hasNextStudyPage) {
      getStudies({ query: { page: nextStudyPage } });
    }
  };

  const handleStudyColourCheckboxStyles = (value: string, isSelected: boolean): CSSProperties => {
    const studyId = value as StudyApiId;
    if (studyColors[studyId] && isSelected) {
      return {
        borderColor: studyColors[studyId].color,
        backgroundColor: studyColors[studyId].background?.color,
      };
    }
    return {};
  };

  const studyItems: ItemsProps[] = useMemo(
    () =>
      _isNotEmpty(studiesPages)
        ? _flatten(studiesPages).map(({ api_id, name }: Study) => ({ value: api_id, name: name }))
        : [],
    [studiesPages]
  );

  return (
    <div className="pa3" data-test-component="StudyFilter" data-test-element="container">
      <h3 className="near-black lh-title f6 pb2">Studies</h3>
      <div className="ui-card">
        <SelectMenu
          items={studyItems}
          onSearch={onSearchStudies}
          onSelect={handleStudyFilter}
          onScrollBottom={OnScrollBottomStudies}
          searchPlaceholderText="Search by study name"
          renderItem={({ name, value, isSelected, onChange }) => (
            <Checkbox
              label={trunc(name, 30)}
              checked={isSelected}
              onChange={onChange}
              value={value}
              style={handleStudyColourCheckboxStyles(value, isSelected)}
            />
          )}
          disabled={fetchingStudies}
          enableSelectAll={false}
        />
      </div>
    </div>
  );
};

export default StudyFilter;
