import Loading from '@/components/Loading';
import { SelectionReorder } from '@/components/Modals/SetupWorkflow/SelectionReorder';
import { assembleToggleOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.utils';
import type { GlossaryItem } from '@/model/Glosary.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';

const formContextPath = 'workflowSetup.workflow[2]';

export const Observations: FC = () => {
  const { control, watch } = useFormContext();

  const { collection: observations, collectionLoading } = useFetchCollection<GlossaryItem>({
    collectionType: 'teamGlossary',
    queryParams: { group: 'observations' },
  });
  const toggleOptions = assembleToggleOptions(watch('workflowSetup.workflow'), 'observations');
  if (collectionLoading) {
    return <Loading />;
  }
  return (
    <>
      <Controller
        name={`${formContextPath}.preSelectedObservationTypeIds`}
        control={control}
        render={({ value, onChange }: ControllerRenderProps) => (
          <SelectionReorder
            selectionTitle="observation"
            options={observations ?? []}
            selection={value}
            selectionChange={(selection) => onChange(selection)}
          />
        )}
      />
      <div className="pa3 bt b-moon-gray flex justify-between items-center w-100">
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name={`${formContextPath}.onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search">Focus search bar</option>
              {Object.keys(toggleOptions).map((k) => (
                <option key={k} value={toggleOptions[k].value}>
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject">Move to next animal</option>
            </select>
          )}
        />
      </div>
    </>
  );
};
