// @ts-nocheck: converted from JS

import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import Icon from '@/components/UI/Icon';
import { hasOwnProperty } from '@/helpers';
import { _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { FC } from 'react';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { Errors } from '../../Metadata/Repeater/Repeater.utils';
import type { ExtendedAnimalSpecies, FormError } from '../SpeciesComponent.model';
import './Repeater.scss';

const TH_CLASSES = 'f7 f6-l ph2 pv2 ph3-l pv3-l b--silver near-black basier-med normal';
const TD_CLASSES = 'f7 f6-l ph2 pv1 ph3-l pv2-l bt';

interface TitleProps {
  errors: Array<Errors>;
  name?: string;
  id?: ID;
  index?: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Title: FC<TitleProps> = ({ name, errors, id, index, handleChange, disabled, readOnly }) => {
  const hasErrors = errors.find((error) => Number(error.index) === index);
  return (
    <DebounceInput
      handleChange={handleChange}
      readOnly={readOnly}
      inputProps={{
        name,
        'data-index': index,
        value: name,
        'data-tooltip-id': 'global-tooltip-id',
        'data-tooltip-content': hasErrors && hasOwnProperty(hasErrors.errors, 'name') ? hasErrors.errors.name[0] : '',
        style: { marginBottom: 0 },
        className: `z-5 relative ${hasErrors && 'input__error'}`,
        disabled,
      }}
    />
  );
};

interface SpeciesStrainsTableProps {
  data: Array<ExtendedAnimalSpecies>;
  setData: React.Dispatch<React.SetStateAction<Array<ExtendedAnimalSpecies>>>;
  errors: Array<FormError>;
  toggleExpanded: (expanded: boolean, id?: ID) => void;
  handleChange: ({ target }: ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (index: number) => void;
}

const SpeciesStrainsTable: FC<SpeciesStrainsTableProps> = ({
  data,
  setData,
  errors,
  toggleExpanded,
  handleChange,
  handleDelete,
  readOnly,
}) => (
  <table
    className="w-100 tl bt b--moon-gray"
    style={{ borderSpacing: 0, whiteSpace: 'nowrap', borderCollapse: 'collapse' }}
  >
    <thead className="bg-near-white">
      <tr>
        <th className={TH_CLASSES}></th>
        <th className={TH_CLASSES}>Species</th>
        <th className={TH_CLASSES}></th>
      </tr>
    </thead>
    <tbody className="black">
      {data.map((row) => (
        <Fragment key={row.id}>
          <tr>
            <td className={TD_CLASSES}>
              <Icon
                icon={row.expanded ? 'chev_up' : 'chev_down'}
                className="dib mid-gray pointer"
                width="14"
                height="14"
                onClick={() => toggleExpanded(row.expanded, row.id)}
              />
            </td>
            <td className={TD_CLASSES}>
              <Title
                name={row.name}
                errors={errors}
                index={row.index}
                id={row.id}
                handleChange={handleChange}
                readOnly={readOnly}
              />
            </td>
            <td className={TD_CLASSES}>
              {!readOnly && (
                <Button
                  soft
                  onClick={() => handleDelete(row.index)}
                  className="ml3"
                  disabled={row.strains?.data?.some((strain) => strain.attached)}
                  tooltip={row.strains?.data?.some((strain) => strain.attached) ? 'This species is in use' : undefined}
                  style={{ width: '40px', padding: '0' }}
                >
                  <Icon icon="close" width="10" height="10" className="dark-gray" />
                </Button>
              )}
            </td>
          </tr>
          {row.expanded === true && (
            <tr>
              <td></td>
              <td colSpan={2}>
                <SubComponent
                  row={row}
                  data={data}
                  setData={setData}
                  errors={errors.find((e) => Number(e.index) === Number(row.index))}
                  readOnly={readOnly}
                />
              </td>
            </tr>
          )}
        </Fragment>
      ))}
    </tbody>
  </table>
);

interface RepeaterProps {
  data: Array<ExtendedAnimalSpecies>;
  setData: React.Dispatch<React.SetStateAction<Array<ExtendedAnimalSpecies>>>;
  errors: Array<false | FormError>;
  readOnly: boolean;
}

const Repeater: FC<RepeaterProps> = ({ data, setData, errors, readOnly }) => {
  const [newIndex, setNewIndex] = useState(0);
  const [expanded, setExpanded] = useState({});
  const initialSpecies = {
    strains: {
      data: [
        {
          index: newIndex + 1,
          attached: false,
        },
      ],
    },
    index: newIndex,
    expanded: true,
  };

  useEffect(() => {
    if (data.length && !newIndex) {
      setNewIndex(data.length);
    }
  }, [data]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const newData = [...data];
    const speciesIndex = target.getAttribute('data-index');
    if (newData[speciesIndex]) {
      newData[speciesIndex].name = target.value;
    }
    return setData(newData);
  };

  const toggleExpanded = (expanded: boolean, id?: ID) => {
    const updatedData = data.map((item) => {
      if (String(item.id) === String(id)) {
        return { ...item, expanded: !expanded };
      }
      return item;
    });
    return setData(updatedData);
  };

  const handleAdd = () => {
    setData([...data, initialSpecies]);
    setExpanded({ ...expanded, [data.length]: {} });
    setNewIndex(newIndex + 1);
  };

  const handleDelete = (index) => {
    const updateExpanded = { ...expanded };
    delete updateExpanded[index];
    setExpanded(updateExpanded);
    setData(data.filter((species) => species.index !== index));
  };

  return (
    <div>
      {_isNotEmpty(data) ? (
        <>
          <div className="ui-card overflow-hidden ui__table ui__repeater bb b--moon-gray">
            <SpeciesStrainsTable
              data={data}
              setData={setData}
              errors={errors}
              toggleExpanded={toggleExpanded}
              handleChange={handleChange}
              handleDelete={handleDelete}
              readOnly={readOnly}
            />
          </div>
          {!readOnly && (
            <div className="mt3">
              <Button onClick={handleAdd} className="mb3 br-pill" icon="add_new" paleBlue>
                <span>Add species</span>
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="mv4">
          <Banner info className="mw6" dismiss={false}>
            <h3 className="f5 normal basier-med lh-title">No species have been added</h3>
            <p className="f6 pt2 lh-copy">
              To get started, add species and strains relevant to your studies. All team members will have access to the
              added species and strains.
            </p>
            {!readOnly && (
              <Button onClick={handleAdd} className="mb3 br-pill" icon="add_new" paleBlue>
                <span>Add species</span>
              </Button>
            )}
          </Banner>
        </div>
      )}
    </div>
  );
};

const DebounceInput = ({ inputProps, handleChange, readOnly }) => {
  useEffect(() => {
    setVal(inputProps.value);
  }, [inputProps.value]);

  const [val, setVal] = useState(inputProps.value || '');
  const [event, setEvent] = useState(false);
  const debouncedFunction = useDebouncedCallback(
    (event) => {
      handleChange(event);
    },
    200,
    { maxWait: 2000 }
  );

  useEffect(() => {
    if (event) {
      debouncedFunction(event);
    }
  }, [val]);

  return (
    <input
      {...inputProps}
      type="text"
      value={val}
      disabled={readOnly}
      onChange={(e) => {
        e.persist();
        setEvent(e);
        setVal(e.target.value);
      }}
    />
  );
};

interface SubComponentProps {
  row: ExtendedAnimalSpecies;
  data: Array<ExtendedAnimalSpecies>;
  setData: React.Dispatch<React.SetStateAction<Array<ExtendedAnimalSpecies>>>;
}

const SubComponent: FC<SubComponentProps> = ({ row, data, setData, readOnly }) => {
  const handleChange = ({ target }) => {
    const newData = [...data];
    const speciesIndex = newData.findIndex(({ index }) => row.index === index);
    const dataIndex = target.getAttribute('data-index');
    newData[speciesIndex].strains.data[dataIndex].name = target.value;
    setData(newData);
  };

  const handleAdd = (species) => {
    const initialStrain = {
      name: '',
      attached: false,
    };

    const newData = [...data];
    newData.forEach((sp) => {
      if (sp.name === species) {
        sp.strains.data.push({
          ...initialStrain,
          species,
          index: sp.strains.data.length,
        });
      }
    });
    return setData(newData);
  };

  const handleRemove = (species, index) => {
    const newData = [...data];
    newData.forEach((sp) => {
      if (sp.name === species) {
        sp.strains.data = sp.strains.data.filter((strain, id) => id !== index);
      }
    });
    setData(newData);
  };

  const strainHasName = (strain) => {
    return strain.name ? false : "Strain can't be blank";
  };

  return (
    <div className="ml5">
      <div className="ui__repeater__children mb2">
        <label className="pl4">Strains</label>
        {row?.strains?.data &&
          row.strains.data.map((strain, index) => (
            <div key={index} className="ui__repeater__child flex items-center pv2">
              <DebounceInput
                handleChange={handleChange}
                readOnly={readOnly}
                inputProps={{
                  className: `z-5 relative ${strainHasName(strain) && 'input__error'}`,
                  'data-tooltip-id': 'global-tooltip-id',
                  'data-tooltip-content': strainHasName(strain) ? strainHasName(strain) : '',
                  value: strain.name,
                  'data-index': index,
                  style: { width: '268px', marginBottom: 0 },
                }}
              />
              {!readOnly && (
                <Button
                  soft
                  onClick={() => handleRemove(row.name, index)}
                  disabled={row.strains.data.length === 1 || strain.attached}
                  className="ml3"
                  style={{ width: '40px', padding: '0' }}
                >
                  <Icon icon="close" width="10" height="10" className="dark-gray" />
                </Button>
              )}
            </div>
          ))}
      </div>
      {!readOnly && (
        <Button onClick={() => handleAdd(row.name)} className="ml4 mb3 br-pill" icon="add_new" paleBlue>
          <span>Add strain</span>
        </Button>
      )}
    </div>
  );
};

export default Repeater;
