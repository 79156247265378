//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { DateUtils } from '@/utils/Date.utils';
import styles from '../../AddTask.module.scss';
import InputWithUnits from '@/components/UI/FormElements/InputWithUnits';
import { ErrorMessage } from '@hookform/error-message';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import {
  repeatPeriodOptions,
  daysOptions,
  startDateValidator,
  repeatValidator,
  recurringDayValidator,
} from './AddTaskScheduleForm.utils';
import type { ChangeEvent } from 'react';
import { AddTaskFormTypes, StepRenderProps } from '../../AddTask.model';

interface ReccuringFieldsProps extends Pick<StepRenderProps, 'disabledFields'> {}
interface DayChecklistProps {
  selectedDays: string[];
  onChange: (event: any[]) => void;
}

const RecurringFields = ({ disabledFields = [] }: ReccuringFieldsProps): JSX.Element => {
  const { control, watch, errors } = useFormContext();
  const startValue = watch(`${AddTaskFormTypes.schedule}.start`);
  const endValue = watch(`${AddTaskFormTypes.schedule}.end`);
  const repeat = watch(`${AddTaskFormTypes.schedule}.repeat`);

  return (
    <div className="pt3 ml4">
      <div className="mb3">
        <div className="flex items-center">
          <label className="w4 mb0 lh-title">Repeat every</label>
          <Controller
            name={`${AddTaskFormTypes.schedule}.repeat`}
            control={control}
            defaultValue={{ unit: repeatPeriodOptions.days.value }}
            rules={{ validate: repeatValidator }}
            render={({ value, onChange }: ControllerRenderProps) => (
              <InputWithUnits
                value={value?.value}
                unit={value?.unit}
                classes={`${styles['add-task-input-field']} ${styles['add-task-recurring-field']}`}
                unitOptions={Object.values(repeatPeriodOptions)}
                onChange={onChange}
                name="repeat"
                disabled={disabledFields?.includes(`${AddTaskFormTypes.schedule}.repeat`)}
              />
            )}
          />
        </div>
        <ErrorMessage
          errors={errors}
          name={`${AddTaskFormTypes.schedule}.repeat`}
          render={({ message }) => <p className="f6 red db pv2">{message}</p>}
        />
      </div>
      <div className="mb3">
        {repeat?.unit === repeatPeriodOptions.weeks.value && (
          <>
            <div
              className={`flex items-top ${
                disabledFields.includes(`${AddTaskFormTypes.schedule}.repeat.days`) ? 'ui__disabled' : ''
              }`}
              data-test-element="repeat-on"
            >
              <label className="w4 mb0 lh-title">Repeat on</label>
              <Controller
                name={`${AddTaskFormTypes.schedule}.repeat.days`}
                control={control}
                defaultValue={[]}
                rules={{ validate: recurringDayValidator }}
                render={({ value: selectedDays, onChange }: ControllerRenderProps) => (
                  <DayChecklist onChange={onChange} selectedDays={selectedDays} />
                )}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name={`${AddTaskFormTypes.schedule}.repeat.days`}
              render={({ message }) => <p className="f6 red db pv2">{message}</p>}
            />
          </>
        )}
      </div>
      <div className="flex mb3" data-test-component="DurationForm" data-test-element="container">
        <div data-test-component="DurationForm" data-test-element="begins">
          <label>Begins</label>
          <Controller
            name={`${AddTaskFormTypes.schedule}.start`}
            control={control}
            defaultValue={DateUtils.dateNow()}
            rules={{ validate: (value: any) => startDateValidator(value, endValue) }}
            render={({ onChange, value }: ControllerRenderProps) => (
              <DatePickerNative value={value} onChange={onChange} className={styles['add-task-input-field']} />
            )}
          />
        </div>
        <div className="ml4" data-test-component="DurationForm" data-test-element="ends">
          <label>Ends</label>
          <Controller
            name={`${AddTaskFormTypes.schedule}.end`}
            control={control}
            defaultValue=""
            rules={{ validate: (value: any) => startDateValidator(startValue, value) }}
            render={({ onChange, value }: ControllerRenderProps) => (
              <DatePickerNative value={value} onChange={onChange} className={styles['add-task-input-field']} />
            )}
          />
        </div>
      </div>
      <div className="w-100">
        <ErrorMessage
          errors={errors}
          name={`${AddTaskFormTypes.schedule}.start`}
          render={({ message }) => <p className="f6 red db pb2">{message}</p>}
        />
      </div>
    </div>
  );
};

export const DayChecklist = ({ selectedDays, onChange }: DayChecklistProps): JSX.Element => {
  const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>): void => {
    const day = event.target.value;
    const setDays = new Set(selectedDays);
    if (setDays.has(day)) {
      setDays.delete(day);
    } else {
      setDays.add(day);
    }
    onChange(Array.from(setDays));
  };

  return (
    <div className={styles['schedule-daylist-container']} data-test-component="DayPicker" data-test-element="container">
      {daysOptions.map(({ label, value: dayValue }) => {
        return (
          <div
            className={`${styles['schedule-daylist-item']} mb2`}
            data-test-component="DayPicker"
            data-test-element="day"
            data-test-key={dayValue}
          >
            <Checkbox
              key={dayValue}
              label={label}
              value={dayValue}
              checked={selectedDays.includes(dayValue)}
              onChange={handleCheckboxClick}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RecurringFields;
