/**
 * A service used to inferface with serial ports and the devices connected
 * such as RFID readers and balances.
 */

import { _isNil, _notNil } from '@/littledash';
import type { iPort } from '@/model/Device.model';
export class Port implements iPort {
  private port: SerialPort | null;

  constructor() {
    this.port = null;
  }

  public async requestPort({ filters = [] }: { filters: Array<SerialPortFilter> }): Promise<SerialPort> {
    try {
      this.port = await navigator.serial.requestPort({ filters });
      return this.port;
    } catch (error) {
      throw new Error('Error requesting serial port access:');
    }
  }

  public async getPorts(): Promise<Array<SerialPort>> {
    try {
      const ports: Array<SerialPort> = await navigator.serial.getPorts();
      return ports;
    } catch (error) {
      throw new Error('Error listing serial ports:');
    }
  }

  public async open(serialOptions: SerialOptions = { baudRate: 9600 }): Promise<void> {
    try {
      if (_notNil(this.port)) {
        await this.port.open(serialOptions);
      }
    } catch (error) {
      throw new Error("'Error connecting to serial port:'");
    }
  }

  public async close(): Promise<void> {
    try {
      if (_notNil(this.port)) {
        await this.port.close();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error closing serial port');
    }
  }

  public async read(): Promise<string | undefined> {
    if (_isNil(this.port)) {
      throw new Error("'No Serial port selected:'");
    }

    while (this.port.readable) {
      const reader = this.port.readable.getReader();
      const textDecoder = new TextDecoder();
      let receivedData = ''; // Initialize an empty string to accumulate data
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { value, done } = await reader.read();
          receivedData += textDecoder.decode(value);
          if (done) {
            // |reader| has been canceled.
            break;
          }
        }
        return receivedData;
      } catch (error) {
        throw new Error("'Error receiving data from serial port:'");
      } finally {
        reader.releaseLock();
      }
    }
  }
}
