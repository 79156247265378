import Checkbox from '@/components/UI/FormElements/Checkbox/Checkbox';
import { SelectMenu } from '@/components/UI/SelectMenu';
import type { ItemsProps } from '@/components/UI/SelectMenu/SelectMenu.model';
import { trunc } from '@/helpers';
import { _flatten, _isNotBlank, _isNotEmpty } from '@/littledash';
import type { UserApiId } from '@/model/User.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useApiPagination } from '@/support/Hooks/api/useApiPagination';
import type { User } from '@sentry/react';
import { type FC, useMemo } from 'react';
import type { CalendarFilters } from '../Calendar.model';

interface UserFiltersProps {
  filters: CalendarFilters;
  onFilterChange: (filters: CalendarFilters) => void;
}

const UserFilter: FC<UserFiltersProps> = ({ filters, onFilterChange }) => {
  const {
    response: users,
    loading: fetchingUsers,
    invoke: getUsers,
  } = useApiHook({
    endpoint: 'GET /api/v1/users',
    query: {
      status: ['active'],
    },
  });

  const {
    pages: usersPages,
    nextPage: nextUserPage,
    hasNextPage: hasNextUserPage,
    reset: resetUsersPages,
  } = useApiPagination({ response: users });

  const handleUserFilter = (selected: string[]): void => {
    const selectedApiIds = selected as UserApiId[];
    onFilterChange({ ...filters, assignee: selectedApiIds });
  };

  const onSearchUsers = (query: string): void => {
    resetUsersPages();
    getUsers({ query: { 'query_by[]': 'name', query, status: ['active'] } });
  };

  const onScrollBottomUsers = (): void => {
    if (hasNextUserPage) {
      getUsers({ query: { page: nextUserPage, status: ['active'] } });
    }
  };

  const userItems: ItemsProps[] = useMemo(
    () =>
      _isNotEmpty(usersPages)
        ? _flatten(usersPages).map(({ api_id, name, email }: User) => ({
            value: api_id,
            name: _isNotBlank(name) ? name : email,
          }))
        : [],
    [usersPages]
  );

  return (
    <div className="pa3" data-test-component="UserFilter" data-test-element="container">
      <h3 className="near-black lh-title f6 pb2">Users</h3>
      <div className="ui-card">
        <SelectMenu
          items={userItems}
          onSearch={onSearchUsers}
          onSelect={handleUserFilter}
          onScrollBottom={onScrollBottomUsers}
          searchPlaceholderText="Search by user name"
          disabled={fetchingUsers}
          renderItem={({ name, value, isSelected, onChange }) => (
            <Checkbox label={trunc(name, 30)} checked={isSelected} onChange={onChange} value={value} />
          )}
          enableSelectAll={false}
        />
      </div>
    </div>
  );
};

export default UserFilter;
