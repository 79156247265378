// @ts-nocheck: converted from JS

import { _chunk } from '@/littledash';

export const SEQUENTIAL = 'SEQUENTIAL';
export const REPEATING = 'REPEATING';

export const ID_METHODS = [
  {
    value: SEQUENTIAL,
    label: 'Sequential',
  },
  {
    value: REPEATING,
    label: 'Repeating',
  },
];
export const groupAnimalsByTreatmentGroup = (accumulator, subject) => {
  const foundIndex = accumulator.findIndex(({ group }) => group.id === subject.study_group.id);
  if (foundIndex !== -1) {
    accumulator[foundIndex].animals.push({ ...subject, newAnimalId: '' });
    return accumulator;
  }
  accumulator.push({
    group: subject.study_group,
    animals: [{ ...subject, newAnimalId: '' }],
  });
  return accumulator;
};

export const groupAnimalsByCollectionId = (accumulator, subject) => {
  const foundIndex = accumulator.findIndex(({ collection_id }) => collection_id === subject.cage?.id);
  if (foundIndex !== -1) {
    accumulator[foundIndex].animals.push(subject);
    return accumulator;
  }
  accumulator.push({
    collection_id: subject.cage?.id,
    collection: subject.cage?.name,
    animals: [subject],
  });
  return accumulator;
};

export const sortAnimalsByFrequency = (a, b) => b.animals.length - a.animals.length;

export const sortAnimalsByNumber = (a, b) => a.number - b.number;

export const spreadAnimals = (accumulator, group) => {
  accumulator.push(...group.animals);
  return accumulator;
};

export const chunkGroups = (groups, chunkSize) => _chunk(groups, chunkSize);

export const groupAndSortAnimals = (subjects) =>
  subjects
    .reduce(groupAnimalsByTreatmentGroup, [])
    .sort((a, b) => a.group.id - b.group.id)
    .map((group) => ({
      ...group,
      animals: group.animals
        .reduce(groupAnimalsByCollectionId, [])
        .sort(sortAnimalsByFrequency)
        .reduce(spreadAnimals, [])
        .sort(sortAnimalsByNumber),
    }));

export const generateRepeatingIds = (proposedCages) => {
  const update = [...proposedCages].map((cage) => ({
    ...cage,
    animals: cage.animals.map((animal, index) => ({
      ...animal,
      newAnimalId: `Animal ${index + 1}`,
    })),
  }));
  return update;
};

export const generateSequentialIds = (proposedCages) => {
  let index = 1;
  const update = [...proposedCages].map((cage) => ({
    ...cage,
    animals: cage.animals.map((animal) => ({
      ...animal,
      newAnimalId: `Animal ${index++}`,
    })),
  }));
  return update;
};
