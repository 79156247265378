import { _isFunction, _notNil } from '@/littledash';
import ReactPortal from '@/support/HOCs/ReactPortal';
import type { FC, MouseEvent } from 'react';
import { createContext, createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type {
  DropdownContextProps,
  DropdownMenuItemProps,
  DropdownMenuProps,
  DropdownProps,
  DropdownProviderProps,
  DropdownToggleProps,
} from './Dropdown.model';
import styles from './Dropdown.module.scss'; // TODO Menu item up/down keyboard navigation + enter to select
import { EMPTY_RECT, getMenuPosition } from './Dropdown.utils'; // TODO Menu item up/down keyboard navigation + enter to select

// TODO Menu item up/down keyboard navigation + enter to select
// TODO Menu open position `startTop` `startBottom` based on window or overflow (or set by state)
// TODO override Menu open position
// TODO Consider adding an outer state handler for open/close like `Tabs`
// TODO Add tooltip support to menu item

const reactPortalInnerRef = createRef<HTMLDivElement>();
const DropdownContext = createContext<DropdownContextProps | null>(null);
const DropdownProvider: FC<DropdownProviderProps> = (props) => {
  const { children, value } = props;
  return <DropdownContext.Provider value={value}>{children}</DropdownContext.Provider>;
};

export const Dropdown: FC<DropdownProps> = ({ disabled = false, open, children, renderMenu, onOpen }) => {
  const dropdownToggleRef = useRef<HTMLDivElement | null>(null);
  const [dropdownMenuOpen, setToggleDropdownMenu] = useState<boolean>(false);

  useEffect(() => {
    onOpen?.(dropdownMenuOpen);
  }, [dropdownMenuOpen, onOpen]);
  const toggleDropdownMenu = (bool?: boolean) => {
    setToggleDropdownMenu(bool ?? !dropdownMenuOpen);
  };

  useEffect(() => {
    if (open !== undefined && dropdownMenuOpen !== open) {
      setToggleDropdownMenu(open);
    }
  }, [open]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setToggleDropdownMenu(false);
      }
    };
    const handeEventOutsideRef = ({ target }: Event): void => {
      if (_notNil(reactPortalInnerRef) && !reactPortalInnerRef?.current?.contains(target as Node)) {
        toggleDropdownMenu(false);
      }
    };
    window.addEventListener('keydown', handleEsc, { passive: true });
    window.addEventListener('mousedown', handeEventOutsideRef, { passive: true });
    document?.querySelector('body')?.addEventListener('mousewheel', handeEventOutsideRef, { passive: true });
    return () => {
      window.removeEventListener('keydown', handleEsc);
      window.removeEventListener('mousedown', handeEventOutsideRef);
      document?.removeEventListener('mousewheel', handeEventOutsideRef);
    };
  }, []);

  return (
    <div
      className={styles['dropdown']}
      data-testid="dropdown-wrapper"
      data-test-component="Dropdown"
      data-test-element="container"
    >
      <DropdownProvider
        value={{
          dropdownMenuOpen: dropdownMenuOpen && !disabled,
          toggleDropdownMenu,
          toggleElementDOMRect: dropdownToggleRef?.current?.getBoundingClientRect() ?? null,
        }}
      >
        <div ref={dropdownToggleRef}>
          <DropdownToggle>{children}</DropdownToggle>
        </div>
        <DropdownMenu>
          <>{_isFunction(renderMenu) ? renderMenu({ dropdownMenuOpen, toggleDropdownMenu }) : null}</>
        </DropdownMenu>
      </DropdownProvider>
    </div>
  );
};

export const DropdownToggle: FC<DropdownToggleProps> = ({ children }) => {
  const { toggleDropdownMenu } = useContext(DropdownContext) as DropdownContextProps;

  return (
    <div
      className={styles['dropdown-toggle']}
      onClick={() => toggleDropdownMenu()}
      data-test-component="DropdownToggle"
      data-test-element="container"
      data-testid="dropdown-toggle"
    >
      {children}
    </div>
  );
};

export const DropdownMenu: FC<DropdownMenuProps> = ({ children }) => {
  const { dropdownMenuOpen, toggleElementDOMRect } = useContext(DropdownContext) as DropdownContextProps;
  const [dropdownRef, setdropdownRef] = useState<HTMLDivElement | null>(null);

  const position = useMemo(
    () =>
      getMenuPosition({
        toggleElementDOMRect: toggleElementDOMRect ?? EMPTY_RECT,
        window,
        dropdownElementDOMRect: dropdownRef?.getBoundingClientRect() ?? EMPTY_RECT,
      }),
    [toggleElementDOMRect, dropdownRef]
  );

  if (!dropdownMenuOpen) {
    return null;
  }

  return (
    <ReactPortal wrapperId="dropdown-menu-portal">
      <div
        ref={reactPortalInnerRef}
        className={`${styles['dropdown-menu']} ${
          dropdownMenuOpen && _notNil(reactPortalInnerRef) ? styles['dropdown-menu--open'] : ''
        }`}
        aria-expanded={dropdownMenuOpen}
        data-testid="dropdown-menu"
        style={_notNil(position) ? position.styles : {}}
      >
        {children && (
          <div ref={setdropdownRef} className={`${styles['dropdown-menu-wrapper']}`}>
            <div className="relative">
              <div data-arrow-align={`${position?.arrowOrientation?.y}-${position.arrowOrientation.x}`} />
              <div>{children}</div>
            </div>
          </div>
        )}
      </div>
    </ReactPortal>
  );
};

export const DropdownMenuItem: FC<DropdownMenuItemProps> = ({ children, onClick, disabled }) => {
  const { toggleDropdownMenu } = useContext(DropdownContext) as DropdownContextProps;
  const handleOnClick = (event: MouseEvent<HTMLDivElement>): void => {
    if (onClick) {
      onClick(event);
    }
    toggleDropdownMenu(false);
  };

  return (
    <div
      className={`${styles['dropdown-menu-item']} ${disabled ? 'ui__disabled' : ''}`}
      onClick={handleOnClick}
      data-test-component="DropdownMenuItem"
      data-test-element="container"
      data-testid="dropdown-menu-item"
    >
      {children}
    </div>
  );
};

export const DropdownMenuDivider: FC = () => (
  <div data-testid="dropdown-menu-divider" className={styles['dropdown-menu-divider']}></div>
);
