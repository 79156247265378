import NoDataCard from '@/components/NoDataCard';
import { _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { ErrorSetting } from '@/model/Study.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { Plotly } from '@/support/plotly';
import { web as webRoute } from '@/support/route';
import type { State } from 'model/State.model';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { GraphsState } from '../Graph.model';
import { getAxisLabel, getLatestMeasurementFromMetrics, graphConfig, graphOptions } from './Graph.utils';
import GraphTitle, { GraphSettings } from './GraphTitle';

interface LineChartErrorBarsProps {
  id: ID;
  studyName: string;
  errorDeviation: ErrorSetting;
  state: GraphsState;
}

// The line chart shows the averages for each group, not the individual animal data. The vertical lines are the standard deviation per value.
const LineChartErrorBars: React.FC<LineChartErrorBarsProps> = ({ id, studyName, errorDeviation, state }) => {
  const { changeOption, activeMetric, metrics, groups, survivalDates } = state;
  const features = useSelector((state: State) => state.team.features);
  const dateRanges = useMemo(
    () => ({ first: survivalDates.start, last: getLatestMeasurementFromMetrics(metrics, survivalDates.start) }),
    [state]
  );
  const [settings, setSettings] = useState<GraphSettings>({
    yaxis: 'linear',
    error: errorDeviation,
    average: 'mean',
  });
  const updateGraphData = (graphData: Plotly.Data) => {
    const graphSettings = graphConfig({
      hasLegend: true,
      xTitle: 'Study day',
      // @ts-expect-error: type mismatch
      yTitle: getAxisLabel(activeMetric, changeOption, settings.average),
      yType: settings.yaxis,
    });
    Plotly.newPlot('errorBars', { ...graphSettings, data: graphData });
  };

  const dataFormat = ({ name, color }: TreatmentGroup, index: number): Plotly.Data => ({
    x: [],
    y: [],
    error_y: {
      type: 'data',
      array: [],
      visible: true,
    },
    marker: {
      color,
      size: 8,
      line: {
        color,
        width: 1,
      },
      symbol: index,
    },
    mode: 'lines+markers',
    name,
    legendgroup: name,
    line: { shape: 'linear' },
    showlegend: true,
    type: 'scatter',
  });

  const formatGraphData = () => {
    const graphData = metrics?.map((group, index) => {
      return group.averages?.reduce(
        (groupData, average) => {
          // Add study day to X array, then corresponding average to Y
          // The error deviation is add to the error_y array this is for the vertical lines seen per study day
          groupData.x.push(average.study_day);
          groupData.y.push(average[settings.average ?? 'mean']);
          if (_notNil(settings.error) && !['dtdc', 'tgi'].includes(settings.average ?? '')) {
            groupData.error_y.array.push(average[settings.average === 'mean' ? settings.error : 'mad']);
          }
          return groupData;
        },
        dataFormat(group, index)
      );
    });
    updateGraphData(graphData as Plotly.Data);
  };

  useEffect(() => {
    if (groups.length) {
      formatGraphData();
    }
  }, [settings, metrics, survivalDates]);

  if (!groups.length) {
    return (
      <NoDataCard
        className="ma3"
        title="You haven't added any measurements yet"
        text="Create measurements to monitor how your study progresses. You can export all group data to excel as a report and download graphs instantly."
        link={webRoute('studies.settings', { id })}
        btnTxt="Study settings"
      />
    );
  }

  return (
    <>
      <div className="ph4 pv3" data-test-component="LineChartErrorBars" data-test-element="container">
        <GraphTitle
          dates={dateRanges}
          studyName={studyName}
          graphType="GROUPS_LINE"
          graphOptions={graphOptions({ withErrors: true, average: settings.average, features })}
          settings={settings}
          setSettings={setSettings}
        />
        <div id="errorBars" className="w-100 h-100" style={{ height: '548px' }} data-testid="graphs__line-chart" />
      </div>
    </>
  );
};

export default LineChartErrorBars;
