import { ErrorMessage } from '@hookform/error-message';
import type {
  DataTableColumn,
  DataTableColumnAddRequest,
  DataTableColumnId,
} from '@/components/UI/DataTable/DataTable.model';
import { DateTimePicker, Select } from '@/components/UI/FormFields';
import { NumberField } from '@/components/UI/FormFields/Number';
import Link from '@/components/UI/Link';
import { _isEmpty, _isNil } from '@/littledash';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { uniqueNameValidatorFactory } from '@/components/UI/DataTable/toolbar/Menus/AddColumn/AddColumn.util';
import styles from './EditDataTableTimestampBaselineColumn.module.scss';

interface EditDataTableTimestamoBaselineColumnForm {
  name: string;
  from: string;
  interval: number;
  interval_time_unit: string;
  window: number;
}

interface EditDataTableTimestamoBaselineColumnProps {
  formData: EditDataTableTimestamoBaselineColumnForm;
  columnId?: DataTableColumnId;
  columns: Array<DataTableColumn>;
  readOnly: boolean;
  onSubmit: (data: DataTableColumnAddRequest) => Promise<void>;
  closeModal: () => void;
}
export const timeUnitOptions = [
  {
    label: 'Minutes',
    value: 'MINUTES',
  },
  {
    label: 'Hours',
    value: 'HOURS',
  },
  {
    label: 'Days',
    value: 'DAYS',
  },
];

export const EditDataTableTimestampBaselineColumn: FC<EditDataTableTimestamoBaselineColumnProps> = ({
  formData,
  columnId,
  columns,
  readOnly,
  onSubmit,
  closeModal,
}) => {
  const uniqueColumnName = useMemo(
    () => uniqueNameValidatorFactory(new Set(columns.filter((c) => c.id !== columnId).map(({ name }) => name.trim()))),
    [columnId, columns]
  );

  const { interval, interval_time_unit } = formData;

  const methods = useForm<EditDataTableTimestamoBaselineColumnForm>({
    mode: 'onChange',
    defaultValues: {
      ...formData,
      interval: DateUtils.convertSecondsToUnit(interval, interval_time_unit),
      interval_time_unit: interval_time_unit ?? 'MINUTES',
    },
  });
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isDirty },
  } = methods;

  const mapFormData = async (data: EditDataTableTimestamoBaselineColumnForm) => {
    const { name, from, interval, interval_time_unit, window } = data;

    await onSubmit({
      type: 'timestampBaseline',
      name,
      pk: {
        from,
        interval: DateUtils.convertUnitToSeconds(interval, interval_time_unit),
        interval_time_unit,
        window,
      },
    });
    closeModal();
  };

  return (
    <ModalContainer size="narrow">
      <ModalHeader
        title={`${_isNil(columnId) ? 'Add' : 'Update'} Timestamp: PK Dose`}
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
        subText={
          <>
            Pick a start time and specify intervals.{' '}
            <Link
              to="https://help.benchling.com/hc/en-us/articles/22269843865357"
              openTab
              style={{ color: 'dodgerblue' }}
            >
              Read more
            </Link>
          </>
        }
      />
      <FormProvider {...methods}>
        <form>
          <div className={styles['data-table-formula-column-container']}>
            <div className="pa3 w-100 bl b--moon-gray h-100">
              <div style={{ flexGrow: 4 }}>
                <label htmlFor="name">Column name</label>
                <input
                  type="text"
                  name="name"
                  ref={register({ required: 'This field is required', maxLength: 255, validate: { uniqueColumnName } })}
                  placeholder="Name"
                  className={errors?.name ? 'input__error' : ''}
                  style={{ marginBottom: 0 }}
                  autoFocus
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => <small className="red db pt2">{message}</small>}
                />
              </div>
              <div className="mt3">
                <DateTimePicker name="from" label="Start time" includeTime={true} disabled={readOnly} />
                <ErrorMessage
                  errors={errors}
                  name="from"
                  render={({ message }) => <small className="red db pt2">{message}</small>}
                />
              </div>
              <div className="mt3">
                <div className="flex justify-apart">
                  <NumberField
                    name="interval"
                    label="Time between doses"
                    required
                    min={1}
                    disabled={readOnly}
                    autoFocus={true}
                  />
                  <div className="pl2">
                    <Select
                      required
                      name="interval_time_unit"
                      label="Unit"
                      options={timeUnitOptions}
                      disabled={readOnly}
                    />
                  </div>
                </div>
              </div>
              <div className="mt3">
                <label htmlFor="">Timestamp clash</label>
                <div className="dark-gray mb2 f6 lh-copy">Warn me when timestamps are within.</div>
                <div className="w4">
                  <input
                    type="number"
                    name="window"
                    min="0"
                    ref={register({
                      valueAsNumber: true,
                    })}
                    placeholder="mins"
                    className={errors?.window ? 'input__error' : ''}
                    style={{ marginBottom: 0 }}
                    disabled={readOnly}
                  />
                </div>
              </div>
            </div>
            <ModalActions
              className="pa3 bt b--moon-gray"
              onSubmit={handleSubmit(mapFormData)}
              onCancel={closeModal}
              submitBtnText={_isNil(columnId) ? 'Add' : 'Update'}
              submitButtonProps={{ disabled: !_isEmpty(errors) || !isDirty || isSubmitting, submit: true }}
            />
          </div>
        </form>
      </FormProvider>
    </ModalContainer>
  );
};
