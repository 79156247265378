import type { ColorDetail } from '@/constants/colors';
import type { components } from '@/generated/internal-api/openapi-types';
import type { StudyApiId } from '@/model/Study.model';
import type { UserApiId } from '@/model/User.model';

export enum CalendarViews {
  Month = 'Month',
  Week = 'Week',
  Day = 'Day',
}
export type MonthViewDays = Record<string, components['schemas']['TaskCalendarDay.schema']>;
export type DateRange = {
  start: Date;
  end: Date;
};
export type StudyColours = Record<StudyApiId, ColorDetail>;

export enum CalendarFilterKeys {
  study_api_id = 'study_api_id',
  assignee = 'assignee',
}

export interface CalendarFilters {
  [CalendarFilterKeys.study_api_id]: Array<StudyApiId>;
  [CalendarFilterKeys.assignee]: Array<UserApiId>;
}

export interface CalendarState {
  chosenDay: Date;
  span: CalendarViews;
  yearAndMonth: Array<number>;
  filters: CalendarFilters;
}

export type CalendarViewsOptions = CalendarViews.Month | CalendarViews.Week | CalendarViews.Day;

export type CalendarAction =
  | { type: 'SET_CHOSEN_DAY'; data: Date }
  | { type: 'SET_SPAN'; data: CalendarViewsOptions }
  | { type: 'SET_YEAR_AND_MONTH'; data: Array<number> }
  | { type: 'SET_FILTERS'; data: CalendarFilters }
  | { type: 'GO_TO_DAY'; data: Date };
