import {
  CreateOrUpdatePresetContext,
  SlugDetail,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util.ts';
import { FormulaInput } from '@/components/UI/FormulaInput/FormulaInput.tsx';
import { _isNotBlank } from '@/littledash.ts';
import { PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import { Variable } from '@/utils/FormulaDsl.ts';
import Fuse from 'fuse.js';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form@latest';
import { RiSearch2Line } from 'react-icons/ri';
import styles from './PresetBuilder.module.scss';

const operators = [
  { label: '+', value: '+' },
  { label: '-', value: '-' },
  { label: '/', value: '/' },
  { label: '*', value: '*' },
  { label: 'π', value: 'PI()' },
  { label: 'avg', value: 'AVERAGE()' },
  { label: '(', value: '(' },
  { label: ')', value: ')' },
] as const;
type Operator = (typeof operators)[number]['value'];
type FormulaInputVariable = Omit<SlugDetail, 'parent'> & { variable: Variable };

export const OutputForm: FC<{ index: number }> = ({ index }) => {
  const formulaInputCaretPositionRef = useRef<number>(0);
  const formMethods = useFormContext<PresetCreateOrUpdateV1>();
  const { slugDetailsRef } = useContext(CreateOrUpdatePresetContext);
  const [searchString, updateSearchString] = useState<string>('');

  const { formulaInputs, formulaInputVariables, formulaInputsFuse } = useMemo(() => {
    const currentSlug = formMethods.getValues(`measurements.${index}.slug`);
    const currentInputCount = slugDetailsRef.current.filter(
      (s) => s.type === 'input' && s.parent === currentSlug
    ).length;

    const { formulaInputs, formulaInputVariables } = [...slugDetailsRef.current].reduce<{
      formulaInputs: Array<FormulaInputVariable>;
      formulaInputVariables: Record<Variable, string>;
    }>(
      (acc, slugDetail) => {
        if (
          (currentInputCount === 0 && slugDetail.type === 'output' && slugDetail.slug !== currentSlug) ||
          (slugDetail.type === 'input' && slugDetail.parent === currentSlug)
        ) {
          const variable: Variable = `$${slugDetail.slug}`;

          acc.formulaInputs.push({ type: slugDetail.type, name: slugDetail.name, slug: slugDetail.slug, variable });
          acc.formulaInputVariables = { ...acc.formulaInputVariables, [variable]: slugDetail.name };
        }
        return acc;
      },
      { formulaInputs: [], formulaInputVariables: {} }
    );
    const formulaInputsFuse = new Fuse(formulaInputs, {
      includeScore: false,
      ignoreLocation: true,
      keys: [
        { name: 'name', weight: 0.9 },
        { name: 'slug', weight: 0.75 },
        { name: 'type', weight: 0.1 },
        { name: 'unit', weight: 0.05 },
      ],
    });
    return { formulaInputs, formulaInputVariables, formulaInputsFuse };
  }, [formMethods, index, slugDetailsRef.current]);

  const filteredFormulaInputs = useMemo(() => {
    if (_isNotBlank(searchString)) {
      return formulaInputsFuse.search(searchString.trim()).map((r) => r.item);
    }
    return formulaInputs;
  }, [searchString, formulaInputs, formulaInputsFuse]);
  const addVariable = (variable: Variable | Operator) => {
    const currentFormula = formMethods.getValues(`measurements.${index}.formula`) ?? '';
    const updatedFormula = [
      currentFormula.substring(0, formulaInputCaretPositionRef.current),
      variable,
      currentFormula.substring(formulaInputCaretPositionRef.current),
    ].join('');
    formMethods.setValue(`measurements.${index}.formula`, updatedFormula);
  };

  return (
    <div className="flex flex-row" data-test-component="OutputForm" data-test-element="container">
      <div className="flex flex-column pa3 br">
        <div className={styles.measurementInputSearch}>
          <span className={styles.searchIcon}>
            <RiSearch2Line size="18" />
          </span>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="search"
            onChange={(event) => updateSearchString(event.target.value)}
          />
        </div>
        <div className={styles.measurementInputSearchList}>
          {filteredFormulaInputs.map((input) => (
            <div key={input.slug} className={styles.searchItem} onClick={() => addVariable(input.variable)}>
              <div className={styles.pill}>{input.name}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-column pa3 w-80">
        <div className="flex flex-column mb3">
          <h3 className="near-black">Calculation</h3>
          <p className="f6">
            Click on any items on the left to build your calculation.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.benchling.com/hc/en-us/articles/29260886232589-Creating-and-managing-presets"
              className="link blue"
            >
              Read more
            </a>
          </p>
        </div>
        <div className="flex flex-row">
          {operators.map((operator) => (
            <div className="formula_operator_pill" key={operator.label} onClick={() => addVariable(operator.value)}>
              {operator.label}
            </div>
          ))}
        </div>
        <Controller
          name={`measurements.${index}.formula`}
          control={formMethods.control}
          render={({ field, fieldState }) => (
            <>
              <FormulaInput
                value={field.value}
                invalid={fieldState.invalid}
                variables={formulaInputVariables}
                onChange={field.onChange}
                onBlur={() => formMethods.trigger(`measurements.${index}`)}
                caretPositionRef={formulaInputCaretPositionRef}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};
