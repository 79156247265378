// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { GroupsAndTreatmentsCreation } from '@/components/Studies/Create/Steps/GroupsAndTreatments/GroupsAndTreatmentsCreation';
import { TaskVersionSelector } from '@/components/Studies/Create/Steps/Tasks/TaskVersionSelector';
import FormRender from '@/components/UI/FormRender';
import Header from '@/components/UI/Header';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { errorToast, successToast } from '@/helpers';
import { _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { StudyDraft } from '@/model/Draft.model';
import InVivoError from '@/model/InVivoError.ts';
import type {
  BuildSummaryStepProps,
  StudyFormMetadataItem,
  StudyFormMetaDetails,
  StudyFormSection,
} from '@/model/StudyForm.model';
import type { TaskSpecCreate } from '@/model/Task.model';
import type { GroupApiId } from '@/model/TreatmentGroup.model';
import { ApiService } from '@/support/ApiService';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import type { CreateStudyReducer, CreateStudyState } from './Create.model';
import { assemblePayload, FormActions, initialiseState, PanelLayout, reducer, UseFormProvider } from './Show.utils';
import Alerts from './Steps/Alerts';
import Attachments from './Steps/Attachments';
import BenchlingIntegration from './Steps/BenchlingIntegration';
import Cages from './Steps/Cages';
import Information from './Steps/Information';
import SelectPreset from './Steps/SelectPreset';
import Summary from './Steps/Summary';
import Team from './Steps/Team';
import withDraft from './withDraft';
import { BENCHLING_INTEGRATION_META_SLUG } from '@/helpers.constants.tsx';

interface CustomSectionComponentProps {
  state: CreateStudyState;
  dispatch: () => void;
  totalSteps: number;
  formId: ID;
  formMeta: StudyFormMetaDetails | undefined;
}

interface StudyStep {
  name: string;
  default_section_number?: number;
  Component: (props: CustomSectionComponentProps) => JSX.Element;
  formMeta?: StudyFormMetaDetails;
}

const INFORMATION_STEP: StudyStep = {
  name: 'Information',
  Component: ({ state, dispatch }) => (
    <UseFormProvider state={state} dispatch={dispatch}>
      <Information dispatch={dispatch} />
    </UseFormProvider>
  ),
};

const TEAM_STEP: StudyStep = {
  name: 'Team',
  Component: ({ state, dispatch }) => {
    const {
      study: { users, owner, author },
    } = state;
    return (
      <UseFormProvider state={state} dispatch={dispatch}>
        <Team users={users} owner={owner} author={author} dispatch={dispatch} />
      </UseFormProvider>
    );
  },
};

const requiredSteps = [INFORMATION_STEP, TEAM_STEP];

const buildSummaryStep: (summaryProps: BuildSummaryStepProps) => StudyStep = ({ handleSubmit, formMeta }) => ({
  name: 'Summary',
  Component: ({ state, dispatch }) => (
    <>
      <div className="mw8 mb4 flex justify-between flex-wrap items-top" data-testid="summary-title__container">
        <div>
          <h3 className="normal f4 lh-title pb2">Summary</h3>
          <p className="lh-copy f5">Confirm your study settings are correct before continuing.</p>
        </div>
        <FormActions state={state} dispatch={dispatch} submitForm={handleSubmit} disableSaveDraft />
      </div>
      <Summary state={state} dispatch={dispatch} formMeta={formMeta} />
      <FormActions state={state} dispatch={dispatch} submitForm={handleSubmit} disableSaveDraft />
    </>
  ),
});

const buildBenchlingIntegrationStep = (metaglossary: StudyFormMetadataItem) => ({
  name: 'Benchling Integration',
  Component: ({ state, dispatch }) => (
    <UseFormProvider state={state} dispatch={dispatch}>
      <BenchlingIntegration state={state} dispatch={dispatch} metaglossary={metaglossary} />
    </UseFormProvider>
  ),
});

const STANDARD_STEPS: Array<StudyStep> = [
  {
    name: 'Cages and animals',
    default_section_number: 1,
    Component: ({ state, dispatch }) => (
      <UseFormProvider state={state} dispatch={dispatch}>
        <Cages state={state} dispatch={dispatch} />
      </UseFormProvider>
    ),
  },
  {
    name: 'Groups & Treatments',
    default_section_number: 2,
    Component: ({ state, dispatch }) => (
      <>
        <GroupsAndTreatmentsCreation state={state} dispatch={dispatch} />
        <FormActions state={state} dispatch={dispatch} />
      </>
    ),
  },
  {
    name: 'Select a preset',
    default_section_number: 3,
    Component: ({ state, dispatch }) => (
      <>
        <SelectPreset state={state} dispatch={dispatch} />
        <FormActions state={state} dispatch={dispatch} />
      </>
    ),
  },
  {
    name: 'Alerts',
    default_section_number: 4,
    Component: ({ state, dispatch }) => (
      <UseFormProvider state={state} dispatch={dispatch}>
        <Alerts state={state} dispatch={dispatch} />
      </UseFormProvider>
    ),
  },
  {
    name: 'Tasks',
    default_section_number: 5,
    Component: ({ state, dispatch }) => (
      <>
        <TaskVersionSelector state={state} dispatch={dispatch} />
        <FormActions state={state} dispatch={dispatch} />
      </>
    ),
  },
  {
    name: 'Attachments',
    default_section_number: 6,
    Component: ({ state, dispatch, formId, formMeta }) => (
      <>
        <Attachments state={state} dispatch={dispatch} formId={formId} formMeta={formMeta} />
        <FormActions state={state} dispatch={dispatch} />
      </>
    ),
  },
];

interface ShowProps {
  draft: StudyDraft;
}

const Show: FC<ShowProps> = ({ draft }) => {
  const initialState = useMemo(() => initialiseState(draft), [draft]);
  const [state, dispatch] = useReducer<CreateStudyReducer>(reducer, initialState);
  const [formMeta, setFormMeta] = useState<StudyFormMetaDetails>();
  const [formMetaLoading, setFormMetaLoading] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();
  const formId = new URLSearchParams(location.search).get('formId') as ID;

  const { benchling_folders: benchlingFoldersEnabled } = useSelector(
    ({
      team: {
        features: { benchling_folders },
      },
    }: State) => ({
      benchling_folders,
    })
  );

  const fetchStudyForm = async (formId: ID) => {
    setFormMetaLoading(true);
    try {
      const {
        data: { data: formTemplate },
      } = await Http.get(
        apiRoute('team.studies.forms.get', {
          formId,
        })
      );
      setFormMeta(formTemplate);
    } catch (e) {
      dispatch({ type: 'updateForm', data: { errors: e } });
    } finally {
      setFormMetaLoading(false);
    }
  };

  const fetchFormRequirements = async () => {
    try {
      const metadataResponse = await Http.get(apiRoute('meta-glossary.show'), {
        params: {
          filter_type: 'study_meta',
        },
      });
      const taskTemplateResponse = await Http.get(apiRoute('tasks.templateGroups.show'));
      const presetResponse = await Http.get(apiRoute('study.presets'));
      dispatch({
        type: 'SET_SELECTABLE_METADATA',
        data: metadataResponse.data.data,
      });
      dispatch({
        type: 'updateForm',
        data: {
          templates: taskTemplateResponse.data.data,
          presets: presetResponse.data.data,
        },
      });
    } catch (errors) {
      dispatch({ type: 'updateForm', data: { errors } });
      dispatch({ type: 'stepReady' });
    } finally {
      dispatch({ type: 'updateForm', data: { loading: false } });
    }
  };

  const handleSubmit = async (innerState) => {
    dispatch({ type: 'updateForm', data: { submitting: true } });
    const payload = assemblePayload(innerState.study, formMeta, formId);
    if (innerState.form.draftId) {
      payload.draft_id = innerState.form.draftId;
    }

    try {
      const {
        data: { data },
      } = await Http.post(apiRoute('studies.create'), payload);

      const v2Tasks: Array<TaskSpecCreate> = Object.values(innerState.study.tasks ?? {});

      if (_isNotEmpty(v2Tasks)) {
        const groupTempIdMap = new Map<string, GroupApiId>(
          (data.studyGroups?.data ?? []).reduce((acc, group) => {
            if (_notNil(group.temp_id)) {
              acc.push([group.temp_id, group.api_id]);
            }
            return acc;
          }, [])
        );

        try {
          await ApiService.call({
            endpoint: 'POST /api/v1/studies/{studyId}/task-specs',
            path: { studyId: data.api_id },
            body: v2Tasks.map((task) => {
              if (task?.target?.type === 'group') {
                const groups = task.target.groups.reduce<Array<GroupApiId>>((acc, tempId) => {
                  const groupId = groupTempIdMap.get(tempId);
                  if (_notNil(groupId)) {
                    acc.push(groupId);
                  }
                  return acc;
                }, []);
                task.target = { ...task.target, groups };
              }
              return task;
            }),
            options: { onError: { toast: false } },
          });
        } catch (err) {
          errorToast('Could not create tasks');
          ExceptionHandler.captureException(
            new InVivoError('Could not create tasks', {
              cause: err,
              slug: 'create-task-spec',
            })
          );
        }
      }
      dispatch({
        type: 'updateForm',
        data: { result: data, submitting: false, submitted: true },
      });
      history.push(webRoute('studies.show', { id: data.id }));
      successToast('Study created successfully');
    } catch (errors) {
      ExceptionHandler.captureException(
        new InVivoError('Could not create study', {
          cause: errors,
          slug: 'study-create',
        })
      );
      dispatch({
        type: 'updateForm',
        data: { errors, submitting: false },
      });
    }
  };

  const handleSaveDraft = useCallback(async () => {
    const {
      study,
      form: { draftId, step, savingDraftOnNext },
    } = state;
    try {
      const payload = {
        name: study.name,
        form_data: assemblePayload(study, formMeta, formId),
        config: {
          last_section_id: step,
          form_id: formId,
        },
      };
      if (draftId) {
        await ApiService.call({
          endpoint: 'PATCH /api/v1/drafts/studies/{draftId}',
          body: payload,
          path: { draftId },
        });
        if (savingDraftOnNext) {
          dispatch({
            type: 'updateForm',
            data: { savingDraft: false, savingDraftOnNext: false, step: step + 1 },
          });
        } else {
          dispatch({
            type: 'updateForm',
            data: { savingDraft: false },
          });
          successToast('Study draft saved successfully');
        }
      } else {
        const response = await ApiService.call({
          endpoint: 'POST /api/v1/drafts/studies',
          body: payload,
        });

        if (response.type === 'success') {
          const draftId = response.body.id;

          if (savingDraftOnNext) {
            dispatch({
              type: 'setDraftId',
              data: {
                draftId: draftId,
                savingDraft: false,
                savingDraftOnNext: false,
                step: step + 1,
              },
            });
          } else {
            dispatch({
              type: 'updateForm',
              data: { savingDraft: false },
            });
            successToast('Study draft saved successfully');
          }
        }
      }
    } catch (errors) {
      dispatch({
        type: 'updateForm',
        data: { errors, savingDraft: false },
      });
      ExceptionHandler.captureException(
        new InVivoError('Could not save draft', {
          cause: errors,
          slug: 'save-draft',
        })
      );
    }
  }, [state]);

  const defaultProps = { state, dispatch };

  const confirmNavigation = (e: BeforeUnloadEvent) => {
    const message = 'Are you sure you want to leave? Data you have entered may not be saved.';

    if (!state.form.submitted) {
      (e || window.event).returnValue = message;
      return message;
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchFormRequirements();
  }, []);

  useEffect(() => {
    if (_notNil(draft?.config?.form_id) || _notNil(formId)) {
      fetchStudyForm(draft?.config?.form_id ?? formId);
    }
  }, [formId, draft]);

  useEffect(() => {
    window.addEventListener('beforeunload', confirmNavigation);
    return () => window.removeEventListener('beforeunload', confirmNavigation);
  }, [state.form.submitted]);

  useEffect(() => {
    if (state.form.savingDraft) {
      handleSaveDraft();
    }
  }, [state.form.savingDraft, handleSaveDraft]);

  interface CustomFormRenderField {
    name: string;
    type: string;
    label: string;
    required: boolean;
    metadata: {
      id: ID;
    };
    options?: Array<{
      label: string;
      value: string;
    }>;
  }

  const buildStudyFormMetadataField = (metadataItem: StudyFormMetadataItem) => {
    const field: CustomFormRenderField = {
      name: `metadata[${metadataItem.info.id}]`,
      type: metadataItem.info.field_type,
      label: metadataItem.info.title,
      required: Boolean(metadataItem.required),
      metadata: {
        id: metadataItem.info.id,
      },
      slug: metadataItem.info.slug,
    };
    if (metadataItem.info.options) {
      field.options = metadataItem.info.options.map((option) => ({
        label: safelyDecodeURIComponent(option),
        value: option,
      }));
    }
    return field;
  };

  const mapFormSectionToFormRenderFields = (section: StudyFormSection) =>
    Object.values(section.metadata).reduce((acc, metadataItem) => {
      if (benchlingFoldersEnabled) {
        if (metadataItem.info.slug !== BENCHLING_INTEGRATION_META_SLUG) {
          acc.push(buildStudyFormMetadataField(metadataItem));
        }
      } else {
        acc.push(buildStudyFormMetadataField(metadataItem));
      }
      return acc;
    }, []);

  const buildCustomSectionDefaultFormData = (section: StudyFormSection, state: CreateStudyState) =>
    Object.values(section.metadata).reduce<Record<string, string>>((acc, metadataItem) => {
      acc[metadataItem.info.id] =
        state?.study?.metadata?.find((stateMetadataItem) => stateMetadataItem.glossary_id === metadataItem.info.id)
          ?.value ?? '';
      return acc;
    }, {});

  const buildSteps: () => Array<StudyStep> = () => {
    if (formMeta) {
      const steps = [...requiredSteps];
      formMeta.sections.forEach((section) => {
        if (section.active) {
          if (section.type === 'standard') {
            const step = STANDARD_STEPS.find(
              (standardStep) => standardStep.default_section_number === section.default_section_number
            );
            if (_notNil(step)) {
              steps.push(step);
            }
          } else if (section.type === 'custom') {
            const metaFields = section.metadata?.reduce((acc, metaField) => {
              if (benchlingFoldersEnabled) {
                if (metaField?.info?.slug !== BENCHLING_INTEGRATION_META_SLUG) {
                  acc.push(metaField);
                }
              } else {
                acc.push(metaField);
              }
              return acc;
            }, []);
            if (_isNotEmpty(metaFields)) {
              steps.push({
                name: section.name,
                Component: ({ state, dispatch }) => {
                  const formMethods = useForm({
                    defaultValues: {
                      metadata: buildCustomSectionDefaultFormData(section, state),
                    },
                  });
                  return (
                    <FormProvider {...formMethods}>
                      <PanelLayout title={section.name}>
                        <FormRender fields={mapFormSectionToFormRenderFields(section)} dispatch={dispatch} />
                      </PanelLayout>
                      <FormActions state={state} dispatch={dispatch} isUseForm submitAction="updateMetadata" />
                    </FormProvider>
                  );
                },
              });
            }
            const benchlingFolderMetaGlossary = section.metadata.find(
              (meta) => meta.info.slug === BENCHLING_INTEGRATION_META_SLUG
            );
            if (benchlingFoldersEnabled && _notNil(benchlingFolderMetaGlossary)) {
              steps.push(buildBenchlingIntegrationStep(benchlingFolderMetaGlossary));
            }
          }
        }
      });
      steps.push(buildSummaryStep({ handleSubmit, formMeta }));
      return steps;
    }
    return [];
  };

  const steps = useMemo(() => buildSteps(), [formMeta]);

  return (
    <div className="h-100">
      <SubHeader linkToText="Studies" link={webRoute('dashboard')} />
      {state.form.loading || formMetaLoading ? (
        <div className="w-100 h-100">
          <Loading txt="Fetching team settings..." />
        </div>
      ) : (
        <div className="h-100">
          {!state.form.submitting ? (
            <>
              {_isNotEmpty(state.form.errors) && (
                <ApiErrorBanner
                  title="There was a problem with your submission"
                  text="We've logged this error. If this problem keeps occurring, please contact support."
                  error={state.form.errors}
                  className="ma4"
                />
              )}
              <div className="ph4 pv3">
                <Header mainHeaderText="Create a new study" />
              </div>
              <div className="ph3 ph4-l mb4">
                <RepurposedStepForm
                  state={state}
                  formMeta={formMeta}
                  formId={formId}
                  steps={steps}
                  panelProps={defaultProps}
                />
              </div>
            </>
          ) : (
            <div className="w-100 h-100">
              <Loading txt="Creating a new study..." />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const RepurposedStepForm = ({ state, formMeta, formId, steps, panelProps }) => {
  const {
    form: { step },
  } = state;
  return (
    <Tabs state={state.form.step}>
      <div className="flex flex-wrap justify-between">
        <div className="w-20">
          {steps.map((s, index) => {
            return (
              <Tab plain key={index} className={`mb3 ${index === step ? 'near-black' : 'dark-gray'}`} disableNavigation>
                {s.name}
              </Tab>
            );
          })}
        </div>
        <div className="w-80 mb5">
          {steps.map((step, i) => (
            <Panel key={i}>
              <step.Component {...panelProps} totalSteps={steps.length} formMeta={formMeta} formId={formId} />
            </Panel>
          ))}
        </div>
      </div>
    </Tabs>
  );
};

export default withDraft(Show);
