// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import SpreadSheet from '@/components/UI/SpreadSheet';
import type { CustomValidation } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { buildCustomValidators, spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import SubHeader from '@/components/UI/SubHeader';
import { clearSessionStorage, successToast } from '@/helpers';
import { _get, _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal, AnimalSpecies } from '@/model/Animal.model';
import type { MetadataField } from '@/model/Metadata.model';
import { createMeta } from '@/support/hot';
import Http from '@/support/http';
import { api as apiRoutes, web as webRoute } from '@/support/route';
import type HotTable from '@handsontable/react';
import type { CellChange, ChangeSource } from 'handsontable/common';
import type { GridSettings } from 'handsontable/settings';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getMetaColumns,
  getMetaData,
  getSpeciesAndStrains,
  initAnimalSettings,
  initialBulkAnimalData,
  updateStrainSelectDropdown,
} from '../Colony.utils';
import '../Colony.scss';

const BulkAnimalEdit = () => {
  const history = useHistory();
  const ref = useRef<HotTable>();
  const [data, setData] = useState([]);
  const [settings, setSettings] = useState<GridSettings>([]);
  const [apiError, setApiError] = useState<Error | false>(false);
  const [metadata, setMetadata] = useState<Array<MetadataField>>([]);
  const [loading, setLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState<boolean>();
  const [metaColumns, setMetaColumns] = useState(false);
  const [species, setSpecies] = useState<AnimalSpecies | undefined>();
  const selectedAnimals: Array<Animal> = JSON.parse(sessionStorage.getItem('selectedColonyAnimals') ?? '[]');
  const { id: studyId } = useParams<{ id: string }>();

  const customValidators = useMemo<Record<string, CustomValidation>>(() => {
    return buildCustomValidators(settings?.columns ?? []);
  }, [settings]);

  useEffect(() => {
    return clearSessionStorage(history, 'selectedColonyAnimals', '/colony/edit/animals');
  }, [history]);

  useEffect(() => {
    if (metaColumns && metadata && species && _isEmpty(settings)) {
      const removeRow = false;
      const newSettings = initAnimalSettings(species, 'addAnimal', removeRow, metaColumns, metadata);
      if (newSettings.settings) {
        setSettings(newSettings.settings);
      }
      if (newSettings.metadata) {
        setMetadata(newSettings.metadata);
      }
      setData(initialBulkAnimalData(selectedAnimals, species));
    }
  }, [species, metaColumns]);

  useEffect(() => {
    if (!_isEmpty(metadata)) {
      setMetaColumns(getMetaColumns(selectedAnimals, metadata));
    } else {
      setMetaColumns([]);
    }
  }, [metadata]);

  const patchMetadata = () => {
    if (ref.current) {
      const hot = ref.current.hotInstance;
      if (_notNil(hot)) {
        const col = hot.propToCol('species');
        const colData = hot.getDataAtCol(col);

        colData.forEach((value, row) => {
          updateStrainSelectDropdown(hot, species, row, value);
        });
        hot.render();
      }
    }
  };

  const backRoute = useMemo(
    () => webRoute(_notNil(studyId) ? 'studies.animals' : 'colony', { id: studyId }),
    [studyId]
  );

  useEffect(() => {
    getMetaData('subject_meta')
      .then(({ data }) => {
        const subjectMeta = data.data ?? [];
        setMetadata(subjectMeta);
      })
      .catch((error) => setApiError(error));

    getSpeciesAndStrains()
      .then(({ data }) => setSpecies(data.data))
      .catch((error) => setApiError(error));
  }, []);

  const afterChange = (changes: CellChange[] | null, source: ChangeSource | 'insert_row'): void => {
    const hot = ref?.current?.hotInstance;

    if (changes) {
      patchMetadata?.();
    }

    changes?.forEach((change) => {
      hot?.validateColumns(
        customValidators?.[hot.colToProp(change[1])]?.linkedColumns?.map((linkedColumn) =>
          hot.propToCol(linkedColumn)
        ) ?? []
      );
    });
  };

  const mapAnimalPatchData = () =>
    data.map(({ id, name, sex, dob, species: animalSpecies, strain, meta }) => {
      const animal = selectedAnimals.find((a) => a.id === id);
      const returnData = {
        id,
        cage_id: animal?.cage_id ?? null,
        name,
        dob,
      };
      if (sex) {
        returnData.sex = sex === 'Male' ? 'm' : 'f';
      }
      if (animalSpecies) {
        const strains = _get(species.find((s) => s.name === animalSpecies).strains, 'data');
        if (strains.length) {
          returnData.strain_id = strains.find((s) => s.name === strain).id;
        }
      }
      if (_isNotEmpty(meta)) {
        returnData.meta = createMeta(meta, settings.columns);
      }
      return returnData;
    });

  const saveAnimals = async (reasonForChange?: string) => {
    setLoading(true);
    const animals = mapAnimalPatchData();
    try {
      await Http.patch(apiRoutes('animals.updateMany'), {
        animals,
        ...(_notNil(reasonForChange) ? { reason_for_change: reasonForChange } : {}),
      });
      history.push(backRoute);
      successToast(`Successfully added ${animals.length > 1 ? 'animals' : 'animal'}`);
    } catch (error) {
      setApiError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const saveAction = async () => await saveAnimals();

  const spreadSheet = (
    <>
      <div className="ow-spreadsheet-styles">
        <SpreadSheet
          data={data}
          settings={settings}
          setSettings={setSettings}
          height="500"
          className="pl4 pr4"
          innerRef={ref}
          metadata={metadata}
          setMetadata={setMetadata}
          afterChange={afterChange}
          setInvalid={setIsInvalid}
          patchMetadataAfterSettingsUpdated={patchMetadata}
          maxRows={data.length}
          testPrefix="bulk-animals"
        />
      </div>
      <div className="pa4">
        <Button
          onClick={saveAction}
          disabled={loading || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
          testId="bulk-animals__submit"
        >
          Save
        </Button>
        <Button plain className="ml3" onClick={() => history.push(backRoute)}>
          Cancel
        </Button>
      </div>
    </>
  );

  return (
    <div className="h-100">
      {apiError && (
        <ApiErrorBanner
          className="mb4"
          title={'There was an error with your request'}
          text={
            'An error has occurred when submitting your request, please try again later. If this keeps occurring please contact support.'
          }
          error={apiError}
        />
      )}
      {loading || _isEmpty(settings) ? (
        <div style={{ height: 650 }}>
          <Loading txt={`${loading ? 'Updating animal...' : 'Loading...'}`} />
        </div>
      ) : (
        <>
          <SubHeader
            linkToText={_notNil(studyId) ? 'Animals' : 'Colony'}
            link={backRoute}
            testId="bulk-animals__return"
          />
          <div className="ph4">
            <Header mainHeaderText="Edit Animals" />
          </div>
          {_isNotEmpty(settings) && _isNotEmpty(data) ? (
            spreadSheet
          ) : (
            <div className="ma4">
              <NoDataCard
                title="You have not selected any animals"
                text={'Please select animals from inside Colony to proceed'}
                NoDataComponent={
                  <Link to={backRoute} className="link blue">
                    {`Go to ${_notNil(studyId) ? 'Animals' : 'Colony'}`}
                  </Link>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BulkAnimalEdit;
