// @ts-nocheck: converted from JS

import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { _get, _isEmpty, _notNil, _size } from '@/littledash';
import { AlertState, ShowStateReducer } from './Workflow.model';

export const assembleWorkflowFilterOptions = ({ study, collections }) => {
  const { study_groups: studyGroups } = study;
  const calculations = study.settings.calculations;

  const studyGroupIds = studyGroups.map((group) => ({
    name: group.name,
    value: group.id,
  }));
  const cageIds = collections.map((cage) => ({
    name: cage.name,
    value: cage.id,
  }));
  const variableFilters = calculations.map((c) => ({
    name: c.name,
    value: c.id,
    type: 'latestMeasurements',
    operations: [
      {
        name: 'greater than',
        value: filterType.gt,
      },
      {
        name: 'less than',
        value: filterType.lt,
      },
      {
        name: filterType.IS_NULL,
        value: filterType.IS_NULL,
      },
      {
        name: filterType.IS_NOT_NULL,
        value: filterType.IS_NOT_NULL,
      },
    ],
  }));

  let filters = {};
  if (_size(cageIds)) {
    filters = [
      {
        value: 'cage',
        name: 'Cage Name',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: cageIds,
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: cageIds,
          },
        ],
      },
      {
        value: 'deceased',
        name: 'Living status',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: [{ value: 'null', name: 'Alive' }],
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: [{ value: 'null', name: 'Alive' }],
          },
        ],
      },
      {
        value: 'sex',
        name: 'Sex',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: [
              { value: 'm', name: 'Male' },
              { value: 'f', name: 'Female' },
            ],
          },
        ],
      },
      {
        value: 'alerts',
        name: 'Alert',
        operations: [
          {
            name: 'is',
            value: filterType.eq,
            options: [
              { value: 'alert_unresolved', name: 'Unresolved' },
              { value: 'alert_resolved', name: 'Resolved' },
            ],
          },
        ],
      },
      ...variableFilters,
    ];
    if (studyGroups.length) {
      filters.push({
        value: 'study_group',
        name: 'Study Group',
        operations: [
          {
            name: 'is equal to',
            value: filterType.eq,
            options: studyGroupIds,
          },
          {
            name: 'is not equal to',
            value: filterType.ne,
            options: studyGroupIds,
          },
        ],
      });
    }
  }
  return filters;
};

export const getNextAnimal = (subjectId, subjects, direction = 'desc') => {
  let nextAnimalIndex;

  const currentIndex = subjects.findIndex((subject) => subject.id === subjectId);

  if (direction === 'desc') {
    nextAnimalIndex = currentIndex + 1 === subjects.length ? 0 : currentIndex + 1;
  } else {
    nextAnimalIndex = currentIndex === 0 ? subjects.length - 1 : currentIndex - 1;
  }

  return subjects[nextAnimalIndex];
};

export const reducer: ShowStateReducer = (state, action) => {
  switch (action.type) {
    case 'setSubjects': {
      return { ...state, originalSubjects: action.data, subjects: action.data };
    }
    case 'setCollections': {
      return { ...state, collections: action.data };
    }
    case 'setFilterOptions': {
      return { ...state, filterOptions: action.data };
    }
    case 'searchQuery': {
      const { originalSubjects, idToSearch } = state;

      if (_isEmpty(action.data.searchQuery)) {
        return {
          ...state,
          searchQuery: '',
          subjects: originalSubjects,
          selectedCage: '',
          selectedAnimal: '',
        };
      }
      const filteredSubjects = originalSubjects.filter((subject) =>
        _get(subject, idToSearch)?.toLowerCase().includes(action.data.searchQuery.toLowerCase())
      );

      const update = {
        ...state,
        searchQuery: action.data.searchQuery,
        subjects: filteredSubjects,
        selectedCage: '',
        selectedAnimal: '',
      };

      if (filteredSubjects.length === 1) {
        const onlySubject = filteredSubjects[0];
        update.selectedCage = onlySubject.cage_id;
        update.selectedAnimal = onlySubject.id;
      }
      return update;
    }
    case 'selectAnimal':
      return {
        ...state,
        selectedAnimal: action.data,
      };
    case 'selectCage':
      return {
        ...state,
        selectedCage: action.data.selectedCage,
        selectedAnimal: '',
      };
    case 'updateChecklist':
      return {
        ...state,
        checklist: action.data,
      };
    case 'updateAlerts': {
      const animalAlerts = action.data.reduce<AlertState['animalAlerts']>((acc, alert) => {
        if (_notNil(alert?.id) && _notNil(alert?.subject_id)) {
          const alertResolved = _notNil(alert?.resolved_at);
          const alertData = acc?.[alert.subject_id]?.[alert.id];
          if (alertData !== alertResolved) {
            return {
              ...acc,
              [alert.subject_id]: { ...(acc?.[alert.subject_id] ?? {}), [alert.id]: alertResolved },
            };
          }
        }
        return acc;
      }, state.alerts.animalAlerts);

      if (state.alerts.animalAlerts !== animalAlerts) {
        const total = Object.values(animalAlerts).reduce(
          (acc, alertsMap) => acc + Object.values(alertsMap).filter((resolved) => !resolved).length,
          0
        );
        return { ...state, alerts: { animalAlerts, total } };
      }
      return state;
    }
    case 'resetAlerts': {
      return {
        ...state,
        alerts: action.data,
      };
    }
    case 'update':
      return {
        ...state,
        ...action.data,
      };
    case 'updateSubject': {
      const subjectIndex = state.subjects.findIndex(({ id }) => id === action.data.id);
      const originalSubjectIndex = state.originalSubjects.findIndex(({ id }) => id === action.data.id);
      if (subjectIndex >= 0) {
        state.subjects[subjectIndex] = action.data;
      }
      if (originalSubjectIndex >= 0) {
        state.originalSubjects[originalSubjectIndex] = action.data;
      }
      return { ...state, subjects: [...state.subjects] };
    }
    default:
      return state;
  }
};

const getFilterOperation = (name) => Object.keys(filterType).find((key) => filterType[key] === name);

export const generateFilterParams = (filters: Array<unknown>, filterMatch: unknown): string =>
  filters.reduce(
    (acc, filter) => {
      if (!filter.category.value.includes('metadata.') && filter.option.value !== '') {
        acc = `${acc}&filters[]=${filter.category.value}|${getFilterOperation(filter.operation.name)}|${
          filter.option.value
        }`;
      }
      return acc;
    },
    `filterType=${filterMatch ? 'and' : 'or'}`
  );
