import { calculateDaysDifference } from '@/components/Modals/AddTask/AddTask.utils';
import { _isEmpty, _isEmptyString, _isNil } from '@/littledash';
import type { RepeatUnit, RepeatValue } from 'model/Task.model';
import { isDateGreaterThan, isNegativeInteger } from '@/utils/forms/validators';

export const repeatPeriodOptions: Record<RepeatUnit, { label: string; value: RepeatUnit }> = {
  days: { label: 'Day(s)', value: 'days' },
  weeks: { label: 'Week(s)', value: 'weeks' },
};

export const daysOptions = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
];

export const startDateValidator = (startDate: string, endDate: string): boolean | string => {
  if (isDateGreaterThan(startDate, endDate)) {
    return 'Start date must be before end date';
  }
  if (_isEmptyString(startDate)) {
    return 'A start date is required';
  }
  if (_isEmptyString(endDate)) {
    return 'An end date is required';
  }
  if (calculateDaysDifference(startDate, endDate) > 365) {
    return 'Start date must be within 1 year of end date';
  }
  return true;
};

export const repeatValidator = (value: Omit<RepeatValue, 'on_day'>): boolean | string => {
  const repeatOptionKeys = Object.keys(repeatPeriodOptions);
  if (_isNil(value?.value)) {
    return 'A repeat value is required';
  }
  if (_isNil(value?.unit)) {
    return 'A repeat unit is required';
  }
  if (isNegativeInteger(value.value)) {
    return 'Value must be a positive number';
  }
  if (!repeatOptionKeys.includes(value.unit)) {
    return `Unit must be one of ${repeatOptionKeys.join(',')}`;
  }
  return true;
};

export const recurringDayValidator = (value: string[]): boolean | string => {
  if (_isEmpty(value) || _isNil(value)) {
    return 'At least one day is required';
  }
  return true;
};

export const conditionalValueValidator = (value: string[]): boolean | string => {
  if (_isEmpty(value) || _isNil(value)) {
    return 'At least one value is required';
  }
  return true;
};

const DAY_MAX = 3650;
const DAY_MIN = -365;

export const conditionalAnimalAgeValidator = (ageValues: Array<string>) => {
  if (ageValues.some((age) => Number.isNaN(parseInt(age)) || parseInt(age) < 0 || parseInt(age) > DAY_MAX)) {
    return `Age must be a number between 0 and ${DAY_MAX} days`;
  }
  return true;
};

export const conditionalStudyDayValidator = (days: Array<string>) => {
  if (
    days.some((day) => Number.isNaN(parseInt(day)) || parseInt(day) < DAY_MIN || day === '0' || parseInt(day) > DAY_MAX)
  ) {
    return `Study day must be a number between ${DAY_MIN} and ${DAY_MAX}, and can't be 0`;
  }
  return true;
};

export const conditionalTrackingDayValidator = (days: Array<string>) => {
  if (days.some((day) => Number.isNaN(parseInt(day)) || parseInt(day) < DAY_MIN || parseInt(day) > DAY_MAX)) {
    return `Tracking day must be a number between ${DAY_MIN} and ${DAY_MAX}`;
  }
  return true;
};
