import Button from '@/components/UI/Button';
import { FormProvider, useForm, useFormContext } from 'react-hook-form@latest';
import { useDispatch, useSelector } from 'react-redux';
import { api as apiRoute } from '@/support/route';
import { errorToast, successToast } from '@/helpers';
import type { State } from '@/model/State.model';
import { useRequest } from '@/support/Hooks/request';
import { FC, useMemo } from 'react';
import { TimeZoneUser } from '@/model/User.model';
import { ErrorMessage } from '@hookform/error-message';

export interface FormValues {
  name: string;
  timezone: string;
}
interface UpdateAccountFormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  isDisabled: boolean;
  timezones: State['user']['timezones'];
}
interface PutResponse {
  data: TimeZoneUser;
}

const UpdateAccount = (): JSX.Element => {
  const dispatch = useDispatch();
  const { currentUser, timezones } = useSelector(({ user: { currentUser, timezones } }: State) => ({
    currentUser,
    timezones,
  }));

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      timezone: currentUser?.timezone || 'UTC',
    }),
    [currentUser]
  );

  const formMethods = useForm({
    defaultValues,
  });

  const { sendRequest: updateUser, requestSending: userUpdating } = useRequest<FormValues, PutResponse>({
    route: apiRoute('user.update'),
    method: 'put',
  });

  const onSubmit = async (data: FormValues): Promise<void> => {
    try {
      const {
        data: { data: user },
      } = await updateUser(data);
      dispatch({
        type: 'USER_SET_CURRENT_USER',
        currentUser: user,
      });
      formMethods.reset({ name: user.name, timezone: user.timezone });
      successToast('Successfully updated account');
    } catch (error) {
      errorToast('Problem communicating with server.');
    }
  };

  return (
    <div className="pa4">
      <FormProvider {...formMethods}>
        <UpdateAccountForm onSubmit={onSubmit} isDisabled={userUpdating} timezones={timezones} />
      </FormProvider>
    </div>
  );
};

export const UpdateAccountForm: FC<UpdateAccountFormProps> = ({ onSubmit, isDisabled, timezones }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<FormValues>();
  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="update-account-form">
      <div className="mb3">
        <label htmlFor="name">Full name (required)</label>
        <input
          type="text"
          data-test-component="SetProfile"
          data-test-element="name-input"
          data-testid="name-input"
          {...register('name', {
            required: 'This field is required',
          })}
          style={{ marginBottom: 0 }}
          placeholder="Marie Curie"
          className={`${errors.name ? 'input__error' : ''}`}
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
            <p data-testid="name-error-message" className="f6 red db pt2">
              {message}
            </p>
          )}
        />
      </div>
      <div className="mb3">
        <label>Your timezone</label>
        <select
          style={{ marginBottom: 0 }}
          data-test-component="SetProfile"
          data-test-element="timezone-select"
          data-testid="timezone-select"
          {...register('timezone', {
            required: 'Required',
          })}
        >
          {timezones.map((timezone) => (
            <option key={timezone} value={timezone} data-testid="timezone-option">
              {timezone}
            </option>
          ))}
        </select>
      </div>
      <div className="pt3 mt3">
        <Button submit disabled={isDisabled} testId="update-account-submit-button">
          Save
        </Button>
      </div>
    </form>
  );
};

export default UpdateAccount;
