import logo from '@/assets/images/logo.svg';
import Button from '@/components/UI/Button';
import { ReleaseInfo as ReleaseInfoData } from '@/generated/ReleaseInfo';
import { errorToast, successToast } from '@/helpers.tsx';
import { _notNil, getJwtPayload, getSessionCorrelationId } from '@/littledash.ts';
import { DateUtils } from '@/utils/Date.utils.ts';
import { ReleaseChecker, ReleaseInfo } from '@/utils/ReleaseChecker.ts';
import { formatDistanceToNowStrict } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RiAlertFill, RiCheckboxCircleFill } from 'react-icons/ri';
import { Flip, ToastContainer } from 'react-toastify';
import styles from './AppDebug.module.scss';

type DebugData = Array<{ slug: string; label: string; value: string }>;

export const AppDebug: FC = () => {
  const [currentRelease, updateCurrentRelease] = useState<ReleaseInfo | null>();

  useEffect(() => {
    const abortController = new AbortController();
    if (window.navigator.onLine) {
      ReleaseChecker.fetchLatestReleaseInfo()
        .then((latestRelease) => updateCurrentRelease(latestRelease))
        .catch(() => {
          errorToast('Could not load latest release');
        });
    }
    ReleaseChecker.subscribe((event) => updateCurrentRelease(event.detail), abortController.signal);
    return () => abortController.abort();
  }, [updateCurrentRelease]);

  const debugData = useMemo<DebugData>(() => {
    const result: DebugData = [];
    const pendingUpdate = _notNil(currentRelease) && ReleaseInfoData.commitId !== currentRelease.commitId;
    result.push({ slug: 'client-up-to-date', label: 'Client - Up To Date', value: pendingUpdate ? 'FALSE' : 'TRUE' });
    if (pendingUpdate) {
      result.push(
        { slug: 'update-release-version', label: 'Update - Release Version', value: currentRelease.releaseVersion },
        { slug: 'update-release-id', label: 'Update - Release ID', value: currentRelease.commitId },
        {
          slug: 'update-release-time',
          label: 'Update - Release Time',
          value: DateUtils.renderDateTime(currentRelease.commitTime),
        },
        {
          slug: 'update-release-age',
          label: 'Update - Release Age',
          value: formatDistanceToNowStrict(currentRelease.commitTime),
        }
      );
    }
    result.push(
      { slug: 'client-release-version', label: 'Client - Release Version', value: ReleaseInfoData.releaseVersion },
      { slug: 'client-release-id', label: 'Client - Release ID', value: ReleaseInfoData.commitId },
      {
        slug: 'client-release-time',
        label: 'Client - Release Time',
        value: DateUtils.renderDateTime(ReleaseInfoData.commitTime),
      },
      {
        slug: 'client-release-age',
        label: 'Client - Release Age',
        value: formatDistanceToNowStrict(ReleaseInfoData.commitTime),
      }
    );
    const nextReleaseCheck = Number(localStorage.getItem('release-check.next') ?? -1);
    if (Number.isFinite(nextReleaseCheck) && nextReleaseCheck > 0) {
      result.push({
        slug: 'client-next-update-check',
        label: 'Client - Next Update Check',
        value: DateUtils.renderDateTime(new Date(nextReleaseCheck).toISOString()),
      });
    }
    result.push(
      { slug: 'browser-online', label: 'Browser - Online', value: navigator.onLine ? 'TRUE' : 'FALSE' },
      { slug: 'browser-platform', label: 'Browser - Platform', value: `${navigator.userAgentData?.platform ?? '-'}` },
      { slug: 'browser-user-agent', label: 'Browser - User Agent', value: navigator.userAgent },
      { slug: 'browser-language', label: 'Browser - Language', value: navigator.language },
      { slug: 'browser-timezone', label: 'Browser - Timezone', value: DateUtils.timezone() },
      {
        slug: 'browser-connection-type',
        label: 'Browser - Connection Type',
        value: `${navigator.connection?.type ?? '-'}`,
      },
      {
        slug: 'browser-connection-effective-type',
        label: 'Browser - Connection Effective Type',
        value: `${navigator.connection?.effectiveType ?? '-'}`,
      },
      {
        slug: 'browser-connection-rtt',
        label: 'Browser - Connection Round Trip Time',
        value: `${navigator.connection?.rtt ?? '-'}ms`,
      },
      { slug: 'browser-device-memory', label: 'Browser - Device Memory', value: `${navigator.deviceMemory ?? '-'}gb` },
      {
        slug: 'browser-hardware-concurrency',
        label: 'Browser - Hardware Concurrency',
        value: `${navigator.hardwareConcurrency ?? '-'}`,
      },
      {
        slug: 'browser-max-touch-point',
        label: 'Browser - Max Touch Points',
        value: `${navigator.maxTouchPoints ?? '-'}`,
      },
      {
        slug: 'browser-pdf-viewer-enabled',
        label: 'Browser - PDF Viewer Enabled',
        value: navigator.pdfViewerEnabled ? 'YES' : 'NO',
      },
      { slug: 'login-session-id', label: 'Login - Session ID', value: getSessionCorrelationId() }
    );
    const jwtPayload = getJwtPayload();
    if (_notNil(jwtPayload)) {
      const iat = new Date(jwtPayload.iat * 1000);
      const exp = new Date(jwtPayload.exp * 1000);
      result.push(
        { slug: 'login-valid', label: 'Login - Valid', value: exp > iat ? 'TRUE' : 'FALSE' },
        { slug: 'login-iat', label: 'Login - Issued At', value: DateUtils.renderDateTime(iat.toISOString()) },
        { slug: 'login-exp', label: 'Login - Expires At', value: DateUtils.renderDateTime(exp.toISOString()) },
        { slug: 'login-sub', label: 'Login - User ID', value: jwtPayload.sub }
      );
    }
    return result;
  }, [currentRelease]);

  const handleDetailCopy = useCallback(async () => {
    const text = debugData.map(({ label, value }) => `${label}\t${value}`).join('\n');
    await navigator.clipboard.writeText(text);
    successToast('Details copied to clipboard');
  }, [debugData]);

  return (
    <>
      <div className="w-100 h-100 flex flex-row justify-around">
        <div className="flex-column">
          <div className="flex flex-row justify-around pv2">
            <img className="dib" src={logo} alt="Benchling In Vivo" style={{ width: 150, height: 60 }} />
          </div>
          <div className="flex flex-row justify-around ui-card bg-moon-gray">
            <table className={styles.table}>
              <tbody>
                {debugData.map(({ slug, label, value }) => (
                  <tr key={slug} data-testid={slug}>
                    <th className="black fw5" data-test-element="label-cell">
                      {label}
                    </th>
                    <td className="near-black" data-test-element="value-cell">
                      <DebugValue value={value} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row justify-around">
            <Button pill onClick={handleDetailCopy}>
              Copy Details
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-left" transition={Flip} closeOnClick={true} icon={false} />
    </>
  );
};

const DebugValue: FC<{ value: string }> = ({ value }) => {
  switch (value) {
    case 'TRUE':
      return <RiCheckboxCircleFill className="green" size={25} />;
    case 'FALSE':
      return <RiAlertFill className="gold" size={25} />;
    default:
      return value;
  }
};
