import type { FC } from 'react';
import { AddTaskFormTypes, AddTaskScheduleData, type StepRenderProps } from '../../AddTask.model';
import Button from '@/components/UI/Button';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import Radio from '@/components/UI/FormElements/Radio';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { DateUtils } from '@/utils/Date.utils';
import RecurringFields from './AddTaskScheduleRecurringFields';
import styles from '../../AddTask.module.scss';
import ConditionalFields from './AddTaskScheduleConditionalFields';

interface AddTaskScheduleFormProps extends Omit<StepRenderProps, 'defaultValues'> {
  defaultValues?: AddTaskScheduleData;
}

const AddTaskScheduleForm: FC<AddTaskScheduleFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText,
  cancelButtonText,
  disabledFields = [],
}) => {
  const { control, watch, handleSubmit: useFormHandleSubmit } = useFormContext();
  const selectedType = watch(`${AddTaskFormTypes.schedule}.type`);

  return (
    <form onSubmit={useFormHandleSubmit(onSubmit)} className={styles['step-content']}>
      <div>
        <div className="ph3 pb3" data-test-component="OneOffScheduleForm" data-test-element="container">
          <Controller
            name={`${AddTaskFormTypes.schedule}.type`}
            control={control}
            defaultValue="one_off"
            render={({ onChange }: ControllerRenderProps) => (
              <Radio
                onChange={onChange}
                id="one_off"
                value="one_off"
                label="One off"
                checked={selectedType === 'one_off'}
                disabled={disabledFields.includes(`${AddTaskFormTypes.schedule}.type`)}
              />
            )}
          />
          {selectedType === 'one_off' && (
            <div className="pt3 ml4">
              <Controller
                name={`${AddTaskFormTypes.schedule}.start`}
                control={control}
                defaultValue={DateUtils.dateNow()}
                render={({ onChange, value }: ControllerRenderProps) => (
                  <DatePickerNative value={value} onChange={onChange} className={styles['add-task-input-field']} />
                )}
              />
            </div>
          )}
        </div>

        <div className="ph3 pb3" data-test-component="RecurringScheduleForm" data-test-element="container">
          <Controller
            name={`${AddTaskFormTypes.schedule}.type`}
            control={control}
            defaultValue="one_off"
            render={({ onChange }: ControllerRenderProps) => (
              <Radio
                onChange={onChange}
                id="recurring"
                value="recurring"
                label="Recurring"
                checked={selectedType === 'recurring'}
                sublabel="Performed in a recurring pattern (eg. Monday and Wednesday)."
                disabled={disabledFields.includes(`${AddTaskFormTypes.schedule}.type`)}
              />
            )}
          />
          {selectedType === 'recurring' && <RecurringFields disabledFields={disabledFields} />}
        </div>
        <div className="ph3 pb3" data-test-component="ConditionalScheduleForm" data-test-element="container">
          <Controller
            name={`${AddTaskFormTypes.schedule}.type`}
            control={control}
            defaultValue="one_off"
            render={({ onChange }: ControllerRenderProps) => (
              <Radio
                onChange={onChange}
                id="conditional"
                value="conditional"
                label="Conditional"
                checked={selectedType === 'conditional'}
                sublabel="Generated once a condition is met (eg. age is 35 days)."
                disabled={disabledFields.includes(`${AddTaskFormTypes.schedule}.type`)}
              />
            )}
          />
          {selectedType === 'conditional' && <ConditionalFields disabledFields={disabledFields} />}
        </div>
      </div>
      <div className="pa3 bt b--moon-gray mt3">
        <Button submit testKey="taskSpecStepFormSubmit">
          {submitButtonText}
        </Button>
        <Button plain className="ml2" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </form>
  );
};

export default AddTaskScheduleForm;
