import { MeasurementConfigForm } from '@/components/Glossary/Sections/Presets/Builder/MeasurementConfigForm.tsx';
import { OutputForm } from '@/components/Glossary/Sections/Presets/Builder/OutputForm.tsx';
import Button from '@/components/UI/Button';
import { _isNil } from '@/littledash.ts';
import { PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import cn from 'classnames';
import { FC, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext, useFormState, useWatch } from 'react-hook-form@latest';
import styles from './PresetBuilder.module.scss';
import { PresetHeader } from './PresetHeader';
import { RiCloseFill } from 'react-icons/ri';
import { ErrorMessage } from '@hookform/error-message';

interface MeasurementFormProps {
  index: number;
  handleClose: () => void;
}

export const MeasurementForm: FC<MeasurementFormProps> = ({ index, handleClose }) => {
  const formMethods = useFormContext<PresetCreateOrUpdateV1>();
  const {
    fields: measurementInputFields,
    append: appendMeasurementInputField,
    remove: removeMeasurementInputField,
  } = useFieldArray({
    control: formMethods.control,
    name: `measurements.${index}.inputs`,
  });
  const formState = useFormState({ control: formMethods.control, name: `measurements.${index}`, exact: true });
  const isValid = useMemo(() => _isNil(formState?.errors?.measurements?.[index]), [index, formState, formState.errors]);
  const measurementName = useWatch({ control: formMethods.control, name: `measurements.${index}.name` });

  const handleSaveClick = () => {
    formMethods.resetField(`measurements.${index}`, {
      keepDirty: true,
      keepTouched: true,
      keepError: true,
      defaultValue: { ...formMethods.getValues(`measurements.${index}`) },
    });
    handleClose();
    formMethods.trigger();
  };
  const handleCancelClick = () => {
    formMethods.resetField(`measurements.${index}`, { keepDirty: true, keepTouched: true, keepError: true });
    handleClose();
    formMethods.trigger();
  };
  return (
    <>
      <PresetHeader measurementName={measurementName ?? ''} />
      <div className="ui-card pa4" data-test-component="MeasurementForm" data-test-element="inputs-container">
        <div className="flex flex-row justify-start">
          <div className="pr2">
            <label>Measurement name</label>
            <Controller
              control={formMethods.control}
              name={`measurements.${index}.name`}
              render={({ field, fieldState }) => (
                <span className={styles.measurementName}>
                  <input
                    type="text"
                    {...field}
                    maxLength={32}
                    className={cn({ ui__error: fieldState.invalid })}
                    data-tooltip-id="global-tooltip-id"
                    data-tooltip-content={fieldState.error?.message}
                  />
                  <span className={styles.characterCount}>{field.value.length}/32</span>
                </span>
              )}
            />
          </div>
          <div className="w-10">
            <label>Unit</label>
            <Controller
              control={formMethods.control}
              name={`measurements.${index}.unit`}
              render={({ field, fieldState }) => (
                <input
                  type="text"
                  {...field}
                  value={field.value ?? ''}
                  maxLength={16}
                  className={cn(styles.unitInput, { ui__error: fieldState.invalid })}
                  data-tooltip-id="global-tooltip-id"
                  data-tooltip-content={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.measurementInputsContainer}>
          {measurementInputFields.map((measurementInputField, measurementInputFieldIndex) => (
            <div
              key={measurementInputField.id}
              className={`mb3 flex flex-row justify-start ${styles.inputContainer}`}
              data-test-element="input-container"
              data-test-key={measurementInputField.slug}
            >
              <div className="pr2">
                <label>Input name</label>
                <Controller
                  control={formMethods.control}
                  name={`measurements.${index}.inputs.${measurementInputFieldIndex}.name`}
                  render={({ field, fieldState }) => (
                    <span className={styles.measurementInputName}>
                      <input
                        type="text"
                        {...field}
                        maxLength={32}
                        className={cn({ ui__error: fieldState.invalid })}
                        data-tooltip-id="global-tooltip-id"
                        data-tooltip-content={fieldState.error?.message}
                      />
                      <span className={styles.characterCount}>{field.value?.length}/32</span>
                    </span>
                  )}
                />
              </div>
              <div className="pr2">
                <label>Unit</label>
                <div className="flex justify-between items-center">
                  <Controller
                    control={formMethods.control}
                    name={`measurements.${index}.inputs.${measurementInputFieldIndex}.unit`}
                    render={({ field, fieldState }) => (
                      <input
                        type="text"
                        {...field}
                        value={field.value ?? ''}
                        maxLength={16}
                        style={{ marginBottom: 0 }}
                        className={cn(styles.unitInput, { ui__error: fieldState.invalid })}
                        data-tooltip-id="global-tooltip-id"
                        data-tooltip-content={fieldState.error?.message}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={formState.errors}
                    name={`measurements.${index}.inputs.${measurementInputFieldIndex}.unit`}
                    render={({ message }) => <p className="f6 red db">{message}</p>}
                  />
                  <div
                    className="mid-gray hover-dark-gray f3 ml3 mt1"
                    data-test-element="remove-input"
                    onClick={() => removeMeasurementInputField(measurementInputFieldIndex)}
                  >
                    <RiCloseFill />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Button plain outline onClick={() => appendMeasurementInputField({ slug: '', name: '', unit: '' })}>
          Add Input
        </Button>
      </div>
      <div className="ui-card mt4" data-test-component="MeasurementForm" data-test-element="formula-container">
        <OutputForm index={index} />
      </div>
      <div className="ui-card pa4 mt4" data-test-component="MeasurementForm" data-test-element="config-container">
        <MeasurementConfigForm index={index} />
      </div>

      <div
        className="flex flex-row pt4 justify-between"
        data-test-component="MeasurementForm"
        data-test-element="actions-container"
      >
        <div className="pb5">
          <Button
            className="mr3"
            tooltip={!isValid ? 'Resolve errors to save' : undefined}
            disabled={!isValid}
            onClick={handleSaveClick}
          >
            Save measurement
          </Button>
          <Button plain onClick={handleCancelClick}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};
