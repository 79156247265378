import { useState, useRef, useCallback } from 'react';
import { _noop } from '@/littledash.ts';

interface UseAudioNotificationsOutputProps {
  playError: () => Promise<void>;
  playNotify: () => Promise<void>;
  isAudioPlaying: boolean;
  stopAudio: () => Promise<void>;
}

interface UseAudioNotificationsProps {
  enabled?: boolean;
}

const useAudioNotifications = ({ enabled }: UseAudioNotificationsProps): UseAudioNotificationsOutputProps => {
  const [isAudioPlaying, setAudioIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const playAudio = useCallback(async (src: string) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current = new Audio(src);
    audioRef.current.onended = () => setAudioIsPlaying(false);
    setAudioIsPlaying(true);
    await audioRef.current.play()?.catch(_noop);
  }, []);

  const playError = useCallback(async () => {
    return !isAudioPlaying && (enabled ?? true) ? await playAudio('/error.mp3') : Promise.resolve();
  }, [playAudio, isAudioPlaying, enabled]);

  const playNotify = useCallback(async () => {
    return !isAudioPlaying && (enabled ?? true) ? await playAudio('/notification.mp3') : Promise.resolve();
  }, [playAudio, isAudioPlaying, enabled]);

  const stopAudio = useCallback(async () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setAudioIsPlaying(false);
    }
  }, []);

  return {
    playError,
    playNotify,
    isAudioPlaying,
    stopAudio,
  };
};

export default useAudioNotifications;
