import ApiErrorBanner from '@/components/ApiErrorBanner';
import type {
  ExtendedAnimalSpecies,
  FormError,
} from '@/components/Glossary/Sections/SpeciesStrains/SpeciesComponent.model';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import { successToast } from '@/helpers';
import { _notNil } from '@/littledash';
import type { AnimalSpecies } from '@/model/Animal.model';
import type { State } from '@/model/State.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Repeater from './Repeater';
import { removeSpecies, updateSpecies, validateData } from './SpeciesStrains.utils';

const SpeciesStrains: React.FC = () => {
  const [data, setData] = useState<Array<ExtendedAnimalSpecies> | []>([]);
  const [initialData, setInitialData] = useState<Array<ExtendedAnimalSpecies> | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<Array<false | FormError>>([]);
  const [submissionError, setSubmissionError] = useState<false | Error>(false);

  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));
  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  const mapIndex = (species: AnimalSpecies, index: number): ExtendedAnimalSpecies => ({
    ...species,
    index,
    expanded: false,
  });

  const { collection, collectionLoading, fetchCollection } = useFetchCollection<AnimalSpecies>({
    collectionType: 'species',
    includes: 'strains',
  });

  useEffect(() => {
    if (_notNil(collection)) {
      const modifiedCollection = collection.map(mapIndex);
      setData(modifiedCollection);
      setInitialData(modifiedCollection);
      setLoading(collectionLoading);
    }
  }, [collection]);

  const validateField = async (d: ExtendedAnimalSpecies): Promise<false | FormError> => {
    const validateRow = await validateData(d);
    if (validateRow) {
      return {
        index: d.index,
        errors: {
          ...validateRow,
        },
      };
    }

    return false;
  };

  const validateFields = async () => {
    const results: Array<false | FormError> = [];
    for (let index = 0; index < data.length; index++) {
      results[index] = await validateField(data[index]);
    }

    return setFormErrors(results);
  };

  useEffect(() => {
    validateFields();
  }, [data]);

  const updateStrains = (updates: Array<AnimalSpecies>) => {
    Http.post(apiRoutes('species.update'), {
      data: updates,
    })
      .then(() => {
        setLoading(false);
        successToast('Successfully saved species and strains');
        fetchCollection();
      })
      .catch((error) => {
        setLoading(false);
        setSubmissionError(error);
      });
  };

  const handleSubmit = async () => {
    const validate = formErrors.every((o) => o === false);
    if (validate) {
      setLoading(true);
      const removedSpecies = removeSpecies(data, initialData);
      const updatedSpecies = updateSpecies(data);
      if (removedSpecies.length) {
        Http.delete(apiRoutes('species'), {
          data: { species_id: removedSpecies },
        })
          .then(() => {
            updateStrains(updatedSpecies);
          })
          .catch((error) => {
            setLoading(false);
            setSubmissionError(error);
          });
      } else {
        updateStrains(updatedSpecies);
      }
    }
  };
  if (loading) {
    return <Loading txt="Fetching Species and Strains..." />;
  }

  return (
    <div>
      <Header mainHeaderText="Species and Strains" />
      <div className="pb3 f6">
        {atunesIntegrationEnabled ? (
          <p className="f6 pb2">Species and Strains are managed through tick@lab</p>
        ) : (
          <p className="f6 pb2">Customise the species and strains your team can select from.</p>
        )}

        {submissionError && <ApiErrorBanner className="mb4" error={submissionError} />}
      </div>
      <div>
        <Repeater data={data} errors={formErrors} setData={setData} readOnly={atunesIntegrationEnabled} />
      </div>
      {!atunesIntegrationEnabled && (
        <div className="pv3 mt3 bt b--moon-gray">
          <Button
            tooltip={!formErrors.every((o) => o === false) ? 'Please fill in all required fields' : undefined}
            disabled={!formErrors.every((o) => o === false)}
            className="mr3"
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
          <Button plain onClick={() => fetchCollection()}>
            Undo changes
          </Button>
        </div>
      )}
    </div>
  );
};

// const AtunesEnabled;

export default SpeciesStrains;
