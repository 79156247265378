// @ts-nocheck: converted from JS

import { _isNotEmpty } from '@/littledash';
import { AnimalSpecies } from '@/model/Animal.model';
import validate from 'validate.js';
import { ExtendedAnimalSpecies } from './SpeciesStrains';

export const validateData = (data) => {
  const schema = {
    name: {
      presence: { allowEmpty: false, message: "Species can't be blank" },
    },
  };

  // This is to validate strains
  validate.validators.strainArray = function (value) {
    if (_isNotEmpty(value)) {
      const checkedValues = value.data.map((strain) => (!strain.name ? "Strain can't be blank" : false));
      return checkedValues.every((val) => !val) ? null : [checkedValues];
    } else {
      return 'Species must have strains';
    }
  };

  schema.strains = {
    strainArray: 'test',
  };

  return validate(data, schema, { fullMessages: false });
};

export const removeSpecies = (data: Array<ExtendedAnimalSpecies>, initialData: Array<ExtendedAnimalSpecies>) =>
  initialData.filter((init) => !data.some((update) => update.id === init.id)).map(({ id }) => id);

export const updateSpecies = (data: Array<ExtendedAnimalSpecies>): Array<AnimalSpecies> =>
  data.map(({ id, name, strains }) => {
    const species = {
      name,
      strains: strains.data.map((strain) => {
        const updatedStrain = {
          name: strain.name,
        };
        if (strain.id) {
          updatedStrain.id = strain.id;
        }
        return updatedStrain;
      }),
    };
    if (id) {
      species.id = id;
    }
    return species;
  });
