import useColors from '@/support/Hooks/useColors/useColors';
import { useReducer } from 'react';
import { type CalendarFilters, CalendarViews } from './Calendar.model';
import styles from './Calendar.module.scss';
import { getStartAndEndDateForWeekView, getStartAndEndForDayView, initialState, reducer } from './Calendar.utils';
import CalendarNav from './CalendarNav';
import CalendarSidebar from './CalendarSidebar';
import StudyFilter from './Filters/StudyFilter';
import UserFilter from './Filters/UserFilter';
import DayList from './Views/Day';
import MonthGrid from './Views/Month/MonthGrid';
import WeekLanes from './Views/Week';

const Calendar = (): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState());
  const {
    filters,
    chosenDay,
    yearAndMonth: [year, month],
    span,
  } = state;
  const { itemColors: studyColors } = useColors({ items: filters.study_api_id });

  const handleFilterChange = (filters: CalendarFilters): void => {
    dispatch({ type: 'SET_FILTERS', data: filters });
  };
  const handleSelectDay = (day: Date): void => {
    dispatch({ type: 'SET_CHOSEN_DAY', data: day });
  };
  const handleSelectYearAndMonth = ([newYear, newMonth]: Array<number>): void => {
    dispatch({ type: 'SET_YEAR_AND_MONTH', data: [newYear, newMonth] });
  };
  const handleSelectSpan = (span: CalendarViews): void => {
    dispatch({ type: 'SET_SPAN', data: span });
  };
  const handleGoToDay = (date: Date): void => {
    dispatch({ type: 'GO_TO_DAY', data: date });
  };

  return (
    <div className="flex h-100" data-test-component="Calendar" data-test-element="container">
      <CalendarSidebar>
        <>
          <StudyFilter filters={filters} onFilterChange={handleFilterChange} studyColors={studyColors} />
          <UserFilter filters={filters} onFilterChange={handleFilterChange} />
        </>
      </CalendarSidebar>
      <div className="flex flex-column w-100 h-100 overflow-hidden">
        <CalendarNav
          chosenDay={chosenDay}
          yearAndMonth={[year, month]}
          span={span}
          onSelectDay={handleSelectDay}
          onSelectYearAndMonth={handleSelectYearAndMonth}
          onSelectSpan={handleSelectSpan}
        />
        <div className={`${styles['calendar-view-container']} bg-white h-100`}>
          {span === CalendarViews.Month ? (
            <MonthGrid
              filters={filters}
              studyColours={studyColors}
              year={year}
              month={month}
              handleGoToDay={handleGoToDay}
            />
          ) : span === CalendarViews.Week ? (
            <WeekLanes
              dateRange={getStartAndEndDateForWeekView(chosenDay)}
              filters={filters}
              studyColours={studyColors}
              handleGoToDay={handleGoToDay}
            />
          ) : (
            <DayList dateRange={getStartAndEndForDayView(chosenDay)} filters={filters} studyColours={studyColors} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
