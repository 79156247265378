import { infoToast } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { ID } from '@/model/Common.model';
import { DeviceType, MappedDevice, PresetDeviceMapping, TargetField, TypeMapping } from '@/model/Device.model';
import InVivoError from '@/model/InVivoError.ts';
import type { Preset } from '@/model/Preset.model';
import { State } from '@/model/State.model';
import { Study } from '@/model/Study.model';
import { selectFeatures, selectTeam } from '@/reducers/team';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { createSelector } from '@reduxjs/toolkit';
import { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { stripReading } from './DevicesProviderUtils';

export interface DevicesProviderContextResponse {
  disabled: boolean;
  deviceTypes: DeviceType[];
  mappedDevices: MappedDevice[];
  unmappedDevices: number;
  readings: Record<string, string>;
  activePreset: Preset | undefined;
  readyTargets: Record<string, string[]>;
  isTargetReady: (target: string | undefined, identifier: string) => boolean;
  registerTarget: (target: string, identifier: string) => void;
  deregisterTarget: (target: string, identifier: string) => void;
  onChangeDeviceTarget: (targetDevice: MappedDevice, targetFields: TargetField[]) => void;
  onChangeDeviceType: (targetDevice: TypeMapping, value: string) => Promise<void>;
  nextReading: (targetId: string, complete: boolean) => void;
  getDevices: () => void;
  refreshDevices: () => void;
  getDeviceType: (productId: number | undefined, vendorId: number | undefined) => TypeMapping | undefined;
  clearReading: (targetId: string) => void;
}

export const DeviceContext = createContext<DevicesProviderContextResponse>({
  disabled: true,
  deviceTypes: [],
  mappedDevices: [],
  unmappedDevices: 0,
  readings: {},
  readyTargets: {},
  activePreset: undefined,
  isTargetReady: () => false,
  clearReading: () => {},
  getDevices: () => {},
  registerTarget: () => {},
  nextReading: () => {},
  deregisterTarget: () => {},
  onChangeDeviceTarget: () => {},
  onChangeDeviceType: () => Promise.resolve(),
  refreshDevices: () => {},
  getDeviceType: () => undefined,
});

const broadcastChannel = new BroadcastChannel('devices');

export const DevicesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [ports, setPorts] = useState<SerialPort[]>([]);
  const activePreset = useRef<Preset>();
  const [readings, setReadings] = useState<Record<string, string>>({});
  const [typeMappings, setTypeMappings] = useState<TypeMapping[]>([]);
  const [buffers, setBuffers] = useState<Record<string, string>>({});
  const [mappedDevices, setMappedDevices] = useState<MappedDevice[]>([]);
  const [unmappedDevices, setUnmappedDevices] = useState<number>(0);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [setupComplete, setSetupComplete] = useState(false);
  const [readyTargets, setReadyTargets] = useState<Record<string, string[]>>({});
  const { pathname } = useLocation();

  const selector = createSelector([selectFeatures], (features) => {
    return { features };
  });
  const team = useSelector(selectTeam);

  const store = useStore();
  const { features } = selector(store.getState() as State);
  const path = matchPath(pathname, { path: '/studies/:studyId/' });
  const params = path?.params as { studyId: string };
  const studyIdParam = params?.studyId;
  const isLoggedIn = _notNil(window.localStorage.getItem('token'));
  const isValidStudyId = _notNil(studyIdParam) && studyIdParam !== 'new' && !studyIdParam.startsWith('sdy_');

  const displayReading = (type: DeviceType, value: string) => {
    infoToast(
      <div>
        <p className="normal">New reading from device</p>
        <p className="fw9">{type.title}</p>
        <br />
        <p className="normal">Reading</p>
        <p className="fw9">{value}</p>
      </div>
    );
  };

  useEffect(() => {
    const updated = _isNil(navigator.serial) || !isLoggedIn || _isNil(team);

    if (updated !== disabled) {
      setDisabled(updated);
    }
  }, [features, isLoggedIn, team]);

  useEffect(() => {
    broadcastChannel.onmessage = (event) => {
      const { type, targetId, value } = event.data;
      if (!document.hidden) {
        addReading(type, targetId, value);
      }
    };
  }, []);

  useEffect(() => {
    if (_isNotEmpty(typeMappings)) {
      const unmappedDevices = typeMappings.filter((device) => _isNil(device?.type)).length;
      setUnmappedDevices(unmappedDevices);
    }
  }, [mappedDevices]);

  useEffect(() => {
    if (!isValidStudyId && !disabled) {
      activePreset.current = undefined;
    }

    // Prevent study create page triggering calls
    if (isValidStudyId && !disabled) {
      loadStudy(studyIdParam)
        .then((study) => {
          if (_notNil(study)) {
            activePreset.current = study.settings;
          }

          return loadMappedDevices(ports, activePreset.current);
        })
        .catch((cause) => {
          ExceptionHandler.captureException(
            new InVivoError('Could not load mapped devices', {
              cause,
              slug: 'devices-provider',
              level: 'warning',
              context: { studyId: studyIdParam, ports: JSON.stringify(ports.map((p) => p.getInfo())) },
            })
          );
        });
    }
  }, [studyIdParam, disabled]);

  const { invoke } = useApiHook({
    endpoint: 'GET /api/v1/devices',
    invokeOnInit: false,
  });

  const initialise = async () => {
    try {
      if (isValidStudyId) {
        const study = await loadStudy(studyIdParam);
        if (_notNil(study)) {
          activePreset.current = study.settings;
        }
      }

      const ports = await loadDevices();
      const response = await invoke({ query: { perPage: 1000 } });

      // @ts-expect-error api types
      const deviceTypes = response?.body?.data as DeviceType[];
      const typeMappings = await initialiseConnections(deviceTypes, ports);
      await loadMappedDevices(ports, activePreset.current);

      setPorts(ports);
      setTypeMappings(typeMappings);
      setDeviceTypes(deviceTypes);
      setSetupComplete(true);
    } catch (error) {
      if (notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError('Could not load study', {
            cause: error,
            slug: 'device-provider',
          })
        );
      }
    }
  };
  useEffect(() => {
    if (!disabled) {
      initialise();
    }
  }, [disabled]);

  useEffect(() => {
    if (setupComplete) {
      mappedDevices.forEach((targetDevice) => {
        consumeData(targetDevice);
      });
    }
  }, [mappedDevices, setupComplete]);

  const initialiseConnections = async (types: DeviceType[], ports: SerialPort[]) => {
    const mappedTypes: TypeMapping[] = ports.reduce((acc: TypeMapping[], port: SerialPort) => {
      const { usbProductId, usbVendorId } = port.getInfo();
      const found = types?.find(
        ({ usb_product_id, usb_vendor_id }) => usb_product_id === usbProductId && usbVendorId === usb_vendor_id
      );
      if (_notNil(found)) {
        acc.push({ type: found, typeId: found.id, port });
      }
      return acc;
    }, []);

    const updatedTypeMapping = await connectToDevices(mappedTypes);
    return updatedTypeMapping;
  };

  const addReading = (type: DeviceType, targetId: string, value: string) => {
    value = stripReading(value, type.reading_type);
    setReadings((readings) => {
      const updatedReadings = { ...readings };
      updatedReadings[targetId] = value;
      return updatedReadings;
    });
    if (document.hidden) {
      broadcastChannel.postMessage({ type, targetId, value });
    } else {
      displayReading(type, value);
    }
  };

  const clearReading = (targetId: string) => {
    setReadings((readings) => {
      const updatedReadings = { ...readings };
      delete updatedReadings[targetId];
      return updatedReadings;
    });
  };

  const getDevices = () => {
    navigator.serial
      .requestPort()
      .then((port) => {
        setPorts([...ports, port]);
        initialise();
      })
      .catch((errors) => {
        // eslint-disable-next-line no-console
        console.log('getDevices error: ', errors);
      });
  };

  const addReadingToBuffer = (targetDevice: MappedDevice, typeMappings: TypeMapping[], value?: Uint8Array) => {
    // This function takes the values from outside state, this is to ensure the latest value for target and preset are used.
    // The read data function is called asynchronously, the state inside of it could be out of date
    // e.g. if the user changes the target or study after the read call has been made
    const latestMapping = getLatestMapping();
    const decoder = new TextDecoder('utf-8');
    let combinedArray = new Uint8Array(0);
    let done = false;

    const targetConfig = latestMapping?.mappedDevices.find(
      ({ usb_product_id, usb_vendor_id }) =>
        targetDevice.usb_product_id === usb_product_id && targetDevice.usb_vendor_id === usb_vendor_id
    );
    const targetId = targetConfig?.activeTarget?.value;
    const typeMapping = typeMappings?.find(
      ({ type }) =>
        targetDevice.usb_product_id === type?.usb_product_id && targetDevice.usb_vendor_id === type?.usb_vendor_id
    );

    if (targetId && _notNil(typeMapping) && _notNil(typeMapping.type?.reading_type)) {
      const existingValue = buffers[targetId] ?? '';
      const terminationCharacter = typeMapping.type?.termination_character;
      value?.forEach((char) => {
        if (char === terminationCharacter) {
          const decoded = decoder.decode(combinedArray);

          setBuffers((previousBuffers) => {
            previousBuffers[targetId] = '';
            return previousBuffers;
          });

          addReading(typeMapping.type!, targetId, existingValue + decoded);
          done = true;
        } else {
          combinedArray = new Uint8Array([...combinedArray, char]);
        }
      });

      if (!done) {
        const decoded = decoder.decode(combinedArray);
        const combinedReading = existingValue + decoded;

        setBuffers((previousBuffers) => {
          previousBuffers[targetId] = combinedReading;
          return previousBuffers;
        });
      }
      consumeData(targetDevice);
    }
  };

  const consumeData = async (targetDevice: MappedDevice) => {
    const typeMapping = typeMappings?.find(
      ({ type }) =>
        targetDevice.usb_product_id === type?.usb_product_id && targetDevice.usb_vendor_id === type?.usb_vendor_id
    );
    if (_notNil(typeMapping?.reader)) {
      const { port, reader, type } = typeMapping;

      if (_notNil(port?.readable) && _notNil(type) && _notNil(reader)) {
        try {
          // eslint-disable-next-line no-console
          console.log('Listening to device: ', type.title, targetDevice);

          const { value } = await reader.read();

          if (_notNil(value)) {
            addReadingToBuffer(targetDevice, typeMappings, value);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Error while reading, waiting then will attempt to reconnect: ', error);
          setTimeout(() => {
            disconnectPort(typeMapping).then(() => {
              // eslint-disable-next-line no-console
              console.log('Port disconnected attempting to reconnect: ', targetDevice);
              initialise();
            });
          }, 1000);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('port not readable or no reader: ', port, reader);
      }
    }
  };

  const connectToDevice = async (targetDevice: TypeMapping) => {
    if (_notNil(targetDevice?.type) && _notNil(targetDevice.port)) {
      const { port, type } = targetDevice;
      const { baud_rate, stop_bits: stopBits, data_bits: dataBits, parity } = type;

      if (!port?.readable) {
        try {
          await port?.open({ baudRate: baud_rate, stopBits, dataBits, parity });
          return port.readable?.getReader();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('Unable to open port:', error);
        }
      } else if (port?.readable && port.readable.locked) {
        // eslint-disable-next-line no-console
        console.log('Port is already readble and locked:', port.readable, port.readable.locked);
        return targetDevice.reader;
      }
    }
  };

  const connectToDevices = async (mappedTypes: TypeMapping[]): Promise<TypeMapping[]> => {
    const updatedTypeMappings: TypeMapping[] = [...mappedTypes];
    let index = 0;
    for await (const targetDevice of updatedTypeMappings) {
      const reader = await connectToDevice(targetDevice);
      updatedTypeMappings[index].reader = reader;
      index++;
    }
    return updatedTypeMappings;
  };

  const resetDevices = () => {
    setPorts([]);
    setMappedDevices([]);
  };

  const refreshDevices = () => {
    resetDevices();
    return navigator.serial.getPorts().then((ports: any) => {
      setPorts(ports);
      initialise();
    });
  };

  const loadDevices = async () => {
    const ports = await navigator.serial.getPorts();
    return ports;
  };

  const loadStudy = async (studyId: ID): Promise<Study> => {
    const response = await Http.get(apiRoute('studies.show.p', { id: studyId }));

    return response?.data?.data;
  };

  const mapDevices = async (
    ports: SerialPort[],
    activePreset?: Preset,
    exstingMappedDevices?: PresetDeviceMapping[]
  ) => {
    const existingMappingForPreset = exstingMappedDevices?.find(({ presetId }) => {
      return activePreset?.id === presetId;
    });
    const updatedMappedDevices: MappedDevice[] = await ports.reduce((acc, port: SerialPort) => {
      const portInfo = port.getInfo();
      const { usbProductId, usbVendorId } = portInfo;

      const existingMappingForDevice = existingMappingForPreset?.mappedDevices?.find(
        ({ usb_product_id }) => usb_product_id === usbProductId
      );
      const types = deviceTypes.filter(
        ({ usb_product_id, usb_vendor_id }) => usb_product_id === usbProductId && usb_vendor_id === usbVendorId
      );

      const typeMapping = getDeviceType(usbProductId, usbVendorId);

      // a type has already been configured for this device, use that type config
      if (_notNil(usbProductId) && _notNil(usbVendorId)) {
        acc.push({
          name: typeMapping?.type?.title,
          target: existingMappingForDevice?.target ?? [],
          // Reset to first target on reload
          activeTarget: existingMappingForDevice?.target?.[0],
          usb_product_id: usbProductId,
          usb_vendor_id: usbVendorId,
        });
      } else if (
        _notNil(existingMappingForDevice?.usb_product_id) &&
        _notNil(existingMappingForDevice?.usb_vendor_id) &&
        types.length > 1
      ) {
        // if we find more than one device in the catalogue with the usb vendor/product id, then don't map. user has to manually map
        acc.push({
          name: 'Unmapped',
          target: [],
          activeTarget: undefined,
          usb_product_id: usbProductId,
          usb_vendor_id: usbVendorId,
        });
        infoToast(
          <div>
            <p className="normal">Unable to map device</p>
            <p className="fw9">Please map manually</p>
          </div>
        );
      } else if (usbProductId && usbVendorId) {
        // we dont have a pre selected device type, but do have usbProductId && usbVendorId
        // const type = mapDeviceType(existingMappingForDevice, portInfo);

        acc.push({
          name: typeMapping?.type?.title,
          target: existingMappingForDevice?.target ?? [],
          activeTarget: existingMappingForDevice?.activeTarget,
          usb_product_id: usbProductId,
          usb_vendor_id: usbVendorId,
        });
      } else {
        // We dont have config and the usb ids are not being reported, label unknown

        acc.push({
          name: 'Unmapped',
          target: [],
          activeTarget: undefined,
          usb_product_id: existingMappingForDevice?.usb_product_id,
          usb_vendor_id: existingMappingForDevice?.usb_product_id,
        });
      }
      return acc;
    }, [] as MappedDevice[]);
    setMappedDevices(updatedMappedDevices);
    updateLocalStorage(updatedMappedDevices);
    return updatedMappedDevices;
  };

  const loadMappedDevices = async (ports: SerialPort[], activePreset?: Preset) => {
    if (_isNotEmpty(ports) && _notNil(activePreset)) {
      const deviceMappingFromStorage = localStorage.getItem('deviceMapping');
      return await mapDevices(
        ports,
        activePreset,
        deviceMappingFromStorage ? JSON.parse(deviceMappingFromStorage) : undefined
      );
    }
  };
  const getLatestMapping = (): PresetDeviceMapping => {
    const deviceMappingFromStorage = localStorage.getItem('deviceMapping');
    const updatedLocalStorage = deviceMappingFromStorage ? JSON.parse(deviceMappingFromStorage) : [];
    return updatedLocalStorage.find(({ presetId }: PresetDeviceMapping) => presetId === activePreset?.current?.id);
  };

  const disconnectPort = async (targetDevice: TypeMapping) => {
    const { reader } = targetDevice;
    // eslint-disable-next-line no-console
    console.log('Disconnecting device: ', targetDevice?.type?.title);

    try {
      // eslint-disable-next-line no-console
      console.log('Attempting to release lock on device: ', targetDevice?.type?.title);
      await reader?.cancel();
      reader?.releaseLock();
      // eslint-disable-next-line no-console
      console.log('Releasing lock on device a success: ', targetDevice?.type?.title);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error releasing lock on device: ', e);
    }

    try {
      // eslint-disable-next-line no-console
      console.log('Attempting to close the port on device: ', targetDevice?.type?.title);
      await targetDevice?.port?.close();
      // eslint-disable-next-line no-console
      console.log('Closing the port on device a success: ', targetDevice?.type?.title);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error closing port on target device: ', e);
    }
  };

  const registerTarget = (targetId: string, identifier: string) => {
    const mappingExists = mappedDevices.find(({ target }) => target.find(({ value }) => value === targetId));
    if (_notNil(mappingExists)) {
      setReadyTargets((previousReadyTargets) => {
        const updatedReadyTargets = mappedDevices.reduce((acc: Record<string, string[]>, device) => {
          const { target } = device;
          const found = target?.find(({ value }) => value === targetId);
          if (_notNil(found) && _notNil(targetId)) {
            const existingTargets = previousReadyTargets[targetId] ?? [];
            acc[targetId] = [...existingTargets, identifier];
          }
          return acc;
        }, {});
        return { ...previousReadyTargets, ...updatedReadyTargets };
      });
    }
  };

  const deregisterTarget = (targetId: string, identifier: string) => {
    setReadyTargets((previousReadyTargets) => {
      const existingTargets = previousReadyTargets[targetId] ?? [];
      const updatedTargets = existingTargets?.filter((value) => value !== identifier);
      previousReadyTargets[targetId] = updatedTargets;
      return previousReadyTargets;
    });
  };
  const isTargetReady = (targetId: string | undefined, identifier: string) => {
    if (_notNil(targetId)) {
      const readyTarget = readyTargets[targetId];
      if (_isEmpty(readyTarget)) {
        return false;
      } else if (readyTarget?.length === 1) {
        return true;
      } else {
        const latestTarget = readyTarget[readyTarget.length - 1];
        if (latestTarget === identifier) {
          return true;
        }
      }
    }
    return false;
  };

  const nextReading = (targetId: string, complete: boolean) => {
    setMappedDevices((mappedDevices) => {
      const targetDevice = mappedDevices.find(({ activeTarget }) => activeTarget?.value === targetId);
      if (_notNil(targetDevice)) {
        const { activeTarget, target, usb_product_id, usb_vendor_id } = targetDevice;
        const targetLength = target.length;
        const currentTargetIndex = target?.findIndex(({ value }) => value === activeTarget?.value);
        const nextTargetIndex = (currentTargetIndex + 1) % targetLength;
        const nextTarget = target[nextTargetIndex];

        const updatedMappedDevices = mappedDevices.reduce((acc, device, idx) => {
          // Only trigger an update when there is actually a change otherwise leave the instance id of mapped devices the same
          if (
            (complete || device.activeTarget?.value !== activeTarget?.value) &&
            usb_product_id === device.usb_product_id &&
            usb_vendor_id === device.usb_vendor_id
          ) {
            const result = [...acc];
            result[idx] = { ...device, activeTarget: complete ? nextTarget : activeTarget };
            return result;
          }
          return acc;
        }, mappedDevices);
        // Update local storage on change
        if (mappedDevices !== updatedMappedDevices) {
          updateLocalStorage(updatedMappedDevices);
        }
        return updatedMappedDevices;
      }
      return mappedDevices;
    });
  };

  const clearBuffers = (target: TargetField[]) => {
    setBuffers((previousBuffers) => {
      target.forEach(({ value }) => {
        previousBuffers[value] = '';
      });
      return previousBuffers;
    });
  };

  const onChangeDeviceType = async (targetDevice: TypeMapping | undefined, newValue: string) => {
    let updatedTypeMappings = [...typeMappings] as TypeMapping[];
    const newType = deviceTypes?.find(({ id }) => id === newValue);

    const deviceExists = updatedTypeMappings?.find(({ type }) => type?.id === targetDevice?.type?.id);

    // if the device already exists in the mapped devices then update it
    if (deviceExists) {
      updatedTypeMappings = updatedTypeMappings.map((device) => {
        const { type } = device;

        if (type?.id === targetDevice?.type?.id) {
          device.type = newType;
        }
        return device;
      });
    } else {
      updatedTypeMappings = [...updatedTypeMappings, { ...targetDevice, type: newType }];
    }

    if (targetDevice) {
      await disconnectPort(targetDevice);
    }

    const reader = await connectToDevice({ ...targetDevice, type: newType });
    // eslint-disable-next-line no-console
    console.log('Connected and got new reader:', reader);

    const deviceIndex = updatedTypeMappings.findIndex(({ type }) => type?.id === targetDevice?.type?.id);
    if (reader) {
      updatedTypeMappings[deviceIndex].reader = reader;
    }
    setTypeMappings(updatedTypeMappings);
    updateTypeMappingLocalStorage(updatedTypeMappings);
  };

  const getDeviceType = (
    usb_product_id: number | undefined,
    usb_vendor_id: number | undefined
  ): TypeMapping | undefined => {
    return typeMappings?.find(
      ({ type }) => usb_product_id === type?.usb_product_id && usb_vendor_id === type?.usb_vendor_id
    );
  };

  const onChangeDeviceTarget = (targetDevice: MappedDevice, newValue: TargetField[]) => {
    let updatedMappedDevices = [...mappedDevices];
    const deviceExists = updatedMappedDevices?.find(
      ({ usb_product_id, usb_vendor_id }) =>
        usb_product_id === targetDevice.usb_product_id && usb_vendor_id === targetDevice.usb_vendor_id
    );

    clearBuffers(newValue);
    // if the device already exists in the mapped devices then update it
    if (deviceExists) {
      updatedMappedDevices = updatedMappedDevices.map((device) => {
        const { usb_product_id, usb_vendor_id } = device;

        if (usb_product_id === targetDevice.usb_product_id && usb_vendor_id === targetDevice.usb_vendor_id) {
          device.target = newValue;
          device.activeTarget = device.target[0];
        }
        return device;
      });
    } else {
      updatedMappedDevices = [
        ...updatedMappedDevices,
        { ...targetDevice, target: newValue, activeTarget: newValue[0] },
      ];
    }
    setMappedDevices(updatedMappedDevices);
    updateLocalStorage(updatedMappedDevices);
  };

  const updateTypeMappingLocalStorage = (updatedTypeMappings: TypeMapping[]) => {
    localStorage.setItem('typeMapping', JSON.stringify(updatedTypeMappings));
  };

  const updateLocalStorage = (updatedMappedDevices: MappedDevice[]) => {
    if (_notNil(activePreset.current)) {
      const stringFromLocalStorage = localStorage.getItem('deviceMapping');
      const updatedLocalStorage: PresetDeviceMapping[] = stringFromLocalStorage
        ? JSON.parse(stringFromLocalStorage)
        : [];
      const targetPresetMappingIndex = updatedLocalStorage.findIndex(
        ({ presetId }) => presetId === activePreset?.current?.id
      );

      if (targetPresetMappingIndex >= 0) {
        updatedLocalStorage[targetPresetMappingIndex].mappedDevices = updatedMappedDevices.map(
          ({ target, activeTarget, usb_product_id, usb_vendor_id, name }) => ({
            name,
            target,
            usb_product_id,
            usb_vendor_id,
            activeTarget,
          })
        );
      } else {
        updatedLocalStorage.push({
          presetId: activePreset?.current?.id,
          mappedDevices: updatedMappedDevices.map(({ name, target, activeTarget, usb_product_id, usb_vendor_id }) => ({
            name,
            target,
            usb_product_id,
            usb_vendor_id,
            activeTarget,
          })),
        });
      }
      localStorage.setItem('deviceMapping', JSON.stringify(updatedLocalStorage));
    }
  };

  return (
    <DeviceContext.Provider
      value={{
        disabled,
        deviceTypes,
        mappedDevices,
        unmappedDevices,
        activePreset: activePreset.current,
        readings,
        readyTargets,
        isTargetReady,
        getDeviceType,
        onChangeDeviceTarget,
        onChangeDeviceType,
        registerTarget,
        deregisterTarget,
        getDevices,
        nextReading,
        clearReading,
        refreshDevices,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
