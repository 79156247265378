import { AddTaskFormTypes } from '@/components/Modals/AddTask/AddTask.model.ts';
import type { CreateStudyAction, CreateStudyState } from '@/components/Studies/Create/Create.model';
import { TaskSpecIssuesModal } from '@/components/Studies/Create/Steps/Tasks/TaskSpecIssuesModal.tsx';
import {
  TaskSpecIssueAction,
  TaskSpecV2Issue,
  TaskSpecWrapper,
  validateTaskSpec,
} from '@/components/Studies/Create/Steps/Tasks/TasksV2.util.ts';
import { TaskScheduleFrequencyCell } from '@/components/Studies/Settings/Settings.utils';
import { taskTargetTypes } from '@/components/Studies/Settings/Steps/Tasks/TasksV2';
import { taskTypes } from '@/components/Studies/Tasks/Task';
import Button from '@/components/UI/Button';
import Indicator from '@/components/UI/Indicator';
import Link from '@/components/UI/Link';
import SelectDropDown from '@/components/UI/SelectDropDown';
import type { ActionProps } from '@/components/UI/SelectDropDown/Menus/ActionList';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import type { CellProps, Columns } from '@/components/UI/Table/TableComponent.model';
import UserAvatar from '@/components/UI/UserAvatar';
import { errorToast, successToast, warningToast } from '@/helpers.tsx';
import { _isNil, _notNil, uuid } from '@/littledash';
import type { State } from '@/model/State.model';
import type { TaskSpecCreate } from '@/model/Task.model';
import type { MainUser, StudyUser, UserApiId } from '@/model/User.model';
import { useModalAction } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import { Dispatch, FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

interface TaskV2Props {
  state: CreateStudyState;
  dispatch: Dispatch<CreateStudyAction>;
  isSummary?: boolean;
}

interface TaskSpecsBulkActionsProps {
  selectedTaskSpecs: Array<TaskSpecWrapper>;
  dispatch: TaskV2Props['dispatch'];
}

const TaskSpecsBulkActions: FC<TaskSpecsBulkActionsProps> = ({ selectedTaskSpecs, dispatch }) => {
  const selectedTaskSpecCount = selectedTaskSpecs.length;
  const hasSelectedTaskSpecs = selectedTaskSpecCount > 0;
  const actions: Array<ActionProps> = [
    {
      name: 'Duplicate',
      key: 'duplicate',
      action: () =>
        dispatch({
          type: 'add-task',
          data: selectedTaskSpecs.reduce(
            (acc, { taskSpec }) => ({
              ...acc,
              [uuid()]: { ...taskSpec, title: `${taskSpec.title ?? 'untitled'} (duplicate)` },
            }),
            {}
          ),
        }),
    },
    {
      name: 'Delete',
      key: 'delete',
      className: 'red',
      action: () => dispatch({ type: 'delete-task', data: selectedTaskSpecs.map((s) => s.id) }),
    },
  ];
  return (
    <div className="w-100 flex items-center mt3">
      {hasSelectedTaskSpecs && (
        <div
          className="mid-gray bg-light-gray ph3 ba b--moon-gray f6 br-0 br--left br1"
          style={{
            whiteSpace: 'nowrap',
            lineHeight: '2.4rem',
          }}
        >
          {selectedTaskSpecCount} selected
        </div>
      )}
      <SelectDropDown
        title="Bulk actions"
        className={`plain f6 ${hasSelectedTaskSpecs ? 'br--right' : ''}`}
        alignMenu="right"
        disabled={!hasSelectedTaskSpecs}
      >
        <ActionList actions={actions} />
      </SelectDropDown>
    </div>
  );
};

const selector = createSelector(
  [(state: State) => state.user.currentUser, (state: State) => state.team.team.users],
  (currentUser, teamUsers) => {
    const { activeUsers, teamUserMap } = teamUsers.reduce<{
      activeUsers: Array<MainUser>;
      teamUserMap: Map<UserApiId, MainUser>;
    }>(
      (acc, user) => {
        if (user.pivot.status === 'active') {
          acc.activeUsers.push(user);
          acc.teamUserMap.set(user.api_id, user);
        }
        return acc;
      },
      { activeUsers: [], teamUserMap: new Map() }
    );
    return { currentUser, teamUsers: activeUsers, teamUserMap };
  }
);

export const TasksV2: FC<TaskV2Props> = ({ state, dispatch, isSummary = false }) => {
  const { openModal } = useModalAction();
  const { currentUser, teamUsers, teamUserMap } = useSelector(selector);
  const [displayIssuesModal, setDisplayIssuesModal] = useState(false);

  const { studyUsers } = useMemo(() => {
    const studyUserMap = new Map(
      [
        ...(_isNil(state?.study?.author) ? [] : [state.study.author]),
        ...(state?.study?.users ?? []),
        ...(_isNil(currentUser) ? [] : [currentUser]),
      ].map((user) => [user.api_id, user as StudyUser])
    );
    return { studyUsers: Array.from(studyUserMap.values()).sort((a, b) => a.name.localeCompare(b.name)), studyUserMap };
  }, [currentUser, state]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const { taskSpecs, taskSpecTitleMap } = useMemo<{
    taskSpecs: Array<TaskSpecWrapper>;
    taskSpecTitleMap: Map<string, string>;
  }>(() => {
    const taskSpecs = Object.entries(state.study.tasks ?? {}).map(([id, taskSpec]) => ({
      id,
      taskSpec,
    }));
    const taskSpecTitleMap = new Map(taskSpecs.map((tsw) => [tsw.id, tsw.taskSpec.title as string]));
    return { taskSpecs, taskSpecTitleMap };
  }, [state]);

  const taskSpecIssues = useMemo<Map<TaskSpecWrapper['id'], Array<TaskSpecV2Issue>>>(() => {
    const groupTempIds = state.study.groups.reduce((acc, group) => {
      if (_notNil(group.temp_id)) {
        acc.add(group.temp_id);
      }
      return acc;
    }, new Set<string>());
    return validateTaskSpec(
      taskSpecs,
      new Set([currentUser.api_id, ...state.study.users.map((u) => u.api_id)]),
      groupTempIds
    );
  }, [taskSpecs, state, currentUser]);

  const selectedTaskSpecs = useMemo<Array<TaskSpecWrapper>>(
    () =>
      Object.entries(selectedRows).reduce<Array<TaskSpecWrapper>>((acc, [rowIndex, selected]) => {
        const taskSpec = taskSpecs?.[Number(rowIndex)];
        if (selected && _notNil(taskSpec)) {
          acc.push(taskSpec);
        }
        return acc;
      }, []),
    [taskSpecs, selectedRows]
  );
  const handleTaskSpecCreateOrUpdate = (id: string, taskSpec: TaskSpecCreate) => {
    dispatch({ type: 'add-task', data: { [id]: taskSpec } });
  };

  const handleAddClick = () => {
    openModal('ADD_TASK', {
      studyStartedOn: state?.study?.started_on,
      studyCreationUsers: studyUsers,
      studyCreationGroups: state?.study?.groups ?? [],
      handleSubmit: (taskSpec: TaskSpecCreate) => handleTaskSpecCreateOrUpdate(uuid(), taskSpec),
    });
  };

  const handleTaskSpecClick = async (
    event: MouseEvent<HTMLAnchorElement> | undefined,
    { id, taskSpec }: TaskSpecWrapper,
    initialTab?: AddTaskFormTypes
  ) => {
    event?.preventDefault();
    openModal('EDIT_TASK', {
      studyCreationTaskSpec: taskSpec,
      studyCreationUsers: studyUsers,
      teamUsers: teamUsers,
      studyCreationGroups: state?.study?.groups ?? [],
      handleSubmit: (studyCreateTaskSpec: TaskSpecCreate) => handleTaskSpecCreateOrUpdate(id, studyCreateTaskSpec),
      initialTab,
    });
  };

  const columns: Columns<TaskSpecWrapper> = [
    {
      id: 1,
      Header: 'Title',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpecWrapper>) =>
        isSummary ? (
          (props.row.original.taskSpec.title ?? '-')
        ) : (
          <Link className="link blue" onClick={(e) => handleTaskSpecClick(e, props.row.original)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {taskSpecIssues.has(props.row.original.id) && <Indicator smallIndicator indicatorClassName="mr2" />}
              <span>{props.row.original.taskSpec.title ?? '-'}</span>
            </div>
          </Link>
        ),
    },
    {
      id: 2,
      Header: 'Description',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpecWrapper>) => (
        <p className="truncate">{props.row.original.taskSpec.description ?? '-'}</p>
      ),
    },
    {
      id: 3,
      Header: 'Type',
      width: 75,
      isVisible: true,
      Cell: (props) => taskTypes[props.row.original.taskSpec.type],
    },
    {
      id: 4,
      Header: 'Target',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpecWrapper>) => taskTargetTypes[props.row.original.taskSpec.target?.type],
    },
    {
      id: 5,
      Header: 'Frequency',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpecWrapper>) => (
        <TaskScheduleFrequencyCell schedule={props.row.original.taskSpec.schedule} />
      ),
    },
    {
      id: 6,
      Header: 'Assignees',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpecWrapper>) => (
        <div>
          {(props.row.original.taskSpec.assignees ?? []).map((userId) => {
            const user = teamUserMap.get(userId);
            return <UserAvatar key={userId} user={user} style={{ marginRight: '3px' }} tooltip={user?.name} />;
          })}
        </div>
      ),
    },
  ];

  useEffect(() => {
    const valid = (taskSpecIssues?.size ?? 0) === 0;
    if (state.stepReady !== valid) {
      dispatch({ type: 'stepReady', data: (taskSpecIssues?.size ?? 0) === 0 });
    }
  }, [taskSpecIssues]);

  const handleTaskSpecIssueAction = (action: TaskSpecIssueAction) => {
    switch (action.type) {
      case 'close-modal':
        setDisplayIssuesModal(false);
        break;
      case 'open-task-spec': {
        setDisplayIssuesModal(false);
        const taskSpec = taskSpecs.find((t) => t.id === action.taskSpecId);
        if (_notNil(taskSpec)) {
          handleTaskSpecClick(undefined, taskSpec, action.tab);
        } else {
          errorToast('Could not open task spec');
          setDisplayIssuesModal(false);
        }
        break;
      }
      case 'add-invalid-users': {
        const usersToAdd = action.users.reduce<Array<StudyUser>>((acc, userId) => {
          const user = teamUserMap.get(userId);
          if (_notNil(user)) {
            acc.push({
              id: user.id,
              api_id: user.api_id,
              name: user.name,
              email: user.email,
              access: action.access,
            } as StudyUser);
          }
          return acc;
        }, []);
        if (usersToAdd.length > 0) {
          dispatch({ type: 'updateStudy', data: { users: [...state.study.users, ...usersToAdd] } });
          successToast(
            <>
              <p>
                {usersToAdd.length} assignee{usersToAdd.length > 0 ? 's' : ''} added to study with {action.access}{' '}
                access
              </p>
              <ul className="pt1 fw1">
                {usersToAdd.map((u) => (
                  <li key={u.api_id}>{u.name}</li>
                ))}
              </ul>
            </>
          );
        } else {
          errorToast('Could not add assignee(s) to study');
          setDisplayIssuesModal(false);
        }
        break;
      }
      case 'remove-invalid-users': {
        const issueTaskSpec = taskSpecs.find((t) => t.id === action.taskSpecId);
        const taskSpecsToUpdate = action.allTasks ? taskSpecs : _isNil(issueTaskSpec) ? [] : [issueTaskSpec];
        if (taskSpecsToUpdate.length > 0) {
          const userIdsToRemove = new Set(action.users);
          const removedUsers = Array.from(userIdsToRemove.values()).reduce<Array<MainUser>>((acc, userId) => {
            if (teamUserMap.has(userId)) {
              acc.push(teamUserMap.get(userId) as MainUser);
            }
            return acc;
          }, []);

          dispatch({
            type: 'add-task',
            data: taskSpecsToUpdate.reduce<Record<TaskSpecWrapper['id'], TaskSpecWrapper['taskSpec']>>(
              (acc, taskSpec) => {
                return {
                  ...acc,
                  [taskSpec.id]: {
                    ...taskSpec.taskSpec,
                    assignees: (taskSpec.taskSpec.assignees ?? []).filter((userId) => !userIdsToRemove.has(userId)),
                  },
                };
              },
              {}
            ),
          });
          successToast(
            <>
              <p>
                {userIdsToRemove.size} assignee{userIdsToRemove.size > 0 ? 's' : ''} removed from{' '}
                {taskSpecsToUpdate.length > 1 ? `${taskSpecsToUpdate.length} tasks` : 'task'}
              </p>
              <ul className="pt1 fw1">
                {removedUsers.map((u) => (
                  <li key={u.api_id}>{u.name}</li>
                ))}
              </ul>
            </>
          );
        }
        break;
      }
      case 'remove-invalid-groups-and-open': {
        const taskSpec = taskSpecs.find((t) => t.id === action.taskSpecId);
        if (_notNil(taskSpec) && taskSpec.taskSpec.target.type === 'group') {
          const groupIdsToRemove = new Set(action.groups);
          const updatedGroupList = taskSpec.taskSpec.target.groups.filter((groupId) => !groupIdsToRemove.has(groupId));
          const updatedTaskSpecWrapper: TaskSpecWrapper = {
            ...taskSpec,
            taskSpec: {
              ...taskSpec.taskSpec,
              target: updatedGroupList.length === 0 ? { type: 'animal' } : { type: 'group', groups: updatedGroupList },
            },
          };
          handleTaskSpecCreateOrUpdate(action.taskSpecId, updatedTaskSpecWrapper.taskSpec);
          handleTaskSpecClick(undefined, updatedTaskSpecWrapper, AddTaskFormTypes.target);
          if (updatedGroupList.length > 0) {
            successToast(`${groupIdsToRemove.size} group${groupIdsToRemove.size > 0 ? 's' : ''} removed`);
          } else {
            warningToast(
              <>
                <p>Task target has been changed to all animals</p>
                <p className="pt1 fw1">
                  No groups existed after removing {groupIdsToRemove.size} group{groupIdsToRemove.size > 0 ? 's' : ''}
                </p>
              </>
            );
          }
        } else {
          errorToast('Could not remove group(s)');
        }
        setDisplayIssuesModal(false);
        break;
      }
      default:
        errorToast('Something went wrong');
    }
  };

  return (
    <>
      {displayIssuesModal && (
        <ReactModal
          isOpen={true}
          className="ui-modal"
          style={{
            overlay: { backgroundColor: 'rgba(00,00,00,.5)' },
            content: { maxHeight: '100vh' },
          }}
          ariaHideApp={false}
        >
          <TaskSpecIssuesModal
            taskSpecTitleMap={taskSpecTitleMap}
            issues={taskSpecIssues}
            onAction={handleTaskSpecIssueAction}
          />
        </ReactModal>
      )}
      <div className="ui-card mv4 flex items-end flex-column" data-testid="tasks-table__container">
        {!isSummary && (
          <div className="w-100 flex justify-between items-center">
            <p className="ph3 lh-copy f6">
              <TaskSpecsBulkActions selectedTaskSpecs={selectedTaskSpecs} dispatch={dispatch} />
            </p>
            <div className="flex" style={{ alignItems: 'center' }}>
              {taskSpecIssues.size > 0 && (
                <Link className="link blue fl" openModal={true} onClick={() => setDisplayIssuesModal(true)}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RiAlertFill className="red" size={25} />
                    <span className="pl2">Resolve {taskSpecIssues.size} conflicts to proceed</span>
                  </div>
                </Link>
              )}
              <Button className="ma3" onClick={handleAddClick}>
                Add Task
              </Button>
            </div>
          </div>
        )}
        <Table
          className="w-100 bt"
          columns={columns}
          data={taskSpecs}
          selectedRows={isSummary ? undefined : selectedRows}
          setSelectedRows={isSummary ? undefined : setSelectedRows}
          noDataMessage="Your tasks will appear here"
        />
      </div>
    </>
  );
};
