import { _first } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import { differenceInDays, parse } from 'date-fns';
import type { TaskSpecCreate } from 'model/Task.model';
import type { AddTaskScheduleData, AddTaskTargetData, StepFormPayloadProps } from './AddTask.model';
import { AddTaskFormTypes } from './AddTask.model';
import { conditionTypeOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleConditionalFields';
import { repeatPeriodOptions } from './Steps/AddTaskScheduleForm/AddTaskScheduleForm.utils';

/** Based on the `type` of schedule form data it structures the corresponding API payload */
export const handleAddTaskSchedulePayload = (
  addTaskScheduleFormData: AddTaskScheduleData
): TaskSpecCreate['schedule'] => {
  switch (addTaskScheduleFormData.type) {
    case 'recurring':
      return {
        type: addTaskScheduleFormData.type,
        repeat: {
          value: addTaskScheduleFormData.repeat?.value ?? NaN,
          unit: addTaskScheduleFormData.repeat?.unit ?? repeatPeriodOptions.days.value,
          on_day: addTaskScheduleFormData?.repeat?.days ?? [],
        },
        duration: {
          type: 'date_time', // always static
          start: DateUtils.startOfDay(addTaskScheduleFormData?.start ?? DateUtils.dateNow()),
          end: DateUtils.endOfDay(addTaskScheduleFormData?.end ?? DateUtils.dateNow()),
        },
        timezone: null,
      };
    case 'conditional':
      return {
        type: addTaskScheduleFormData.type,
        condition: {
          type: addTaskScheduleFormData.condition?.type ?? _first(conditionTypeOptions).value,
          value: addTaskScheduleFormData.condition?.value ?? [],
          unit: 'days', // currently static
        },
        timezone: null,
      };
    case 'one_off':
      return {
        type: addTaskScheduleFormData.type,
        duration: {
          type: 'date_time', // always static
          start: DateUtils.startOfDay(addTaskScheduleFormData?.start ?? DateUtils.dateNow()),
          // `end` will use the end of the `start` day as they only last one day (00:00:00—23:59:59) local
          end: DateUtils.endOfDay(addTaskScheduleFormData?.start ?? DateUtils.dateNow()),
        },
        timezone: null,
      };
  }
};

export const handleAddTaskTargetPayload = (AddTaskTargetFormData: AddTaskTargetData): TaskSpecCreate['target'] => {
  switch (AddTaskTargetFormData.type) {
    case 'animal':
      return {
        type: 'animal',
      };
    case 'group':
      return {
        type: 'group',
        groups: AddTaskTargetFormData?.groups ?? [],
      };
  }
};

/** Turn each step form section data into API Payload */
export const handleAddTaskStepFormPayload = (stepFormData: StepFormPayloadProps): TaskSpecCreate => {
  const { title, description, type } = stepFormData[AddTaskFormTypes.overview];
  const { assignees } = stepFormData;

  return {
    title,
    description,
    type,
    assignees,
    schedule: handleAddTaskSchedulePayload(stepFormData[AddTaskFormTypes.schedule]),
    target: handleAddTaskTargetPayload(stepFormData[AddTaskFormTypes.target]),
  };
};

export const calculateDaysDifference = (startDateString: string, endDateString: string): number => {
  const startDate = parse(startDateString, 'yyyy-MM-dd', new Date());
  const endDate = parse(endDateString, 'yyyy-MM-dd', new Date());
  return differenceInDays(endDate, startDate);
};
