import Button from '@/components/UI/Button';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import { addDays, addMonths, format, subDays, subMonths } from 'date-fns';
import type { FC } from 'react';
import { RiArrowDownSFill, RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import { type CalendarState, CalendarViews } from './Calendar.model';
import { getStartAndEndDateForWeekView } from './Calendar.utils';

interface CalendarNavProps {
  chosenDay: CalendarState['chosenDay'];
  yearAndMonth: CalendarState['yearAndMonth'];
  span: CalendarState['span'];
  onSelectDay: (day: Date) => void;
  onSelectYearAndMonth: (yearAndMonth: Array<number>) => void;
  onSelectSpan: (span: CalendarViews) => void;
}

const CalendarNav: FC<CalendarNavProps> = ({
  chosenDay,
  yearAndMonth: [year, month],
  span,
  onSelectDay,
  onSelectYearAndMonth,
  onSelectSpan,
}) => {
  const dateText = (): string => {
    switch (span) {
      case CalendarViews.Month: {
        const date = new Date(year, month, 1);
        return `${format(date, 'MMMM')} ${year}`;
      }
      case CalendarViews.Week: {
        const dateRange = getStartAndEndDateForWeekView(chosenDay);
        return `${format(dateRange.start, 'do MMMM yyyy')} - ${format(dateRange.end, 'do MMMM yyyy')}`;
      }
      case CalendarViews.Day:
        return format(chosenDay, 'do MMMM yyyy');
    }
  };
  const handlePreviousClick = (): void => {
    switch (span) {
      case CalendarViews.Month: {
        const newMonth = subMonths(new Date(year, month, 1), 1);
        onSelectYearAndMonth([newMonth.getFullYear(), newMonth.getMonth()]);
        break;
      }
      case CalendarViews.Week:
        onSelectDay(subDays(chosenDay, 7));
        break;
      case CalendarViews.Day:
        onSelectDay(subDays(chosenDay, 1));
        break;
    }
  };
  const handleNextClick = (): void => {
    switch (span) {
      case CalendarViews.Month: {
        const newMonth = addMonths(new Date(year, month, 1), 1);
        onSelectYearAndMonth([newMonth.getFullYear(), newMonth.getMonth()]);
        break;
      }
      case CalendarViews.Week:
        onSelectDay(addDays(chosenDay, 7));
        break;
      case CalendarViews.Day:
        onSelectDay(addDays(chosenDay, 1));
        break;
    }
  };
  const handleTodayClick = (): void => {
    switch (span) {
      case CalendarViews.Month:
        onSelectYearAndMonth([new Date().getFullYear(), new Date().getMonth()]);
        break;
      default:
        onSelectDay(new Date());
    }
  };
  const handleSpanSelect = (selectedSpan: CalendarViews): void => {
    onSelectSpan(selectedSpan);
  };

  return (
    <div
      className="flex w-100 min-h3 items-center justify-between bb b--moon-gray"
      data-test-component="CalendarNav"
      data-test-element="container"
    >
      <div className="flex items-center">
        <div onClick={handlePreviousClick} className="pl2 mt1" data-testid="previous-date">
          <RiArrowLeftLine size={28} className="dark-gray dim pointer lh-solid" />
        </div>
        <div onClick={handleNextClick} className="pl2 mt1">
          <RiArrowRightLine size={28} className="dark-gray dim pointer lh-solid" data-testid="next-date" />
        </div>
        <Button className="mh3 ph3 no-select" plain onClick={handleTodayClick} testId="today-button">
          Today
        </Button>
        <div>
          <h3 className="lh-title near-black" data-testid="date-text">
            {dateText()}
          </h3>
        </div>
      </div>
      <div className="flex items-center mr4" data-test-component="CalendarNav" data-test-element="span-select">
        <Dropdown
          renderMenu={() => (
            <>
              {Object.keys(CalendarViews).map((key) => (
                <DropdownMenuItem key={key} onClick={() => handleSpanSelect(key as CalendarViews)}>
                  {key}
                </DropdownMenuItem>
              ))}
            </>
          )}
        >
          <Button plain className="ph3">
            <div className="flex items-center">
              {span} <RiArrowDownSFill className="ml1" />
            </div>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default CalendarNav;
