// @ts-nocheck: converted from JS

import { _isEmpty } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import _differenceBy from 'lodash/differenceBy';

export const flatMapAnimalsFromCollections = (collections) => {
  const animals = [];

  if (!_isEmpty(collections) && Array.isArray(collections)) {
    collections.map((c) => {
      if (!_isEmpty(c.subjects.data)) {
        c.subjects.data.map((s) => {
          animals.push(s);
        });
      }
    });

    return animals;
  }

  return animals;
};

/**
 * Used to pull all of the animals into a flat array
 * by their ID out of the `study.collections` prop
 */
export const flatMapAnimalsFromCollectionsById = (collections, selection) => {
  const selectedAnimals = [];

  if (!_isEmpty(collections) && Array.isArray(collections) && !_isEmpty(selection) && Array.isArray(selection)) {
    collections.map((c) => {
      if (!_isEmpty(c.subjects.data)) {
        c.subjects.data.map((s) => {
          if (selection.includes(s.id)) {
            selectedAnimals.push(s);
          }
        });
      }
    });

    return selectedAnimals;
  }

  return selectedAnimals;
};

/**
 * Compares two arrays of objects, returns the new addition
 */

export const returnNewObjectsAddedToArray = (arr1, arr2, key) => {
  if (!_isEmpty(arr1) && Array.isArray(arr1) && !_isEmpty(arr2) && Array.isArray(arr2)) {
    return _differenceBy(arr2, arr1, key);
  }

  return [];
};

export const getCageCardMetadata = (metadata, study, team, cage = null) => {
  const getMetadataItem = (glossaryId) =>
    [...(study?.metas?.data ?? []), ...(cage?.meta?.data ?? [])].find(
      (metaItem) => metaItem.glossary_id === glossaryId
    );

  const getMetadataValue = (glossaryId) => {
    const metadataItem = getMetadataItem(glossaryId);
    if (metadataItem?.value == null) {
      return '-';
    }
    if (metadataItem?.field_type === 'date') {
      return DateUtils.renderDate(metadataItem.value);
    }
    return metadataItem.value;
  };

  return metadata
    .filter((metadataItem) => Boolean(team?.cage_card_settings?.metadata?.includes(metadataItem.id)))
    .map((metadataItem) => ({ ...metadataItem, value: getMetadataValue(metadataItem.id) }));
};
