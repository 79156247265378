// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { formatISO } from 'date-fns';
import { useState } from 'react';

const UpdateDOB = ({ subject, closeModal, handleCallback }) => {
  const dateNowIso = formatISO(new Date(), { representation: 'date' });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dateNowIso);

  const bulkUpdate = (subjects) => {
    const animalIds = subjects.map((s) => s._id || s.id);

    return Http.patch(apiRoute('animals.dob.bulk.update'), {
      animal_ids: animalIds,
      dob: date,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    bulkUpdate(Array.isArray(subject) ? subject : [subject])
      .then(() => {
        return handleCallback('Successfully updated date of birth');
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
      });
  };

  const what = Array.isArray(subject) && subject.length > 1 ? 'animals' : 'animal';
  const whatDemonstrative = what === 'animals' ? 'these' : 'this';
  const message = `Update the date of birth for ${whatDemonstrative} ${what}?`;

  return (
    <form onSubmit={submitForm} className="ui__narrow-modal">
      <div className="mb3 tc">
        <h3 className="normal f4 pb2">{message}</h3>
      </div>
      <div className="mb4">
        <label htmlFor="dob">Date of birth</label>
        {error && (
          <p className="red f6 mt2 mb0">
            There was a problem updating subjects. if this keeps occurring, please contact support.
          </p>
        )}
        <DatePickerNative type="date" value={date} onChange={setDate} max={dateNowIso} style={{ marginBottom: 0 }} />
      </div>
      <div>
        <Button submit disabled={loading} className="w-100 mb2">
          Save
        </Button>
        <Button plain className="w-100" onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default UpdateDOB;
