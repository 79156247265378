import Loading from '@/components/Loading/Loading';
import { TargetPanel } from '@/components/Modals/TaskDetails/TaskDetailsTabs/TargetPanel';
import { TaskOverdue } from '@/components/Studies/Tasks/Task';
import Button from '@/components/UI/Button';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { errorToast } from '@/helpers';
import { _isNil } from '@/littledash';
import type { PickRequired } from '@/model/Common.model';
import type { TaskApiId, TaskAssignees, TaskStatus, TaskV1 } from '@/model/Task.model';
import type { UserApiId } from '@/model/User.model';
import type { ApiSuccessResponse } from '@/support/ApiService';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ModalActions, ModalContainer } from '@/utils/modal';
import { type FC, useState } from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { AssigneesPanel } from './TaskDetailsTabs/AssigneesPanel';
import { OverviewPanel } from './TaskDetailsTabs/OverviewPanel';
import * as Auth from '@/support/auth';

export interface TaskDetailsProps {
  taskId: TaskApiId;
  onTaskUpdate: (task: TaskV1) => unknown;
  startingTab?: TaskDetailsTabs;
  selectionDisabled?: boolean;
  closeModal: () => void;
}

interface TaskDetailContainerProps {
  task: PickRequired<TaskV1, 'animals'>;
  handleTaskUpdate: () => void;
  startingTab?: TaskDetailsTabs;
}

interface TaskStatusOption {
  value: TaskStatus;
  label: string;
}

export const enum TaskDetailsTabs {
  Overview = 0,
  Assignees = 1,
  Target = 2,
}

export const statusOptions: Record<TaskStatus, TaskStatusOption> = {
  to_do: { value: 'to_do', label: 'To do' },
  in_progress: { value: 'in_progress', label: 'In progress' },
  done: { value: 'done', label: 'Done' },
};

export const TaskDetails: FC<TaskDetailsProps> = ({ taskId, onTaskUpdate, closeModal, startingTab }) => {
  const { response, invoke: reloadTask } = useApiHook({
    endpoint: 'GET /api/v1/tasks/{taskId}',
    path: { taskId },
    query: { include: ['animals'] },
  });

  const task =
    response?.type === 'success'
      ? (response.body as PickRequired<ApiSuccessResponse<'GET /api/v1/tasks/{taskId}'>['body'], 'animals'>)
      : null;

  if (_isNil(task)) {
    return <Loading />;
  }

  const handleTaskUpdate = async () => {
    const result = await reloadTask();
    if (result?.type === 'success') {
      onTaskUpdate(result.body);
    }
  };

  return (
    <ModalContainer size="narrow">
      <TaskDetailsContainer task={task} handleTaskUpdate={handleTaskUpdate} startingTab={startingTab} />
      <ModalActions className="pa3 mt2 bt b--moon-gray" onCancel={closeModal} cancelBtnText="Close" />
    </ModalContainer>
  );
};

export const checkCanTransitionTask = (isWriteUser: boolean, assignees: TaskAssignees) => {
  if (!isWriteUser) {
    const currentUser = Auth.getCurrentUser();
    return assignees?.some(({ id }) => id === currentUser?.api_id) ?? false;
  }
  return true;
};

const TaskDetailsContainer: FC<TaskDetailContainerProps> = ({ task, startingTab, handleTaskUpdate }) => {
  const [activeTab, setActiveTab] = useState(startingTab ?? TaskDetailsTabs.Overview);
  const { response: userListResponse } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/users',
    path: { studyId: task.study.api_id },
    query: { perPage: -1 },
  });
  const users = userListResponse?.type === 'success' ? userListResponse.body.data : [];
  const isWriteUser = Auth.isWriteUserForStudyUsers(users);
  const taskTransitionDisabled = !checkCanTransitionTask(isWriteUser, task.assignees ?? []);
  const { invoke: updateStatus } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/tasks/{taskId}/transition',
    path: { studyId: task.study.api_id, taskId: task.id },
    invokeOnInit: false,
  });
  const { invoke: updateAssignees } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/tasks/{taskId}/assign',
    path: { studyId: task.study.api_id, taskId: task.id },
    invokeOnInit: false,
  });
  const handleTransition = async (status: TaskStatus) => {
    await updateStatus({
      path: { studyId: task.study.api_id, taskId: task.id },
      body: { status },
    });
    await handleTaskUpdate();
  };
  const handleAssigneeUpdate = async (selection: UserApiId[]) => {
    try {
      await updateAssignees({
        path: { studyId: task.study.api_id, taskId: task.id },
        body: { assignees: selection },
      });
      await handleTaskUpdate();
    } catch (e) {
      errorToast('Error updating task assignees');
    }
  };

  return (
    <>
      <div className="flex pa3 w-100 justify-between center">
        <div className="flex items-center">
          {task.overdue && task.status !== 'done' && <TaskOverdue />}
          <p className="fw5 f5 near-black">{task.title}</p>
        </div>
        <Dropdown
          renderMenu={() => (
            <>
              {Object.values(statusOptions).map((option) => (
                <DropdownMenuItem key={option.value} onClick={() => handleTransition(option.value)}>
                  {option.label}
                </DropdownMenuItem>
              ))}
            </>
          )}
          disabled={taskTransitionDisabled}
        >
          <Button paleBlue className="ph3 br-pill">
            <div className="flex items-center">
              {statusOptions[task.status].label} <RiArrowDownSFill className="ml1" />
            </div>
          </Button>
        </Dropdown>
      </div>
      <Tabs outerState={[activeTab, setActiveTab]}>
        <div className="pt2 mh4 bb b--moon-gray">
          <Tab>Overview</Tab>
          <Tab>Assignees</Tab>
          <Tab>Target</Tab>
        </div>
        <Panel style={{ minHeight: '50vh' }}>
          <OverviewPanel task={task} setActiveTab={setActiveTab} />
        </Panel>
        <Panel style={{ minHeight: '50vh' }}>
          <AssigneesPanel
            task={task}
            handleAssigneesChange={handleAssigneeUpdate}
            selectionDisabled={task.status === 'done' || !isWriteUser}
            users={users}
          />
        </Panel>
        <Panel className="overflow-scroll" style={{ minHeight: '50vh', maxHeight: '50vh' }}>
          <TargetPanel task={task} />
        </Panel>
      </Tabs>
    </>
  );
};
