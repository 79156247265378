import { AddTaskFormTypes } from '@/components/Modals/AddTask/AddTask.model.ts';
import { _notNil } from '@/littledash.ts';
import type { TaskSpecCreate } from '@/model/Task.model.ts';
import { UserApiId } from '@/model/User.model.ts';

export interface TaskSpecWrapper {
  id: string;
  taskSpec: TaskSpecCreate;
}

export type InvalidUsersIssue = { type: 'invalid-users'; users: Array<UserApiId> };
export type InvalidGroupsIssue = { type: 'invalid-groups'; groups: Array<string> };
export type TaskSpecV2Issue = InvalidUsersIssue | InvalidGroupsIssue;
export type TaskSpecIssueAction =
  | { type: 'close-modal' }
  | { type: 'add-invalid-users'; taskSpecId: string; users: Array<UserApiId>; access: 'read' | 'write' }
  | { type: 'remove-invalid-users'; taskSpecId: string; users: Array<UserApiId>; allTasks: boolean }
  | { type: 'open-task-spec'; taskSpecId: string; tab?: AddTaskFormTypes }
  | { type: 'remove-invalid-groups-and-open'; taskSpecId: string; groups: Array<string> };

export const validateTaskSpec = (
  taskSpecs: Array<TaskSpecWrapper>,
  studyUserIds: Set<UserApiId>,
  studyGroupIds: Set<string>
): Map<TaskSpecWrapper['id'], Array<TaskSpecV2Issue>> => {
  return taskSpecs.reduce((acc, taskSpec) => {
    const invalidGroupsIssue = validateTaskSpecGroups(taskSpec, studyGroupIds);
    if (_notNil(invalidGroupsIssue)) {
      acc.set(taskSpec.id, [...(acc.get(taskSpec.id) ?? []), invalidGroupsIssue]);
    }
    const invalidUsersIssue = validateTaskSpecUsers(taskSpec, studyUserIds);
    if (_notNil(invalidUsersIssue)) {
      acc.set(taskSpec.id, [...(acc.get(taskSpec.id) ?? []), invalidUsersIssue]);
    }
    return acc;
  }, new Map<TaskSpecWrapper['id'], Array<TaskSpecV2Issue>>());
};

const validateTaskSpecUsers = (
  taskSpec: TaskSpecWrapper,
  studyUserIds: Set<UserApiId>
): InvalidUsersIssue | undefined => {
  const invalidUsers = (taskSpec.taskSpec.assignees ?? []).reduce<Array<UserApiId>>(
    (acc, userId) => (studyUserIds.has(userId) ? acc : [...acc, userId]),
    []
  );
  if (invalidUsers.length > 0) {
    return { type: 'invalid-users', users: invalidUsers };
  }
};
const validateTaskSpecGroups = (
  taskSpec: TaskSpecWrapper,
  studyGroupIds: Set<string>
): InvalidGroupsIssue | undefined => {
  if (taskSpec.taskSpec.target.type === 'group') {
    const invalidGroups = taskSpec.taskSpec.target.groups.reduce<Array<string>>(
      (acc, groupId) => (studyGroupIds.has(groupId) ? acc : [...acc, groupId]),
      []
    );
    if (invalidGroups.length > 0) {
      return { type: 'invalid-groups', groups: invalidGroups };
    }
  }
};
