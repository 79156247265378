import {
  CreateOrUpdatePresetContext,
  DraftMeasurement,
  toSlug,
  toTitle,
} from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.util';
import Icon from '@/components/UI/Icon';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import { _isNil, _notNil } from '@/littledash.ts';
import { PresetCreateOrUpdateV1 } from '@/model/Preset.model.ts';
import { useModalAction } from '@/utils/modal.tsx';
import cn from 'classnames';
import { FC, useContext, useEffect, useRef } from 'react';
import { DragObjectWithType, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { SubmitHandler, useFieldArray, useForm, useFormContext, useFormState, useWatch } from 'react-hook-form@latest';
import { RiAlertFill, RiCheckFill, RiCloseFill, RiPencilLine } from 'react-icons/ri';
import style from './PresetBuilder.module.scss';

export const MeasurementsForm: FC<{
  handleMeasurementSelected: (selectedIndex: number) => void;
}> = ({ handleMeasurementSelected }) => {
  const { openModal, closeModal } = useModalAction();
  const formMethods = useFormContext<PresetCreateOrUpdateV1>();
  const { state, dispatch } = useContext(CreateOrUpdatePresetContext);
  const { fields, remove, move, append, replace } = useFieldArray({
    control: formMethods.control,
    name: 'measurements',
  });
  const formState = useFormState({ control: formMethods.control, name: 'measurements', exact: true });
  const addCalculatedMeasurement = () => {
    append({
      name: 'New Calculation',
      unit: '',
      slug: 'new-calculation',
      formula: '',
      inputs: [],
      config: {
        all_inputs_required: true,
        auto_swap: null,
        data_analysis: {
          survival: true,
          tolerance: true,
          efficacy: false,
          efficacy_prophylactic: false,
          oncology: false,
        },
      },
    });
    formMethods.reset(
      { ...formMethods.getValues() },
      {
        keepDirtyValues: true,
        keepErrors: true,
        keepDirty: true,
        keepValues: true,
        keepDefaultValues: false,
        keepIsSubmitted: true,
        keepIsSubmitSuccessful: true,
        keepTouched: true,
        keepIsValidating: true,
        keepIsValid: true,
        keepSubmitCount: true,
      }
    );
    handleMeasurementSelected(fields.length);
  };

  const handleDraftMeasurementCommit = ({ id, name, unit }: DraftMeasurement) => {
    if (!formMethods.formState.isSubmitting) {
      dispatch({ type: 'remove-draft-measurement', data: { id } });
      const slug = toSlug(name);
      append({
        name,
        slug,
        unit,
        formula: `$${slug}`,
        inputs: [{ name, slug, unit }],
        config: {
          all_inputs_required: true,
          auto_swap: null,
          data_analysis: {
            survival: true,
            tolerance: true,
            efficacy: false,
            efficacy_prophylactic: false,
            oncology: false,
          },
        },
      });
      formMethods.reset(
        { ...formMethods.getValues() },
        {
          keepDirtyValues: true,
          keepErrors: true,
          keepDirty: true,
          keepValues: true,
          keepDefaultValues: false,
          keepIsSubmitted: true,
          keepIsSubmitSuccessful: true,
          keepTouched: true,
          keepIsValidating: true,
          keepIsValid: true,
          keepSubmitCount: true,
        }
      );
    }
  };
  return (
    <div className={`ui-card pa4 mr2 ${style.measurementsForm}`}>
      <h3 className="black fw5 pb2">Measurements</h3>
      <p className="flex flex-wrap items-center dark-gray pb3">
        Reorder by dragging the <Icon icon="drag" className="mh1 mid-gray" width={20} height={20} />
        icon or click the name to edit.
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="dib ml1 link blue"
          href="https://help.benchling.com/hc/en-us/articles/29260886232589-Creating-and-managing-presets"
        >
          Read more
        </a>
      </p>

      {fields.map((field, fieldIndex) => (
        <MeasurementCard
          key={field.slug ?? fieldIndex}
          index={fieldIndex}
          name={field.name}
          unit={field.unit ?? ''}
          slug={field.slug}
          invalid={_notNil(formState.errors?.measurements?.[fieldIndex])}
          disabled={field.slug === 'weight' || formMethods.formState.isSubmitting}
          handleMeasurementSelected={handleMeasurementSelected}
          move={move}
          remove={(index) => {
            remove(index);
            formMethods.trigger('measurements');
          }}
          onNameEdit={(index: number) => {
            openModal('WEIGHT_TITLE_EDIT', {
              name: field.name,
              onUpdate: ({ name }: { name: string }) => {
                closeModal();
                formMethods.setValue(`measurements.${index}.name`, name);
                formMethods.setValue(`measurements.${index}.inputs.0.name`, name);
                replace(formMethods.getValues('measurements'));
              },
              onCancel: closeModal,
            });
          }}
        />
      ))}
      {Array.from(state.draftMeasurements.values()).map((draft) => (
        <DraftMeasurementCard
          key={draft.id}
          draft={draft}
          handleCommit={handleDraftMeasurementCommit}
          handleUpdate={(data) => dispatch({ type: 'update-draft-measurement', data })}
          handleRevert={(id) => dispatch({ type: 'remove-draft-measurement', data: { id } })}
        />
      ))}
      <div className="mt3">
        <AddMeasurementButton
          addMeasurement={() => dispatch({ type: 'add-draft-measurement' })}
          addCalculatedMeasurement={addCalculatedMeasurement}
          disabled={formMethods.formState.isSubmitting}
        />
      </div>
    </div>
  );
};

const AddMeasurementButton: FC<{
  addMeasurement: () => void;
  addCalculatedMeasurement: () => void;
  disabled: boolean;
}> = ({ addMeasurement, addCalculatedMeasurement, disabled }) => {
  const actions = [
    {
      name: 'Without calculation',
      action: addMeasurement,
    },
    {
      name: 'With calculation',
      action: addCalculatedMeasurement,
    },
  ];
  return (
    <SelectDropDown
      title="Add measurement"
      className="plain"
      disabled={disabled}
      testId="add-measurement-for-preset-options"
    >
      <ActionList actions={actions} testPrefix="addMeasurement__" />
    </SelectDropDown>
  );
};

const DraftMeasurementCard: FC<{
  draft: DraftMeasurement;
  handleCommit: (draft: DraftMeasurement) => void;
  handleUpdate: (draft: DraftMeasurement) => void;
  handleRevert: (id: string) => void;
}> = ({ draft, handleCommit, handleUpdate, handleRevert }) => {
  const formMethods = useForm<Pick<DraftMeasurement, 'name' | 'unit'>>({
    mode: 'onBlur',
    defaultValues: { name: draft.name, unit: draft.unit },
  });
  const updatedFormData = useWatch({ control: formMethods.control });
  useEffect(() => {
    if (
      formMethods.formState.isValid &&
      formMethods.formState.isDirty &&
      (draft.name !== updatedFormData.name || draft.unit !== updatedFormData.unit)
    ) {
      handleUpdate({ id: draft.id, ...formMethods.getValues() });
    }
  }, [draft, updatedFormData, formMethods.formState.isValid, formMethods.formState.isDirty, handleUpdate]);

  const handleSubmit: SubmitHandler<Pick<DraftMeasurement, 'name' | 'unit'>> = (formData) =>
    handleCommit({ ...draft, ...formData });

  return (
    <div
      className="flex flex-row items-center mt2 justify-start w-100"
      data-test-component="DraftMeasurementCard"
      data-test-element="container"
      data-test-key={draft.id}
    >
      <input
        {...formMethods.register('name', { required: 'Name is required' })}
        type="text"
        maxLength={32}
        placeholder="Measurement name"
        data-test-element="name-input"
        autoComplete="off"
        className={`mr2 w-100 ${style.marginBottomReset}`}
      />
      <input
        {...formMethods.register('unit')}
        type="text"
        placeholder="Unit"
        maxLength={20}
        data-test-element="unit-input"
        autoComplete="off"
        className={`${style.marginBottomReset} ${style.draftCardUnitInput}`}
      />
      <div className={`f3 pl2 flex flex-row items-center justify-start ${style.cardActionContainer}`}>
        <div
          className={cn('ml2 mr3 mid-gray hover-dark-gray', {
            ui__disabled: !(formMethods.formState.isValid || formMethods.formState.isDirty),
            ui__valid: formMethods.formState.isValid || formMethods.formState.isDirty,
          })}
          data-test-element="commit-button"
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          <RiCheckFill />
        </div>
        <div
          className="mid-gray hover-dark-gray"
          data-test-element="revert-button"
          onClick={() => handleRevert(draft.id)}
        >
          <RiCloseFill />
        </div>
      </div>
    </div>
  );
};
const MeasurementCard: FC<{
  index: number;
  name: string;
  slug: string;
  unit?: string;
  invalid: boolean;
  disabled: boolean;
  handleMeasurementSelected: (index: number) => void;
  move: (from: number, to: number) => void;
  remove: (index: number) => void;
  onNameEdit: (index: number) => void;
}> = ({ index, name, slug, unit, invalid, disabled, handleMeasurementSelected, move, remove, onNameEdit }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'measurement-card',
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover: (item: DragObjectWithType & { index: number }, monitor: DropTargetMonitor) => {
      const clientOffset = monitor.getClientOffset();
      const fromIndex = item.index;
      const toIndex = index;
      if (!ref.current || _isNil(clientOffset) || fromIndex === toIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (fromIndex < toIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      move(fromIndex, toIndex);
      item.index = toIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'measurement-card', index },
    canDrag: () => true,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className="flex flex-row justify-start mt2 w-100"
      data-handler-id={handlerId ?? ''}
      data-test-component="MeasurementCard"
      data-test-element="container"
      data-test-key={slug}
      style={{ cursor: isDragging ? 'grabbing' : 'default' }}
    >
      <div
        className={cn('flex flex-row items-center justify-between bg-near-white pa2 br2 mr3 w-100 ba b--gray', {
          disabled,
          'is-dragging': isDragging,
          [style.invalid]: invalid,
          [style.weightMeasurementCard]: slug === 'weight',
        })}
        onClick={() => {
          if (!disabled) {
            handleMeasurementSelected(index);
          }
        }}
      >
        <div className="flex flex-row items-center justify-start">
          <Icon className="mid-gray" icon="drag" width={20} height={20} />
          <p className={cn('ph2 f6 ml2 black', { ['link blue']: !disabled })}>{toTitle(name, unit)}</p>
          {slug === 'weight' && (
            <span className={style.weightTitleEdit} onClick={() => onNameEdit(index)}>
              <RiPencilLine />
            </span>
          )}
        </div>
        {invalid && <RiAlertFill className="red" />}
      </div>
      <div className={`f3 pl4 flex flex-row items-center justify-start ${style.cardActionContainer}`}>
        {!disabled && (
          <div
            data-test-element="remove-button"
            className="mid-gray hover-dark-gray"
            onClick={() => {
              if (!disabled) {
                remove(index);
              }
            }}
          >
            <RiCloseFill className="flex" />
          </div>
        )}
      </div>
    </div>
  );
};
