// @ts-nocheck: converted from JS

import { FieldTypes } from '@/components/FormBuilder/Item.utils';
import { DateTimePicker, Number, Radio, Select, Text, Textarea } from '@/components/UI/FormFields';
import {
  DateTimePicker as DateTimePickerLatest,
  Number as NumberLatest,
  Select as SelectLatest,
  Text as TextLatest,
} from '@/components/UI/FormFields/Latest';
import {
  Datepicker as ReadOnlyDatepicker,
  Select as ReadOnlySelect,
  Text as ReadOnlyText,
} from '@/components/UI/FormFields/ReadOnly';
import SelectLookup from '@/components/UI/FormFields/SelectLookup';
import Summary, { SummaryItem } from '@/components/UI/Summary';
import { _notNil } from '@/littledash';
import type { RequestTemplateGroups } from '@/model/Request.model';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';

interface FormRenderProps {
  fields?: Array<{ name: string; type: string }>;
  groups?: Array<RequestTemplateGroups>;
  groupContainer?: ReactNode | FC<PropsWithChildren>;
  disabled?: boolean;
  submitted?: boolean;
  dispatch?: Function; // eslint-disable-line @typescript-eslint/ban-types
  testPrefix?: string;
  hookFormVersion?: 'legacy' | 'latest';
}

const FormRender: FC<FormRenderProps> = ({
  fields,
  groups,
  groupContainer,
  disabled = false,
  submitted = false,
  dispatch,
  testPrefix = 'metadata-field',
  hookFormVersion = 'legacy',
}) => {
  useEffect(() => {
    if (_notNil(dispatch)) {
      dispatch({ type: 'stepReady' });
    }
  }, []);

  const getComponent = (type, props) => {
    const testId = `${testPrefix}__${props.slug ?? type}`;

    const componentMap = {
      [FieldTypes.text]:
        hookFormVersion !== 'legacy' ? <TextLatest {...props} testId={testId} /> : <Text {...props} testId={testId} />,
      [FieldTypes.textarea]: <Textarea {...props} testId={testId} />,
      [FieldTypes.select]:
        hookFormVersion !== 'legacy' ? (
          <SelectLatest {...props} testId={testId} />
        ) : (
          <Select {...props} testId={testId} />
        ),
      [FieldTypes.multi_select]:
        hookFormVersion !== 'legacy' ? (
          <SelectLatest {...props} isMulti testId={testId} />
        ) : (
          <Select {...props} isMulti testId={testId} />
        ),
      [FieldTypes.date]:
        hookFormVersion !== 'legacy' ? (
          <DateTimePickerLatest {...props} testId={testId} />
        ) : (
          <DateTimePicker {...props} testId={testId} />
        ),
      [FieldTypes.radio]: <Radio {...props} testId={testId} />,
      [FieldTypes.lookup_select]: <SelectLookup {...props} testId={testId} />,
      [FieldTypes.lookup_multi_select]: <SelectLookup {...props} isMulti testId={testId} />,
      [FieldTypes.numeric]:
        hookFormVersion !== 'legacy' ? (
          <NumberLatest {...props} testId={testId} />
        ) : (
          <Number {...props} testId={testId} />
        ),
    };
    return componentMap[type];
  };

  const getReadOnlyComponent = (type, props) => {
    const { name } = props;
    const formatMap = {
      [FieldTypes.text]: <ReadOnlyText {...props} />,
      [FieldTypes.textarea]: <ReadOnlyText {...props} />,
      [FieldTypes.select]: <ReadOnlySelect name={name} isMulti={false} />,
      [FieldTypes.multi_select]: <ReadOnlySelect name={name} isMulti={true} />,
      [FieldTypes.date]: <ReadOnlyDatepicker name={name} />,
      [FieldTypes.radio]: <ReadOnlyText {...props} />,
    };
    return formatMap[type];
  };

  const GroupContainer = groupContainer || 'div';
  return (
    <div data-test-component="FormRender" data-test-element="container" data-testid="metadata-field-group">
      {fields?.map((field) => (
        <div
          key={field.name}
          className="mb4"
          data-test-element="field-container"
          data-test-key={field.slug}
          data-testid={`metadata-field-group__${field.slug}`}
        >
          {getComponent(field.type, {
            ...field,
            disabled,
          })}
        </div>
      ))}
      {groups &&
        (submitted ? (
          <div className="mw7 w-100" data-test-element="submitted-group-container">
            {groups.map((group) => (
              <Summary key={group.name} title={group.label}>
                {group.fields.map((field) => (
                  <SummaryItem key={field.name} label={field.label}>
                    {getReadOnlyComponent(field.type, field)}
                  </SummaryItem>
                ))}
              </Summary>
            ))}
          </div>
        ) : (
          groups.map((group) => (
            <GroupContainer key={group.name}>
              <>
                {group.label && (
                  <h2 className="f4 normal lh-title mb4" data-test-element="group-label" data-test-key={group.name}>
                    {group.label}
                  </h2>
                )}
                {group.fields.map((field) => (
                  <div
                    key={field.name}
                    className="mb4"
                    data-test-element="group-field"
                    data-test-key={field.name}
                    data-testid={`group-field-${field.type}`}
                  >
                    {getComponent(field.type, {
                      ...field,
                      disabled,
                    })}
                  </div>
                ))}
              </>
            </GroupContainer>
          ))
        ))}
    </div>
  );
};

export default FormRender;
