import { useVirtualizer } from '@tanstack/react-virtual';
import NoData from '@/components/NoData';
import { Task } from '@/components/Studies/Tasks/Task';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Header from '@/components/UI/Header/Header';
import Spinner from '@/components/UI/Spinner/Spinner';
import { isToday } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { _flatten, _isEmpty, _notNil } from '@/littledash';
import _groupBy from 'lodash/groupBy';
import type { Study } from '@/model/Study.model';
import type { TaskV1 } from '@/model/Task.model';
import type { FC, UIEvent } from 'react';
import { memo, useMemo, useRef, useState } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useApiPagination } from '@/support/Hooks/api/useApiPagination';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import * as Auth from '@/support/auth';

interface ScheduleProps {
  study: Study;
}

interface DateRow {
  type: 'date';
  value: string;
}

interface TaskRow {
  type: 'task';
  value: TaskV1;
}

const NextEntries = memo(() => (
  <div className="flex items-center justify-center pa3">
    <Spinner size="small" color="" /> <h3 className="f6 lh-title ml2">Loading...</h3>
  </div>
));

const DateItem = memo(({ value }: { value: string }) => (
  <div className="near-black f4 pv4">
    {isToday(toZonedTime(value, DateUtils.timezone())) && <span className="dib lh-title pr1 fw6">Today</span>}
    <DateTimeRenderer value={value} format={DateRenderFormat.Date} />
  </div>
));

export const generateVirtualRows = (tasks: Record<string, TaskV1[]>): Array<DateRow | TaskRow> => {
  return Object.keys(tasks).reduce((acc: Array<DateRow | TaskRow>, k: string) => {
    acc.push({ type: 'date', value: k } as DateRow);
    if (_notNil(tasks[k])) {
      tasks[k].forEach((task) => {
        acc.push({ type: 'task', value: task });
      });
    }
    return acc;
  }, []);
};

export const Schedule: FC<ScheduleProps> = ({ study }) => {
  // Stores any tasks updated to render instead of the API response
  const [updatedTasks, setUpdatedTasks] = useState<Record<TaskV1['id'], TaskV1>>({});
  const isWriteUser = Auth.isWriteUserForStudy(study);

  const scrollElement = useRef<HTMLDivElement>(null);
  const {
    response: tasksDataResponse,
    loading: tasksDataLoading,
    invoke: fetchTasks,
  } = useApiHook({
    endpoint: 'GET /api/v1/tasks',
    query: {
      study_api_id: [study.api_id],
      sort: 'start',
      order: 'asc',
    },
  });
  const { pages, hasNextPage, nextPage } = useApiPagination({ response: tasksDataResponse });
  const tasks: Record<string, TaskV1[]> = useMemo(
    () => _groupBy(_flatten(pages), (task) => task?.duration?.start),
    [pages]
  );

  const rows: Array<DateRow | TaskRow> = useMemo(() => generateVirtualRows(tasks), [tasks]);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => scrollElement?.current,
    estimateSize: () => 100,
    paddingEnd: 30,
  });

  const handleScrollBottom = (e: UIEvent<HTMLDivElement>): void => {
    if (e.target) {
      const { target } = e;
      if (!(target instanceof HTMLDivElement)) {
        return;
      }
      const { scrollTop, clientHeight, scrollHeight } = target;
      const atBottom = scrollTop + clientHeight >= scrollHeight;
      if (atBottom && hasNextPage && !tasksDataLoading) {
        fetchTasks({
          query: {
            study_api_id: [study.api_id],
            sort: 'start',
            order: 'asc',
            page: nextPage,
          },
        });
      }
    }
  };

  const handleTaskUpdate = (task: TaskV1): void => {
    setUpdatedTasks({ ...updatedTasks, [task.id]: task });
  };

  return (
    <>
      <div className="ph4 pb2 bb b-moon-gray">
        <Header mainHeaderText="Schedule" />
      </div>
      {_isEmpty(rows) && !tasksDataLoading ? (
        <NoData
          title="No tasks have been created"
          text="Tasks can be added in the Study settings section."
          link={`/studies/${study.id}/settings/`}
          btnTxt="Study settings"
          isFullScreen
        />
      ) : (
        <div
          className="ph4 mb4"
          ref={scrollElement}
          style={{
            height: '100%',
            overflowY: 'auto',
            contain: 'strict',
          }}
          onScroll={handleScrollBottom}
        >
          <div
            className="w-100 relative"
            style={{
              height: `${virtualizer.getTotalSize()}px`,
            }}
          >
            <div
              style={{
                transform: `translateY(${virtualizer.getVirtualItems()?.[0]?.start ?? 0}px)`,
              }}
              className="w-100 absolute top-0 left-0"
            >
              {virtualizer.getVirtualItems().map((virtualRow) => {
                const isLoaderRow = virtualRow.index >= rows.length - 1 && hasNextPage;
                const item = rows?.[virtualRow.index] as DateRow | TaskRow;
                if (item) {
                  return (
                    <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                      {item.type === 'date' ? (
                        <DateItem value={item.value} />
                      ) : (
                        <div className="pb3">
                          <Task
                            task={updatedTasks?.[item.value.id] ?? item.value}
                            study={study}
                            onTaskUpdate={handleTaskUpdate}
                            canTransition={isWriteUser}
                          />
                        </div>
                      )}
                      {isLoaderRow && <NextEntries />}
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
