import NoDataCard from '@/components/NoDataCard';
import { generateTreatmentColumns } from '@/components/Studies/Treatments/Treatment.utils';
import Button from '@/components/UI/Button';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseAPITableProps, UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import { updateColumns, updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import type { MetadataField } from '@/model/Metadata.model';
import { State } from '@/model/State.model.ts';
import type { Study } from '@/model/Study.model';
import type { Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import * as Auth from '@/support/auth';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface TreatmentsTableProps {
  study: Study;
  onError?: (error: Error) => void;
  handleAddOrUpdateTreatmentsClick: HandleTreatmentsClick;
  metadata?: Array<MetadataField>;
  groups?: Array<TreatmentGroup>;
}

type HandleTreatmentsClick = (event: React.MouseEvent<Element, MouseEvent>, treatment?: Treatment) => Promise<boolean>;

export const TreatmentsTable: React.FC<TreatmentsTableProps> = ({
  study,
  handleAddOrUpdateTreatmentsClick,
  onError,
  groups,
  metadata,
}) => {
  const studyId = study?.id;
  const [isWriteUserForStudy, setIsWriteUserForStudy] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<Column<Treatment>>>([]);
  const [duplicates, setDuplicates] = useState<Array<ID>>([]);
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const groupsEmpty = _isEmpty(groups);

  const settings = useSelector((state: State) => state.ui.settings);

  const updatedSettings = useMemo(() => {
    if (_isNotEmpty(columns)) {
      // Ensure any derived columns (e.g: from measurements) are listed in the settings.
      return updateSettings('treatments', columns, settings);
    }
    return settings;
  }, [columns]);

  useEffect(() => {
    if (_isNotEmpty(columns)) {
      const updatedColumns = updateColumns('treatments', columns, settings);
      setColumns(updatedColumns);
    }
  }, [settings?.tables]);

  useEffect(() => {
    if (study) {
      const writeUser = Auth.isWriteUserForStudy(study);
      setIsWriteUserForStudy(writeUser || Auth.isAuthor(study) || Auth.isOwner(study));
      setColumns(
        generateTreatmentColumns(
          writeUser,
          duplicates,
          settings.tables.treatments.columns,
          isMounted,
          handleAddOrUpdateTreatmentsClick,
          metadata ?? []
        )
      );
    }
  }, [study, duplicates]);

  if (studyId == null) {
    return null;
  }

  const handleDelete = async (treatments: Array<ID>) => {
    try {
      await Http.delete(
        apiRoute('studies.treatments', {
          studyId,
        }),
        {
          data: {
            treatments: Object.values(treatments),
          },
        }
      );
      successToast(`Successfully deleted treatment${treatments.length > 1 ? 's' : ''}`);
    } catch (error) {
      if (_notNil(onError)) {
        onError(error as Error);
      }
      ExceptionHandler.captureException(
        new InVivoError('Could not delete study treatments', {
          cause: error,
          slug: 'treatments-delete',
        })
      );
    }
  };

  const handleDuplicate = async (treatments: Array<ID>) => {
    try {
      await Http.post(apiRoute('studies.treatments.duplicate', { studyId }), {
        ids: treatments,
      }).then(({ data: { ids } }) => {
        successToast(`Successfully created treatment${treatments.length > 1 ? 's' : ''}`);
        setDuplicates(duplicates.concat(ids));
      });
    } catch (error) {
      if (_notNil(onError)) {
        onError(error as Error);
      }
      ExceptionHandler.captureException(
        new InVivoError('Could not duplicate treatment', {
          cause: error,
          slug: 'treatment-clone',
        })
      );
    }
  };

  const bulkActions = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { setUpdating, fetchTableData },
    },
  }: UseTableProps<Treatment>) => {
    const selectedTreatmentsInUse = selectedFlatRows.some(({ original: { in_use } }) => in_use);
    return [
      {
        name: 'Duplicate',
        key: 'duplicate_treatments',
        disabled: !isWriteUserForStudy,
        action: () => {
          setUpdating(true);
          const treatmentIDs = selectedFlatRows.map(({ original: { id } }) => id);
          handleDuplicate(treatmentIDs)
            .then(() => fetchTableData())
            .catch(() => setUpdating(false));
        },
      },
      {
        name: 'Delete',
        key: 'delete_treatments',
        className: 'red',
        disabled: !isWriteUserForStudy || selectedTreatmentsInUse,
        tooltip: selectedTreatmentsInUse && 'One or more of the selected treatments are in use',
        action: () => {
          const treatmentIDs = selectedFlatRows.map(({ original: { id } }) => id);
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'CONFIRM_DELETE_TREATMENTS',
            props: {
              onClick: () => {
                setUpdating(true);
                handleDelete(treatmentIDs)
                  .then(() => fetchTableData())
                  .catch(() => setUpdating(false));
              },
              length: treatmentIDs.length,
            },
          });
        },
      },
    ];
  };

  const isComplete = _notNil(study.archived_at);

  const disabled = !isWriteUserForStudy || groupsEmpty || isComplete;

  const AsideComponent: React.FC<UseTableProps<Treatment>> | null = !(
    handleAddOrUpdateTreatmentsClick instanceof Function
  )
    ? null
    : ({
        useTableProps: {
          apiTable: { setUpdating, fetchTableData },
        },
      }) => (
        <Button
          disabled={disabled}
          tooltip={groupsEmpty ? 'Please add at least one group' : ''}
          testId="add-new-treatment"
          className="ml2"
          onClick={(e) => {
            setUpdating(true);
            handleAddOrUpdateTreatmentsClick(e)
              .then((reload) => {
                if (reload) {
                  return fetchTableData();
                }
                setUpdating(false);
              })
              .catch(() => setUpdating(false));
          }}
        >
          Add New
        </Button>
      );

  const NoDataComponent: React.FC<UseAPITableProps<Treatment>> = ({ apiTable: { setUpdating, fetchTableData } }) => (
    <NoDataCard
      title="This study has no treatments yet"
      text={
        groupsEmpty
          ? 'Please add at least one group to enable adding treatments'
          : 'You can add and view treatments for this study here.'
      }
      onLinkClick={
        groupsEmpty
          ? undefined
          : (e) => {
              if (!disabled) {
                setUpdating(true);
                handleAddOrUpdateTreatmentsClick(e)
                  .then((reload) => {
                    if (reload) {
                      return fetchTableData();
                    }
                    setUpdating(false);
                  })
                  .catch(() => setUpdating(false));
              }
            }
      }
      btnTxt={isWriteUserForStudy && 'Add a treatment'}
      openModal
      dark
    />
  );

  return (
    <APITable<Treatment>
      NoDataComponent={NoDataComponent}
      AsideComponent={AsideComponent}
      bulkActions={isWriteUserForStudy && !isComplete ? bulkActions : undefined}
      apiInfo={{
        type: 'legacyInternalApi',
        route: apiRoute('studies.treatments', {
          studyId,
        }),
      }}
      settings={updatedSettings}
      reduxTableName="treatments"
      includeParam="study,study_groups,metadata"
      searchPlaceholderText="Search by treatment name"
      columns={columns.filter((col) => col.Header)}
      defaultSortBy={{ id: 'treatment_name', desc: false }}
    />
  );
};
