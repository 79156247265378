// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { classNames, successToast } from '@/helpers';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';

interface EditDatatableProps {
  studyId: string;
  dataTableId: string;
  closeModal: () => void;
  fetchTableData: () => Promise<void>;
}

interface EditDatatableFormProps extends EditDatatableProps {
  //
}

interface FormValues {
  name: string;
}

const EditDatatable: FC<EditDatatableProps> = ({ studyId, dataTableId, closeModal, fetchTableData }) => {
  const { response: datatableGETResponse, loading: fetchingDatatable } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyId}/datatables/{dataTableId}',
    path: { studyId, dataTableId },
  });

  if (datatableGETResponse?.type === 'error') {
    closeModal();
    return null;
  }

  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Edit datatable" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      {fetchingDatatable && !datatableGETResponse?.body ? (
        <Loading />
      ) : (
        <EditDatatableForm
          closeModal={closeModal}
          studyId={studyId}
          datatable={datatableGETResponse?.body}
          fetchTableData={fetchTableData}
        />
      )}
    </ModalContainer>
  );
};

const EditDatatableForm: FC<EditDatatableFormProps> = ({ studyId, datatable, closeModal, fetchTableData }) => {
  const {
    invoke: patchDatatable,
    response: datatablePATCHResponse,
    loading: patchingDatatable,
  } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/datatables/{dataTableId}',
    path: { studyId, dataTableId: datatable?.id },
    invokeOnInit: false,
  });
  const { register, errors, handleSubmit } = useForm({
    defaultValues: { name: datatable?.name ?? '', unit: datatable?.unit },
  });
  const onSubmit = async (formValues: FormValues): Promise<void> => {
    await patchDatatable({
      endpoint: 'PATCH /api/v1/studies/{studyId}/datatables/{dataTableId}',
      path: { studyId, dataTableId: datatable?.id },
      body: formValues,
    });
    await fetchTableData();
    closeModal();
    successToast('Updated datatable');
  };
  return (
    <>
      {datatablePATCHResponse?.type === 'error' && <ApiErrorBanner />}
      <div className="pa3">
        <label>Name</label>
        <input
          type="text"
          name="name"
          ref={register({
            required: 'This field is required',
            maxLength: { value: 255, message: 'Maximum of 255 characters' },
          })}
          style={{ marginBottom: 0 }}
          className={classNames('', { input__error: errors?.name })}
          autoFocus
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <small className="red db pt2">{message}</small>}
        />
      </div>
      {datatable.type === 'numeric' && (
        <div className="ph3 pb3">
          <label>Unit</label>
          <input
            ref={register({
              required: 'This field is required',
              maxLength: { value: 255, message: 'Maximum of 255 characters' },
            })}
            type="text"
            name="unit"
            style={{ marginBottom: 0 }}
            className={classNames('', { input__error: errors?.unit })}
          />
          <ErrorMessage
            errors={errors}
            name="unit"
            render={({ message }) => <small className="red db pt2">{message}</small>}
          />
        </div>
      )}
      <ModalActions
        submitButtonProps={{ disabled: patchingDatatable || datatablePATCHResponse?.type === 'error' }}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        className="pa3 bt b--moon-gray"
      />
    </>
  );
};

export default EditDatatable;
