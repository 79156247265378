// @ts-nocheck: converted from JS

import { pad } from '@/helpers';
import type { Animal } from '@/model/Animal.model';

export const repeatIdentifersAcrossCages = (animals: Array<Animal>, accessor = 'name', ids) => {
  const rows = [];
  const cageCountRecord = {};
  for (let index = 0; index < animals.length; index++) {
    const animal = animals[index];
    if (!cageCountRecord[animal.cage.id]) {
      cageCountRecord[animal.cage.id] = 0;
    }
    if (ids[cageCountRecord[animal.cage.id]]) {
      rows.push([index, accessor, ids[cageCountRecord[animal.cage.id]]]);
    }
    cageCountRecord[animal.cage.id]++;
  }
  return rows;
};

export const nameAnimalsInSequence = (animals = [], accessor = 'name', prefix, countFrom) => {
  const rows = [];
  for (let index = 0; index < animals.length; index++) {
    rows.push([index, accessor, `${prefix}${pad(Number(countFrom) + index, String(countFrom).length)}`]);
  }
  return rows;
};
