import type { StudyApiId } from '@/model/Study.model';
import { ApiService } from '@/support/ApiService';
import type { AltId, Animal, AnimalApiId } from '@/model/Animal.model';
import { _isNotEmpty } from '@/littledash.ts';

export function checkStudyForDuplicates(
  value: string,
  altIdType: AltId,
  studyApiId: StudyApiId,
  animalApiId: AnimalApiId
): Promise<boolean> {
  return ApiService.call({
    endpoint: 'POST /api/v1/studies/{studyId}/animals/{animalId}/{altIdType}/verify-unique',
    path: { studyId: studyApiId, animalId: animalApiId, altIdType },
    body: { alt_id_value: value },
  }).then((result) => result?.body?.success === true);
}

export const HoTValidators = {
  notEmpty: (value: unknown, callback: (check: boolean) => void) => callback(!!value),
};

export const HoTcolumns = [
  {
    title: 'Cage',
    data: 'cage.name',
    readOnly: true,
  },
  {
    title: 'Name',
    data: 'name',
    validator: HoTValidators.notEmpty,
  },
  {
    title: 'Tail',
    data: 'alt_ids.tail',
  },
  {
    title: 'Ear',
    data: 'alt_ids.ear',
  },
  {
    title: 'Tag',
    data: 'alt_ids.tag',
  },
  {
    title: 'Donor',
    data: 'alt_ids.donor',
  },
];

// Should be exported to the API as a SSOT
export const identifierTypes: Array<AltId> = ['tail', 'ear', 'tag', 'donor'];

export const filterEmptyAltIds = (animals: Array<Animal>) => {
  return animals.map((animal) => ({
    ...animal,
    alt_ids: _isNotEmpty(animal.alt_ids) ? animal.alt_ids : null,
  }));
};
