import Button from '@/components/UI/Button/Button';
import { Select, Text } from '@/components/UI/FormFields';
import { NumberField } from '@/components/UI/FormFields/Number';
import { errorToast, successToast } from '@/helpers';
import { _isNumber } from '@/littledash';
import type { RegisteredDevice } from '@/model/Device.model';
import {
  flowControlOptions,
  parityOptions,
  readingTypeOptions,
  terminationCharacterOptions,
} from '@/support/Devices/DeviceUtils';
import { Port } from '@/support/Devices/Serial/port';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import type { components } from '@/generated/internal-api/openapi-types';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import { useStore } from 'react-redux';
import { State } from '@/model/State.model';
import { createSelector } from '@reduxjs/toolkit';
import { selectFeatures } from '@/reducers/team';

interface EditDeviceProps {
  closeModal: () => void;
  onEditDevice: () => void;
  device: RegisteredDevice;
}

export const EditDevice: FC<EditDeviceProps> = ({ onEditDevice, closeModal, device }) => {
  const defaultValues = useMemo(
    () => ({
      title: device.title,
      device_type_api_id: device.device_type_api_id,
      usb_vendor_id: device.usb_vendor_id,
      usb_product_id: device.usb_product_id,
      vendor_name: device.vendor_name,
      product_name: device.product_name,
      baud_rate: device.baud_rate ?? 9600,
      data_bits: device.data_bits,
      stop_bits: device.stop_bits,
      parity: device.parity ?? parityOptions[0].value,
      flow_control: device.flow_control ?? flowControlOptions[0].value,
      termination_character: device.termination_character ?? terminationCharacterOptions[0].value,
      reading_type: device.reading_type ?? readingTypeOptions[0].value,
    }),
    [device]
  );

  const selector = createSelector([selectFeatures], (features) => features);

  const store = useStore();
  const features = selector(store.getState() as State);

  const readingTypeOptionsContext = useMemo(() => {
    return !features.device_trim_channel
      ? readingTypeOptions.filter((option) => option.value !== 'trim_channel')
      : readingTypeOptions;
  }, [features.device_trim_channel]);

  const formMethods = useForm({
    defaultValues,
  });
  const { handleSubmit, setValue } = formMethods;
  const { response: deviceTypes, loading: fetchDeviceTypesLoading } = useApiHook({
    endpoint: 'GET /api/v1/device-types',
  });
  const deviceTypeOptions = useMemo(() => {
    if (deviceTypes?.type === 'success') {
      return (deviceTypes.body.data ?? []).map((type) => ({
        label: type.title,
        value: type.id,
      }));
    }
    return [];
  }, [deviceTypes]);

  const { invoke: updateDevice, loading: updateDeviceLoading } = useApiHook({
    endpoint: 'PATCH /api/v1/devices/{deviceId}',
    invokeOnInit: false,
  });

  const onSubmit = async (formData: components['schemas']['RegisteredDeviceCreateV1.schema']) => {
    try {
      await updateDevice({ path: { deviceId: device.id }, body: formData });
      onEditDevice();
      closeModal();
      successToast(`Edited ${formData.title}.`);
    } catch (error) {
      // error toast generated by `updateDevice`
    }
  };

  const requestDevice = async () => {
    const port = new Port();
    try {
      const requestPort = await port.requestPort({ filters: [] });
      await port.open();
      const getInfo = requestPort.getInfo();
      if (
        getInfo?.usbProductId &&
        getInfo?.usbVendorId &&
        _isNumber(getInfo.usbProductId) &&
        _isNumber(getInfo.usbVendorId)
      ) {
        setValue('usb_vendor_id', getInfo.usbVendorId);
        setValue('usb_product_id', getInfo.usbProductId);
        successToast('Successfully detected USB Vendor and USB Product ID');
      } else {
        errorToast('Unable to get USB Vendor ID and USB Product ID');
      }
    } catch (error: any) {
      errorToast('There was a problem connecting to the device.');
    } finally {
      await port.close();
    }
  };

  return (
    <ModalContainer size="small">
      <ModalHeader
        title="Edit device"
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
        subText={'Enter the device configuration.'}
        readMoreLink="https://help.benchling.com/hc/en-us/articles/22038980333325"
      />
      <FormProvider {...formMethods}>
        <div className="pv3 ph2">
          <div className="mb3 ph2">
            <Text name="title" label="Device name" required autoFocus />
          </div>
          <div className="mb3 ph2">
            {!fetchDeviceTypesLoading && (
              <Select name="device_type_api_id" label="Device type" options={deviceTypeOptions} required={false} />
            )}
          </div>
          <div className="flex mb3">
            <div className="w-25 ph2">
              <NumberField name="usb_vendor_id" label="USB Vendor ID" min={0} />
            </div>
            <div className="w-75 ph2">
              <Text name="vendor_name" label="Vendor name" />
            </div>
          </div>
          <div className="flex mb3">
            <div className="w-25 ph2">
              <NumberField name="usb_product_id" label="USB Product ID" min={0} />
            </div>
            <div className="w-75 ph2">
              <Text name="product_name" label="Product name" />
            </div>
          </div>
          <div className="mb3 ph2">
            <NumberField name="baud_rate" label="Baud rate" min={0} value={9600} required />
          </div>
          <div className="flex">
            <div className="mb3 w-50 ph2">
              <NumberField name="data_bits" label="Data bits" min={5} max={8} />
            </div>
            <div className="mb3 w-50 ph2">
              <NumberField name="stop_bits" label="Stop bits" min={1} max={2} />
            </div>
          </div>
          <div className="mb3 w-100 ph2">
            <Select name="parity" label="Parity" options={parityOptions} required={true} />
          </div>
          <div className="mb3 w-100 ph2">
            <Select name="flow_control" label="Flow control" options={flowControlOptions} required={true} />
          </div>
          <div className="flex flex-column">
            <div className="mb3 w-50 ph2">
              <Select
                name="termination_character"
                label="Termination character"
                options={terminationCharacterOptions}
                required={true}
              />
            </div>
            <div className="mb3 w-50 ph2">
              <Select name="reading_type" label="Response type" options={readingTypeOptionsContext} required={true} />
            </div>
          </div>
        </div>
      </FormProvider>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        submitBtnText="Save"
        cancelBtnText="Cancel"
        submitButtonProps={{
          disabled: fetchDeviceTypesLoading || updateDeviceLoading,
          loading: fetchDeviceTypesLoading || updateDeviceLoading,
        }}
        asideComponent={<Button onClick={requestDevice}>Auto-detect</Button>}
      />
    </ModalContainer>
  );
};
