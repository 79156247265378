// @ts-nocheck: converted from JS

import Radio from '@/components/UI/FormElements/Radio';
import * as Form from '@/support/form';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import _map from 'lodash/map';
import React from 'react';

class UpdateSex extends React.Component {
  defaultFields = {};
  validationSchema = {};

  validate = Form.validate.bind(this);
  handleInput = Form.handleInput.bind(this);
  resetFields = Form.resetFields.bind(this);

  constructor(props) {
    super(props);
    this.defaultFields = this.getDefaultFields();
    this.state = {
      sex: '',
      error: null,
      isLoading: false,
      fields: Form.fieldObject(this.defaultFields),
      errors: Form.errorObject(this.defaultFields),
    };
  }

  /**
   * Rendering
   */

  render() {
    const what = Array.isArray(this.props.subject) && this.props.subject.length > 1 ? 'animals' : 'animal';
    const whatDemonstrative = what === 'animals' ? 'these' : 'this';
    const message = `Update sex of ${whatDemonstrative} ${what}?`;

    return (
      <form onSubmit={this.submitForm} className="ui__narrow-modal">
        <div className="mb3 tc">
          <h3 className="normal f4 pb2">{message}</h3>
        </div>
        {this.state.error && <p className="red">{this.state.error}</p>}
        <div className="mb3">
          <Radio
            checked={this.state.sex === 'm'}
            onChange={() => this.setState({ sex: 'm' })}
            id={'SexM'}
            name={'sex'}
            label={'Male'}
            value={'m'}
            className="mb2"
          />
          <Radio
            checked={this.state.sex === 'f'}
            onChange={() => this.setState({ sex: 'f' })}
            id={'SexF'}
            name={'sex'}
            label={'Female'}
            value={'f'}
          />
        </div>
        <div>
          <button type="submit" disabled={this.state.isLoading} className="button w-100">
            Update sex
          </button>
          <a className="button plain db w-100 mt2" onClick={this.props.closeModal}>
            Cancel
          </a>
        </div>
      </form>
    );
  }

  /**
   * Methods
   */
  submitForm = (e) => {
    e.preventDefault();
    if (this.validate()) {
      this.setState({ isLoading: true });
      this.bulkUpdate(Array.isArray(this.props.subject) ? this.props.subject : [this.props.subject])
        .then(() => this.props.handleCallback('Successfully updated sex'))
        .catch((e) => this.setState({ error: e.response.data.message, isLoading: false }));
    }
  };

  bulkUpdate = (subjects) => {
    const animalIds = subjects.map((s) => s._id || s.id);

    return Http.patch(apiRoute('animals.sex.bulk.update'), { animal_ids: animalIds, sex: this.state.sex });
  };

  getDefaultFields = () => {
    const acc = [];
    _map(this.props.subject, (s, i) => {
      acc[s._id || s.id] = s.sex;
    });
    return acc;
  };
}

export default UpdateSex;
