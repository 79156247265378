import { FormField } from '@/components/UI/FormFields';
import { preventNumberScroll } from '@/helpers';
import { _get } from '@/littledash';
import React, { CSSProperties, InputHTMLAttributes, useEffect, useRef } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import type { Validate, ValidationRule } from 'react-hook-form';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { getRegisterConfig } from './FormField.utils';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange?: () => void;
  onBlur?: () => void;
  style?: CSSProperties;
  className?: string;
  defaultValue?: number;
  testId?: string;
  id?: string;
}

export const NumberInput: React.FC<InputProps> = ({
  onChange,
  onBlur,
  style,
  className = '',
  disabled = false,
  value = '',
  step = 'any',
  placeholder,
  autoFocus,
  testId,
  id,
}) => {
  const quantityInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    // Remove the scroll wheel on numeric input
    const ignoreScroll = (e: WheelEvent): void => e.preventDefault();
    quantityInputRef.current && quantityInputRef.current.addEventListener('wheel', ignoreScroll);
  }, [quantityInputRef]);

  return (
    <input
      ref={quantityInputRef}
      id={id}
      type="number"
      onWheel={preventNumberScroll}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      placeholder={placeholder}
      className={className}
      value={value}
      disabled={disabled}
      step={step}
      autoFocus={autoFocus}
      data-test-component="Number"
      data-test-element="input"
      data-testid={testId}
    />
  );
};
interface FieldProps {
  value?: number;
  name: string;
  label: string;
  disabled?: boolean;
  hint?: string;
  required?: ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  min?: ValidationRule<number>;
  max?: ValidationRule<number>;
  step?: string;
  placeholder?: string;
  autoFocus?: boolean;
  customRules?: Record<string, Validate>;
  testId?: string;
}

export const NumberField: React.FC<FieldProps> = ({
  value,
  name,
  label,
  disabled = false,
  hint,
  required,
  pattern,
  min,
  max,
  step = 'any',
  placeholder,
  autoFocus,
  customRules,
  testId = 'number',
}) => {
  const { control, errors } = useFormContext();
  const rules = getRegisterConfig({ required, pattern, min, max });
  const isError = _get(errors, name);

  return (
    <FormField label={label} name={name} hint={hint} required={required} testPrefix={testId}>
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={{ ...rules, validate: { ...rules.validate, ...customRules } }}
        render={({ onChange, value }: ControllerRenderProps) => (
          <NumberInput
            id={name}
            onChange={onChange}
            value={value}
            step={step}
            className={isError ? 'input__error' : ''}
            disabled={disabled}
            style={{ marginBottom: 0 }}
            placeholder={placeholder}
            autoFocus={autoFocus}
            testId={testId}
          />
        )}
      />
    </FormField>
  );
};
