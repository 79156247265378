// @ts-nocheck: converted from JS

import DatePickerNative from '@/components/UI/DatePickerNative';
import type { StudyCode } from '@/model/StudyCode.model';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import ProjectsField from './ProjectsField';
import { StudyCodeLookup } from './StudyCodeLookup';

const Information = ({ state, dispatch, settings }) => {
  const { register, control, errors } = useFormContext();

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);
  return (
    <div data-testid="information-settings__container">
      <div className="mb4">
        <h3 className="fw5 f5 pb2" data-testid="information-settings__title">
          Information
        </h3>
      </div>
      <div className="ui-card mw7">
        <div className="pa4">
          <div>
            <div className="mb3">
              <label htmlFor="name">Study name (required)</label>
              <input
                name="name"
                type="text"
                style={{ marginBottom: 0 }}
                className={`${errors?.name ? 'input__error' : ''}`}
                autoFocus
                data-testid="study-name-field"
                ref={register({
                  minLength: {
                    value: 3,
                    message: 'Enter a study name that is longer than 3 characters',
                  },
                  maxLength: {
                    value: 255,
                    message: 'Study name must be less than 255 characters',
                  },
                  required: 'A study name is required',
                })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
            <div className="mb3">
              <label htmlFor="notes">Description</label>
              <textarea
                cols="30"
                rows="10"
                name="notes"
                style={{ marginBottom: 0, resize: 'vertical' }}
                className={`${errors.notes ? 'input__error' : ''}`}
                data-testid="study-description-field"
                ref={register({
                  maxLength: {
                    value: 2048,
                    message: 'Maximum of 2048 characters',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="notes"
                render={({ message }) => <p className="f6 red db pt2">{message}</p>}
              />
            </div>
            <div className="mb3">
              <StudyCodeLookup
                settings={settings}
                updateStudyCode={(selectedStudyCode: StudyCode) =>
                  dispatch({ type: 'updateForm', data: { selectedStudyCode } })
                }
              />
            </div>
            <div className="mb3">
              <ProjectsField
                setProjectNameForSummary={(selectedProjectName) =>
                  dispatch({ type: 'updateForm', data: { selectedProjectName } })
                }
              />
            </div>
            <div>
              <label>Study start date</label>
              <Controller
                defaultValue={state?.study?.started_on}
                name={'started_on'}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({ onChange, value }: ControllerRenderProps) => (
                  <DatePickerNative
                    value={value}
                    style={{ width: '260px', marginBottom: 0 }}
                    onChange={onChange}
                    testId="study-start-date-field"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
