// @ts-nocheck: converted from JS
import Authenticated from '@/components/Authenticated';
import ErrorBoundary from '@/components/ErrorPage/ErrorBoundary';
import Intercom from '@/components/Intercom';
import Layout from '@/components/Layout';
import NotFound from '@/components/NotFound';
import ZendeskMessenger from '@/components/Zendesk/ZendeskMessenger';
import { loggedInRoutes, notLoggedInRoutes } from '@/routes';
import './sass/app.scss';
import store from '@/store';
import { AccountChecker } from '@/support/AccountChecker';
import { ApiIdMapper } from '@/support/ApiId';
import { BrowserHistoryEventBridge } from '@/support/BrowserHistoryEventBridge.tsx';
import { NetworkStatusDetector } from '@/support/NetworkStatusDetector.tsx';
import { UpdateChecker } from '@/support/UpdateChecker';
import { AlertContainer } from '@/utils/alerts/AlertContainer';
import { DevicesProvider } from '@/utils/devices/DevicesProvider';
import { FollowFocusConsumer } from '@/utils/devices/FollowFocusConsumer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <>
      <ReduxProvider store={store}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter basename="/">
            <BrowserHistoryEventBridge />
            <ErrorBoundary>
              <DevicesProvider>
                <FollowFocusConsumer />
                <Routes></Routes>
              </DevicesProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </DndProvider>
      </ReduxProvider>
      <ToastContainer position="bottom-left" transition={Flip} closeOnClick={true} icon={false} />
      <AlertContainer />
      <UpdateChecker />
      <NetworkStatusDetector />
    </>
  );
};

const Routes = () => {
  return (
    <Switch>
      {notLoggedInRoutes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
      {loggedInRoutes.map((route, index) => (
        <PrivateRoute path={route.path} name={route.name} exact={route.exact} key={`public-${index}`}>
          <ApiIdMapper>
            <AccountChecker currentPath={route.path}>
              <PrivateRender route={route} />
            </AccountChecker>
          </ApiIdMapper>
        </PrivateRoute>
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  const storageToken = window.localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        storageToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRender = ({ route, ...rest }) => {
  // @TODO remove need for history to be passed
  const history = useHistory();

  if (route.path === '/create-account') {
    return (
      <Authenticated globalData={false}>
        <route.component {...rest} page={route.name} />
      </Authenticated>
    );
  } else {
    return (
      <Authenticated>
        <Intercom>
          <Layout route={route}>
            <ZendeskMessenger />
            {route.disabled ? (
              <Redirect
                to={{
                  pathname: '/not-found',
                }}
              />
            ) : (
              <route.component {...rest} page={route.name} history={history} />
            )}
          </Layout>
        </Intercom>
      </Authenticated>
    );
  }
};

export default App;
