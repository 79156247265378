import Loading from '@/components/Loading';
import { _isNil } from '@/littledash';
import type { TaskApiId } from '@/model/Task.model';
import { usePrevious } from '@/support/Hooks';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useModalAction } from '@/utils/modal';
import { format, getDate, getMonth, isFirstDayOfMonth, isToday } from 'date-fns';
import _isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { getFilterQuery } from '../../Calendar.utils';
import MonthCard from './MonthCard';
import type { DayTask, MonthGridDayDateProps, MonthGridDayTaskListProps, MonthGridProps } from './MonthGrid.model';
import styles from './MonthGrid.module.scss';
import { DateUtils } from '@/utils/Date.utils';

export const MAX_TASKS_BEFORE_TRUNCATE = 3;

const MonthGrid = ({ filters, studyColours, year, month, handleGoToDay }: MonthGridProps): JSX.Element => {
  const query = useMemo(() => getFilterQuery(filters), [filters]);
  const path = useMemo(() => ({ year, month: month + 1 }), [year, month]); // +1 JS Month is an index starting at 0
  const prevQuery = usePrevious(query);
  const prevPath = usePrevious(path);

  const {
    response: monthTasks,
    loading: fetchingMonthTasks,
    invoke: getMonthTasks,
  } = useApiHook({
    endpoint: 'GET /api/v1/tasks/view/calendar-month/{year}/{month}',
    query,
    path,
  });
  const days = monthTasks?.type === 'success' ? monthTasks.body.days : {};

  useEffect(() => {
    if ((!fetchingMonthTasks && !_isEqual(query, prevQuery)) || !_isEqual(path, prevPath)) {
      getMonthTasks({ query, path });
    }
  }, [prevQuery, query, prevPath, path, fetchingMonthTasks]);

  if (_isNil(monthTasks)) {
    return <Loading />;
  }

  return (
    <div
      className={`w-100 h-100 ${fetchingMonthTasks ? 'ui__disabled' : ''}`}
      data-test-component="MonthGrid"
      data-test-element="container"
    >
      <div className={`w-100 h-100 overflow-auto ${styles['month-grid-container']}`}>
        {Object.keys(days).map((day, index) => {
          const startOfDayObject = new Date(DateUtils.startOfDay(day));
          return (
            <div
              key={day}
              className={`flex flex-column w-100 h-100 bb br b--moon-gray ${styles['month-grid-day']}`}
              data-test-component="MonthGrid"
              data-test-element="day"
              data-test-key={day}
              data-testid="month-grid-day"
            >
              <MonthGridDayDate
                date={startOfDayObject}
                firstRow={index < 7}
                highlight={getMonth(startOfDayObject) !== month}
                isToday={isToday(startOfDayObject)}
                onClick={() => handleGoToDay(startOfDayObject)}
              />
              <MonthGridDayTaskList
                tasks={days?.[day].tasks ?? []}
                totalTaskCount={days?.[day]?.totalTaskCount}
                studyColours={studyColours}
                onViewMoreClick={() => handleGoToDay(startOfDayObject)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const MonthGridDayDate = ({
  date,
  firstRow,
  highlight,
  isToday,
  onClick,
}: MonthGridDayDateProps): JSX.Element => (
  <div
    className={`tc pa1 ${highlight ? 'o-70' : ''} ${onClick ? 'pointer dim' : ''}`}
    onClick={onClick}
    data-test-component="MonthGridDayDate"
    data-test-element="container"
    data-testid="date-container"
  >
    <div>
      {firstRow && (
        <h3
          className={`${styles['date-weekday-header']} ttu fw5 f7 lh-title`}
          data-test-component="MonthGridDayDate"
          data-test-element="day-of-week"
          data-testid="date-weekday-header"
        >
          {format(date, 'EEE')}
        </h3>
      )}
    </div>
    <div
      className={`flex items-center ma0-auto ${isFirstDayOfMonth(date) ? 'w3 br-pill' : 'w2 br-100'} h2 ${
        isToday ? 'bg-blue' : ''
      }`}
      data-test-component="MonthGridDayDate"
      data-test-element="day-date"
      data-testid="date-day"
    >
      <h4 className={`w-100 fw5 f7 lh-title ${isToday ? 'white' : ''}`} data-testid="date-day-text">
        {getDate(date)} {isFirstDayOfMonth(date) && format(date, 'MMM')}
      </h4>
    </div>
  </div>
);

export const MonthGridDayTaskList = ({
  tasks,
  totalTaskCount,
  studyColours,
  onViewMoreClick,
}: MonthGridDayTaskListProps): JSX.Element => {
  const [updatedTasks, setUpdatedTasks] = useState<Record<TaskApiId, DayTask>>({});
  const { openModal } = useModalAction();
  const handleTaskUpdate = (task: DayTask) => {
    setUpdatedTasks({ ...updatedTasks, [task.id]: task });
  };

  return (
    <div
      className="flex flex-column justify-between h-100"
      data-test-component="MonthGridDayTaskList"
      data-test-element="container"
    >
      <div>
        {tasks.slice(0, MAX_TASKS_BEFORE_TRUNCATE).map((task, index) => (
          <div
            key={task.id}
            className={`mh1 ${index !== 0 ? 'mt1' : ''}`}
            data-test-component="MonthGridDayTaskList"
            data-test-element="task-card"
            data-test-key={task.id}
            data-testid="task-list-cards"
          >
            <MonthCard
              task={updatedTasks?.[task.id] ?? task}
              colours={studyColours?.[task.study.api_id]}
              onClick={() => openModal('TASK_DETAILS', { taskId: task.id, onTaskUpdate: handleTaskUpdate })}
            />
          </div>
        ))}
      </div>
      {tasks.length > MAX_TASKS_BEFORE_TRUNCATE && (
        <div
          onClick={onViewMoreClick}
          className={`${styles['truncated-task-text']} blue link lh-title f7 ma1`}
          data-test-component="MonthGridDayTaskList"
          data-test-element="more-tasks"
          data-testid="test-list-truncated-link"
        >
          + {totalTaskCount - MAX_TASKS_BEFORE_TRUNCATE} more
        </div>
      )}
    </div>
  );
};

export default MonthGrid;
