import apiRoutes from '@/constants/apiRoutes.json';
import type { Endpoints, HttpMethod } from '@/generated/internal-api/Endpoints';
import { endpointPattern, hydrateUrlTemplate, updateQueryParams } from './RouteService.util';

type GenericQueryParameters = Record<string, string | number | boolean | Array<string | number | boolean>>;

interface ApiRouteProps<Endpoint extends keyof Endpoints> {
  endpoint: Endpoint;
  path: Endpoints[Endpoint]['path'];
  query?: Endpoints[Endpoint]['query'] extends undefined ? GenericQueryParameters : Endpoints[Endpoint]['query'];
}

interface LegacyRouteProps {
  apiRoute: string;
  path?: Record<string, string | number>;
  query?: GenericQueryParameters;
}

interface ApiRoute {
  method: HttpMethod;
  url: URL;
}

export class RouteService {
  static api<Endpoint extends keyof Endpoints>({ endpoint, path, query }: ApiRouteProps<Endpoint>): ApiRoute {
    const { method, path: templateUrl } = (endpointPattern.exec(endpoint)?.groups ?? {}) as {
      method: HttpMethod;
      path: string;
    };
    const url = new URL(
      hydrateUrlTemplate(templateUrl, path ?? {}),
      RouteService.#absoluteURL(AppConfig.internalApiUrl)
    );
    updateQueryParams(url.searchParams, query);
    return { method, url };
  }

  static legacyApi({ apiRoute, path, query }: LegacyRouteProps): Omit<ApiRoute, 'method'> {
    const url = new URL(
      hydrateUrlTemplate((apiRoutes as Record<string, string>)?.[apiRoute] ?? '', path ?? {}),
      RouteService.#absoluteURL(AppConfig.legacyInternalApiUrl)
    );
    updateQueryParams(url.searchParams, query);
    return { url };
  }

  static #absoluteURL(value: string): string {
    return value.endsWith('/') ? value : `${value}/`;
  }
}
