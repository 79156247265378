// @ts-nocheck: converted from JS

import { _isEmpty } from '@/littledash';
import { PercentChangeOptions } from './SetupWorkflow.model';

export const assembleInitialWorkflow = (calculations = [], workflow) => {
  const measurementChildren = calculations
    .filter((c) => !_isEmpty(c.measurements))
    .map((c) => ({
      id: c.id,
      name: c.id,
      displayName: c.name,
      value: true,
    }));

  if (!_isEmpty(workflow)) {
    return workflow;
  }

  return [
    {
      name: 'measurements',
      displayName: 'Measurements',
      value: !_isEmpty(calculations),
      canReorder: true,
      children: measurementChildren,
      onSave: 'focus-search',
      disabled: _isEmpty(calculations),
      soundOnMeasurementRecord: true,
      percentChange: { weight: PercentChangeOptions.fromFirstMeasurement }, // all presets default with a `weight` calculation
    },
    {
      name: 'samples',
      displayName: 'Samples',
      value: true,
      autoGenerateSampleIds: true,
      children: [],
      onSave: 'focus-search',
      disabled: false,
    },
    {
      name: 'observations',
      displayName: 'Observations',
      value: true,
      onSave: 'focus-search',
      disabled: false,
    },
    {
      name: 'dosing',
      displayName: 'Dosing',
      value: true,
      onSave: 'focus-search',
      disabled: false,
      takeWeight: false,
      skipDosing: false,
    },
  ];
};

export const assembleToggleOptions = (checks, activeCheck) =>
  checks?.reduce((acc, { value, name }) => {
    if (value && activeCheck !== name) {
      acc[name] = {
        value: `toggle-${name}`,
        name: `Toggle ${name}`,
      };
    }

    return acc;
  }, {});
