import { identifierTypes } from '@/components/Modals/AssignIdentifiers/AssignIdentifiers.utils';
import { PercentChangeOptions } from '@/components/Modals/SetupWorkflow/SetupWorkflow.model';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import List from '@/components/UI/List';
import { asSortableListItem } from '@/components/UI/List/List';
import type { WorkflowModel, WorkflowModelChild } from '@/components/Workflow/Show/Workflow.model';
import type { State } from '@/model/State.model';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { assembleToggleOptions } from '../SetupWorkflow.utils';

// measurements is always index 0 in workflow,
// think about refactor into a simple object/key later
const measurementsPathInFormState = 'workflowSetup.workflow[0]';

const WorkflowMeasurements = () => {
  const soundOnMeasurementRecordEnabled = useSelector(
    (state: State) => state?.team?.features?.workflow_measurement_audible_notification ?? false
  );
  const { control, watch, setValue, register } = useFormContext<WorkflowModel>();
  const { fields, swap: swapFormItems } = useFieldArray<WorkflowModelChild, 'fieldId'>({
    control,
    keyName: 'fieldId',
    name: `${measurementsPathInFormState}.children`,
  });

  const assignAltId = watch(`${measurementsPathInFormState}.assignAltId`);

  // There should be a better way to handle how we generate the after saving options,
  // the toggled state on `toggleOptions` is no longer required or to be stored in state
  // left as is for now for the sake of unit tests and other views working
  const workflowWatch = watch('workflowSetup.workflow');
  const toggleOptions = assembleToggleOptions(workflowWatch, 'measurements');

  const toggleAllMeasurements = (selected = true) => {
    fields.forEach((_: any, index: any) => {
      setValue(`${measurementsPathInFormState}.children[${index}].value`, selected);
    });
  };

  return (
    <>
      <div className="overflow-auto pa3" style={{ height: 375 }}>
        <div className="pb3">
          <a className="link blue f7 dib" onClick={() => toggleAllMeasurements()}>
            Select all
          </a>
          <a className="link blue f7 dib ml3" onClick={() => toggleAllMeasurements(false)}>
            Clear selection
          </a>
        </div>
        <List
          canAmend={false}
          canAdd={false}
          canRemove={false}
          data={fields}
          renderItem={asSortableListItem({
            canSort: true,
            sortableType: 'workflow-setup-measurement',
            moveCallback: swapFormItems,
          })(FormItem)}
        />
      </div>
      {soundOnMeasurementRecordEnabled && (
        <div className="pa3 bt b--moon-gray flex justify-between items-center">
          <label className="mb0 f6">Play sound on measurement record</label>
          <Controller
            defaultValue={true}
            name={`${measurementsPathInFormState}.soundOnMeasurementRecord`}
            control={control}
            render={({ onChange, value }: ControllerRenderProps) => (
              <div className="flex justify-between items-center">
                <Checkbox checked={value} onChange={() => onChange(!value)} />
              </div>
            )}
          />
        </div>
      )}
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">Weight percentage change from</label>
        <select className="mw5 mb0" name={`${measurementsPathInFormState}.percentChange.weight`} ref={register()}>
          <option value={PercentChangeOptions.fromFirstMeasurement}>First measurement</option>
          <option value={PercentChangeOptions.fromLatestMeasurement}>Latest measurement</option>
          <option value={PercentChangeOptions.trackingDate}>Tracking date</option>
        </select>
      </div>
      <div style={{ height: '12%' }} className="pa3 bt b--moon-gray flex justify-between items-center">
        <Controller
          defaultValue={false}
          name={`${measurementsPathInFormState}.assignAltId`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Checkbox
              checked={value}
              onChange={({ target: { checked } }) => onChange(checked)}
              label="Assign identifiers when taking measurements"
            />
          )}
        />
        {assignAltId ? (
          <Controller
            defaultValue={identifierTypes[0]}
            name={`${measurementsPathInFormState}.altIdToAssign`}
            control={control}
            render={({ value, onChange }: ControllerRenderProps) => (
              <select className="mw5 mb0" value={value} onChange={onChange}>
                {identifierTypes.map((type) => (
                  <option key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </option>
                ))}
              </select>
            )}
          />
        ) : null}
      </div>
      <div className="pa3 bt b--moon-gray flex justify-between items-center">
        <label className="mb0 f6">After saving</label>
        <Controller
          defaultValue="focus-search"
          name={`${measurementsPathInFormState}.onSave`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <select className="mw5 mb0" value={value} onChange={onChange}>
              <option value="focus-search">Focus search bar</option>
              {Object.keys(toggleOptions).map((k) => (
                <option key={k} value={toggleOptions[k].value}>
                  {toggleOptions[k].name}
                </option>
              ))}
              <option value="next-subject">Move to next animal</option>
            </select>
          )}
        />
      </div>
    </>
  );
};

interface FormItemProps {
  fieldId: string;
  id: string;
  displayName: string;
  value: unknown;
  index: number;
}

const FormItem = ({ fieldId, id, displayName, value, index }: FormItemProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      key={fieldId}
      defaultValue={value}
      name={`${measurementsPathInFormState}.children[${index}].value`}
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div className="flex justify-between items-center">
          <Checkbox
            className={`pointer ${index !== 0 ? 'pv1' : ''}`}
            label={displayName}
            name={id}
            checked={value}
            onChange={() => onChange(!value)}
          />
        </div>
      )}
    />
  );
};

export default WorkflowMeasurements;
