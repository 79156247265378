// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import { NoDataMessage } from '@/components/NoDataCard';
import GroupLabel from '@/components/UI/GroupLabel';
import Header from '@/components/UI/Header';
import Spinner from '@/components/UI/Spinner';
import SubHeader from '@/components/UI/SubHeader';
import { successToast } from '@/helpers';
import { _isEmpty, uuid } from '@/littledash';
import { api as apiRoute, web as webRoute } from '@/support/route';
import Http from '@/support/http';
import { ErrorMessage } from '@hookform/error-message';
import { Fragment, useEffect, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RIEInput } from 'riek';
import {
  chunkGroups,
  generateRepeatingIds,
  generateSequentialIds,
  groupAndSortAnimals,
  ID_METHODS,
  REPEATING,
  SEQUENTIAL,
} from './Recaging.utils';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { modalAction } from '@/utils/modal.tsx';
import { RouteService } from '@/support/RouteService';
import { StudyMetrics } from '@/model/Study.model.ts';

const TH_CLASSES = 'f7 f6-l ph2 pv2 ph3-l pv3-l b--silver near-black basier-med normal';
const TD_CLASSES = 'f7 f6-l ph2 pv1 ph3-l pv2-l bt';

const ProposedCage = ({ cage, treatmentGroup, animals, index: cageIndex, onChangeAnimalId, onChangeCageName }) => {
  return (
    <div className="ui-card mv4">
      <div className="flex flex-row pa3 justify-between items-center bb b--moon-gray">
        <div
          className="basier-med near-black normal f5 lh-solid dib v-mid"
          data-tooltip-id="global-tooltip-id"
          data-tooltip-content="Click to rename"
        >
          <RIEInput
            value={cage}
            change={(value) => {
              onChangeCageName(value.text.trim().length > 0 ? value.text : cage, cageIndex);
            }}
            propName="text"
            classInvalid="ui__error"
            className="f4 ui__editable"
          >
            EDIT
          </RIEInput>
        </div>
        <div>
          <GroupLabel group={treatmentGroup} />
        </div>
      </div>

      <table className="w-100 tl bt b--moon-gray" style={{ borderSpacing: 0 }}>
        <thead className="bg-near-white">
          <tr>
            <th className={TH_CLASSES}>Old Animal ID</th>
            <th className={TH_CLASSES}>Old Cage ID</th>
            <th className={TH_CLASSES}>New Animal ID</th>
          </tr>
        </thead>
        <tbody>
          {animals.map(({ id, name, cage: { name: cageName }, newAnimalId }, animalIndex) => (
            <tr key={id}>
              <td className={TD_CLASSES}>{name}</td>
              <td className={TD_CLASSES}>{cageName}</td>
              <td className={TD_CLASSES}>
                <input
                  value={newAnimalId}
                  onChange={(event) => {
                    onChangeAnimalId(event.target.value, cageIndex, animalIndex);
                  }}
                  propName="text"
                  classInvalid="ui__error"
                  className="f4 ui__editable"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Recaging = () => {
  const { id: studyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [proposedCages, setProposedCages] = useState([]);
  const [idGenerationMethod, setIdGenerationMethod] = useState(SEQUENTIAL);
  const [nextCage, setNextCage] = useState<number>();
  const history = useHistory();
  const [deleteCages, setDeleteCages] = useState(false);
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const { handleSubmit, register, errors, getValues } = useForm({
    defaultValues: { per_cage: 5 },
  });

  const selectedSubjects = useSelector((state) => state.subjects.selectedSubjects);

  useEffect(() => {
    const { url } = RouteService.legacyApi({
      apiRoute: 'studies.metrics',
      path: { id: studyId },
      query: { include: 'counts' },
    });

    Http.get<{ data: StudyMetrics }>(url.href)
      .then(({ data }) => {
        setNextCage(data.data.counts.cages_with_trashed + 1);
      })
      .catch((error) => {
        setApiError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onRecageSubmit = (deleteCages: boolean): void => {
    if (deleteCages) {
      openModal('CONFIRM_DELETE_EMPTY_COLLECTIONS', {
        onClick: submitRecaging,
        closeModal,
      });
    } else {
      submitRecaging();
    }
  };

  const onChangeCageName = (newCageName, cageIndex) => {
    const update = [...proposedCages];
    update[cageIndex].cage = newCageName;
    setProposedCages(update);
  };

  const onChangeAnimalId = (newId, cageIndex, animalIndex) => {
    const update = [...proposedCages];
    update[cageIndex].animals[animalIndex].newAnimalId = newId;
    setProposedCages(update);
  };

  const submitRecaging = () => {
    setLoading(true);
    const data = {
      study_id: studyId,
      cages: proposedCages.map((cage) => ({
        name: cage.cage,
        animals: cage.animals.map(({ id, newAnimalId, name }) => ({
          id,
          name: newAnimalId || name,
        })),
      })),
      delete_empty_cages: deleteCages,
    };
    Http.post(apiRoute('recaging'), data)
      .then(() => {
        successToast('Successfully recaged');
        history.push(webRoute('studies.animals', { id: studyId }));
      })
      .catch((error) => {
        setApiError(error);
        setLoading(false);
      });
  };

  const recage = () => {
    const { per_cage } = getValues();
    const cages = [];
    const groupedAndSortedCages = groupAndSortAnimals(selectedSubjects);

    let cageNumber = nextCage;
    groupedAndSortedCages.forEach(({ group, animals }) => {
      chunkGroups(animals, per_cage).forEach((chunk) => {
        cages.push({
          id: uuid(),
          cage: `Cage ${cageNumber}`,
          treatmentGroup: group,
          animals: chunk,
        });
        cageNumber++;
      });
    });

    setProposedCages(cages);
  };
  const changeIdGenerationMethod = (event) => {
    setIdGenerationMethod(event.target.value);
  };
  const generateIds = () => {
    let update = [];
    if (idGenerationMethod === REPEATING) {
      update = generateRepeatingIds(proposedCages);
    }
    if (idGenerationMethod === SEQUENTIAL) {
      update = generateSequentialIds(proposedCages);
    }
    setProposedCages(update);
  };

  return (
    <div className="h-100">
      <SubHeader linkToText="Back to study" link={webRoute('studies.show', { id: studyId })} />

      <div className="ph4 pb3">
        <Header mainHeaderText="Recaging" />
        <p className="lh-copy">
          Enter the number of animals to be placed in each new cage and visualize your results below.
          <a
            href="https://help.benchling.com/hc/en-us/articles/22269908325005"
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml2 link blue"
          >
            Read more.
          </a>
        </p>
        {apiError && <ApiErrorBanner errorType={ApiErrorType.FETCH} error={apiError} />}
        <form onSubmit={handleSubmit(recage)}>
          <div className="mv3">
            <div className="flex flex-row justify-between">
              <div>
                <label htmlFor="per_cage">Animals per cage</label>

                <div className="flex flex-row">
                  <div className="w3 mr3">
                    <input
                      name="per_cage"
                      type="text"
                      style={{ marginBottom: 0 }}
                      className={errors.per_cage ? 'input__error mr1' : ''}
                      ref={(e) => {
                        register(e, {
                          required: true,
                          validate: (value) => {
                            if (isNaN(value)) {
                              return false;
                            }
                            if (value <= 0) {
                              return 'Minimum animals per cage is 1';
                            }
                            if (value > 10) {
                              return 'Maximum animals  per cage is 10';
                            }
                          },
                        });
                      }}
                    />
                  </div>
                  <button
                    className="w-100 mb3 w-auto-ns mb0-ns plain"
                    type="submit"
                    disabled={!_isEmpty(errors) || _isEmpty(selectedSubjects) || loading}
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="per_cage">Auto generate IDs</label>

                <div className="flex flex-row">
                  <select
                    name="id_generation"
                    disabled={loading || _isEmpty(proposedCages)}
                    onChange={changeIdGenerationMethod}
                    value={idGenerationMethod}
                  >
                    {ID_METHODS.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <button
                    className="ml1 w-auto-ns mb0-ns plain"
                    onClick={generateIds}
                    type="button"
                    disabled={loading || _isEmpty(proposedCages) || _isEmpty(selectedSubjects)}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </div>
            <ErrorMessage
              errors={errors}
              name="per_cage"
              render={({ message }) => <p className="f6 red db pt2">{message}</p>}
            />
          </div>
        </form>

        {loading && (
          <div className="pa6 tc bg-white bb b--moon-gray">
            <div className="pa3 dib tc">
              <Spinner size="large" color="blue" className="dib" />
              <h3 className="lh-title f4 basier-reg normal pv3">Loading...</h3>
            </div>
          </div>
        )}
        {!loading && (
          <Fragment>
            {_isEmpty(proposedCages) ? (
              <div className="ui-card">
                <NoDataMessage
                  title="Recaging results will appear here"
                  text="Enter in the required number of animals per cage above and click generate to continue."
                />
              </div>
            ) : (
              proposedCages.map(({ id, cage, treatmentGroup, animals }, index) => {
                return (
                  <ProposedCage
                    key={id}
                    id={id}
                    index={index}
                    cage={cage}
                    treatmentGroup={treatmentGroup}
                    animals={animals}
                    onChangeAnimalId={onChangeAnimalId}
                    onChangeCageName={onChangeCageName}
                  />
                );
              })
            )}
          </Fragment>
        )}

        <div className="flex items-center w-100 mv3 w-auto-ns mb0-ns">
          <button disabled={loading || _isEmpty(proposedCages)} onClick={() => onRecageSubmit(deleteCages)}>
            Submit
          </button>

          <Checkbox
            className={'dib pl3'}
            type="checkbox"
            name="delete-cages"
            label="Delete empty cages in study"
            checked={deleteCages}
            onChange={({ target: { checked } }) => setDeleteCages(checked)}
          />
        </div>
      </div>
      <div className="ph3 ph4-l mb4"></div>
    </div>
  );
};

export default Recaging;
