import {
  checkCanTransitionTask,
  statusOptions,
  type TaskDetailsProps,
} from '@/components/Modals/TaskDetails/TaskDetails';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import SelectDropDown from '@/components/UI/SelectDropDown/SelectDropDown';
import UserAvatar from '@/components/UI/UserAvatar';
import { _isEmptyString, _isNil, _notNil } from '@/littledash';
import type { Study } from '@/model/Study.model';
import type { TaskStatus, TaskType, TaskV1 } from '@/model/Task.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { modalAction } from '@/utils/modal';
import type { FC } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Schedule.module.scss';

interface TaskProps {
  task: TaskV1;
  study: Study;
  onTaskUpdate: (task: TaskV1) => void;
  canTransition: boolean;
}

interface TaskStatusDropdownProps {
  status: TaskStatus;
  disabled: boolean;
  onChange: (status: TaskStatus) => Promise<void>;
}

export const taskTypes: Record<TaskType, string> = {
  measurement: 'Measurement',
  sample: 'Sample',
  observation: 'Observation',
  dosing: 'Dosing',
  other: 'Other',
};

const TaskStatusDropdown: FC<TaskStatusDropdownProps> = ({ status, disabled, onChange }) => {
  const [saving, setSaving] = useState(false);

  const changeStatus = (option: { value: TaskStatus; label: string }) => {
    if (_notNil(option) && option.value !== status && !disabled) {
      setSaving(true);
      onChange(option.value).finally(() => setSaving(false));
    }
  };

  const actions = Object.values(statusOptions).map((option) => ({
    name: option.label,
    action: () => changeStatus(option),
  }));

  return (
    <SelectDropDown
      title={statusOptions[status].label}
      disabled={disabled || saving}
      loading={saving}
      loadingText="updating"
      className={`${styles.taskStatusDropdown} f6 br-pill mr3`}
    >
      <ActionList actions={actions} />
    </SelectDropDown>
  );
};

export const TaskOverdue: FC = () => (
  <div className="ph2 pv1 mr2 bg-dark-red-05 br2 mr2">
    <p className="dark-red ttu tracked f6">Overdue</p>
  </div>
);

export const Task = ({ task, study, onTaskUpdate, canTransition }: TaskProps) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const { invoke: updateStatus } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/tasks/{taskId}/transition',
    path: { studyId: task.study.api_id, taskId: task.id },
    invokeOnInit: false,
  });

  const handleViewMoreClick = () => {
    const props: TaskDetailsProps = {
      taskId: task.id,
      onTaskUpdate,
      closeModal,
    };
    openModal('TASK_DETAILS', props);
  };
  const studyInActive = !(_isNil(study?.archived_at) && _isNil(study?.canceled_at));
  const handleTransition = async (status: TaskStatus) => {
    const result = await updateStatus({
      path: { studyId: task.study.api_id, taskId: task.id },
      body: { status },
    });
    if (result?.type === 'success') {
      onTaskUpdate(result.body);
    }
  };

  const taskTransitionDisabled = !checkCanTransitionTask(canTransition, task.assignees ?? []);

  return (
    <div className="ui-card" style={{ width: '650px' }}>
      <div className="flex pa3 justify-between-l bb b--moon-gray items-center">
        <div className="flex items-center">
          {task.overdue && <TaskOverdue />}
          <p className="fw5 f4 near-black">{task.title}</p>
        </div>
        <TaskStatusDropdown
          status={task.status}
          disabled={studyInActive || taskTransitionDisabled}
          onChange={handleTransition}
        />
      </div>
      <div className="pa3">
        <div className="flex items-center justify-between-l mb3">
          <p className="fw5 near-black">{taskTypes[task.type]}</p>
          <div>
            {task.assignees?.map(({ id, name }) => (
              <span className="mr1" key={id}>
                <UserAvatar user={{ name }} />
              </span>
            ))}
          </div>
        </div>
        {!_isEmptyString(task.description) && (
          <div className="mb3 bg-near-white pa2" style={{ borderRadius: '0 8px 8px 8px' }}>
            <p className="near-black f6">{task.description}</p>
          </div>
        )}
        <div className="mt3 mb2">
          <span className="link blue f6" style={{ textDecoration: 'underline' }} onClick={handleViewMoreClick}>
            View More →
          </span>
        </div>
      </div>
    </div>
  );
};
