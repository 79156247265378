import { eachDayOfInterval, format, isToday } from 'date-fns';
import { useMemo } from 'react';
import type { CalendarFilters, DateRange, StudyColours } from '../../Calendar.model';
import { getStartAndEndForDayView } from '../../Calendar.utils';
import DayList from '../Day/DayList';

interface WeekLanesProps {
  dateRange: DateRange;
  filters: CalendarFilters;
  studyColours: StudyColours;
  handleGoToDay: (date: Date) => void;
}

export const WeekLanes = ({ dateRange, filters, studyColours, handleGoToDay }: WeekLanesProps): JSX.Element => {
  const weekDates: Date[] = useMemo(() => eachDayOfInterval(dateRange), [dateRange]);
  return (
    <div className="flex justify-between w-100 h-100" data-test-component="WeekLanes" data-test-element="container">
      {weekDates.map((date, index) => (
        <div
          key={date.toISOString()}
          className={`flex flex-column w-100 h-100 ${index > 0 ? 'bl b--moon-gray' : ''}`}
          data-test-component="WeekLanes"
          data-test-element="day-column"
          data-test-key={date.toISOString().substring(0, 10)}
          data-testid="lane-container"
        >
          <LaneDate date={date} onClick={() => handleGoToDay(date)} />
          <DayList dateRange={getStartAndEndForDayView(date)} filters={filters} studyColours={studyColours} narrow />
        </div>
      ))}
    </div>
  );
};

export const LaneDate = ({ date, onClick }: { date: Date; onClick?: () => void }): JSX.Element => (
  <div
    className={`w-100 tc pa3 bb b--moon-gray ${onClick ? 'dim pointer' : ''}`}
    onClick={onClick}
    data-test-component="LaneDate"
    data-test-element="container"
    data-testid="lane-date-container"
  >
    <h4 className="ttu fw5 f6 lh-title pb2">{format(date, 'EEE')}</h4>
    <div
      className={`flex items-center ma0-auto br-100 w2 h2 ${isToday(date) ? 'bg-blue' : ''}`}
      data-testid={`${isToday(date) ? '' : 'not-'}today-date`}
    >
      <h3
        className={`f5 lh-title w-100 ${isToday(date) ? 'white' : ''}`}
        data-testid={`${isToday(date) ? '' : 'not-'}today-date-text`}
      >
        {format(date, 'd')}
      </h3>
    </div>
  </div>
);

export default WeekLanes;
