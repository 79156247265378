// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import ApprovalComments from '@/components/Approvals/ApprovalComments';
import ApprovalFormSummary from '@/components/Approvals/ApprovalFormSummary';
import ApprovalRequestDetails from '@/components/Approvals/ApprovalRequestDetails';
import { getApprovers } from '@/components/Approvals/Approvals.utils';
import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import { approvalStatusToChecklistStatusMap } from '@/components/Studies/Approval/Approval.utils';
import { ApprovalStatus } from '@/components/Studies/Show';
import Project from '@/components/Studies/Show/Project';
import Groups from '@/components/Studies/StudyGroups/RequestApprovals/Groups';
import Button from '@/components/UI/Button';
import SubHeader from '@/components/UI/SubHeader';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Status } from '@/referenceData/study/status';
import * as Auth from '@/support/auth';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoute } from '@/support/route';
import Attachments from '../Studies/Attachments/Attachments';

const ApprovalDetails = () => {
  const params = useParams();
  const { approvalRequestId, tab } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitApprovalError, setSubmitApprovalError] = useState();
  const { team, currentUser } = useSelector((state) => ({
    team: state.team?.team,
    currentUser: state.user?.currentUser,
  }));

  const {
    entity: approval,
    fetchEntity: fetchApproval,
    entityLoading: approvalLoading,
    entityUpdating: approvalUpdating,
    entityError: fetchApprovalError,
  } = useFetchEntity({
    entityType: 'requestApproval',
    params: { approvalRequestId },
    includes: 'study,study[users],project',
  });

  const tabState = useMemo(() => {
    if (_notNil(tab)) {
      const tabs = ['detail', ...(_isNil(approval?.project) ? [] : ['project']), 'groups', 'attachments', 'comments'];
      const selectedTab = tabs.indexOf(tab);
      if (selectedTab >= 0) {
        return selectedTab;
      }
    }
    return 0;
  }, [tab, approval?.project]);

  const {
    entity: comments,
    entityLoading: commentsLoading,
    fetchEntity: fetchComments,
    entityError: commentsError,
  } = useFetchEntity({
    entityType: 'approvalComments',
    params: { approvalId: approvalRequestId },
    queryParams: { perPage: -1 },
  });

  const loading = commentsLoading || approvalLoading;

  if ((fetchApprovalError || submitApprovalError) && _isEmpty(approval)) {
    return (
      <div className="w-100 h-100 flex items-center justify-center">
        <ApiErrorBanner
          className="mb4 mw6"
          title="There was an error retrieving review"
          text="An error has occurred retrieving review information, please try again later. If this keeps occurring please contact support."
          error={fetchApprovalError}
          altButtonFunc={() => history.push(webRoute('approvals'))}
          altButtonText="Back to reviews"
        />
      </div>
    );
  }

  if (loading || _isNil(approval) || _isNil(team) | _isNil(currentUser)) {
    return <Loading />;
  }

  const isTeamOwner = Auth.isCurrentTeamOwner();
  const isApprovalPending = approval.status === Status.pending;
  const hasResponded = Boolean(
    approval.approvers?.find((approver) => approver.id === currentUser.id)?.responded_at ||
      approval.required_approvers?.find((approver) => approver.id === currentUser.id)?.responded_at
  );
  const showApproveRejectButtons = isApprovalPending && (isTeamOwner || !hasResponded);

  const getAdditionalAppovers = () => {
    return (
      approval?.required_approvers?.map((approver) => ({
        name: approver.name,
        email: approver.email,
        status: approvalStatusToChecklistStatusMap[approver?.response] || 'idle',
      })) || []
    );
  };
  const approvers = getApprovers(approval, team);
  const additionalApprovers = getAdditionalAppovers();

  const ordered = approval.approval?.approvers?.type === 'ordered';

  const closeModal = () => dispatch({ type: 'CLOSE_MODAL' });

  const openConfirmModal = (status) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'CONFIRM_APPROVAL_STATUS',
      props: {
        approvalID: approval.id,
        approvers: [...approval.approvers, ...approval.required_approvers],
        status,
        callback: async (error) => {
          if (error) {
            setSubmitApprovalError(error);
          } else {
            await fetchApproval();
          }
        },
      },
    });
  };

  const openAddAdditionalReviewersModal = () => {
    dispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_ADDITIONAL_REVIEWERS',
      props: {
        approval,
        team,
        approvalRequestId,
        fetchApproval,
        closeModal,
      },
    });
  };

  const approveRejectButtons = (
    <span>
      <Button className="mr3" testId="approval-complete" onClick={() => openConfirmModal('approve')}>
        Complete
      </Button>
      <Button plain onClick={() => openConfirmModal('reject')}>
        Reject
      </Button>
    </span>
  );

  const retrievalError = commentsError || fetchApprovalError;

  const { study } = approval;

  return (
    <div>
      <SubHeader linkToText="Reviews" link={webRoute('approvals')} />
      <div className="pa4">
        {_isNil(approval.approval) ? (
          <NoData
            title="Problem fetching this review"
            text="An error has occurred retrieving review information, please try again later. If this keeps occurring please contact support."
            link="/approvals"
            btnTxt="Return to reviews"
            isFullScreen
          />
        ) : (
          <>
            <div className="flex flex-row justify-between items-center mb4">
              <h1 className="f3">{approval.approval.name}</h1>
              {showApproveRejectButtons && approveRejectButtons}
            </div>
            <div>
              {retrievalError && (
                <ApiErrorBanner
                  className="mb4 mw6"
                  title={`There was an error retrieving ${fetchApprovalError ? 'reviews' : 'comments'}`}
                  text={
                    'An error has occurred retrieving review information, please try again later. If this keeps occurring please contact support.'
                  }
                  error={commentsError ?? fetchApprovalError}
                />
              )}
              {submitApprovalError && (
                <ApiErrorBanner
                  className="mb4 mw6"
                  title={'There was an error submitting the review'}
                  text={
                    'An error has occurred when submitting the review, please try again later. If this keeps occurring please contact support.'
                  }
                  error={submitApprovalError}
                />
              )}
              <Tabs state={tabState}>
                <div className="mv4 bb b--moon-gray flex">
                  <Tab>Review details</Tab>
                  {_notNil(approval.project) && <Tab>Project</Tab>}
                  <Tab>Groups</Tab>
                  <Tab>Attachments</Tab>
                  <Tab counter={comments?.length ?? 0}>Comments</Tab>
                </div>
                <div className="pb4">
                  <Panel>
                    {_isNotEmpty(approval) && !approvalUpdating && (
                      <div className="flex flex-row">
                        <div className="mw7 w-two-thirds">
                          {_isNotEmpty(study) && <ApprovalRequestDetails study={study} />}
                          <ApprovalFormSummary approval={approval} />
                          {showApproveRejectButtons && approveRejectButtons}
                        </div>
                        <div className="pl4 w-33">
                          <ApprovalStatus
                            approvers={approvers}
                            additionalApprovers={additionalApprovers}
                            ordered={ordered}
                            status={approval.status}
                            onAddReviewersClick={openAddAdditionalReviewersModal}
                          />
                        </div>
                      </div>
                    )}
                  </Panel>
                  {_notNil(approval.project) && (
                    <Panel>
                      <Project projectId={approval.project.id} />
                    </Panel>
                  )}
                  <Panel>{_notNil(approval?.study?.id) && <Groups studyId={approval?.study?.id} />}</Panel>
                  <Panel>
                    <Attachments studyId={study?.id} isReview />
                  </Panel>
                  <Panel>
                    <ApprovalComments
                      comments={comments}
                      fetchComments={fetchComments}
                      approvalId={approvalRequestId}
                    />
                  </Panel>
                </div>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApprovalDetails;
