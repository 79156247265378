import type { components } from '@/generated/internal-api/openapi-types';
import type { ApiID, ID } from './Common.model';

export interface iPort {
  requestPort({ filters }: { filters: Array<SerialPortFilter> }): Promise<SerialPort>;
  getPorts(): Promise<Array<SerialPort>>;
  open(): Promise<void>;
  close(): Promise<void>;
  read(): Promise<string | undefined>;
}

export enum ParityTypes {
  'None' = 'none',
  'Odd' = 'odd',
  'Even' = 'even',
}

export enum FlowControlTypes {
  'None' = 'none',
  'Hardware' = 'hardware',
}

export enum ReadingTypes {
  'Text' = 'text',
  'Number' = 'number',
  'Trim Channel' = 'trim_channel',
}

export const TerminationCharacters = {
  'Carriage return': 13,
  Comma: 44,
  'Horizontal Tab': 9,
  'Vertical Tab': 11,
  'Line Feed': 10,
  Space: 32,
};

export interface DeviceFormData {
  id: ID;
  title: string;
  device_type_api_id?: ApiID<'dvt'>;
  baud_rate: number;
  usb_vendor_id?: number;
  usb_product_id?: number;
  product_name?: string;
  vendor_name?: string;
  data_bits?: number;
  stop_bits?: number;
  flow_control: FlowControlTypes;
  parity: ParityTypes;
  buffer_size?: number;
}

export interface DeviceType {
  id: ID;
  title: string;
  created_at: string;
  updated_at: string;
  usb_vendor_id: number;
  vendor_name: string;
  usb_product_id: number;
  product_name: string;
  baud_rate: number;
  data_bits: number;
  stop_bits: number;
  parity: ParityTypes;
  flowControl: string;
  termination_character: number;
  reading_type: ReadingTypes;
}
export interface TargetField {
  label: string;
  value: string;
}

export interface MappedDevice {
  name?: string;
  target: TargetField[];
  activeTarget?: TargetField;
  usb_vendor_id?: number;
  usb_product_id?: number;
}
export interface TypeMapping {
  typeId?: ID;
  type?: DeviceType;
  reader?: ReadableStreamDefaultReader<Uint8Array>;
  port?: SerialPort;
}
export interface PresetDeviceMapping {
  presetId: ID;
  mappedDevices: MappedDevice[];
}

export interface TargetField {
  name: string;
  id: string;
}

export type RegisteredDevice = components['schemas']['RegisteredDeviceV1.schema'];
