import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import GroupLabel from '@/components/UI/GroupLabel';
import SearchSelect from '@/components/UI/SearchSelect';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _isEmpty, _isNotEmpty } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { Treatment } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import { web as webRoute } from '@/support/route';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  formatGroupConfigurePayload,
  generateAvailableAndAssignedSections,
  initialGroupsWithTreatmentsMap,
} from './Configuration.utils';

interface ConfigurationProps {
  canEdit: boolean;
}

const Show: React.FC<ConfigurationProps> = ({ canEdit }) => {
  const [groupsWithTreatments, setGroupsWithTreatments] = useState({});
  const { id: studyId } = useParams<{ id: string }>();
  const { collection: groups, collectionLoading: groupsLoading } = useFetchCollection<TreatmentGroup>({
    collectionType: 'studyGroups',
    includes: 'metadata',
    params: { id: studyId },
    queryParams: { perPage: -1 },
  });
  const { collection: treatments, collectionLoading: treatmentsLoading } = useFetchCollection<Treatment>({
    collectionType: 'studyTreatments',
    includes: 'study_groups,metadata',
    params: { studyId },
    queryParams: { perPage: -1 },
  });
  const {
    sendRequest: updateGroupConfig,
    requestError,
    requestSending,
  } = useRequest({
    route: 'studies.groups.configure.treatments',
    params: { studyId },
    method: 'put',
  });

  useEffect(() => {
    if (!treatmentsLoading && _isNotEmpty(treatments)) {
      setGroupsWithTreatments(initialGroupsWithTreatmentsMap(treatments));
    }
  }, [treatmentsLoading, treatments]);

  const onToggleTreatment = (update: Record<ID, Array<ID>>) => {
    const updatedValue = { ...groupsWithTreatments, ...update };
    setGroupsWithTreatments(updatedValue);
    handleSave(updatedValue);
  };

  const handleSave = async (data: Record<ID, Array<ID>>) => {
    const payload = formatGroupConfigurePayload(data);
    await updateGroupConfig(payload);
  };

  if (groupsLoading || treatmentsLoading) {
    return (
      <div className="pa4 mv4">
        <Loading />
      </div>
    );
  } else if (!groupsLoading && _isEmpty(groups)) {
    const linkToGroups = webRoute('studies.studyGroups', { id: studyId });
    return (
      <NoDataCard
        className="mv4"
        title="This study has no groups yet"
        text="You can add and view groups for this study here."
        link={`${linkToGroups}?tab=groups`}
        btnTxt="Create treatment groups"
      />
    );
  } else {
    return (
      <>
        {requestError && <ApiErrorBanner className="ma3" />}
        <GroupTreatmentConfiguration
          groups={groups ?? []}
          treatments={treatments ?? []}
          groupsWithTreatments={groupsWithTreatments}
          onToggleTreatment={onToggleTreatment}
          canEdit={canEdit}
          requestSending={requestSending}
        />
      </>
    );
  }
};

interface GroupTreatmentConfigurationProps {
  groups: Array<TreatmentGroup>;
  treatments: Array<Treatment>;
  groupsWithTreatments: Record<ID, Array<ID>>;
  onToggleTreatment: (update: Record<ID, Array<ID>>) => void;
  canEdit?: boolean;
  requestSending?: boolean;
}

export const GroupTreatmentConfiguration: React.FC<GroupTreatmentConfigurationProps> = ({
  groups = [],
  treatments = [],
  groupsWithTreatments = {},
  onToggleTreatment,
  canEdit = true,
  requestSending = false,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="pv4">
      <Tabs outerState={[activeTab, setActiveTab]}>
        <div className="flex justify-between items-top mw8">
          <div className="w-50">
            <h3 className="lh-title f5">Groups</h3>
            <div className="ui-card mv3 mr5 overflow-auto" style={{ maxHeight: 506 }}>
              {groups.map((group, index) => (
                <Tab key={index} plain={true}>
                  <SelectableGroupItem group={group} isActive={index === activeTab} isFirst={index === 0} />
                </Tab>
              ))}
            </div>
          </div>
          <div className="w-50">
            <h3 className="lh-title f5">Treatments</h3>
            <>
              {groups.map(({ id: groupId }, index) => (
                <Panel key={index}>
                  <SelectedGroupPanel
                    groupId={groupId}
                    treatments={treatments}
                    onToggleTreatment={onToggleTreatment}
                    groupsWithTreatments={groupsWithTreatments}
                    disabled={!canEdit || requestSending}
                  />
                </Panel>
              ))}
            </>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

interface SelectableGroupItemProps {
  group: TreatmentGroup;
  isActive?: boolean;
  isFirst?: boolean;
}

const SelectableGroupItem: React.FC<SelectableGroupItemProps> = ({ group, isActive = false, isFirst = false }) => {
  return (
    <div
      className={`pointer pa3 ${isFirst ? '' : 'bt b--moon-gray'} ${
        isActive ? 'bg-lightest-blue' : 'bg-light-gray-hover '
      }`}
    >
      <GroupLabel group={group} className="fw5 near-black" />
    </div>
  );
};

interface SelectedGroupPanelProps {
  groupId: ID;
  treatments: Array<Treatment>;
  onToggleTreatment: (groupsWithTreatments: Record<ID, Array<ID>>) => void;
  groupsWithTreatments: Record<ID, Array<ID>>;
  disabled: boolean;
}

const SelectedGroupPanel: React.FC<SelectedGroupPanelProps> = ({
  groupId,
  treatments,
  onToggleTreatment,
  groupsWithTreatments,
  disabled,
}) => {
  const sections = generateAvailableAndAssignedSections(groupId, groupsWithTreatments, treatments);
  const selected = groupsWithTreatments?.[groupId] ?? [];
  const onSelect = ({ type: action, value: treatmentId }: { type: 'ADDED' | 'REMOVED'; value: ID }) => {
    const updatedGroupsWithTreatments = { ...groupsWithTreatments };
    const groupToAction = updatedGroupsWithTreatments?.[groupId];
    if (action === 'ADDED') {
      if (!groupToAction) {
        updatedGroupsWithTreatments[groupId] = [];
      }
      updatedGroupsWithTreatments[groupId].push(treatmentId);
    } else {
      const indexToRemove = groupToAction.indexOf(treatmentId);
      if (indexToRemove > -1) {
        updatedGroupsWithTreatments[groupId].splice(indexToRemove, 1);
      }
    }
    onToggleTreatment(updatedGroupsWithTreatments);
  };

  if (_isEmpty(treatments)) {
    return <NoDataCard className="mv3" title="No treatments have been created yet" />;
  }

  return (
    <SearchSelect
      className="ui-card mv3"
      sections={sections}
      selected={selected}
      onSelect={onSelect}
      disabled={disabled}
      enableClearSelection={false}
      enableSelectAll={false}
      enableSelectedCount={false}
    />
  );
};

export default Show;
