// @ts-nocheck: converted from JS
import type { Animal, DeceasedAnimal } from '@/model/Animal.model';
import { Nullable } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import type {
  MetadataColumns,
  MetadataField,
  MetadataFieldWithValue,
  MetadataValueRender,
} from '@/model/Metadata.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import { Status } from '@/referenceData/study/status';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import Big from 'big.js';
import gmean from 'compute-gmean';
import { differenceInDays, differenceInWeeks, isAfter } from 'date-fns';
import purify from 'dompurify';
import _each from 'lodash/each';
import _reduce from 'lodash/reduce';
import _sum from 'lodash/sum';
import type { WheelEvent } from 'react';
import { RiAlertFill, RiCheckboxCircleFill, RiInformationFill } from 'react-icons/ri';
import { toast, ToastContent } from 'react-toastify';
import { _isDeepEqual, _isEmpty, _isNil, _isNotEmpty, _noop, _notNil, safelyDecodeURIComponent } from './littledash';

type Primitive = string | number | bigint | boolean | symbol | null | undefined;

export const isOdd = (num) => num % 2;

export const trunc = (str: string, n: number): string => (str.length > n ? str.substring(0, n - 1) + '...' : str);

export const pad = (number, length) => {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }

  return str;
};
/**
 * This function takes two javascript date objects and combines them into one date
 * with the date of the "date" prop and the time of "time" prop
 **/
export const combineDateAndTime = ({ date, time }: { date: Date; time?: Date }): Date => {
  // take a copy of the date or create a new date if not supplied
  const result = time ? new Date(time.getTime()) : new Date();
  result.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  return result;
};

/**
 * Wrapper around a common _reduce case; if you return a [ key => value ]
 * array, we'll return an object with those key value pairings.
 */
export const mapWithKeys = (collection, predicate) => {
  return _reduce(
    collection,
    (obj, item, key) => {
      const map = predicate(item, key);
      _each(Object.keys(map), (key) => (obj[key] = map[key]));
      return obj;
    },
    {}
  );
};

export const roundToTwo = (value: number | string) => roundNumber(value, 2);
export const roundNumber = (value: number | string | undefined, fractionDigits = 2) =>
  Number(Number(value).toFixed(fractionDigits));

export const standardDeviation = (array) => {
  let std = NaN;
  if (!_isEmpty(array) && array.length > 1) {
    const m = _sum(array) / array.length;

    // Sample STD
    std = Math.sqrt(
      array.reduce(function (sq, n) {
        return sq + Math.pow(n - m, 2);
      }, 0) /
        (array.length - 1)
    );
    // @This is a POPULATION sd calculation@
    // return Math.sqrt(_sum(_map(array, (i) => Math.pow((i - m), 2))) / array.length)
  }
  return std;
};

export const standardErrorOfMean = (sd, count) => {
  return sd / Math.sqrt(count);
};

export const returnUserFromStudy = (study, id) => {
  const userPool = [study.author];
  if (Array.isArray(study.users?.data)) {
    userPool.push(...study.users.data);
  } else if (Array.isArray(study.users)) {
    userPool.push(...study.users);
  }
  if (study.owner) {
    userPool.push(study.owner);
  }
  const test = userPool.find((u) => Number(u.id) === Number(id));
  return test;
};

export const calculatePercentageChange = (a, b) => (b === 0 ? false : (a / b) * 100);

export const calculateAverage = (calc, values) => {
  let avg = NaN;
  if (!_isEmpty(values)) {
    if (calc === 'arithmetic') {
      const sum = values.reduce((sum, value) => {
        return sum + value;
      }, 0);

      avg = sum / values.length;
    } else {
      avg = gmean(values);
    }
  }
  return avg;
};

export const hasOwnProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export const capitalise = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const expo = (x: string | number, f = 3) => {
  return Number(x).toExponential(f);
};

export const isExpo = (num) => {
  const numberString = String(num);
  const expRegex = '[-+]?[0-9]*.?[0-9]+([eE][-+]?[0-9]+)';
  return numberString.match(expRegex);
};

export const formatNumber = (value: number | string, round = true, roundingPrecision = 2): number | string => {
  if (_notNil(value)) {
    const valueAsNumber = Number(value);
    if (valueAsNumber === 0) {
      return valueAsNumber;
    }
    const valueSize = Math.floor(Math.log10(Math.abs(valueAsNumber)));
    if (valueSize >= -4 && valueSize <= 5) {
      return round ? roundNumber(valueAsNumber, Math.abs(valueAsNumber) < 1 ? 4 : roundingPrecision) : valueAsNumber;
    }
    return expo(valueAsNumber);
  }
  return NaN;
};

export const formatNumberComingIn = (num) => {
  let formattedNumber = NaN;
  if (num) {
    if (isExpo(num)) {
      const x = new Big(num);
      formattedNumber = x.toPrecision();
    } else {
      formattedNumber = num;
    }
  }
  return formattedNumber;
};

export const isEquivalent = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) {
      return false;
    }

    if (Array.isArray(a[propName])) {
      return _isDeepEqual(a[propName], b[propName]);
    }
  }

  return true;
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const hasDuplicates = (arr) => arr.some((item, index) => arr.indexOf(item) !== index);

export const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }

  return arr;
};

export const sortArrAlphabetically = (arr, attr = 'title') => {
  return arr.sort((a, b) => {
    const textA = a?.[attr]?.toUpperCase?.() ?? '';
    const textB = b?.[attr]?.toUpperCase?.() ?? '';
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const newLineText = (text) => {
  return text.split('\n').map((item, i) => (
    <span className="db lh-copy mb2" key={i}>
      {item}
    </span>
  ));
};

export const tagIdValidate = (val) => {
  return /^(?! ).*$/.test(val);
};

export const missingNameUseEmail = (user) =>
  !user.name
    ? {
        ...user,
        name: user.email,
      }
    : user;

export const calculateAge = (startDate, endDate?, shortHand = false) => {
  if (_isNil(endDate)) {
    endDate = new Date();
  }
  const deltaInDays = Math.floor(differenceInDays(endDate, startDate));
  let result = `${deltaInDays} ${shortHand ? 'd' : 'Days'}`;
  if (Number(deltaInDays) >= 28) {
    const deltaInWeeks = Math.floor(differenceInWeeks(endDate, startDate));
    result = `${deltaInWeeks} ${shortHand ? 'w' : 'Weeks'}`;
  }
  return result;
};

export const isNotApproved = (study) =>
  Boolean(study?.status === Status.awaiting_approval || study?.status === Status.rejected);
export const isCancelled = (study) => Boolean(study?.canceled_at);

export const fetchStudiesMetadataColumns = async (signal: AbortSignal) => {
  const data = await Http.get(apiRoute('meta-glossary.show'), {
    params: {
      filter_type: 'study_meta',
    },
    signal,
  });

  return (data?.data?.data ?? []).reduce<Array<MetadataField>>((activeMeta, metadataItem) => {
    if (metadataItem?.in_use && metadataItem.active) {
      activeMeta.push({ ...metadataItem, header: metadataItem.title });
    }
    return activeMeta;
  }, []);
};

export const renderMetadataValue = ({ value, field_type: fieldType }: MetadataValueRender): string => {
  if (_notNil(value)) {
    if (fieldType === 'date') {
      return DateUtils.renderDate(value);
    }
    if (fieldType === 'numeric') {
      return formatNumber(value);
    }
    if (Array.isArray(value)) {
      return value.map((v) => safelyDecodeURIComponent(v)).join(', ');
    }
    try {
      const decodedValue = decodeURIComponent(value);
      return JSON.parse(decodedValue).join(', ');
    } catch (e) {
      return value;
    }
  }
  return '';
};

interface MetadataColumnProps {
  metadata: Array<MetadataColumns>;
  columns?: Record<string, boolean>;
  showAllColumns?: boolean;
  nestedStudyLocation?: string;
}

export const fetchMetadataColumns = ({
  metadata = [],
  columns,
  showAllColumns = false,
  nestedStudyLocation = undefined,
}: MetadataColumnProps) =>
  (metadata ?? []).map(({ header, title, name, id }) => {
    const metadataHeader = header ?? title ?? name;
    return {
      id,
      Header: metadataHeader,
      sortDisabled: true,
      isVisible: (showAllColumns || (columns?.[id] ?? columns?.[header])) ?? false,
      accessor: `metadata.${id}`,
      Cell: ({ row: { original } }: CellProps<Study>): string => {
        const metadataParent = _notNil(nestedStudyLocation) ? original[nestedStudyLocation] : original;
        // We really need to get the api return for metadata consistent
        const metadata = metadataParent?.metadata ?? metadataParent?.metas?.data ?? metadataParent?.meta?.data;
        const found = metadata?.find(({ glossary_id }) => glossary_id === id);

        return _notNil(found) ? renderMetadataValue(found) : '-';
      },
    };
  });

export const validateFilter = (filter) => {
  return filter.category && filter.operation && filter.option;
};

export const infoToast = (content: ToastContent) => {
  toast(content, { icon: <RiInformationFill fill="#96ccff" size={20} />, type: 'info' });
};
export const successToast = (content: ToastContent) => {
  toast(content, { icon: <RiCheckboxCircleFill fill="#00c690" size={20} />, type: 'success' });
};
export const warningToast = (content: ToastContent) => {
  toast(content, { icon: <RiAlertFill fill="#fbf1a9" size={20} />, type: 'warning' });
};
export const errorToast = (content: ToastContent) => {
  toast(content, { icon: <RiAlertFill fill="#ff725c" size={20} />, type: 'warning' });
};

/**
 * Function that checks if input is a function.
 * Chosen based on benchmark and browser compatibility: https://jsben.ch/B6h73
 *
 * @param {any} functionToCheck
 * @returns {boolean}
 */
export const isFunction = (functionToCheck) => functionToCheck instanceof Function;

/**
 * Function that checks if input is a function.
 * Chosen based on benchmark and browser compatibility: https://jsben.ch/B6h73
 *
 * @param {any} functionToCheck
 * @returns {boolean}
 */
export const isString = (stringToCheck: any): stringToCheck is string => typeof stringToCheck === 'string';

/**
 * Function to swap given items in an array
 *
 * @param array {array} Array to swap items in
 * @param from {number} item to swap
 * @param to {number} item to swap
 * @returns {array} Updated Array
 */
export const swapArrayItems = (array, from, to) => {
  if (Object.prototype.toString.call(array) !== '[object Array]') {
    throw new Error('Please provide a valid array');
  }

  [array[from], array[to]] = [array[to], array[from]];
  return array;
};

export const isDateGreaterThanToday = (userDate: Date) => {
  return isAfter(userDate, new Date());
};

export const getMetadata = (entity: Study): Array<MetadataFieldWithValue> => entity?.metadata ?? entity?.metas?.data;

export const copyText = (textToCopy: string, toastMessage?: string) => {
  if (_isNotEmpty(textToCopy)) {
    navigator.clipboard.writeText(textToCopy);
    toastMessage && successToast(toastMessage);
  }
};

export const checkIsDeceased = (animal: Animal): animal is DeceasedAnimal => {
  return (animal as DeceasedAnimal).terminated_at != null;
};

export const getCalculationsAndMeasurements = (calculations?: Array<PresetCalculation>): Map<ID, PresetCalculation> => {
  return new Map(
    (calculations ?? []).flatMap<[ID, PresetCalculation]>((calculation) => [
      [
        calculation.id,
        { id: calculation.id, name: calculation.name, unit: calculation.unit, formula: calculation.formula },
      ],
      ...(calculation.measurements ?? []).map<[ID, PresetCalculation]>((measurement) => [measurement.id, measurement]),
    ])
  );
};

export const cleanseSpacesFromString = (value: string | null): string =>
  _notNil(value) ? value.replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g, ' ') : value; // eslint-disable-line no-irregular-whitespace

export const clearSessionStorage = (history: RouterHistory, itemName: string, pathName: string) => {
  return () => {
    if ((history.action === 'POP' || history.action === 'PUSH') && history.location?.pathname !== pathName) {
      sessionStorage.removeItem(itemName);
    }
  };
};
export const classNames = (constant: string, dynamic: Record<string, boolean>): string =>
  Object.entries(dynamic ?? {})
    .reduce((acc, [className, active]) => (active ? `${acc} ${className}` : acc), constant)
    .trim();

export const defaultPromiseErrorHandler = (error) =>
  ExceptionHandler.captureException(
    new InVivoError('Default promise error handler error', {
      cause: error,
      slug: 'default-error-handler',
    }),
    {
      UnhandledPromiseRejection: true,
    }
  );
export const swallowPromiseResponse = (promise: Promise<any>): void =>
  promise.then(_noop).catch(defaultPromiseErrorHandler);

export const numericStringPattern = /^[+\-\d]+(\.\d+)?([eE][+-]?\d+)?$/;
export const isNumericString = (value: Nullable<string>): value is string | boolean =>
  numericStringPattern.test(value ?? '');

const isoDurationPattern =
  /^(?<negative>-)?P((?<years>\d+)Y)?((?<months>\d+)M)?((?<days>\d+)D)?T((?<hours>\d+)H)?((?<minutes>\d+)M)?((?<seconds>\d+)S)?$/;

export const isIsoDurationString = (value?: string): boolean => {
  const result = isoDurationPattern.exec(value ?? '');
  if (_isNil(result)) {
    return false;
  }
  const components = Object.values(result.groups).filter(_notNil);
  return _isNil(result?.groups?.negative) ? components.length >= 1 : components.length >= 2;
};

export const preventNumberScroll = (e: WheelEvent<HTMLInputElement>): void => (e.target as HTMLInputElement).blur();

export const findStringMatch = (searchString, searchTerm): boolean => {
  return searchString.toLowerCase().includes(searchTerm.toLowerCase());
};

/**
 * Example usage:
 * const obj1 = { a: 1, b: 2, c: 3 };
 * const obj2 = { a: 1, b: 4, d: 'hello' };
 * const differences = objectDiff(obj1, obj2);
 * result: {'b': [2, 4], 'd': [undefined, 5], 'c': [3, undefined])}
 */

export function objectDiff<T extends Record<string, Primitive>>(
  obj1: T,
  obj2: T
): Record<string, { obj1: Primitive; obj2: Primitive }> {
  const diff: Record<string, { obj1: Primitive; obj2: Primitive }> = {};
  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  allKeys.forEach((key) => {
    const val1 = obj1[key as keyof T];
    const val2 = obj2[key as keyof T];
    if (val1 !== val2) {
      diff[key] = { obj1: val1, obj2: val2 };
    }
  });

  return diff;
}

export const wait = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

type QueryParams = {
  [key: string]: string;
};

/*
 * Parse a query string into an object
 * Example:
 * parseQueryString('?key1=value1&key2=value2') => { key1: 'value1', key2: 'value2' }
 */
export const parseQueryString = (queryString: string): QueryParams => {
  const result: QueryParams = {};

  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(queryString);

  params.forEach((value, key) => {
    // allow only alphanumeric keys and underscores
    if (key.match(/^[a-zA-Z0-9_]+$/)) {
      result[key] = value;
    }
  });

  return result;
};

export type FilterByType<T, Prop> = Pick<
  T,
  {
    [K in keyof T]: T[K] extends Prop ? K : never;
  }[keyof T]
>;

export const isValidLinkAttribute = (value: string, tag: string = 'a', attr: string = 'href'): boolean => {
  return purify.isValidAttribute(tag, attr, value);
};

export function parseStringToNumber(value: string): number | null {
  // Trim whitespace and check if the string is empty
  const trimmedValue = value.trim();
  if (trimmedValue === '') {
    return null;
  }

  // Attempt to parse the string as a float
  const parsedValue = parseFloat(trimmedValue);

  // Check if the parsed value is a number and is finite
  if (isNaN(parsedValue) || !isFinite(parsedValue)) {
    return null;
  }

  return parsedValue;
}
