// @ts-expect-error: untyped lib
import * as jstat from 'jstat';
import { sum } from 'mathjs';
import { mean } from 'simple-statistics';
import type { AnovaMetric, AnovaTable, OneWayAnovaResults } from './Statistics.model';

export const calculatePValueAndAnova = (anovaMetric: AnovaMetric): OneWayAnovaResults => {
  const anovaTable = calculateOneWayAnova(anovaMetric);
  return { anovaTable, pvalue: calculatePValue(anovaTable) };
};

export const calculatePValue = (anovaResult: AnovaTable): number =>
  jstat.ftest(anovaResult.treatment.fdist, anovaResult.treatment.degreesOfFreedom, anovaResult.error.degreesOfFreedom);

// Compute the F-test for one way ANOVA
export const calculateOneWayAnova = (samples: Array<Array<number>>): AnovaTable => {
  const ms = meanSquare(samples, true);
  return {
    treatment: {
      sumOfSquares: ms.ss.treatment,
      degreesOfFreedom: ms.df.treatment,
      meanSquare: ms.treatment,
      fdist: ms.treatment / ms.error,
    },
    error: {
      sumOfSquares: ms.ss.error,
      degreesOfFreedom: ms.df.error,
      meanSquare: ms.error,
    },
    total: {
      sumOfSquares: ms.ss.total,
      degreesOfFreedom: ms.df.total,
    },
  };
};

// Comptute the Mean Square
const meanSquare = (samples: Array<Array<number>>, verbose?: boolean) => {
  const ss = sumOfSquares(samples);
  const df = degreesOfFreedom(samples);

  const results = {
    treatment: ss.treatment / df.treatment,
    error: ss.error / df.error,
    ss,
    df,
  };

  return results;
};

// Compute the Sum of Squares
const sumOfSquares = (samples: Array<Array<number>>) => {
  let treatment = 0;
  let error = 0;
  const fullMean = calculateFullMean(samples);

  samples.forEach((sample) => {
    const mu = mean(sample);
    sample.forEach(function (val) {
      error += (val - mu) * (val - mu);
    });
    treatment += (mu - fullMean) * (mu - fullMean) * sample.length;
  });

  return {
    total: treatment + error,
    treatment,
    error,
  };
};

const calculateFullMean = (samples: Array<Array<number>>): number => {
  let total = 0,
    count = 0;
  samples.forEach((sample) => {
    total += sum(sample);
    count += sample.length;
  });
  return total / count;
};

// Compute the Degrees of Freedom
const degreesOfFreedom = (samples: Array<Array<number>>) => {
  const n = samples.reduce((acc, sample) => (acc += sample.length), 0);

  const treatment = samples.length - 1; // Numerator
  const error = n - samples.length;

  return {
    total: treatment + error,
    treatment,
    error,
  };
};
