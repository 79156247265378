import Tooltip from '@/components/UI/Tooltip';
import type { ColorDetail } from '@/constants/colors';
import { _first, _isNotEmpty } from '@/littledash';
import type { DayTask } from './MonthGrid.model';
import styles from './MonthGrid.module.scss';

interface MonthCardProps {
  task: DayTask;
  colours?: ColorDetail;
  onClick?: () => void;
}

export const MonthCard = ({ task, colours, onClick }: MonthCardProps): JSX.Element => {
  return (
    <div
      className={`${styles['month-grid-task-card']} w-100 pa2 br-pill flex items-center ${
        colours ? colours?.background?.class : 'bg-near-white'
      } ${onClick ? 'pointer' : ''}`}
      onClick={onClick}
      data-test-component="MonthCard"
      data-test-element="container"
      data-testid="month-card"
    >
      <div className="flex items-center">
        {_isNotEmpty(task.assignees) && (
          <>
            <div className="mr1" data-testid="month-assignee">
              <MonthCardUserAvatar
                name={_first(task.assignees)?.name ?? 'Not Found'}
                backgroundColor={colours?.color}
              />
            </div>
            {task.assignees.length > 1 && (
              <div className="mr1" data-testid="truncated-users">
                <MonthCardTruncatedUsers remainder={task.assignees.length - 1} />
              </div>
            )}
          </>
        )}
      </div>
      <h3
        className={`${styles['month-card-title']} f7 lh-title w-100 truncate`}
        data-test-component="MonthCard"
        data-test-element="task-title"
      >
        {task.title}
      </h3>
    </div>
  );
};

export const MonthCardUserAvatar = ({
  name,
  backgroundColor,
}: {
  name?: string;
  backgroundColor?: string;
}): JSX.Element => {
  const initials = (name?.match(/\b\w/g) || []).join('').toUpperCase() || '-';

  return (
    <Tooltip render={name ?? 'Not found'}>
      <div
        className={`${styles['month-card-user-avatar']} br-pill pa1 ph2 lh-solid bg-dark-gray f7 white`}
        data-testid="month-user-avatar"
        style={{ backgroundColor }}
      >
        {initials}
      </div>
    </Tooltip>
  );
};

export const MonthCardTruncatedUsers = ({ remainder }: { remainder: number }): JSX.Element => {
  return (
    <div
      className={`${styles['month-card-truncated-users']} br-100 tc flex justify-center items-center f7 lh-title near-black bg-near-white ba b--white`}
    >
      <span>+</span> {remainder}
    </div>
  );
};

export default MonthCard;
