import { _throttle } from '@/littledash';
import { useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
interface UseThrottleProps<T extends Function> {
  func: T;
  duration: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const useThrottle = <T extends Function>({ func, duration }: UseThrottleProps<T>): T =>
  useRef(_throttle(func, duration)).current;
