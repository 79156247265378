import {
  AdditionOperator,
  AverageFunction,
  AverageFunctionInput,
  ClosingBracket,
  Comma,
  NumericExpression,
  NumericOperator,
  OpeningBracket,
  PI,
  PiFunction,
  Variable,
} from '@/generated/dsl/PresetFormula.terms.ts';
import { warningToast } from '@/helpers.tsx';
import { _isEmptyString, _isNil, _isNotBlank, _noop, _notNil, _set, uuid } from '@/littledash.ts';
import { Nullable } from '@/model/Common.model.ts';
import { PresetCreateOrUpdateV1, PresetMeasurementCreateV1, PresetMeasurementInputV1 } from '@/model/Preset.model.ts';
import { presetFormulaParser, Variable as VariableType } from '@/utils/FormulaDsl.ts';
import { compileFormula } from '@/utils/FormulaEngine.ts';
import { createContext, Dispatch, MutableRefObject, Reducer, useCallback } from 'react';
import { FieldError, Resolver } from 'react-hook-form@latest';
// @ts-expect-error: weird import
import type { ResolverResult } from 'react-hook-form@latest/dist/types/resolvers';

export type SlugDetail = Pick<PresetMeasurementInputV1, 'name' | 'slug'> &
  ({ type: 'output' } | { type: 'input'; parent: string });
const invalidCharactersPattern = /[^a-z0-9-_%\s]/g;
const replaceWithSeparatorPattern = /[\s-_]+/g;

export interface DraftMeasurement {
  id: string;
  name: string;
  unit: string;
}

export type PresetBuilderStateActions =
  | { type: 'add-draft-measurement' }
  | { type: 'update-draft-measurement'; data: DraftMeasurement }
  | { type: 'remove-draft-measurement'; data: Pick<DraftMeasurement, 'id'> };

interface PresetBuilderState {
  draftMeasurements: Map<string, DraftMeasurement>;
}

export const reducer: Reducer<PresetBuilderState, PresetBuilderStateActions> = (prevState, action) => {
  switch (action.type) {
    case 'add-draft-measurement': {
      const id = uuid();
      const draftMeasurements = prevState.draftMeasurements;
      draftMeasurements.set(id, { id, name: '', unit: '' });
      return { ...prevState, draftMeasurements };
    }
    case 'update-draft-measurement': {
      const draftMeasurements = prevState.draftMeasurements;
      if (draftMeasurements.has(action.data.id)) {
        draftMeasurements.set(action.data.id, action.data);
        return { ...prevState, draftMeasurements };
      }
      return prevState;
    }
    case 'remove-draft-measurement': {
      const draftMeasurements = prevState.draftMeasurements;
      draftMeasurements.delete(action.data.id);
      return { ...prevState, draftMeasurements };
    }
    default:
      return prevState;
  }
};

export const toSlug = (value: Nullable<string>, separator = '_'): string =>
  (value ?? '')
    .toLowerCase()
    .replaceAll('%', 'percentage ')
    .replace(invalidCharactersPattern, '')
    .trim()
    .replace(replaceWithSeparatorPattern, separator);

export const toTitle = (name: string, unit?: string | null) => name + (_isNotBlank(unit) ? ` (${unit})` : '');

export interface CreateOrUpdatePresetContextData {
  state: PresetBuilderState;
  slugDetailsRef: MutableRefObject<Array<SlugDetail>>;
  dispatch: Dispatch<PresetBuilderStateActions>;
}

export const CreateOrUpdatePresetContext = createContext<CreateOrUpdatePresetContextData>({
  state: { draftMeasurements: new Map<string, DraftMeasurement>() },
  slugDetailsRef: { current: [] },
  dispatch: _noop,
});

const parseFormula = (
  formula: string
): {
  variables: Set<string>;
  errors: Array<FieldError>;
  formula: string;
} => {
  const components: Array<string> = [];
  const variables = new Set<string>();
  const errors: Array<FieldError> = [];
  const cursor = presetFormulaParser.parse(formula).cursor();
  do {
    if (cursor.node.type.isError) {
      errors.push({
        type: `syntax-error:${cursor.from}-${cursor.to}`,
        message: `Invalid syntax "${formula.slice(cursor.from, cursor.to)}"`,
      });
    }
    if (_isNil(cursor.node.firstChild)) {
      const component = formula.slice(cursor.from, cursor.to);
      switch (cursor.node.type.id) {
        case OpeningBracket: {
          const parent = cursor.node.parent?.type.id;
          if (parent === PiFunction || parent === AverageFunction) {
            components.push(component);
          } else {
            components.push(` ${component}`);
          }
          break;
        }
        case ClosingBracket: {
          const parent = cursor.node.parent?.type.id;
          if (parent === PiFunction) {
            components.push(component);
          } else {
            components.push(` ${component}`);
          }
          break;
        }
        case Comma: {
          components.push(component);
          break;
        }
        case Variable: {
          variables.add(component.slice(1));
          components.push(` ${component}`);
          break;
        }
        default:
          components.push(` ${component}`);
      }
    }
  } while (cursor.next());
  return { variables, errors, formula: components.join('').trim() };
};

const setError = (errors: ResolverResult<PresetCreateOrUpdateV1>['errors'], field: string, error: FieldError) =>
  _set(errors, field, error);

export const validatePresetCreateOrUpdateV1 = (
  values: PresetCreateOrUpdateV1,
  context: { outputSlugCount: Map<string, number>; inputSlugCount: Map<string, number> }
): ResolverResult<PresetCreateOrUpdateV1> => {
  const errors: ResolverResult<PresetCreateOrUpdateV1>['errors'] = {};
  if (_isEmptyString(values.name ?? '')) {
    setError(errors, 'name', { type: 'required', message: 'Name is required' });
  } else if (values.name.length >= 255) {
    setError(errors, 'name', { type: 'maxLength', message: 'Name must have length less than 255 characters' });
  }

  if (!context.outputSlugCount.has('weight')) {
    setError(errors, 'measurements', {
      type: 'missing-weight-measurement',
      message: 'Measurements must contain a weight measurement',
    });
  }
  values.measurements.forEach((measurement, measurementIndex) => {
    let formulaVariables = new Set<string>();
    const inputSlugs = measurement.inputs.reduce(
      (acc, i) => (_isNotBlank(i.slug) ? acc.add(i.slug) : acc),
      new Set<string>()
    );
    if (_isNotBlank(measurement.name)) {
      measurement.name = measurement.name.trim();
      if (measurement.name.length > 32) {
        setError(errors, `measurements.${measurementIndex}.name`, {
          type: 'maxLength',
          message: 'Name must have length less than 32 characters',
        });
      }
    } else {
      setError(errors, `measurements.${measurementIndex}.name`, {
        type: 'required',
        message: 'Name is required',
      });
    }
    if (_isNotBlank(measurement.slug)) {
      if ((context.outputSlugCount.get(measurement.slug) ?? 0) > 1) {
        setError(errors, `measurements.${measurementIndex}.name`, {
          type: 'duplicate',
          message: `Generated id '${measurement.slug}' must be unique`,
        });
      }
      if (measurement.slug.length > 32) {
        setError(errors, `measurements.${measurementIndex}.slug`, {
          type: 'maxLength',
          message: `Generated id '${measurement.slug}' must have length less than 32 characters`,
        });
      }
    } else {
      setError(errors, `measurements.${measurementIndex}.slug`, {
        type: 'required',
        message: 'Slug is required',
      });
    }
    measurement.unit = (measurement.unit ?? '').trim();
    if (_isNotBlank(measurement.unit)) {
      if (measurement.unit.length > 20) {
        setError(errors, `measurements.${measurementIndex}.unit`, {
          type: 'maxLength',
          message: 'Unit must have length less than 20 characters',
        });
      }
    } else {
      measurement.unit = null;
    }
    if (_isNotBlank(measurement.formula)) {
      const { errors: formulaErrors, variables } = parseFormula(measurement.formula);
      formulaVariables = variables;
      variables.forEach((variable) => {
        if (!(context.outputSlugCount.has(variable) || (inputSlugs.has(variable) ?? false))) {
          formulaErrors.push({ type: `unknown-variable__${variable}`, message: `Unknown variable "$${variable}"` });
        }
      });

      if (formulaErrors.length === 1) {
        setError(errors, `measurements.${measurementIndex}.formula`, formulaErrors[0]);
      } else if (formulaErrors.length > 1) {
        const { messages, types } = formulaErrors.reduce<{
          messages: Array<string>;
          types: FieldError['types'];
        }>(
          (acc, error) => {
            acc.messages.push(error.message as string);
            acc.types = { ...acc.types, [error.type as string]: error.message };
            return acc;
          },
          { messages: [], types: {} }
        );
        setError(errors, `measurements.${measurementIndex}.formula`, {
          type: 'syntax-error',
          message: messages.join('\n'),
          types,
        });
      }
    } else {
      setError(errors, `measurements.${measurementIndex}.formula`, {
        type: 'required',
        message: 'Formula is required',
      });
    }
    if (_notNil(measurement.config.auto_swap)) {
      if (_isNotBlank(measurement.config.auto_swap.max)) {
        if (!(inputSlugs.has(measurement.config.auto_swap.max) ?? false)) {
          setError(errors, `measurements.${measurementIndex}.config.auto_swap.max`, {
            type: 'invalid-slug',
            message: 'Slug not found',
          });
        }
      } else {
        setError(errors, `measurements.${measurementIndex}.config.auto_swap.max`, {
          type: 'required',
          message: 'Slug is required',
        });
      }
      if (_isNotBlank(measurement.config.auto_swap.min)) {
        if (!(inputSlugs.has(measurement.config.auto_swap.min) ?? false)) {
          setError(errors, `measurements.${measurementIndex}.config.auto_swap.min`, {
            type: 'invalid-slug',
            message: 'Slug not found',
          });
        } else if (measurement.config.auto_swap.max === measurement.config.auto_swap.min) {
          setError(errors, `measurements.${measurementIndex}.config.auto_swap.min`, {
            type: 'invalid-auto-swap',
            message: 'Slugs cannot be the same',
          });
        }
      } else {
        setError(errors, `measurements.${measurementIndex}.config.auto_swap.min`, {
          type: 'required',
          message: 'Slug is required',
        });
      }
    }
    if (measurement.inputs.length > 0) {
      measurement.inputs.forEach((input, inputIndex) => {
        if (_isNotBlank(input.name)) {
          input.name = input.name.trim();
          if (measurement.name.length > 32) {
            setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.name`, {
              type: 'maxLength',
              message: 'Name must have length less than 32 characters',
            });
          }
        } else {
          setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.name`, {
            type: 'required',
            message: 'Name is required',
          });
        }
        if (_isNotBlank(input.slug)) {
          if (
            (context.inputSlugCount.get(input.slug) ?? 0) > 1 ||
            (measurement.slug !== input.slug && context.outputSlugCount.has(input.slug))
          ) {
            setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.name`, {
              type: 'duplicate',
              message: `Generated id '${input.slug}' must be unique`,
            });
          }
          if (measurement.slug.length > 32) {
            setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.name`, {
              type: 'maxLength',
              message: `Generated id '${input.slug}' must have length less than 32 characters`,
            });
          }
          if (
            formulaVariables.size === 1 &&
            measurement.slug === input.slug &&
            measurement.formula != `$${input.slug}`
          ) {
            setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.name`, {
              type: 'invalid-slug',
              message: 'Measurement and input names must be unique if calculation exists',
              ...(errors?.measurements?.[measurementIndex]?.inputs?.[inputIndex] ?? {}),
            });
          }

          if (!formulaVariables.has(input.slug)) {
            setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.name`, {
              type: 'unused',
              message: 'Input is not used in output formula',
              ...(errors?.measurements?.[measurementIndex]?.inputs?.[inputIndex] ?? {}),
            });
          }
        } else {
          setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.slug`, {
            type: 'required',
            message: 'Slug is required',
          });
        }
        input.unit = (input.unit ?? '').trim();
        if (_isNotBlank(input.unit)) {
          if (input.unit.length > 20) {
            setError(errors, `measurements.${measurementIndex}.inputs.${inputIndex}.unit`, {
              type: 'maxLength',
              message: 'Unit must have length less than 20 characters',
            });
          }
        } else {
          input.unit = null;
        }
      });
    }
  });
  return Object.keys(errors).length > 0 ? { values: {}, errors } : { values, errors: {} };
};

export const useFormResolverHook = (
  slugDetailRef: CreateOrUpdatePresetContextData['slugDetailsRef']
): Resolver<PresetCreateOrUpdateV1> =>
  useCallback(
    (values) => {
      const slugDetails: Array<SlugDetail> = [];
      const outputSlugCount = new Map<string, number>();
      const inputSlugCount = new Map<string, number>();
      values.measurements.forEach((measurement) => {
        if (_isNotBlank(measurement.name)) {
          const outputSlug = toSlug(measurement.name);
          if (measurement.slug !== 'weight' && outputSlug !== 'weight' && measurement.slug !== outputSlug) {
            measurement.slug = outputSlug;
          }
          outputSlugCount.set(measurement.slug, (outputSlugCount.get(measurement.slug) ?? 0) + 1);
          if (measurement.slug === 'weight' && outputSlug !== 'weight') {
            outputSlugCount.set(outputSlug, (outputSlugCount.get(outputSlug) ?? 0) + 1);
          }

          slugDetails.push({
            type: 'output',
            slug: measurement.slug,
            name: measurement.name,
          });
          measurement.inputs.forEach((input) => {
            if (_isNotBlank(input.name)) {
              const inputSlug = toSlug(input.name);
              if (input.slug !== 'weight' && inputSlug !== 'weight' && input.slug !== inputSlug) {
                input.slug = inputSlug;
              }
              inputSlugCount.set(input.slug, (inputSlugCount.get(input.slug) ?? 0) + 1);
              if (input.slug === 'weight' && inputSlug !== 'weight') {
                inputSlugCount.set(inputSlug, (inputSlugCount.get(inputSlug) ?? 0) + 1);
              }
              slugDetails.push({
                type: 'input',
                slug: input.slug,
                name: input.name,
                parent: measurement.slug,
              });
            } else {
              input.slug = input.slug === 'weight' ? 'weight' : '';
            }
          });
        } else {
          measurement.slug = measurement.slug === 'weight' ? 'weight' : '';
        }
      });
      slugDetailRef.current = slugDetails;
      return validatePresetCreateOrUpdateV1(values, { outputSlugCount, inputSlugCount });
    },
    [slugDetailRef]
  );

const generateIntegrity = async (data: string): Promise<string> => {
  const integrityHashBuffer = await window.crypto.subtle.digest('SHA-512', new TextEncoder().encode(data));
  const integrityHash = Array.from(new Uint8Array(integrityHashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return `sha512-${btoa(integrityHash)}`;
};

export const createExport = async (formData: PresetCreateOrUpdateV1): Promise<Blob> => {
  const integrity = await generateIntegrity(JSON.stringify(formData));
  return new Blob([JSON.stringify({ data: formData, integrity })], { type: 'application/json' });
};
export const verifyImport = async (data: any): Promise<boolean> => {
  if (typeof data?.data !== 'object' && typeof data?.integrity !== 'string') {
    return false;
  }
  const integrity = await generateIntegrity(JSON.stringify(data.data));
  if (integrity !== data.integrity) {
    warningToast('Import has been modified');
  }
  return true;
};
export const triggerDownload = (filename: string, data: Blob) => {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

type Ancestry = { parents: Set<string>; children: Set<string> };
const getOrDefault = (key: string, map: Map<string, Ancestry>): Ancestry => {
  return (
    map.has(key)
      ? map.get(key)
      : map
          .set(key, {
            parents: new Set<string>(),
            children: new Set<string>(),
          })
          .get(key)
  ) as Ancestry;
};

export const generateMeasurementDependencyGraph = (
  preset: PresetCreateOrUpdateV1
): {
  measurementData: Record<string, PresetMeasurementCreateV1>;
  measurementDependencyGraph: Map<string, { parents: Set<string>; children: Set<string> }>;
  measurementSlugsSortedByDependants: Array<string>;
} => {
  const { measurementSlugs, measurementData, measurementFormulaInputs } = preset.measurements.reduce(
    (acc, measurement) => {
      acc.measurementData = { ...acc.measurementData, [measurement.slug]: measurement };
      acc.measurementSlugs.add(measurement.slug);
      acc.measurementFormulaInputs.set(measurement.slug, parseFormula(measurement.formula).variables);
      return acc;
    },
    {
      measurementData: {},
      measurementSlugs: new Set<string>(),
      measurementFormulaInputs: new Map<string, Set<string>>(),
    }
  );
  const measurementSlugList = Array.from(measurementSlugs);

  const measurementDependencyGraph = measurementSlugList.reduce((acc, measurementSlug) => {
    measurementFormulaInputs.get(measurementSlug)?.forEach((inputSlug) => {
      const current = getOrDefault(measurementSlug, acc);
      if (measurementSlug !== inputSlug && measurementFormulaInputs.has(inputSlug)) {
        current.parents.add(inputSlug);
        getOrDefault(inputSlug, acc).children.add(measurementSlug);
      }
    });
    return acc;
  }, new Map<string, { parents: Set<string>; children: Set<string> }>());
  const measurementSlugsSortedByDependants = measurementSlugList.sort((a, b) => {
    const deps = measurementDependencyGraph.get(a);
    if (deps?.parents.has(b) ?? false) {
      return 1;
    }
    if (deps?.children.has(b) ?? false) {
      return -1;
    }
    return 0;
  });
  return {
    measurementData,
    measurementDependencyGraph,
    measurementSlugsSortedByDependants,
  };
};

export const compileFormulaEngine = (formula: string, options?: { variablesToZero?: Set<VariableType> }) => {
  const components: Array<string> = [];
  const cursor = presetFormulaParser.parse(formula).cursor();
  do {
    if (cursor.node.type.isError) {
      return null;
    }
    if (_isNil(cursor.node.firstChild)) {
      const component = formula.slice(cursor.from, cursor.to);
      switch (cursor.node.type.id) {
        case PI: {
          components.push('pi');
          break;
        }
        case OpeningBracket: {
          const parent = cursor.node.parent?.type.id;
          if (parent !== PiFunction) {
            if (parent === AverageFunction) {
              components.push(component);
            } else {
              components.push(` ${component}`);
            }
          }
          break;
        }
        case ClosingBracket: {
          const parent = cursor.node.parent?.type.id;
          if (parent !== PiFunction) {
            components.push(` ${component}`);
          }
          break;
        }
        case Comma: {
          components.push(component);
          break;
        }
        case Variable: {
          if (
            cursor.node.parent?.type.id !== AverageFunctionInput &&
            (options?.variablesToZero?.has(component as VariableType) ?? false)
          ) {
            components.push(` ${NaN}`); // Will be omitted from average function as it wont pass isFinite
          } else {
            components.push(` ${component}`);
          }
          break;
        }
        default:
          components.push(` ${component}`);
      }
    }
  } while (cursor.next());
  return compileFormula(components.join(''));
};

export const allInputsRequiredFormDisabled = (formula: string) => {
  const cursor = presetFormulaParser.parse(formula).cursor();
  do {
    if (cursor.node.type.isError) {
      return true;
    }
    if (cursor.node.type.id === NumericExpression && cursor.node.getChildren(Variable).length > 0) {
      const onlyAdditionOperators = cursor.node
        .getChildren(NumericOperator)
        .every((n) => n.firstChild?.node.type.id === AdditionOperator);
      if (!onlyAdditionOperators) {
        return true;
      }
    }
  } while (cursor.next());
  return false;
};

export const formDefault: Partial<PresetCreateOrUpdateV1> = {
  measurements: [
    {
      name: 'Weight',
      slug: 'weight',
      unit: 'g',
      formula: '$weight',
      inputs: [{ name: 'Weight', slug: 'weight', unit: 'g' }],
      config: {
        auto_swap: null,
        all_inputs_required: true,
        data_analysis: {
          survival: true,
          tolerance: true,
          efficacy: false,
          efficacy_prophylactic: false,
          oncology: false,
        },
      },
    },
  ],
};
