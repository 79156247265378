// @ts-nocheck: converted from JS

import { DateEditorWrapper } from '@/components/UI/SpreadSheet/editors/DateEditorWrapper';
import type { CustomGridSettings } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { _isNotEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import type { Animal, AnimalSpecies } from '@/model/Animal.model';
import type { Cage } from '@/model/Cage.model';
import type { ID } from '@/model/Common.model';
import type { MetadataField, MetadataItem } from '@/model/Metadata.model';
import { defaultMetadataToColumnSettingMapper, registerHOTModules, returnInitialMetaValues } from '@/support/hot';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import { isAfter } from 'date-fns';
import Handsontable from 'handsontable/base';

registerHOTModules();

const requiredValidater = (value: unknown, callback: (required: boolean) => void) =>
  !value ? callback(false) : callback(true);

export const addMetaColumns = (settings, metaColumns: Array<MetadataField>, metadata: Array<MetadataField>) => {
  const colHeaders = [...settings.colHeaders];
  const columns = [...settings.columns];

  metaColumns.forEach((columnMetadata) => {
    const { columnHeader, columnSettings } = defaultMetadataToColumnSettingMapper(columnMetadata);
    colHeaders.push(columnHeader);
    columns.push(columnSettings);
  });

  const newColIDs = metaColumns.map((m) => m.id);
  const newMetadata = metadata.filter((data) => !newColIDs.includes(data.id));
  return {
    settings: { ...settings, columns, colHeaders },
    metadata: newMetadata,
  };
};

export const animalCountValidater = (value: number) =>
  value <= 10 && !isNaN(value) && value !== null && value !== '' && value !== undefined;

export const initCageSettings = (
  isBulk: boolean,
  removeRow: boolean,
  metaColumns: Array<MetadataField>,
  metadata: Array<MetadataField>
) => {
  const contextMenu = removeRow ? ['remove_row'] : false;
  const settings = {
    columns: [
      { data: 'catalog', value: 'No', width: 150, readOnly: true },
      {
        data: 'name',
        value: 'Name',
        type: 'text',
        validator: requiredValidater,
        linkedValidationColumns: ['population'],
        width: 150,
      },
      {
        data: 'population',
        value: 'Population',
        type: 'numeric',
        readOnly: isBulk,
        validator: (value: number, callback: (valid: boolean) => void) => callback(animalCountValidater(value)),
        validationErrorMessage: (value) => `${value ?? 'Value'} must be a number with a maximum of 10`,
        linkedValidationColumns: ['name'],
        width: 150,
      },
    ],
    hiddenColumns: {
      columns: [0],
    },
    colHeaders: ['No', 'Cage Name', 'Population'],
    columnSorting: false,
    contextMenu,
    className: 'htMiddle',
    manualColumnResize: true,
    rowHeights: 50,
    height: 500,
    rowHeaders: true,
  };

  const updatedSettings = _isNotEmpty(metaColumns) ? addMetaColumns(settings, metaColumns, metadata) : { settings };

  return updatedSettings;
};

export const initAnimalSettings = (
  species: Array<AnimalSpecies>,
  creation: boolean,
  removeRow: boolean,
  metaColumns?: Array<MetadataField>,
  metadata?: Array<MetadataField>
) => {
  const dobValidater = (value: string, callback: (valid: boolean) => void) => {
    if (_isNotEmpty(value)) {
      const result = DateUtils.isValidDate(value) && !isAfter(new Date(value), new Date(DateUtils.dateNow()));
      return callback(result);
    }
    return callback(false);
  };
  const sexValidater = (value: 'Male' | 'Female', callback: (valid: boolean) => void) =>
    callback(_notNil(value) && (value === 'Male' || value === 'Female'));

  const speciesValidator = (value: string, callback: (valid: boolean) => void) => {
    if (_isNotEmpty(value)) {
      return callback(species.some((s) => s.name === value));
    }
    return callback(true);
  };

  const hiddenColumns = creation ? [0, 1, 3] : [0, 2, 3];
  const contextMenu = removeRow ? ['remove_row'] : false;
  const settings: CustomGridSettings = {
    columns: [
      { data: 'id', value: 'No', type: 'numeric', width: 150, readOnly: true },
      {
        data: 'cage_catalog',
        value: 'Cage No.',
        type: 'text',
        width: 150,
        readOnly: true,
      },
      {
        data: 'cage_name',
        value: 'Cage Name',
        type: 'text',
        width: 150,
        readOnly: true,
      },
      {
        data: 'catalog',
        value: 'Animal No.',
        type: 'text',
        width: 150,
        readOnly: true,
      },
      {
        data: 'name',
        value: 'Name',
        type: 'text',
        width: 150,
        validator: requiredValidater,
      },
      {
        data: 'sex',
        value: 'Sex',
        type: 'dropdown',
        source: ['Male', 'Female'],
        width: 150,
        validator: sexValidater,
      },
      {
        data: 'dob',
        value: 'D.O.B',
        type: DateEditorWrapper.type,
        dateFormat: DateInputUtils.hotDatePattern,
        restrictFutureDates: true,
        datePickerConfig: {
          maxDate: new Date(),
        },
        width: 150,
        validator: dobValidater,
        validationErrorMessage: () => 'Required in YYYY-MM-DD and must not be a future date.',
      },
      {
        data: 'species',
        value: 'Species',
        type: 'dropdown',
        width: 150,
        source: species.map((s) => s.name),
        validator: speciesValidator,
        linkedValidationColumns: ['strain'],
      },
      {
        data: 'strain',
        value: 'Strain',
        type: 'dropdown',
        width: 150,
        strict: true,
        source: [],
        validationErrorMessage: (value) =>
          'Strain is required when a Species is selected, otherwise it should be left blank.',
      },
    ],
    hiddenColumns: {
      columns: hiddenColumns,
    },
    colHeaders: ['ID', 'Cage No.', 'Cage Name', 'Animal No.', 'Animal Name', 'Sex', 'DOB', 'Species', 'Strain'],
    columnSorting: false,
    contextMenu,
    className: 'htMiddle',
    manualColumnResize: true,
    rowHeights: 50,
    height: 500,
    rowHeaders: true,
  };
  const updatedSettings = _isNotEmpty(metaColumns) ? addMetaColumns(settings, metaColumns, metadata) : { settings };
  return updatedSettings;
};

export const getMetaData = (filterType: string) =>
  Http.get(apiRoute('meta-glossary.show'), {
    params: {
      filter_type: filterType,
    },
  });

export const fetchExactCageData = (id: ID) =>
  Http.get(
    apiRoute('cage.index', {
      id,
      query: 'counts',
    })
  );

export const getMetaColumns = (selectedRows: Array<Animal> | undefined = [], metadata: Array<MetadataField>) => {
  const glossaryIDs = selectedRows?.reduce((ids, row) => {
    if (row.meta?.data) {
      const metas = row.meta.data.filter((m) => !ids.includes(m.glossary_id)).map((m) => m.glossary_id);
      ids = [...ids, ...metas];
    } else if (row.metadata) {
      const metas = row.metadata.filter((m) => !ids.includes(m.glossary_id)).map((m) => m.glossary_id);
      ids = [...ids, ...metas];
    }
    return ids;
  }, []);
  if (_isNotEmpty(metadata) && _isNotEmpty(glossaryIDs)) {
    return metadata.filter((data) => glossaryIDs.includes(data.id));
  }
  return [];
};

export const initialAddAnimalData = ({ name, catalog, counts }: Cage): Array<Animal> => [
  {
    id: 1,
    cage_name: name,
    cage_catalog: catalog,
    catalog: '',
    name: `Animal ${counts.animals + 1}`,
  },
];

/**
 * @param selectedRows {Array<Cage>|undefined}
 * @returns {Array<Cage>}
 */
export const initialBulkCageData = (selectedRows) =>
  selectedRows?.map(({ id, name, catalog, counts, meta: { data: meta } }) => {
    const cage = {
      id,
      catalog,
      name,
      population: counts.animals,
      meta: {},
    };
    if (meta) {
      cage.meta = returnInitialMetaValues(meta);
    }
    return cage;
  }) ?? [];

export const initialBulkAnimalData = (animalData, speciesObj) =>
  animalData?.map(({ id, catalog, collection, cage, name, sex, dob, strain_id: strainId, strain, meta, metadata }) => {
    const innerMeta = meta?.data ?? metadata;
    const innerCollection = collection?.data ?? cage;
    const animal = {
      id,
      catalog,
      name,
      dob,
      cage_catalog: innerCollection.catalog,
      cage_name: innerCollection.name,
      meta: {},
    };
    if (_isNotEmpty(innerMeta)) {
      animal.meta = returnInitialMetaValues(innerMeta);
    }
    if (_notNil(sex)) {
      animal.sex = sex === 'm' ? 'Male' : 'Female';
    }
    if (_notNil(strainId)) {
      speciesObj.forEach((species) => {
        species.strains.data.forEach((strain) => {
          if (strain.id === strainId) {
            animal.species = strain.species;
            animal.strain = strain.name;
          }
        });
      });
    } else if (_notNil(strain)) {
      animal.species = strain.species.name;
      animal.strain = strain.name;
    }
    return animal;
  }) ?? [];

export const validationMessages: Record<string, string> = {
  strain: 'Species is required when a Strain is selected, otherwise it should be left blank.',
  sex: 'Sex is required, select Male or Female.',
  dob: 'Required and must not be a future date.',
  name: 'Animal name is a required field.',
  population: 'No. of animals must be a valid number, of max 10.',
  numeric: 'Please enter a number for numeric metadata.',
  all: 'Please ensure you provide an Animal name, Sex and DOB for all animals.',
};

export const updateStrainSelectDropdown = (hot, species, row, selectedSpecies) => {
  const col = hot.propToCol('strain');

  const strains = [];

  if (species) {
    species.forEach((sp) => {
      if (sp.name === selectedSpecies && sp.strains) {
        sp.strains.data.forEach((strain) => {
          strains.push(strain.name);
        });
      }
    });
  }

  hot.setCellMeta(row, col, 'source', strains);
  hot.setCellMeta(row, col, 'allowEmpty', !selectedSpecies);
};

export const getSpeciesAndStrains = () =>
  Http.get(
    apiRoute('species.index', {
      query: 'strains',
    })
  );

export const afterChangeIncludeSources = ['edit', 'CopyPaste.paste', 'Autofill.fill'];

export const filterOptionsFromApi = (name: string) => name === 'Study';

export const returnColsToValidate = (
  sourceData: Array<Record<string, any>>,
  metaGlossaries: Array<MetadataItem>,
  type: string
): Array<string> => {
  // Meta won't be in sourceData unless metadata columns are added
  if (_notNil(sourceData?.[0]?.meta) && _notNil(type)) {
    const spaceRegex = /\s+/g;
    // Only have a key/value pair e.g. meta: { cage%20number: "5" }
    return Object.keys(sourceData[0].meta).reduce((acc: Array<string>, key: string) => {
      // find metadata object from meta glossaries to check field_type
      const decodedKey = safelyDecodeURIComponent(key).replace(spaceRegex, '-');
      const metaGlossary = metaGlossaries.find(({ slug }) => slug === decodedKey);
      if (_notNil(metaGlossary) && metaGlossary.field_type === type) {
        acc.push(key);
      }
      return acc;
    }, []);
  }
  return [];
};

export const checkAndValidateMetadataColumns = (
  sourceData: Array<Record<string, any>>,
  hot: Handsontable,
  metaGlossaries: Array<MetadataItem>,
  type: string,
  updatedErrors: Array<string>,
  updateCellErrors: (cellErrors: Array<string>) => React.Dispatch<any>
) => {
  const metaDataCols = returnColsToValidate(sourceData, metaGlossaries, type);
  if (_isNotEmpty(metaDataCols)) {
    hot.validateColumns(
      metaDataCols.map((col) => hot.propToCol(`meta.${col}`)),
      (valid) => {
        if (!valid) {
          updatedErrors.push(validationMessages[type]);
        }
        updateCellErrors(updatedErrors);
      }
    );
  }
};
